// 列表頁無數據時提示組件
// src/components/pageComponents/ListNoData.jsx
// created on april-18, 2019, by Tony Day
import React from "react";
import { translate } from "../../shared/translate";

export default () => (
  <div className="card-body text-center">
    <p className="lead font-italic text-muted mb-0">{translate.no_data}</p>
  </div>
);
