import React from 'react';
import Form from '../../components/form';

import WECHAT_KB from '../../assets/wechat-kb.png';
import gql from 'graphql-tag';
import Loading from '../../components/loading';
import { Query } from 'react-apollo';

const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Shop {
        menu {
          name
          url
          type
          sub_button {
            name
            url
            type
          }
        }
      }
    }
  }
`;

export default class Index extends Form {
  constructor(props) {
    super(props);
    this.state = {
      showRemoveBtn: false
    };
  }

  async submit(client) {
    console.log(this.buttons);
  }

  addButton() {
    if (this.buttons.length === 3) {
      return;
    }
    this.buttons.push({
      name: '新按鍵',
      type: 'undefined:undefined',
      url: '',
      sub_button: []
    });

    this.setState({});
  }

  removeButton() {
    if (this.state.viewingType === 'main') {
      this.buttons.splice(this.state.index, 1);
      this.setState({
        viewing: undefined,
        index: undefined,
        viewingType: undefined,
        showRemoveBtn: false
      });
    } else {
      this.buttons[this.state.parentIndex].sub_button.splice(
        this.state.index,
        1
      );
      this.setState({
        viewing: undefined,
        parentIndex: undefined,
        index: undefined,
        viewingType: undefined,
        showRemoveBtn: false
      });
    }
  }

  addSubButton(index) {
    if (this.buttons[index].sub_button.length >= 5) {
      return;
    }
    this.buttons[index].sub_button.push({
      name: '新按鍵',
      type: 'string:view',
      url: ''
    });
    this.setState({});
  }

  selectButton(
    type = this.state.viewingType,
    index = this.state.index,
    parent = this.state.parentIndex
  ) {
    if (type === 'main') {
      let viewingButton = this.buttons[index];
      this.setState({
        viewing: viewingButton,
        index: index,
        viewingType: type
      });
    } else {
      let viewingButton = this.buttons[parent].sub_button[index];
      this.setState({
        viewing: viewingButton,
        parentIndex: parent,
        index: index,
        viewingType: type
      });
    }
  }

  handleChange(field) {
    const { index, parentIndex, viewingType } = this.state || {};
    if (field === 'type') {
      this.buttons[index].type = this.getCurrentRef('type').value;
      this.buttons[index].sub_button = [];
      this.selectButton();
    } else if (field === 'name') {
      if (viewingType === 'main') {
        this.buttons[index].name = this.getCurrentRef('name').value;
      } else {
        this.buttons[parentIndex].sub_button[index].name = this.getCurrentRef(
          'name'
        ).value;
      }
      this.selectButton();
    } else {
      if (viewingType === 'main') {
        this.buttons[index].url = this.getCurrentRef('url').value;
      } else {
        this.buttons[parentIndex].sub_button[index].url = this.getCurrentRef(
          'url'
        ).value;
      }
      this.selectButton();
    }
  }

  render() {
    return (
      <div className='container-fluid mb-5'>
        <ul className='breadcrumb'>
          <li className='breadcrumb-item'>公眾號選單</li>
        </ul>

        <Query
          query={GET_QUERY}
          variables={{ id: localStorage.getItem('shopId') }}
        >
          {({
            client,
            loading,
            error: { message } = {},
            data,
            data: { node } = {},
            refetch
          }) => {
            if (loading && data !== {}) return <Loading />;

            const { menu = [] } = node || {};

            this.buttons =
              this.buttons ||
              menu.reduce((prev, { name, url, type, sub_button = [] }) => {
                const newSub = sub_button.reduce(
                  (prev, { name, url, type }) => {
                    return [
                      ...prev,
                      {
                        name,
                        url,
                        type
                      }
                    ];
                  },
                  []
                );
                return [
                  ...prev,
                  {
                    name,
                    url,
                    type,
                    sub_button: newSub
                  }
                ];
              }, []);
            return (
              <div className='row'>
                <div className='col-sm'>
                  <div className='wechat-view-container embed-responsive embed-responsive-9by16'>
                    <div className='wechat-menu row no-gutters'>
                      <div className='col-1 text-center'>
                        <img className='mt-2' src={WECHAT_KB} />
                      </div>

                      <div className='col row no-gutters'>
                        {this.buttons.map(
                          ({ name, type, sub_button = [] }, index) => {
                            const {
                              viewingType,
                              parentIndex: selectedParent,
                              index: selectedIndex
                            } = this.state;
                            return (
                              <div className='col' key={index}>
                                <div
                                  className={`wechat-menu-item ${viewingType ===
                                    'main' &&
                                    index === selectedIndex &&
                                    'active'}`}
                                  onClick={() =>
                                    this.selectButton('main', index)
                                  }
                                >
                                  {name}
                                </div>
                                {type !== 'string:view' && (
                                  <div className='wechat-submenu'>
                                    {sub_button.map(({ name }, subIndex) => {
                                      return (
                                        <div
                                          className={`wechat-menu-item ${viewingType ===
                                            'sub' &&
                                            index === selectedParent &&
                                            subIndex === selectedIndex &&
                                            'active'}`}
                                          key={subIndex}
                                          onClick={() =>
                                            this.selectButton(
                                              'sub',
                                              subIndex,
                                              index
                                            )
                                          }
                                        >
                                          {name}
                                        </div>
                                      );
                                    })}
                                    {sub_button.length < 5 && (
                                      <div
                                        className='wechat-menu-item'
                                        onClick={() => this.addSubButton(index)}
                                      >
                                        +
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                      {this.buttons.length < 3 && (
                        <div
                          className='col-1 text-center btn-add'
                          onClick={() => this.addButton()}
                        >
                          +
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='col pt-5 pt-sm-0'>
                  <div className='card'>
                    <div className='card-header d-flex'>
                      <h6 className='flex-fill'>選單功能</h6>
                      {this.state.viewing && (
                        <div className='btn-group btn-group-sm'>
                          {this.state.showRemoveBtn && (
                            <button
                              className='btn btn-danger no-animate ng-scope'
                              type='button'
                              onClick={() => this.removeButton()}
                            >
                              <i className='fa fa-fw fa-exclamation-circle' />
                              <span className='ng-scope'>刪除選單</span>
                            </button>
                          )}
                          <button
                            className='btn btn-danger'
                            type='button'
                            onClick={() =>
                              this.setState({
                                showRemoveBtn: !this.state.showRemoveBtn
                              })
                            }
                          >
                            {!this.state.showRemoveBtn && (
                              <i className='fa fa-caret-left' />
                            )}
                            {this.state.showRemoveBtn && (
                              <i className='fa fa-caret-right' />
                            )}
                            <i className='fa fa-fw fa-trash' />
                          </button>
                        </div>
                      )}
                    </div>
                    {!this.state.viewing && (
                      <div className='card-body'>
                        <p className='lead text-center text-muted'>
                          點選左方選單進行操作
                        </p>
                      </div>
                    )}
                    {this.state.viewing && (
                      <div className='card-body'>
                        <div className='form-row mb-2'>
                          <label className='col-lg-4 col-form-label'>
                            按鈕類型
                          </label>

                          <div className='col'>
                            <select
                              className='form-control'
                              disabled={this.state.viewingType === 'sub'}
                              value={this.state.viewing.type}
                              ref={this.getRef('type')}
                              onChange={() => this.handleChange('type')}
                            >
                              <option
                                label='menu-type.href'
                                value='string:view'
                              >
                                menu-type.href
                              </option>
                              <option
                                label='menu-type.menu'
                                value='undefined:undefined'
                              >
                                menu-type.menu
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className='form-row mb-2'>
                          <label className='col-lg-4 col-form-label'>
                            按鈕名稱
                          </label>

                          <div className='col'>
                            <input
                              className='form-control'
                              type='text'
                              value={this.state.viewing.name}
                              ref={this.getRef('name')}
                              onChange={() => this.handleChange('name')}
                            />
                          </div>
                        </div>

                        <div className='form-row mb-2'>
                          <label className='col-lg-4 col-form-label'>
                            頁面連結
                          </label>

                          <div className='col'>
                            <input
                              className='form-control'
                              type='url'
                              value={this.state.viewing.url}
                              ref={this.getRef('url')}
                              onChange={() => this.handleChange('url')}
                              disabled={
                                this.state.viewing.type !== 'string:view'
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='card'>
                    <div className='card-body d-flex'>
                      <button
                        className='btn btn-primary ml-auto'
                        type='button'
                        onClick={() => console.log(this.buttons)}
                      >
                        儲存
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}
