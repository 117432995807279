import React from 'react';
import { Input } from 'reactstrap';
import { translate as t } from '../../../shared/translate';
import { Checkbox } from '../../../components/omniwe';
import Options from './Options';

const GenInputTypeOptions = t => [
  {
    value: 'TEXT',
    text: t.input_type_text
  },
  {
    value: 'TEXTAREA',
    text: t.input_type_textarea
  },
  {
    value: 'CHECKBOX',
    text: t.input_type_checkbox
  },
  {
    value: 'SELECT',
    text: t.input_type_select
  }
];

const COLUMN_COUNT_WITHOUT_TRASH = 5;
const COLUMN_COUNT_WITH_TRASH = COLUMN_COUNT_WITHOUT_TRASH + 1;

export default class NewItem extends React.Component {
  constructor(props) {
    super(props);

    const { onChange } = this.props;
    this.onChange = onChange;
    this.InputTypeOptions = GenInputTypeOptions(t);

    const {
      item: { type }
    } = this.props;
    this.state = {
      optionsVisible: type === 'SELECT'
    };
  }

  handleOptionsChange = options => {
    // console.log('handleOptionsChange', options);
    const mockEvent = {
      target: {
        value: options
      }
    };
    this.onChange(mockEvent, 'options');
  };

  handleDataTypeChange = e => {
    const {
      target: { value }
    } = e;
    this.onChange(e, 'type');

    if (value === 'SELECT') {
      this.setState({
        optionsVisible: true
      });
    } else {
      const optionsMackEvent = {
        target: {
          value: []
        }
      };
      this.onChange(optionsMackEvent, 'options');
      this.setState({
        optionsVisible: false
      });
    }
  };

  render() {
    const { showActionColumn = false, item, removeItem, onChange } = this.props;
    const {
      name,
      description,
      type,
      sortIndex,
      required,
      display,
      options
    } = item;
    const { optionsVisible } = this.state;

    console.log('item', item);

    return (
      <React.Fragment>
        <tr>
          <td>
            <Input
              type='text'
              placeholder={t.name}
              value={name}
              onChange={e => onChange(e, 'name')}
              required
            />
          </td>
          <td>
            <Input
              type='select'
              value={type}
              onChange={this.handleDataTypeChange}
            >
              {this.InputTypeOptions.map(({ value, text }) => (
                <option value={value} key={value}>
                  {text}
                </option>
              ))}
            </Input>
          </td>
          <td>
            <Input
              type='number'
              maxLength={4}
              value={sortIndex}
              onChange={e => onChange(e, 'sortIndex')}
              required
            />
          </td>
          <td className='text-center'>
            <Checkbox
              defaultChecked={required}
              onChange={e => onChange(e, 'required')}
            />
          </td>
          <td className='text-center'>
            <Checkbox
              defaultChecked={display}
              onChange={e => onChange(e, 'display')}
            />
          </td>
          {showActionColumn && (
            <td>
              <button
                onClick={removeItem}
                type='button'
                className='btn btn-link text-danger btn-sm'
              >
                <i className='fa fa-times' />
              </button>
            </td>
          )}
        </tr>
        <tr>
          <td
            colSpan={
              showActionColumn
                ? COLUMN_COUNT_WITHOUT_TRASH
                : COLUMN_COUNT_WITH_TRASH
            }
            className='border-top-0'
          >
            <Input
              type='text'
              maxLength={200}
              placeholder={t.remark}
              value={description}
              onChange={e => onChange(e, 'description')}
            />
          </td>
          {showActionColumn && <td className='border-top-0'>&nbsp;</td>}
        </tr>
        {optionsVisible && (
          <tr>
            <td
              colSpan={
                showActionColumn
                  ? COLUMN_COUNT_WITHOUT_TRASH
                  : COLUMN_COUNT_WITH_TRASH
              }
              className='border-top-0'
            >
              <Options items={options} onChange={this.handleOptionsChange} />
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}
