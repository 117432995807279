import { translate } from './translate';

export default (e = '', defaultMessage = '操作失敗　請重試') => {
  let msg = e.message || e;

  msg = msg.replace(/^GraphQL error:/g, '').trim();
  msg = msg.replace(/Error:/g, '').trim();

  const errors = translate['errors'] || {};
  console.log(msg, errors);
  return errors[msg] || defaultMessage;
};
