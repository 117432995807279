import React from 'react';
import PropTyppes from 'prop-types';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import FormCard from './Cards/_formCard';
import ImageCard from './Cards/_imageCard';
import ImagesCard from './Cards/_imagesCard';
import HtmlCard from './Cards/_htmlCard';
import { Loading } from 'components/pageComponents/utility';
import GenericForm from 'components/GenericForm';
import FormToolbar from 'components/form/FormToolbar';
import ActivityIndicator from 'components/ActivityIndicator';
import QueryParamsWrapper from 'components/QueryParamsWrapper';
import { translate } from 'shared/translate';
import { Formik } from 'formik';
import { client } from 'shared/apollo';
import errorParser from 'shared/errorParser';

export default class FormPageMaker extends QueryParamsWrapper {
  constructor(props) {
    super(props);

    const { match: { params = {} } = {} } = this.props;
    const id = params.id === 'new' ? undefined : params.id;

    /* You Should Overwrite this state setting */
    this.state = {
      id,
      breadcrumb: [{ name: 'test', link: '/' }],
      gql: {
        prepare: '',
        submit: '',
        remove: '',
      },
      cards: [
        {
          name: 'Card 1',
          fields: [
            {
              label: 'name',
              type: 'text',
              name: 'name',
              required: true,
              maxLength: 255,
              render: ({ values, handleChange }) => 123,
              text: 'yes',
            },
          ],
        },
      ],
      enableReinitialize: false,
    };
  }

  submit = async values => {
    return '';
  };

  getInitialData(data) {
    return {};
  }

  onSubmitClick = async (values, { setSubmitting, ...actions }) => {
    const {
      history,
      location: { pathname },
    } = this.props;
    const { id } = this.state;

    try {
      ActivityIndicator.show();
      setSubmitting(true);
      const newId = await this.submit(values, { ...actions, setSubmitting });
      toast.success(!!id ? translate.update_success_msg : translate.create_success_msg);

      if (newId === true) history.goBack();
      else if (newId === false) {
      } else if (!!newId) {
        history.replace(pathname.replace('new', newId));
      }
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
      ActivityIndicator.hide();
    }
  };

  onCancelClick = () => {
    const { history } = this.props;
    history.goBack();
  };

  onRemoveClick = async () => {
    const { history } = this.props,
      {
        id,
        gql: { remove },
      } = this.state;
    try {
      ActivityIndicator.show();
      await client.mutate({
        mutation: remove,
        variables: { id },
      });
      await client.resetStore();
      toast.success(translate.remove_success_msg);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      history.goBack();
      ActivityIndicator.hide();
    }
  };
  getExtraFetchVariables() {
    return {};
  }

  renderExtraButtons(actions) {
    return null;
  }

  render() {
    const {
      id,
      gql: { prepare },
    } = this.state;
    if (!!prepare)
      return (
        <Query query={prepare} variables={{ id, ...this.getExtraFetchVariables() }}>
          {this.renderContent.bind(this)}
        </Query>
      );
    return this.renderContent.bind(this)({});
  }

  renderContent({ loading, data = {} } = {}) {
    const { breadcrumb } = this.state,
      { className } = this.props;

    if (loading) return <Loading />;
    this.initialValues = this.getInitialData(data);
    return (
      <GenericForm breadcrumb={breadcrumb} className={className}>
        {this.renderForm({ ...this.initialValues })}
      </GenericForm>
    );
  }

  renderCard(card, actions, i) {
    if (typeof card === 'function' && (!!card.prototype.isReactComponent || String(card).includes('createElement'))) {
      const ControlModule = card;
      return <ControlModule key={i} {...this.props} {...actions} />;
    }

    const { name, type = 'form', render, fields = [], field = {} } = card;
    const {
      id,
      gql: { remove },
    } = this.state;
    if (render) return <div key={i}>{render(actions)}</div>;
    const onRemoveClick = !!remove && !!id && i === 0 && this.onRemoveClick;
    const extra = i === 0 && this.renderExtraButtons(actions);
    const props = { key: i, name, field, fields, actions, onRemoveClick, extra };
    switch (type) {
      case 'image':
        return <ImageCard {...props} />;
      case 'html':
        return <HtmlCard {...props} />;
      case 'images':
        return <ImagesCard {...props} />;
      case 'form':
        return <FormCard {...props} />;
      default:
        return null;
    }
  }

  renderOthers(data, actions) {
    return null;
  }

  renderForm(initialValues) {
    const { cards, enableReinitialize } = this.state;
    return (
      <Formik enableReinitialize={enableReinitialize} initialValues={initialValues} onSubmit={this.onSubmitClick}>
        {actions => (
          <form onSubmit={actions.handleSubmit}>
            {cards.map((card, i) => this.renderCard(card, actions, i))}
            {this.renderOthers(initialValues, actions)}
            {this.renderFormToolBar(initialValues, actions)}
          </form>
        )}
      </Formik>
    );
  }
  renderFormToolBar(initialValues) {
    return (
      <FormToolbar
        onSave={null}
        submitBtnType={'submit'}
        onCancel={this.onCancelClick}
        updatedAt={initialValues.updatedAt}
      />
    );
  }
}
FormPageMaker.propTypes = {
  className: PropTyppes.string,
};
FormPageMaker.defaultProps = {
  className: 'mb-5',
};
