import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Service {
        id
        createdAt
        updatedAt
        name
        price
        group
        display
        description
        images
        active
        items {
          since
          valid
          active
          duration
          summary
          productVariant {
            id
            sku
            product {
              id
              name
            }
            attributes {
              key
              value
            }
          }
        }
        attributes {
          key
          value
        }
        venues {
          id
          name
        }
      }
    }
  }
`;

export const SET_QUERY = gql`
  mutation($id: ID, $input: ServiceInput!) {
    serviceSet(id: $id, input: $input) {
      id
    }
  }
`;

export const UNSET_QUERY = gql`
  mutation($id: ID!) {
    serviceUnset(id: $id) {
      id
    }
  }
`;
