import React from 'react';
import { translate } from 'shared/translate';
import FormPageMaker from '../../components/formPageMaker';
import gql from 'graphql-tag';
import { client } from 'shared/apollo';
import { GET_QUERY, SUBMIT_QUERY } from './query';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    breadcrumb: [
      { name: translate.settings, link: '/settings' },
      {
        name: translate.settings_title_payment_providers,
        link: '/settings/payments',
      },
      { name: translate.swiftpass },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
      remove: '',
    },
    cards: [
      {
        name: translate.activate_services,
        fields: [
          {
            label: translate.wechat_pay,
            type: 'checkbox',
            name: 'active_wechat_pay',
            text: translate.activate_checkbox,
          },
          {
            label: translate.alipay,
            type: 'checkbox',
            name: 'active_alipay',
            text: translate.activate_checkbox,
          },
        ],
      },
      {
        name: translate.account_details,
        fields: [
          {
            label: translate.swiftpass_app_id,
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: translate.swiftpass_secret,
            type: 'text',
            name: 'secret',
            required: true,
            maxLength: 255,
          },
          {
            label: translate.swiftpass_merchant_id,
            type: 'text',
            name: 'merchant_id',
            required: true,
            maxLength: 255,
          },
        ],
      },
    ],
  };

  submit = async ({ wechat = {}, alipay = {}, active_alipay, active_wechat_pay, merchant_id, identity, secret }) => {
    const {
      gql: { submit },
    } = this.state;
    const attributes = [
      {
        key: 'merchant_id',
        value: merchant_id,
      },
    ];

    await client.mutate({
      mutation: submit,
      variables: {
        shopId: localStorage.getItem('shopId'),
        input: {
          id: wechat.id,
          active: !!active_wechat_pay,
          platform: 'SWIFTPASS_WECHAT_PAY',
          type: 'PAYMENT',
          identity,
          secret,
          attributes,
        },
      },
    });
    await client.mutate({
      mutation: submit,
      variables: {
        shopId: localStorage.getItem('shopId'),
        input: {
          id: alipay.id,
          active: !!active_alipay,
          platform: 'SWIFTPASS_ALIPAY',
          type: 'PAYMENT',
          identity,
          secret,
          attributes,
        },
      },
    });
  };

  getInitialData({ node } = {}) {
    const { credentials = [] } = node || {};

    const credential_wechat_pay = credentials.find(cred => cred.platform === 'SWIFTPASS_WECHAT_PAY') || {},
      credential_alipay = credentials.find(cred => cred.platform === 'SWIFTPASS_ALIPAY') || {};

    const { attributes = [] } = credential_wechat_pay;
    const merchant_id = (attributes.find(attr => attr.key === 'merchant_id') || {}).value;

    const { identity, secret } = credential_wechat_pay || {};

    return {
      wechat: credential_wechat_pay,
      alipay: credential_alipay,
      active_wechat_pay: credential_wechat_pay.active,
      active_alipay: credential_alipay.active,
      merchant_id,
      identity,
      secret,
    };
  }
}
