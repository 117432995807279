import React, { Component, Fragment } from 'react';
import { translate } from '../../shared/translate';
import { Locale } from './../../shared/supported-locale';
import GenericForm from '../../components/GenericForm';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import PaymentRow from './_paymentRow';
import StripeConnectRegisterModal from './_stripeConnectRegisterModal';
import { GET_QUERY } from './query';
import ManualCard from './ManualCard';

export default class extends Component {
  isHKD(locale) {
    const currentLocale = Locale.fromCode(locale);
    return currentLocale.currency === 'HKD';
  }

  onCompleteMerchantFormClick = async () => {
    const { history } = this.props;
    history.push('/settings/merchant_info');
  };

  onStripeConnectRegister = async () => {
    const { history } = this.props;
    history.push('/settings/payments/stripe_connect');
    // try {
    //   await this.stripeConnectRegisterModal.start();
    // } catch (e) {}
  };
  onStripeConnectClick = async () => {
    const { history } = this.props;
    history.push('/settings/payments/stripe_connect');
  };
  onStripeRegister = async () => {
    const { history } = this.props;
    history.push('/settings/payments/stripe');
  };
  onStripeClick = async () => {
    const { history } = this.props;
    history.push('/settings/payments/stripe');
  };
  onChinaSmartPayRegister = async () => {
    const { history } = this.props;
    history.push('/settings/payments/china_smart_pay');
  };
  onChinaSmartPayClick = async () => {
    const { history } = this.props;
    history.push('/settings/payments/china_smart_pay');
  };
  onSwiftPassCRegister = async () => {
    const { history } = this.props;
    history.push('/settings/payments/swift_pass');
  };
  onSwiftPassClick = async () => {
    const { history } = this.props;
    history.push('/settings/payments/swift_pass');
  };

  render() {
    return (
      <GenericForm
        breadcrumb={[
          { name: translate.settings, link: '/settings' },
          { name: translate.settings_title_payment_providers },
        ]}
      >
        <Query query={GET_QUERY} fetchPolicy={'cache-and-network'} variables={{ id: localStorage.getItem('shopId') }}>
          {({ data: { node } = {} }) => {
            const { companyVerifyData, credentials = [] } = node || {};
            const hasCompleteMerchantForm = !!companyVerifyData && !!companyVerifyData.merchant_name;

            return (
              <Fragment>
                <div className="alert alert-secondary d-flex flex-row align-items-center" role="alert">
                  <span className={'mr-4'}>{translate.please_fill_form_before_payment_setting}</span>
                  <Link to={'/settings/merchant_info'}>
                    <button className={'btn btn-info'}>
                      {hasCompleteMerchantForm ? translate.view : translate.act_immediately}
                    </button>
                  </Link>
                </div>
                <ul className={'list-group'}>
                  <li className={'list-group-item'}>
                    <PaymentRow
                      source={require('../../assets/unopay.jpg')}
                      title={'UNOPAY'}
                      description={
                        'The payments platform for platforms\n' +
                        'Marketplaces and platforms use Stripe Connect to accept money and pay out to third parties. Connect provides a complete set of building blocks to support virtually any business model, including on-demand businesses, e‑commerce, crowdfunding, and travel and events.'
                      }
                      onRegister={this.onStripeConnectRegister}
                      onClick={this.onStripeConnectClick}
                      register={!credentials.find(cred => ~['STRIPE_CONNECT_CUSTOM'].indexOf(cred.platform))}
                    />
                    <StripeConnectRegisterModal ref={ref => (this.stripeConnectRegisterModal = ref)} />
                  </li>
                  <li className={'list-group-item'}>
                    <PaymentRow
                      source={require('../../assets/stripe_pay.jpg')}
                      title={'Stipe: The new standard in online payments'}
                      description={
                        'Stripe is the best software platform for running an internet business. We handle billions of dollars every year for forward-thinking businesses around the world.'
                      }
                      onRegister={this.onStripeRegister}
                      onClick={this.onStripeClick}
                      register={!credentials.find(cred => ~['STRIPE_CREDIT_CARD'].indexOf(cred.platform))}
                      link={'https://stripe.com/'}
                    />
                  </li>
                  <li className={'list-group-item'}>
                    <PaymentRow
                      source={require('../../assets/china_smart_pay.jpg')}
                      title={'中国支付通: 创新支付服务'}
                      description={
                        '在预付卡、互联网支付、跨境支付等领域，满足不同行业支付需求、为企业提供个性化支付解决方案。'
                      }
                      onRegister={this.onChinaSmartPayRegister}
                      onClick={this.onChinaSmartPayClick}
                      register={
                        !credentials.find(
                          cred => ~['CHINA_SMARTPAY_WECHAT_PAY_HK', 'CHINA_SMARTPAY_ALIPAY_HK'].indexOf(cred.platform),
                        )
                      }
                      link={'http://www.chinasmartpay.com'}
                    />
                  </li>
                  <li className={'list-group-item'}>
                    <PaymentRow
                      source={require('../../assets/swiftpass.jpg')}
                      title={'Swift Pass: THE NEXT PAYMENT ECOSYSTEM'}
                      description={
                        'Our team provides professional industry consulting and analysis services, advanced system solutions, comprehensive marketing strategies, reliable system operation, and maintenance services.'
                      }
                      onRegister={this.onSwiftPassCRegister}
                      onClick={this.onSwiftPassClick}
                      register={!credentials.find(cred => ~['SWIFTPASS_WECHAT_PAY'].indexOf(cred.platform))}
                      link={'http://www.swiftpassglobal.com'}
                    />
                  </li>
                </ul>
              </Fragment>
            );
          }}
        </Query>
        <ManualCard />
      </GenericForm>
    );
  }
}
