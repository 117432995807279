import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: String!) {
    me {
      id
      billings(ids: [$id]) {
        id
        name
        brand
        exp_month
        exp_year
        last4
        isDefault
        address_country
        address_line1
      }
    }
  }
`;

export const CREATE_QUERY = gql`
  mutation billingCreate($input: String!) {
    billingCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation billingUpdate($id: String!, $input: BillingInput!) {
    billingUpdate(id: $id, input: $input) {
      id
      name
      brand
      exp_month
      exp_year
      last4
      isDefault
      address_country
      address_line1
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation($id: ID!) {
    billingUnset(id: $id) {
      id
    }
  }
`;
