import React, { Fragment } from 'react';
import RemoveToggle from '../../form/RemoveToggle';
import { translate } from 'shared/translate';
import TinyComponent from '../../tinyComponent';
import Card from '../../layout/card/Card';
import CardHeader from '../../layout/card/CardHeader';
import CardBody from '../../layout/card/CardBody';

export default ({
  extra,
  name,
  field,
  onRemoveClick,
  actions: { values, handleChange, setFieldValue, isSubmitting },
}) => {
  const { name: fieldName, render, ...props } = field;
  return (
    <Card>
      <CardHeader
        title={name}
        extra={
          <Fragment>
            {extra}
            {onRemoveClick && <RemoveToggle title={translate.confirm_to_delete} onClick={onRemoveClick} />}
          </Fragment>
        }
      />
      <CardBody className={'p-0'}>
        {!!render ? (
          render({ values, handleChange, setFieldValue, isSubmitting })
        ) : (
          <TinyComponent
            value={values[fieldName]}
            onChange={c => setFieldValue(fieldName, c)}
            disabled={isSubmitting}
            {...props}
          />
        )}
      </CardBody>
    </Card>
  );
};
