// 中介服務申請列表（中介查看）
// src/routers/agent-application/index.js
// created on april-25, 2019, by Tony Day
import React from 'react';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import { Button, CardHeader, Card } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import * as PU from '../../components/pageComponents/utility';
import ListComponent from '../../components/ListComponent';
import ListBase from '../../components/ListBase';
import ServiceInfo from './service_info';
import ApplicationDetails from './ApplicationDetails';

const GET_QUERY = gql`
  # AGENT QUERN APPLICATION OF SERVICE
  query($id: ID, $offset: OffsetInput) {
    node(id: $id) {
      id
      ... on AgentService {
        id
        applications(offset: $offset) {
          id
          createdAt
          updatedAt
          status
          comments {
            content
            createdAt
          }
          attributes {
            key
            value
          }
          shop {
            title
          }
        }
        applicationsCount
      }
    }
  }
`;

const TableHeader = () => (
  <thead>
    <tr>
      <th style={{ width: 100 }} className='border-top-0 text-truncate'>
        {translate.operations}
      </th>
      <th className='border-top-0 text-truncate'>{translate.shop_name}</th>
      <th className='border-top-0 text-truncate'>{translate.contact_name}</th>
      <th className='border-top-0 text-truncate'>{translate.contact_number}</th>
      <th style={{ width: 100 }} className='border-top-0 text-truncate'>
        {translate.status}
      </th>
      <th
        style={{ width: 140 }}
        className='border-top-0 text-right text-truncate'
      >
        {translate.created_at}
      </th>
      <th
        style={{ width: 140 }}
        className='border-top-0 text-right text-truncate'
      >
        {translate.updated_at}
      </th>
    </tr>
  </thead>
);

const RenderItem = item => {
  const getContactName = () => {
    const found = _.find(item.attributes, { key: 'contact_name' });
    return found ? found.value : '-';
  };
  const getContactNumber = () => {
    const found = _.find(item.attributes, { key: 'contact_number' });
    return found ? found.value : '-';
  };

  return (
    <tr>
      <td>
        <Button size='sm' onClick={item.view}>
          {translate.view}
        </Button>
      </td>
      <td>
        <Link to={'agent_service/' + item.id}>{item.shop.title}</Link>
      </td>
      <td>{getContactName()}</td>
      <td>{getContactNumber()}</td>
      <td>{item.status}</td>
      <td className='text-right text-truncate'>
        <PU.DateTime datetime={item.createdAt} />
      </td>
      <td className='text-right text-truncate'>
        <PU.DateTime datetime={item.updatedAt} />
      </td>
    </tr>
  );
};

// const _PERM = "agent_service.read";
export default class MyList extends ListBase {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { id }
      }
    } = props;

    this.offset = {
      skip: 0,
      limit: 20
    };

    this.variables = {
      offset: this.offset,
      filter: this.filterInput,
      id
    };
    this.id = id;
    this.state = { modalShow: false };
  }

  getData = data => {
    const { node } = data || {};
    const { applications = [], applicationsCount = 0 } = node || {};
    const obj = { items: applications, count: applicationsCount };
    return obj;
  };

  viewApplicationDetails = appId => {
    const { history } = this.props;
    console.log('app id', appId);
    history.push(`/agent_service_application/${this.id}/${appId}`);
  };

  renderItem = item => {
    return (
      <RenderItem {...item} view={() => this.viewApplicationDetails(item.id)} />
    );
  };

  list = () => {
    return (
      <Card>
        <CardHeader>
          <h6>{translate.all_applications}</h6>
        </CardHeader>
        <ListComponent
          title={translate.all_applications}
          query={GET_QUERY}
          getData={this.getData}
          variables={this.variables}
          renderItem={this.renderItem}
          tableHeader={TableHeader}
          offset={this.offset}
        />
      </Card>
    );
  };

  render() {
    // const { match: { params: { appId } } }  = this.props;
    return (
      <PU.PageBody>
        <ServiceInfo id={this.id} />
        <Switch>
          <Route
            exact
            path='/agent_service_application/:id'
            component={this.list}
          />
          <Route
            exact
            path='/agent_service_application/:id/:appId'
            component={ApplicationDetails}
          />
        </Switch>
      </PU.PageBody>
    );
  } // end render()
} // end class
