import { gql } from 'apollo-boost';

export const SET_QUERY = gql`
  mutation customPageSet($id: ID, $input: CustomPageInput!) {
    customPageSet(id: $id, input: $input) {
      id
      name
      href
      attributes {
        key
        value
      }
      updatedAt
      createdAt
    }
  }
`;

export const GET_PDF_DOWNLOAD_HREF = gql`
  mutation($id: ID) {
    promotionRecordExport(id: $id)
  }
`;

export const UNSET_CUSTOM_PAGE = gql`
  mutation($id: ID!) {
    customPageUnset(id: $id) {
      id
      name
    }
  }
`;

// export const UNSET_QUERY = gql`
//   #DELETE VENUE
//   mutation($id: ID!) {
//     venueUnset(id: $id) {
//       id
//     }
//   }
// `;

export const DETAILS_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on CustomPage {
        id
        name
        href
        active
        display
        promotionRecordsCount
        body {
          name
          config
        }
      }
    }
  }
`;
