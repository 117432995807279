import React, { useState } from 'react';
import { client } from '../../shared/apollo';
import { gql } from 'apollo-boost';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { translate as t } from '../../shared/translate';
import { Icon, Loading } from '../../components/pageComponents/utility';
import PapaParse from 'papaparse';
import { faFile, faUpload } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

export const PRODUCT_SET = gql`
  mutation($input: ProductInput!) {
    productSet(input: $input) {
      id
    }
  }
`;

const Wrapper = styled.div`
  border: dashed 2px #dee2e6;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;

  :hover {
    border-color: #2eb89d;
    cursor: pointer;
    color: #2eb89d;
  }

  .t-italic {
    font-style: italic;
  }
`;

let data;
export default ({ modalOpen, toggle, shopId }) => {
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState(null);
  const [updateDataCount, setUploadDataCount] = useState({ total: 0, uploaded: 0 });
  const fileInput = React.createRef();
  const openFileSelector = () => {
    if (filename) {
      this.fileInput.current.value = '';

      return;
    }

    fileInput.current.click();
  };

  const handleChangeFile = e => {
    data = undefined;
    let reader = new FileReader();
    if (e.target.files.length > 0) {
      const filename = e.target.files[0].name;
      setFilename(filename);
      reader.onload = event => {
        const csvData = PapaParse.parse(
          event.target.result,
          Object.assign(
            {},
            {
              //error: Error
              encoding: 'UTF-8',
            },
          ),
        );

        const res = csvData.data;

        const arr = [];
        for (let i = 1; i < res.length; i++) {
          let obj = {};
          obj[res[0][0]] = res[i][0];
          obj[res[0][1]] = res[i][1];
          obj[res[0][2]] = res[i][2];
          obj[res[0][3]] = res[i][3];
          obj[res[0][4]] = JSON.parse(res[i][4]);
          obj[res[0][5]] = res[i][5];
          obj[res[0][6]] = JSON.parse(res[i][6]);
          obj[res[0][7]] = JSON.parse(res[i][7]);
          obj[res[0][8]] = res[i][8];
          arr.push(obj);
        }
        data = arr;
      };

      reader.readAsText(e.target.files[0], 'UTF-8');
    }
  };

  const submit = async () => {
    setLoading(true);
    if (!data) {
      toast.warn(t.pls_select_file);
      return;
    }
    setUploadDataCount({ uploaded: 0, total: data.length });

    try {
      for (let i = 0; i < data.length; i++) {
        setUploadDataCount({ total: data.length, uploaded: i });
        const obj = data[i];
        await client.mutate({
          mutation: PRODUCT_SET,
          variables: {
            input: {
              shopId,
              name: obj.name,
              weight: obj.weight || 0,
              sortIndex: obj.sortIndex || 0,
              description: obj.description,
              newImages: obj.newImages || [],
              active: obj.active,
              variations: obj.variations || [],
              attributes: obj.attributes || [],
              imageUploads: [],
              remarkSet: obj.remarkSet || '[]',
            },
          },
        });
      }
      toast.success(t.importing_submit);
      toggle();
    } catch (e) {
      toast.error(t.submit_failed);
      console.log(e);
    }
    setLoading(false);
    setFilename(null);
  };

  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>{t.import}</ModalHeader>
      <React.Fragment>
        <ModalBody>
          {loading && (
            <React.Fragment>
              <Loading />
              <div className="text-center" key={updateDataCount.uploaded}>
                Importing... {updateDataCount.uploaded}/{updateDataCount.total}
              </div>
            </React.Fragment>
          )}
          {!loading && (
            <div>
              <p>{t.upload_csv_for_importing}</p>
              <Wrapper onClick={openFileSelector}>
                <p style={{ height: '1px' }}>
                  <input
                    className="csv-reader-input"
                    style={{ visibility: 'hidden' }}
                    type="file"
                    onChange={e => handleChangeFile(e)}
                    ref={fileInput}
                  />
                </p>
                {filename && (
                  <div>
                    <Icon icon={faFile} size="2x" margin />
                    {filename}
                  </div>
                )}
                {!filename && <Icon icon={faUpload} size="2x" />}
              </Wrapper>
            </div>
          )}
        </ModalBody>
        {!loading && (
          <ModalFooter className="d-flex justify-content-between">
            <Button color="light" onClick={toggle} className="shadow">
              {t.close}
            </Button>
            <Button onClick={() => submit()} color="primary" style={{ minWidth: '100px' }}>
              {t.submit}
            </Button>
          </ModalFooter>
        )}
      </React.Fragment>
    </Modal>
  );
};
