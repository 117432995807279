import ISO6391 from 'iso-639-1';
import currency from 'locale-currency';

export class Locale {
  constructor({ languageCode, countryCode }) {
    this._languageCode = languageCode.toLowerCase();
    this._countryCode = countryCode.toLowerCase();
    Locale.validateLanguage(this._languageCode);
    Locale.validateCountry(this._countryCode);
  }

  static validateLanguage(lang) {
    if (!ISO6391.validate(lang))
      throw new Error(
        `Invalid language code given "${lang}" it should be 2 letters following ISO-639-1 standard`
      );
  }

  static validateCountry(country) {
    if (!country) throw new Error(`Empty country code was given`);
    if (country.length !== 2)
      throw new Error(`Country code should be exactly 2 letters`);
  }

  static fromCode(code) {
    const [languageCode, countryCode] = code.split('-');
    return new Locale({ languageCode, countryCode });
  }

  get code() {
    return `${this._languageCode}_${this._countryCode}`;
  }

  get currency() {
    return currency.getCurrency(this.code);
  }

  get lang() {
    return this._languageCode;
  }

  get country() {
    return this._countryCode;
  }

  get display() {
    return ISO6391.getName(this._languageCode);
  }

  get displayInNative() {
    return ISO6391.getNativeName(this._languageCode);
  }
}

const SupportedLocale = {
  CHINESE_TRAD_HK: new Locale({ languageCode: 'zh', countryCode: 'hk' }),
  ENGLISH_HK: new Locale({ languageCode: 'en', countryCode: 'hk' }),
  ENGLISH_US: new Locale({ languageCode: 'en', countryCode: 'us' })
};

export default SupportedLocale;
