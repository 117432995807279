import React from 'react';

export default ({ config: { images = [] } = {} }) => {
  return (
    <div className={'row no-gutters p-3 justify-content-center'}>
      <div className={'col-4 d-flex flex-column'}>
        <div className={'p-1 flex-fill'}>
          <ImagePreview src={images[0]} />
        </div>
        <div className={'row no-gutters flex-fill'}>
          <div className={'col-6 p-1'}>
            <ImagePreview src={images[1]} />
          </div>
          <div className={'col-6 p-1'}>
            <ImagePreview src={images[2]} />
          </div>
        </div>
      </div>
      <div className={'col-4'}>
        <div className={'embed-responsive embed-responsive-1by1'}>
          <div className={'embed-responsive-item p-1'}>
            <ImagePreview src={images[3]} />
          </div>
        </div>
      </div>
      <div className={'col-4 d-flex flex-column'}>
        <div className={'row no-gutters flex-fill'}>
          <div className={'col-6 p-1'}>
            <ImagePreview src={images[4]} />
          </div>
          <div className={'col-6 p-1'}>
            <ImagePreview src={images[5]} />
          </div>
        </div>
        <div className={'p-1 flex-fill'}>
          <ImagePreview src={images[6]} />
        </div>
      </div>
    </div>
  );
};

const ImagePreview = ({ src }) => (
  <div
    className={'w-100 h-100'}
    style={{
      background: `url(${src || require('../../../../assets/gray.jpg')}) center center`,
      backgroundSize: 'cover',
    }}
  />
);
