import React from 'react';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { translate as t } from '../../shared/translate';
import { Loading, toastError, toastSuccess } from '../../components/pageComponents/utility';

const POST_QUERY = gql`
  # send product export request
  mutation($shopId: ID!, $filter: FilterInput, $otherFilter: ProductFilterInput) {
    productExport(shopId: $shopId, filter: $filter, otherFilter: $otherFilter)
  }
`;

export default ({ modalOpen, toggle, shopId, numberOfReocrd, filterObject }) => {
  const submit = async post => {
    const { filter, otherFilter } = filterObject();
    const variables = {
      shopId,
      filter,
      otherFilter,
    };
    try {
      const {
        data: { productExport },
      } = (await post({ variables })) || {};
      console.log('resp', productExport);

      if (productExport) {
        toastSuccess(t.importing_submit);
        toggle();
      } else {
        toastError(t.submit_failed);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>{t.export}</ModalHeader>
      <Mutation mutation={POST_QUERY}>
        {(post, { loading }) => (
          <React.Fragment>
            <ModalBody>
              {loading && <Loading />}
              {!loading && (
                <div>
                  <p>{t.export_tips}</p>
                  {!_.isUndefined(numberOfReocrd) && <p>{t.formatString(t.about_x_records, numberOfReocrd)}</p>}
                </div>
              )}
            </ModalBody>
            {!loading && (
              <ModalFooter className="d-flex justify-content-between">
                <Button color="light" onClick={toggle} className="shadow">
                  {t.close}
                </Button>
                <Button onClick={() => submit(post)} color="primary" style={{ minWidth: '100px' }}>
                  {t.submit}
                </Button>
              </ModalFooter>
            )}
          </React.Fragment>
        )}
      </Mutation>
    </Modal>
  );
};
