import React, { Component } from 'react';
import { Icon } from '../../../iconRender';

const Field = ({ label, data, span = 2 }) => (
  <div className="row mb-2">
    <div className={`col-${span}`}>{label}</div>
    <div className="col">{data}</div>
  </div>
);

const formatFieldLabel = (label, icon) => (
  <span>
    <Icon icon={icon} className="mr-1" />
    <span style={{ whiteSpace: 'pre' }}>{(label || '').trim() || '-'}</span>
  </span>
);

const makeLinkable = (type, link, body) => {
  // only make it linkable if there is content
  if ((link || '').trim()) {
    return <a href={`${type}:${link}`}>{body}</a>;
  } else return body;
};

class AddressBody extends Component {
  constructor(props) {
    super(props);
    const { data = {} } = this.props;

    const { person = '', email = '', tel = '', address, lines } = data;

    this.fields = [
      { label: 'Name', data: formatFieldLabel(person, 'faUser') },
      {
        label: 'Email',
        data: makeLinkable('mailto', email, formatFieldLabel(email, 'faEnvelope')),
      },
      {
        label: 'Contact',
        data: makeLinkable('tel', tel, formatFieldLabel(tel, 'faPhone')),
      },
      { label: 'Address', data: formatFieldLabel(lines ? lines.join('\n') : address || '', 'faMapMarkerAlt') },
    ];
  }
  render() {
    const { span } = this.props;
    return (
      <React.Fragment>
        {this.fields.map(f => (
          <Field key={f.label} label={f.label} data={f.data} span={span} />
        ))}
      </React.Fragment>
    );
  }
}

export default AddressBody;
