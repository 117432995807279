import React, { Component } from 'react';
import { Form, FormGroup, Input, Modal, Row, Col } from 'reactstrap';
import Button from '../../components/form/Button';
import { Formik } from 'formik';
import { client } from '../../shared/apollo';
import QueryParamsWrapper from '../../components/QueryParamsWrapper';
import { toast } from 'react-toastify';
import errorParser from '../../shared/errorParser';
import { Icon } from '../../components/iconRender';
import Copyright from '../../components/copyright';
import { translate } from '../../shared/translate';
import { GET_GROUPS, SESSION_CREATE } from './query';
import { Link } from 'react-router-dom';
import './styles.css';

export default class extends QueryParamsWrapper {
  submit = async (values, { setSubmitting }) => {
    try {
      await client.resetStore();
      localStorage.removeItem('token');
      const {
        data: { sessionCreate },
      } = await client.mutate({
        mutation: SESSION_CREATE,
        variables: values,
      });
      localStorage.setItem('token', sessionCreate);
      await this.onSubmit();
    } catch (e) {
      toast.error(errorParser(e));
      setSubmitting(false);
    }
  };
  onSubmit = async () => {
    const { history } = this.props;

    const {
      data: {
        me: { groups = [] },
      },
    } = await client.query({
      query: GET_GROUPS,
      fetchPolicy: 'no-cache',
    });
    const allowAgentPanel = !!groups.find(group => group.shop === null);
    if (!allowAgentPanel) {
      const firstGroup = groups.find(group => !!group.shop);
      firstGroup && localStorage.setItem('shopId', firstGroup.shop.id);
      firstGroup &&
        firstGroup.shop.stockWarehouse &&
        localStorage.setItem('stockWarehouseId', firstGroup.shop.stockWarehouse.id);
      firstGroup && localStorage.setItem('companyId', firstGroup.company.id);
      history.replace('/');
    } else {
      history.replace('/shops');
    }
  };

  async componentDidMount() {
    const { token } = this.getQueryParams();
    if (token) {
      localStorage.setItem('token', token);
      await this.onSubmit();
    }
  }

  render() {
    return (
      <div className={'d-flex flex-column h-100 bg-dark'}>
        {this.renderModal()}
        <div className={'flex-fill'} />
        <Copyright />
      </div>
    );
  }
  renderModal() {
    return (
      <Modal className={'LoginModal'} isOpen={true} centered={true} size={'lg'} backdrop={false}>
        <Row className={'justify-content-center'}>
          <Col sm={12} md={12} lg={6} xl={6}>
            <div className="d-flex justify-content-center align-items-center mb-5">
              <img
                style={{
                  height: '6rem',
                }}
                src={require('../../assets/HomePage-Menu-Logo@2x.png')}
                alt={'omniwe'}
              />
            </div>
            {this.renderForm()}
            <div className={'d-flex justify-content-center mt-3'}>
              <a href={'https://omniwe.com/'} target={'_blank'}>
                <span className={'text-secondary'}>{translate['register_now']}</span>
              </a>
              <span className={'text-muted mx-2'}>|</span>
              <Link to={'/login'} disabled={true}>
                <span className={'text-secondary'}>{translate['forget_password']}</span>
              </Link>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
  renderForm() {
    return (
      <Formik onSubmit={this.submit} initialValues={{ identity: '', secret: '' }}>
        {({ values, handleSubmit, handleChange, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                name={'identity'}
                type={'email'}
                value={values.identity}
                onChange={handleChange}
                disabled={isSubmitting}
                placeholder={translate['_email']}
                required
              />
            </FormGroup>
            <FormGroup className={'d-flex'}>
              <Input
                type={'password'}
                name={'secret'}
                value={values.secret}
                onChange={handleChange}
                disabled={isSubmitting}
                placeholder={translate['password']}
                required
              />
            </FormGroup>
            <Button block type={'submit'} theme={'primary'} loading={isSubmitting}>
              <Icon icon={'faSignIn'} className={'mr-2'} />
              <span>{translate['login']}</span>
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}
