import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { translate } from '../../../shared/translate';
import Card from '../../../components/layout/card/Card';
import CardHeader from '../../../components/layout/card/CardHeader';
import CardBody from '../../../components/layout/card/CardBody';
import OptionsTable from './_optionsTable';
import VariantTable from './_variantTable';
import CopyModal from './_copyModal';

export default class extends Component {
  onAddOptionsClick = () => {
    const { values, setFieldValue } = this.props;
    const { options = [] } = values;
    setFieldValue('options', [...options, {}]);
  };
  onCopyClick = async () => {
    const { setFieldValue } = this.props;
    try {
      const { variations, attributes } = await this.copyModal.execute();
      setFieldValue(
        'variations',
        variations.map(variation => {
          variation.sku = '';
          delete variation.id;
          delete variation.__typename;
          delete variation.stockItems;
          delete variation.quantity;
          variation.attributes.forEach(attribute => {
            delete attribute.id;
            delete attribute.__typename;
          });
          return variation;
        }),
      );
      setFieldValue('options', getOptionsFromAttributes(attributes));
    } catch (e) {}
  };
  onOptionsChange = options => {
    const { values, setFieldValue } = this.props;
    setFieldValue('options', options);

    if (_.flatten(_.map(options, 'values')).length === 0) {
      return setFieldValue('variations', [{}]);
    }

    const variations = options
      .filter(({ name, values }) => !!name && !!values && values.length > 0)
      .reduce(
        (prev, { values = [] }, i) => {
          return prev.reduce((prev, item) => {
            return [
              ...prev,
              ...values.reduce((prev, value) => {
                return [
                  ...prev,
                  {
                    sku: '',
                    price: 1,
                    quantity: 1,
                    stockItems: [],
                    ...item,
                    attributes: [
                      ...item.attributes,
                      {
                        key: options[i].name,
                        value: value,
                      },
                    ],
                  },
                ];
              }, []),
            ];
          }, []);
        },
        [
          {
            attributes: [],
          },
        ],
      );
    variations.forEach(variation => {
      const find = values.variations.find(_variation => {
        return matchAttributes(_variation.attributes, variation.attributes);
      });
      if (find) Object.assign(variation, find);
    });

    setFieldValue('variations', variations);

    function matchAttributes(attrs1 = [], attrs2 = []) {
      if (attrs1.length !== attrs2.length) return false;
      return (
        _.difference(attrs1.map(attr => `${attr.key}:${attr.value}`), attrs2.map(attr => `${attr.key}:${attr.value}`))
          .length === 0
      );
    }
  };
  render() {
    const { values, setFieldValue, isSubmitting } = this.props;
    const { attributes, variations, options } = values;
    const hasMultipleOptions = !!options && options.length > 0;
    return (
      <Fragment>
        <Card>
          <CardHeader
            title={translate.product_variants}
            extra={
              <Fragment>
                {!hasMultipleOptions && (
                  <button
                    type="button"
                    className="btn btn-light btn-sm pull-right ml-3"
                    onClick={this.onAddOptionsClick}
                  >
                    <i className="fa fa-plus fa-fw" /> {translate.new_variant}
                  </button>
                )}
                <button type={'button'} className={'btn btn-light btn-sm pull-right'} onClick={this.onCopyClick}>
                  <i className={'fa fa-copy fa-fw'} /> {translate.copy_variant}
                </button>
              </Fragment>
            }
          />
          <CardBody>{translate.new_variant_description}</CardBody>
          {!!hasMultipleOptions && (
            <CardBody className={'p-0'}>
              <OptionsTable
                options={options}
                onOptionsChange={this.onOptionsChange}
                onAddOptionClick={this.onAddOptionsClick}
                isSubmitting={isSubmitting}
              />
              <VariantTable
                productName={values.name}
                variations={values.variations}
                onVariationsChange={variations => setFieldValue('variations', variations)}
                isSubmitting={isSubmitting}
              />
            </CardBody>
          )}
        </Card>
        <CopyModal ref={ref => (this.copyModal = ref)} />
      </Fragment>
    );
  }
}

export function getOptionsFromAttributes(attributes) {
  return attributes.reduce((prev = [], { key, value }) => {
    if (isJson(value)) {
      const { values } = JSON.parse(value);
      return [
        ...prev,
        {
          name: key,
          values,
        },
      ];
    }
    return prev;
  }, []);

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
