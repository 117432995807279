import React, { Component } from 'react';
import { gql } from 'apollo-boost';
import GenericForm from '../../components/GenericForm';
import { Query } from 'react-apollo';
import { Loading } from '../../components/pageComponents/utility';
import StatusCard from './_statusCard';
import InfoCard from './_infoCard';
import _ from 'lodash';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';

const GET_QUERY = gql`
  query($shopId: ID) {
    me {
      id
      companies {
        id
        status
      }
      groups {
        name
      }
      credentials {
        id
        identity
        platform
      }
      attributes {
        key
        value
      }
      updatedAt
      createdAt
    }
    shop: node(id: $shopId) {
      id
      ... on Shop {
        company {
          id
          status
          expiredAt
        }
      }
    }
  }
`;

export default class extends Component {
  onLogoutClick = async () => {
    await client.resetStore();
    await client.clearStore();
    localStorage.clear();
    window.location.href = '/login';
  };
  render() {
    const shopId = localStorage.getItem('shopId');
    return (
      <Query query={GET_QUERY} variables={{ shopId }}>
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { shop, me } = data || {},
            { id: userId, groups = [], credentials = [], updatedAt, attributes = [] } = me || {},
            { company } = shop || {},
            { status, createdAt, expiredAt } = company || {},
            [{ name: group = '' } = {}] = groups || [];
          const { identity: email } =
            _.find(credentials, {
              platform: 'PASSWORD',
            }) || {};
          const { name: [{ value: name = '' } = {}] = [] } = _.groupBy(attributes, 'key');
          return (
            <GenericForm breadcrumb={[{ name: translate.account_settings }]}>
              <div className={'mb-5'}>
                <StatusCard
                  onLogoutClick={this.onLogoutClick}
                  status={status}
                  createdAt={createdAt}
                  expiredAt={expiredAt}
                />
              </div>
              <InfoCard userId={userId} name={name} email={email} updatedAt={updatedAt} group={group} />
            </GenericForm>
          );
        }}
      </Query>
    );
  }
}
