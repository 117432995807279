import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query(
    $id: ID!
    $offset: OffsetInput
    $otherFilter: CouponFilterInput
    $filter: FilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Shop {
        id
        couponsCount(otherFilter: $otherFilter, filter: $filter)
        coupons(offset: $offset, otherFilter: $otherFilter, filter: $filter, sorter: $sorter) {
          id
          handle
          name
          amountBeforeDiscount
          discount
          discountType
          publishAt
          publishThru
          publishLimit
          validAt
          validThru
          active
          used
          createdAt
          updatedAt
        }
      }
    }
  }
`;
