import React, { Component } from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { gql } from 'apollo-boost';
import { translate as t } from '../../shared/translate';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const GET_QUERY = gql`
  query($id: ID!, $offset: OffsetInput, $filter: FilterInput, $otherFilter: DeliveryOptionFilterInput) {
    node(id: $id) {
      id
      ... on Shop {
        id
        deliveryOptionsCount(filter: $filter, otherFilter: $otherFilter)
        deliveryOptions(offset: $offset, filter: $filter, otherFilter: $otherFilter) {
          id
          name
          weights {
            maxWeight
            unit
            unitPrice
          }
          attributes {
            key
            value
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.delivery_options }],
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/delivery_options/new',
    fields: [
      {
        title: t.name,
        fieldName: 'name',
        type: 'text',
        render: item => (
          <Link
            to={{
              pathname: '/delivery_options/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            {item.name}
          </Link>
        ),
        filter: true,
      },
      {
        title: t.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        width: 160,
        filter: true,
      },
      {
        title: t.updated_at,
        fieldName: 'updated',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        width: 160,
        filter: true,
      },
    ],
  };
  getData = ({ node } = {}) => {
    const { deliveryOptions = [], deliveryOptionsCount = 0 } = node || {};
    return { list: deliveryOptions, count: deliveryOptionsCount };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
