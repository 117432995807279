import { translate } from 'shared/translate';

export default ({ discountType, amountBeforeDiscount, discount }) => {
  switch (discountType) {
    case 'AMOUNT':
      return translate.formatString(translate['discount_content_amount'], amountBeforeDiscount, discount);
    case 'PERCENTAGE':
      return translate.formatString(translate['discount_content_percentage'], discount);
    default:
      return '';
  }
};
