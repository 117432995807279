import React, { Fragment } from 'react';
import * as RS from 'reactstrap';
import { Query } from 'react-apollo';
import * as icons from '@fortawesome/pro-regular-svg-icons';
import { translate } from '../../shared/translate';
import * as pageUtility from '../../components/pageComponents/utility';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Loading } from '../../components/pageComponents/utility';

const { Icon } = pageUtility;

const ME = gql`
  query {
    me {
      id
    }
  }
`;

const Comment = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px #eee;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: #efefef;
`;

const CommentMySession = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  border-bottom: solid 1px #eee;
  padding-bottom: 1rem;
`;

const Info = styled.div`
  width: 10rem;
  margin-left: 1rem;
  margin-right: 1rem;

  p:last-child {
    color: gray;
    font-size: 80%;
  }
`;

const Bubble = styled.pre`
  width: auto;
`;

const AgentSession = ({ name, content, createdAt }) => (
  <Comment>
    <Info>
      <b>{name}</b>
      <p>
        <Icon icon={icons.faClock} /> {pageUtility.DateTime(createdAt)}
      </p>
    </Info>
    <Bubble>{content}</Bubble>
  </Comment>
);

const MySession = ({ name, content, createdAt }) => (
  <CommentMySession>
    <Bubble>{content}</Bubble>
    <Info style={{ textAlign: 'right' }}>
      <b>{translate.me}</b>
      <p>
        <Icon icon={icons.faClock} /> {pageUtility.DateTime(createdAt)}
      </p>
    </Info>
  </CommentMySession>
);

export default class MyClass extends React.Component {
  getName(user) {
    const { attributes, credentials } = user;
    let name = attributes[0] ? attributes[0].value : '';
    if (!name) name = credentials[0] ? credentials[0].identity : '?';
    return name;
  }

  render() {
    const { comments } = this.props;
    return (
      <Query query={ME}>
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { me } = data || {},
            { id } = me || {};
          return (
            <Fragment>
              <RS.CardHeader className='mb10'>
                {translate.comment_session}
              </RS.CardHeader>
              {comments &&
                comments.map((item, i) =>
                  item.user.id === id ? (
                    <MySession
                      {...item}
                      name={this.getName(item.user)}
                      key={i}
                    />
                  ) : (
                    <AgentSession
                      {...item}
                      name={this.getName(item.user)}
                      key={i}
                    />
                  )
                )}
            </Fragment>
          );
        }}
      </Query>
    ); // end return
  } // end render
} // end class
