import React, { Component } from 'react';
import { Icon } from '../../iconRender';
import { translate } from '../../../shared/translate';

class CardDescriptionWithToggle extends Component {
  state = { show: false };

  handleToggle = () => {
    const { onToggle } = this.props;
    const { show } = this.state;

    this.setState({ show: !show });
    if (onToggle) onToggle(!show);
  };

  getCaret = () => (this.state.show ? 'faCaretUp' : 'faCaretDown');

  render() {
    const { description } = this.props;

    return (
      <div className='card-body'>
        <div className='row align-items-start'>
          <div className='col-10'>
            <p className='text-muted pt-2'>{description}</p>
          </div>
          <div className='col-2 text-right'>
            <button className='btn btn-link' onClick={this.handleToggle}>
              {translate.edit} <Icon icon={this.getCaret()} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CardDescriptionWithToggle;
