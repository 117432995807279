import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'shared/translate';
import { InvoiceStatus } from 'shared/omniwe-types';
import NewInvoiceLink from './NewInvoiceLink';
import ActionButton from '../../components/form/ActionButton';
import RefundModal from '../item_Invoice/_refundModal';

const hiddenOnlyOnSmallSize = 'd-none d-sm-table-cell';

const TableHeader = ({ showActionColumn }) => {
  return (
    <thead>
      <tr>
        <th>{translate.invoice_number}</th>
        <th className={hiddenOnlyOnSmallSize + ' text-center'}>{translate.invoice_status}</th>
        <th className={hiddenOnlyOnSmallSize + ' text-center'}>{translate.payment_method}</th>
        <th className="text-right">{translate.amount}</th>
        <th className={'text-right'}>{translate.refunded}</th>
        {showActionColumn && <th className="text-center">{translate.refund}</th>}
      </tr>
    </thead>
  );
};

const TableColumnDefinition = ({ showActionColumn }) => (
  <colgroup>
    <col width="*" />
    <col width="120" />
    <col width="120" />
    <col width="100" />
    <col width="100" />
    {showActionColumn && <col width="115" />}
  </colgroup>
);

export default class extends Component {
  state = {};
  onRefundClick = ({ id }) => {
    try {
      this[`refundModal_${id}`].toggle();
    } catch (e) {}
  };
  onRefundSuccess = creditNote => {
    const { history } = this.props;
    const {
      invoice: { id },
    } = creditNote;
    history.push(`/invoices/${id}`);
  };
  render() {
    const {
      values,
      values: { invoices, disableNewInvoice },
    } = this.props;

    const hasRefundInvoice =
      invoices.find(
        ({ status, refundedAmount: { amount: refundedAmount } = {}, amount: { amount: total } = {} }) =>
          !!~['COMPLETED', 'REFUNDED'].indexOf(status) && refundedAmount < total,
      ) != null;
    return (
      <div className="card mb-5 shadow">
        <div className="card-header d-flex">
          <h6 className="mb-0 flex-fill">{translate.invoices}</h6>
          <NewInvoiceLink node={values} disabled={disableNewInvoice} />
        </div>
        <div className="card-body p-0">
          <table className="table table-hover">
            <TableColumnDefinition showActionColumn={hasRefundInvoice} />
            <TableHeader showActionColumn={hasRefundInvoice} />
            <tbody>
              {invoices.map((invoice, i) => {
                const {
                  id,
                  status,
                  attributes = [],
                  paymentCredential,
                  paymentMethod,
                  refundedAmount: { amount: refundedAmount } = {},
                  amount: { amount: total } = {},
                } = invoice;
                const { name: credentialName } = paymentCredential || {};
                const hasRefundableAmount = refundedAmount < total;
                return (
                  <tr key={i}>
                    <td>
                      <Link to={{ pathname: `/invoices/${id}` }} className={'text-truncate'}>
                        {(id || '').slice(0, 10)}...
                      </Link>
                    </td>
                    <td className={hiddenOnlyOnSmallSize + ' text-center'}>{InvoiceStatus[status]}</td>
                    <td className={hiddenOnlyOnSmallSize + ' text-center text-truncate'}>
                      {credentialName || translate.paymentPlatforms[paymentMethod]}
                    </td>
                    <td className="text-right">$ {total.toFixed(2)}</td>
                    <td className="text-right">$ {refundedAmount.toFixed(2)}</td>

                    {/* Must show extra td for border-top-bottom if at least one invoice has a refund button */}
                    {hasRefundInvoice && (
                      <td className="text-center">
                        {!!~['COMPLETED', 'REFUNDED'].indexOf(status) && hasRefundableAmount && (
                          <Fragment>
                            <ActionButton
                              onClick={() => this.onRefundClick(invoice)}
                              theme="btn-danger btn-sm no-animate"
                            >
                              {translate.button_refund}
                            </ActionButton>
                            <RefundModal
                              invoice={invoice}
                              ref={ref => (this[`refundModal_${id}`] = ref)}
                              onSuccess={this.onRefundSuccess}
                            />
                          </Fragment>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {invoices.length === 0 && (
            <div className="card-body text-center">
              <p className="lead font-italic text-muted mb-0">{translate.no_data}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
