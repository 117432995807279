import React from 'react';
import { Input, Label } from 'reactstrap';
import FilterRowBase from './FilterRowBase';
import FilterRowBaseComponent from './FilterRowBaseComponent';

export default class MultipleOptions extends FilterRowBase {
  constructor(props) {
    super(props);

    // const { options = [] } = this.props;
    let defaultValue =[];
    this.state = {
      value: defaultValue
    };
  }

  componentDidUpdate() {
    const { onValueChanged, visible } = this.props;
    const { value } = this.state;
    const filterValues = {
      value: visible ? value : null
    };
    onValueChanged(filterValues);
  }

  render() {
    const { title, visible, toggleVisible, options } = this.props;
    return (
      <FilterRowBaseComponent
        title={title}
        visible={visible}
        toggleVisible={toggleVisible}
      >
        {options.map((item, i) => (
          <Label className='mr-3' key={i}>
            <Input
              style={{
                position: 'static',
                marginLeft: '0',
                marginRight: '5px'
              }}
              type='checkbox'
              value={item.value}
              onChange={e => this.handleCheckboxGroupChanged(e, 'value')}
            />
            {item.name}
          </Label>
        ))}
      </FilterRowBaseComponent>
    );
  }
}
