import React from 'react';
import { translate as t } from '../../../shared/translate';

export default ({ onClick }) => (
  <button
    type='button'
    onClick={onClick}
    className='btn btn-light btn-sm'
  >
    <i className='fa fa-plus fa-fw' /> {t.item_create}
  </button>
);
