import React from 'react';
import { translate } from 'shared/translate';
import SearchComboCountry from '../../components/SearchComboCountry';
import { Icon } from 'components/iconRender';

const CountrySelections = props => {
  const {
    selectedCountries = [],
    onSelect = () => console.log(),
    selectedValue = '',
    onCountriesChanged = () => console.log(),
  } = props;

  return (
    <React.Fragment>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label" htmlFor="selectedValue">
          {translate.label_add_country}
        </label>
        <div className="col-2">
          <SearchComboCountry filteredCountries={selectedCountries} value={selectedValue} onChange={onSelect} />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label" htmlFor="">
          {translate.selectedCountries}
        </label>
        <div className="col" key={selectedCountries.length}>
          {selectedCountries.map((title, index) => (
            <button
              key={title}
              type="button"
              className="btn btn-primary btn-sm mr-1 mb-1 shadow-none"
              onClick={() => {
                selectedCountries.splice(index, 1);
                onCountriesChanged(selectedCountries);
              }}
              title={title}
            >
              {translate.countries[title]}
              <Icon icon="faTimes" />
            </button>
          ))}
          {selectedCountries.length > 0 && (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                onCountriesChanged([]);
              }}
            >
              {translate.button_remove_all}
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CountrySelections;
