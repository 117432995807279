import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate as t } from '../../shared/translate';
import { Link } from 'react-router-dom';
import { LIST_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.hc_service }],
    gql: {
      get: LIST_QUERY,
    },
    createUrl: '/hc_services/new',
    fields: [
      {
        title: t.name,
        fieldName: 'name',
        type: 'text',
        render: item => <Link to={`/hc_services/${item.id}`}>{item.name}</Link>,
        filter: true,
        sortable: 'name',
      },
      {
        title: t.price,
        align: 'right',
        fieldName: 'price',
        width: 140,
        sortable: 'price',
      },
      {
        title: t.hc_service_available,
        fieldName: 'active',
        align: 'center',
        type: 'bool',
        filter: true,
        width: 50,
      },
      {
        title: t.visible,
        fieldName: 'display',
        align: 'center',
        type: 'bool',
        filter: true,
        width: 50,
      },
      {
        title: t.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        width: 120,
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: t.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        align: 'right',
        width: 120,
        filter: true,
        sortable: 'updatedAt',
      },
    ],
  };
  getData = (data = {}) => {
    const { node: { services = [], servicesCount = 0 } = {} } = data || {};
    return { list: services, count: servicesCount };
  };
  getExtraFetchVariables() {
    return { shopId: localStorage.getItem('shopId') };
  }
}
