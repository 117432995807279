import { translate } from '../translate';
import differenceWith from 'lodash/differenceWith';

export default currentStatus => {
  // must be here otherwise, will not translate
  const status = [
    {
      value: 'PENDING',
      label: translate.status_pending
    },
    {
      value: 'RETURNED',
      label: translate.status_returned
    }
  ];

  switch (currentStatus) {
    case 'PENDING':
      return status;
    case 'RETURNED':
      return differenceWith(status, ['PENDING'], (a, b) => a.value === b);
    default:
      return [];
  }
};
