import React from 'react';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import { translate as t } from 'shared/translate';
import HorizontalField from 'components/form/horizontal-field';

const SeoCard = props => {
  const { values, handleChange } = props;
  return (
    <Card>
      <CardHeader>{t.seo}</CardHeader>
      <CardBody>
        <HorizontalField label={t.title}>
          <Input name="title" type="text" value={values.title} onChange={handleChange} maxLength={200} />
        </HorizontalField>
        <HorizontalField label={t.description}>
          <Input
            name="description"
            type="textarea"
            value={values.description}
            onChange={handleChange}
            maxLength={200}
          />
        </HorizontalField>
        <HorizontalField label={t.seo_keyword}>
          <Input name="keywords" type="text" value={values.keywords} onChange={handleChange} maxLength={200} />
        </HorizontalField>
      </CardBody>
    </Card>
  );
};

export default SeoCard;
