import React from 'react';
import { Input } from 'reactstrap';
import { translate as t } from '../../../shared/translate';
import { Checkbox } from '../../../components/omniwe';

const COLUMN_COUNT_WITHOUT_TRASH = 4;
const COLUMN_COUNT_WITH_TRASH = COLUMN_COUNT_WITHOUT_TRASH + 1;

export default ({ showActionColumn = false, item, removeItem, onChange }) => {
  const { name, description, sortIndex, required, display } = item;
  return (
    <React.Fragment>
      <tr>
        <td>
          <Input
            type='text'
            placeholder={t.name}
            value={name}
            onChange={e => onChange(e, 'name')}
            required
          />
        </td>
        <td>
          <Input
            type='number'
            maxLength={4}
            value={sortIndex}
            onChange={e => onChange(e, 'sortIndex')}
            required
          />
        </td>
        <td className='text-center'>
          <Checkbox
            defaultChecked={required}
            onChange={e => onChange(e, 'required')}
          />
        </td>
        <td className='text-center'>
          <Checkbox defaultChecked={display} onChange={e => onChange(e, 'display')} />
        </td>
        {showActionColumn && (
          <td>
            <button
              onClick={removeItem}
              type='button'
              className='btn btn-link text-danger btn-sm'
            >
              <i className='fa fa-times' />
            </button>
          </td>
        )}
      </tr>
      <tr>
        <td
          colSpan={
            showActionColumn
              ? COLUMN_COUNT_WITHOUT_TRASH
              : COLUMN_COUNT_WITH_TRASH
          }
          className='border-top-0'
        >
          <Input
            type='text'
            maxLength={200}
            placeholder={`${t.remark}(${t.required})`}
            value={description}
            onChange={e => onChange(e, 'description')}
            required
          />
        </td>
        {showActionColumn && <td className='border-top-0'>&nbsp;</td>}
      </tr>
    </React.Fragment>
  );
};
