import React from "react";
import _ from "lodash";
import { translate as t } from "../../shared/translate";
import moment from "moment";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { Button } from "reactstrap";
import PageError from "../../components/pageComponents/PageError";
import * as PU from "../../components/pageComponents/utility";
import ListLoading from "../../components/pageComponents/LoadingList";
import { PaginationWithDiv } from "../../components/pagination";

const QUERY_APPLICATION_HISTORY = gql`
  # OWNER QUERN APPLICATION HISTORY
  query($serviceId: ID, $offset: OffsetInput, $otherFiter: AgentServiceFilter) {
    node(id: $serviceId) {
      id
      ... on AgentService {
        id
        title
        applications(offset: $offset, otherFilter: $otherFiter) {
          id
          attributes {
            key
            value
          }
          comments {
            createdAt
          }
          createdAt
          updatedAt
          status
        }
        applicationsCount
      }
    }
  }
`;

const TableHeader = () => (
  <thead>
    <tr>
      <th style={{ width: 100 }} className="border-top-0 text-truncate">
        {t.operations}
      </th>
      <th className="border-top-0 text-truncate">{t.contact_name}</th>
      <th className="border-top-0 text-truncate">{t.contact_number}</th>
      <th style={{ width: 100 }} className="border-top-0 text-truncate">
        {t.status}
      </th>
      <th
        style={{ width: 140 }}
        className="border-top-0 text-right text-truncate"
      >
        {t.created_at}
      </th>
      <th
        style={{ width: 140 }}
        className="border-top-0 text-right text-truncate"
      >
        {t.updated_at}
      </th>
    </tr>
  </thead>
);

const RenderItem = ({ item, onOpenAppDetails }) => {
  const getAttrValue = (key, { attributes }) => {
    const found = _.find(attributes, { key });
    if (!found) {
      return "-";
    }

    return found.value;
  };

  return (
    <tr>
      <td>
        <Button size="sm" onClick={() => onOpenAppDetails(item.id)}>{t.view}</Button>
      </td>
      <td>{getAttrValue("contact_name", item)}</td>
      <td>{getAttrValue("contact_number", item)}</td>
      <td>{item.status}</td>
      <td className="text-right text-truncate">
        {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
      </td>
      <td className="text-right text-truncate">
        {moment(item.updatedAt).format("YYYY-MM-DD HH:mm")}
      </td>
    </tr>
  );
};

export default class ApplicationHistory extends React.Component {
  constructor(props) {
    super(props);
    this.variables = {
      serviceId: this.props.serviceId,
      offset: {
        skip: 0,
        limit: 10
      },
      otherFiter: {
        shopId: localStorage.getItem('shopId')
      }
    };
    this.view = this.props.view;
    console.log("variables", this.variables);
  }

  next(refetch) {
    this.variables.offset.skip += this.variables.offset.limit;
    // refresh page
    this.setState({});
    refetch();
  }

  prev(refetch) {
    if (this.variables.offset.skip > 0) {
      this.variables.offset.skip -= this.variables.offset.limit;
      // refresh page
      this.setState({});
      refetch();
    }
  }

  onOpenAppDetails = appId => {
    const { serviceId, history } = this.props;
    console.log("onOpenAppDetails", appId);
    console.log("serviceId", serviceId);
    history.push(`/market_services/${serviceId}/${appId}`);
  };

  render() {
    return (
      <div className="card mb-5 shadow p-0">
        <div className="card-header d-flex">
          <h6 className="mb-0 flex-fill">{t.application_history}</h6>
        </div>
        <div className="card-body p-0">
          <Query
            query={QUERY_APPLICATION_HISTORY}
            variables={this.variables}
            fetchPolicy="network-only"
          >
            {({
              loading: loading2,
              error,
              data: {
                node: { applications = [], applicationsCount = 0 } = {}
              } = {},
              refetch
            }) => {
              if (error) return <PageError errors={error} />;

              return (
                <React.Fragment>
                  <table className="table table-hover table-responsive-md mb-0">
                    <TableHeader />
                    <tbody>
                      {loading2 && <ListLoading />}
                      {!loading2 &&
                        applications.map(item => (
                          <RenderItem
                            key={item.id}
                            item={item}
                            onOpenAppDetails={this.onOpenAppDetails}
                          />
                        ))}
                      {!loading2 && applications && !applications.length && (
                        <PU.EmptyList />
                      )}
                    </tbody>
                  </table>
                  <PaginationWithDiv
                    numberOfCount={applicationsCount}
                    loading={loading2}
                    offset={this.variables.offset}
                    prev={() => this.prev(refetch)}
                    next={() => this.next(refetch)}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}
