import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID!, $offset: OffsetInput, $filter: FilterInput, $sorter: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Shop {
        id
        deliveryNotesCount(filter: $filter)
        deliveryNotes(offset: $offset, filter: $filter, sorter: $sorter) {
          id
          trackingNo
          status
          shipment {
            provider
          }
          shippingAddress {
            person
            tel
            email
            lines
            country
          }
          attributes {
            key
            value
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
