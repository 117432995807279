import React from 'react';
import FilterRowBase from './FilterRowBase';
import FilterRowBaseComponent from './FilterRowBaseComponent';

export default class DateTime extends FilterRowBase {
  // constructor(props) {
  //   super(props);
  //   this.id = Math.random();
  // }

  state = {
    since: '',
    until: ''
  };

  componentDidMount() {
    const { defaultValue: { since = '', until = '' } = {} } = this.props;
    this.setState({ since, until });
  }

  componentDidUpdate() {
    const { onValueChanged, visible } = this.props;
    const { since, until } = this.state;
    const filterValues = visible
      ? {
          since,
          until
        }
      : {
          since: null,
          until: null
        };
    onValueChanged(filterValues);
  }

  render() {
    const { since, until } = this.state;
    const { title, visible, toggleVisible, disabled } = this.props;
    return (
      <FilterRowBaseComponent
        title={title}
        visible={visible}
        disabled={disabled}
        toggleVisible={toggleVisible}
      >
        <div className='form-group m-0' style={{ display: 'flex' }}>
          <div className='input-group input-group-sm'>
            <input
              type='date'
              className='form-control border-right-0'
              onChange={e => this.handleValueChanged(e, 'since')}
              value={since}
              disabled={disabled}
            />
            <div className='input-group-prepend'>
              <span className='input-group-text'>to</span>
            </div>
            <input
              type='date'
              className='form-control'
              onChange={e => this.handleValueChanged(e, 'until')}
              value={until}
              disabled={disabled}
            />
          </div>
        </div>
      </FilterRowBaseComponent>
    );
  }
}
