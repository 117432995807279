import React, { Component } from 'react';
import HorizontalField from './horizontal-field';

import PropTypes from 'prop-types';

class DropdownField extends Component {
  render() {
    const {
      label,
      value,
      onValueChanged,
      selectPlaceholder = null,
      labelWidth,
      colWidth
    } = this.props;

    let { options = [] } = this.props;

    if (selectPlaceholder) {
      options = [
        { value: 'please_select', display: selectPlaceholder },
        ...options
      ];
    }

    return (
      <HorizontalField
        label={label}
        labelWidth={labelWidth}
        colWidth={colWidth}
      >
        <select
          className='form-control'
          value={value}
          onChange={e => onValueChanged(e.target.value)}
        >
          {options.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.display}
            </option>
          ))}
        </select>
      </HorizontalField>
    );
  }
}

DropdownField.propTypes = {
  labelWidth: PropTypes.number,
  colWidth: PropTypes.number
};

DropdownField.defaultProps = {
  labelWidth: 2,
  colWidth: 10
};

export default DropdownField;
