import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Shop {
        hostname
        agent {
          id
        }
        logo
        rasterLogo
        company {
          id
          status
          expiredAt
          createdAt
        }
        #seo
        title
        description
        keywords
        #base
        locale
        hostname
        customDomain
        allowGuestCheckout
        autoComplete
        autoConfirm
        #address
        address {
          person
          tel
          email
          lines
          country
        }
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation companyShopSet($id: ID, $input: ShopInput!) {
    companyShopSet(id: $id, input: $input) {
      id
      title
      logo
      rasterLogo
      hostname
      description
      locale
      updatedAt
    }
  }
`;
