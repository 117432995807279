import { gql } from 'apollo-boost';

export const GET_ME = gql`
  query {
    me {
      id
      groups {
        name
        shop {
          id
          stockWarehouse {
            id
          }
        }
      }
    }
  }
`;

export const GET_QUERY = gql`
  query($otherFilter: ShopFilterInput, $offset: OffsetInput) {
    shops(otherFilter: $otherFilter, offset: $offset) {
      id
      title
      hostname
      description
      stockWarehouse {
        id
      }
      company {
        id
      }
    }
  }
`;
