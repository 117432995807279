import React from 'react';
import SearchComboProduct from 'components/SearchComboProduct';
import gql from 'graphql-tag';
import { client } from 'shared/apollo';
import PropTypes from 'prop-types';
import SearchCombo from 'components/SearchCombo';

const SEARCH_QUERY = gql`
  query($id: ID, $filter: ProductFilterInput) {
    node(id: $id) {
      id
      ... on Shop {
        id
        title
        products(otherFilter: $filter, offset: { skip: 0, limit: 10 }) {
          id
          variations {
            id
            attributes {
              key
              value
            }
            product {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default class SearchComboProductVariant extends SearchComboProduct {
  update = async search => {
    if (!this._mounted) return;
    const {
      data: { node },
    } = await client.query({
      query: SEARCH_QUERY,
      variables: {
        id: await localStorage.getItem('shopId'),
        filter: {
          name: search,
        },
      },
      fetchPolicy: 'network-only',
    });
    const { products = [] } = node || {};
    const options = [];

    products.forEach(({ variations }) => {
      (variations || []).forEach(variation => {
        const { product, attributes } = variation || {},
          { name = '' } = product || {};
        const attrStr = (attributes || [])
          .reduce((reducer, { key, value }) => {
            return reducer.concat(`${key}: ${value}`);
          }, [])
          .join(',');

        options.push({
          label: !!attrStr ? `${name} - ${attrStr}` : name,
          value: variation,
        });
      });
    });
    this.setState({ options });
  };

  getItemDisplay = item => {
    const { product, attributes } = item || {},
      { name = '' } = product || {};
    const attrStr = (attributes || [])
      .reduce((reducer, { key, value }) => {
        return reducer.concat(`${key}: ${value}`);
      }, [])
      .join(',');
    return !!attrStr ? `${name} - ${attrStr}` : name;
  };
}
SearchComboProductVariant.propTypes = {
  selected: PropTypes.any /* Product Variant Item */,
  onChange: PropTypes.func,
};
SearchComboProductVariant.defaultProps = {
  onChange: _ => _,
};
