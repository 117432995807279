import React from 'react';
import _ from 'lodash';

export default class FilterRowBase extends React.Component {
  handleValueChanged = (event, name) => {
    let value;
    if (event.target) {
      // const {
      //   target: { value }
      // } = event;
      value = event.target.value;
    } else {
      value = event;
    }
    this.setState({ [name]: value });
  };

  handleCheckboxGroupChanged = (event, name) => {
    let items = [...this.state[name]];
    const {
      target: { checked, value }
    } = event;
    // console.log(name, checked);
    if (checked) {
      items.push(value);
    } else {
      _.remove(items, x => x === value);
    }

    console.log(items);

    this.setState({
      [name]: items
    });
  };
}
