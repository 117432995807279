import React, { Component, Fragment } from 'react';
import Card from '../../../components/layout/card/Card';
import { translate } from '../../../shared/translate';
import CardHeader from '../../../components/layout/card/CardHeader';
import CardBody from '../../../components/layout/card/CardBody';
import FormRow from '../../../components/form/FormRow';
import StockItemModal from './_stockItemModal';
import md5 from 'md5';

export default class extends Component {
  onAutoSkuClick = () => {
    const { values: { name, variations } = {}, setFieldValue } = this.props,
      [variation = {}] = variations || [];
    variation.sku = md5(name);
    setFieldValue('variations', [variation]);
  };

  render() {
    const { values, setFieldValue, isSubmitting } = this.props;
    const {
      variations,
      variations: [variation],
      options,
    } = values;
    if (!!options && options.length > 0) return null;

    return (
      <Fragment>
        <Card>
          <CardHeader title={translate.price_stock} />
          <CardBody>
            <FormRow title={'SKU **'}>
              <div className={'d-flex'}>
                <input
                  disabled={isSubmitting}
                  type="text"
                  className="form-control"
                  name={'sku'}
                  value={variation.sku}
                  onChange={e => {
                    variation.sku = e.target.value;
                    setFieldValue('variations', [variation]);
                  }}
                  required={true}
                />
                <button type={'button'} className={'ml-2 btn btn-outline-secondary'} onClick={this.onAutoSkuClick}>
                  {translate.auto}
                </button>
              </div>
            </FormRow>
            <FormRow title={translate.price + ' **'}>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">HKD</div>
                </div>
                <input
                  disabled={isSubmitting}
                  type="number"
                  min={0}
                  step={0.01}
                  className="form-control"
                  value={variation.price}
                  onChange={e => {
                    variation.price = e.target.value;
                    setFieldValue('variations', [variation]);
                  }}
                  required={true}
                />
              </div>
            </FormRow>
            <FormRow title={translate.stock + ' **'}>
              {(variation.stockItems || []).length > 0 && (
                <div className={'d-flex'}>
                  <button
                    type={'button'}
                    className={'mr-2 btn btn-outline-secondary'}
                    onClick={async () => {
                      try {
                        variation.stockItems = await this.stockItemModal.execute(variation.stockItems || []);
                        setFieldValue('variations', [variation]);
                      } catch (e) {}
                    }}
                  >
                    {translate.edit_stock_items}
                  </button>
                  <button
                    type={'button'}
                    className={'btn btn-outline-secondary'}
                    onClick={async () => {
                      variation.stockItems = [];
                      setFieldValue('variations', [variation]);
                    }}
                  >
                    {translate.use_quantity}
                  </button>
                </div>
              )}
              {(variation.stockItems || []).length === 0 && (
                <div className={'d-flex'}>
                  <input
                    disabled={isSubmitting}
                    type="number"
                    step="any"
                    className="form-control"
                    value={variation.quantity}
                    onChange={e => {
                      variation.quantity = e.target.value;
                      setFieldValue('variations', [variation]);
                    }}
                    required={true}
                  />
                  <button
                    type={'button'}
                    className={'ml-2 btn btn-outline-secondary'}
                    onClick={async () => {
                      try {
                        variation.stockItems = await this.stockItemModal.execute(variation.stockItems || []);
                        setFieldValue('variations', [variation]);
                      } catch (e) {}
                    }}
                  >
                    {translate.use_stock_items}
                  </button>
                </div>
              )}
            </FormRow>
          </CardBody>
        </Card>
        <StockItemModal ref={ref => (this.stockItemModal = ref)} />
      </Fragment>
    );
  }
}
