import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Table, Input, InputGroupAddon, InputGroup } from 'reactstrap';
import { translate as t } from 'shared/translate';
import SearchComboProductVariant from '../../components/SearchComboProductVariant';
import { Icon } from '../../components/iconRender';

export default ({ values, setFieldValue }) => {
  return (
    <Card style={{ minWidth: 1000 }}>
      <CardHeader>
        <span>{t.hc_serive_items}</span>
        <a
          className="btn btn-light btn-sm pull-right"
          onClick={() => {
            values.items.push({});
            setFieldValue('items', values.items);
          }}
        >
          <i className="fa fa-plus fa-fw" />
          <span>{t.create}</span>
        </a>
      </CardHeader>
      <CardBody className={'p-0'}>
        <Table className={'p-0'}>
          <colgroup>
            <col width={'*'} />
            <col width={'*'} />
            <col width={'150'} />
            <col width={'150'} />
            <col width={'150'} />
            <col width={'50'} />
          </colgroup>
          <thead>
            <tr>
              <th>{t.product}</th>
              <th>{t.remark}</th>
              <th>{t.availd_days}</th>
              <th>{t.available_to_days}</th>
              <th>{t.service_days}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {(values.items || []).length === 0 && (
              <tr>
                <td colSpan={5}>
                  <p className="text-center lead font-italic text-muted mb-0">{t.no_data}</p>
                </td>
              </tr>
            )}
            {(values.items || []).map((item, i) => {
              const { productVariant } = item,
                { product, attributes } = productVariant || {},
                { name: productName = '' } = product || {};
              const attrStr = (attributes || [])
                .reduce((reducer, { key, value }) => {
                  return reducer.concat(`${key}: ${value}`);
                }, [])
                .join(',');

              return (
                <tr key={i}>
                  <td>
                    <SearchComboProductVariant
                      selected={productVariant}
                      onChange={productVariant => {
                        item.productVariant = productVariant;
                        if (!!productVariant) {
                          const { product, attributes } = productVariant || {},
                            { name: productName = '' } = product || {};
                          const attrStr = (attributes || [])
                            .reduce((reducer, { key, value }) => {
                              return reducer.concat(`${key}: ${value}`);
                            }, [])
                            .join(',');
                          item.summary = !!attrStr ? `${productName} - ${attrStr}` : productName;
                        }

                        setFieldValue('items', values.items);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      type="text"
                      maxLength={200}
                      placeholder={t.remark}
                      value={item.summary}
                      onChange={e => {
                        item.summary = e.target.value;
                        setFieldValue('items', values.items);
                      }}
                    />
                  </td>
                  <td>
                    <InputGroup>
                      <Input
                        type="number"
                        maxLength={4}
                        value={item.active}
                        onChange={e => {
                          item.active = e.target.value;
                          setFieldValue('items', values.items);
                        }}
                        required
                      />
                      <InputGroupAddon addonType="append">{t.day}</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup>
                      <Input
                        type="number"
                        maxLength={4}
                        value={item.valid}
                        onChange={e => {
                          item.valid = e.target.value;
                          setFieldValue('items', values.items);
                        }}
                        required
                      />
                      <InputGroupAddon addonType="append">{t.day}</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup>
                      <Input
                        type="number"
                        maxLength={4}
                        step={15}
                        min={15}
                        value={item.duration}
                        onChange={e => {
                          item.duration = e.target.value;
                          setFieldValue('items', values.items);
                        }}
                        required
                      />
                      <InputGroupAddon addonType="append">{t.minute}</InputGroupAddon>
                    </InputGroup>
                  </td>
                  <td>
                    <button
                      className={'btn btn-link text-danger'}
                      type={'button'}
                      onClick={e => {
                        e.preventDefault();
                        values.items.splice(i, 1);
                        setFieldValue('items', values.items);
                      }}
                    >
                      <Icon icon={'faTimes'} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
