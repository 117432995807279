import React, { useState } from 'react';
import DraggableList from 'components/DraggableList';
import BannerEditor from './_bannerEditor';
import { uuid } from 'uuidv4';

export default ({ config = {}, onChange = _ => _, disabled }) => {
  const { images = [] } = config || {};
  const [selectedBanner, setSelectedBanner] = useState(images && images[0]);

  const addBanner = () => {
    const image = { image: uuid() };
    images.push(image);
    config.images = images.map((image, i) => ({ ...image, sortIndex: i }));
    onChange(config);
    setSelectedBanner(image);
  };

  return (
    <div className={'p-3 d-flex flex-row'} style={{ minWidth: 500 }}>
      <div className={'mr-3'} style={{ overflow: 'auto' }}>
        <div style={{ height: 0 }}>
          <DraggableList
            items={images}
            keyField={'image'}
            onChange={images => {
              config.images = images.map((image, i) => ({ ...image, sortIndex: i }));
              onChange(config);
            }}
            disabled={disabled}
            itemRender={({ item: banner }) => {
              const { image } = banner || {};
              const selected = selectedBanner === banner;
              return (
                <div
                  className={`img-thumbnail mb-2 ${selected ? 'p-2 bg-dark' : 'bg-white'}`}
                  style={{ width: 50 }}
                  onClick={() => {
                    setSelectedBanner(banner);
                  }}
                >
                  <div className="embed-responsive embed-responsive-1by1">
                    <div className="embed-responsive-item">
                      <div className={'position-relative w-100 h-100'}>
                        <img className="object-fit-contain" src={image} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
          <button
            type={'button'}
            style={{
              width: 50,
              height: 50,
            }}
            className={'btn btn-light fa fa-plus'}
            onClick={addBanner}
            disabled={disabled}
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {(() => {
          if (!selectedBanner) return null;
          const { id } = selectedBanner;
          return (
            <BannerEditor
              key={id}
              banner={selectedBanner}
              onChange={_selectedBanner => {
                Object.assign(selectedBanner, _selectedBanner);
                onChange(config);
              }}
              onRemove={() => {
                const index = images.findIndex(image => image === selectedBanner);
                if (!!~index) {
                  images.splice(index, 1);
                  config.images = images.map((image, i) => ({ ...image, sortIndex: i }));
                  onChange(config);
                  setSelectedBanner(images[index - 1]);
                }
              }}
              disabled={disabled}
            />
          );
        })()}
      </div>
    </div>
  );
};
