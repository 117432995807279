import React from 'react';
import { translate } from 'shared/translate';
import FormPageMaker from '../../components/formPageMaker';
import { client } from 'shared/apollo';
import { GET_QUERY, SUBMIT_QUERY } from './query';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    breadcrumb: [
      { name: translate.settings, link: '/settings' },
      {
        name: translate.settings_title_payment_providers,
        link: '/settings/payments',
      },
      { name: translate.stripe_connect },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
    },
    cards: [
      {
        name: translate.stripe_connect,
        fields: [
          {
            label: ' ',
            type: 'checkbox',
            name: 'active',
            text: translate.activate_services,
          },
          {
            label: translate.account,
            name: 'identity',
            type: 'text',
            maxLength: 255,
          },
        ],
      },
    ],
  };

  submit = async ({ id, identity, active }) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        shopId: localStorage.getItem('shopId'),
        input: {
          id,
          active: !!active,
          platform: 'STRIPE_CONNECT_CUSTOM',
          type: 'PAYMENT',
          identity,
          secret: identity,
          attributes: [],
        },
      },
    });
  };

  getInitialData({ node } = {}) {
    const { credentials = [] } = node || {},
      credential = credentials[0] || {};

    return {
      ...credential,
    };
  }
}
