import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

import _ from 'lodash';
import moment from 'moment';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on DeliveryNote {
        order {
          id
          shop {
            id
            title
          }
          attributes {
            key
            value
          }
        }
        items {
          orderItem {
            id
            productVariant {
              id
              product {
                id
                name
              }
              attributes {
                key
                value
              }
            }
            quantity
            unitPrice {
              amount
              locale
            }
          }
          quantity
        }
        attributes {
          key
          value
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export default class Login extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params = {} }
    } = this.props;

    this.state = {
      currentId: params.id === 'new' ? undefined : params.id
    };
    this.items = [];
  }

  render() {
    return (
      <Query query={GET_QUERY} variables={{ id: this.state.currentId }}>
        {({ client, data, data: { node = {} } = {}, loading }) => {
          if (loading) return <div />;

          const { id, order = {}, items = [], createdAt } = node;

          const {
            attributes: orderAttributes = [],
            shop: { title: shopTitle } = {}
          } = order;

          let OrderAttrs = _.groupBy(orderAttributes, 'key');
          const { receiver: [{ value: receiver } = {}] = [] } = OrderAttrs;

          const { lines: billLines = [], person: billPerson } = JSON.parse(
            receiver || '{}'
          );

          const { match: { path } = {} } = this.props;

          const hasPrice = path === '/delivery_note_print_view_with_price/:id?';

          return (
            <div className='container mt-5'>
              <h1>
                Delivery Note{' '}
                <span className='pull-right border border-dark pl-4 pr-4'>
                  <small>{shopTitle}</small>
                </span>
              </h1>

              <table className='dn' width='100%'>
                <tbody>
                  <tr>
                    <td width='130' valign='top' nowrap='nowrap'>
                      <strong>Delivery Note No.</strong>
                    </td>
                    <td width='555' valign='top'>
                      {id}
                    </td>
                  </tr>
                  <tr>
                    <td valign='top' nowrap='nowrap'>
                      <strong>Delivery Date</strong>
                    </td>
                    <td valign='top'>
                      {moment(createdAt).format('YYYY-MM-DD')}
                    </td>
                  </tr>
                  <tr>
                    <td valign='top' nowrap='nowrap'>
                      <strong>From</strong>
                    </td>
                    <td valign='top'>{shopTitle}</td>
                  </tr>
                  <tr>
                    <td valign='top' nowrap='nowrap'>
                      <strong>To</strong>
                    </td>
                    <td valign='top'>{shopTitle}</td>
                  </tr>
                  <tr>
                    <td valign='top' nowrap='nowrap'>
                      <strong>Delivery Address</strong>
                    </td>
                    <td valign='top'>{billLines.join(', ')}</td>
                  </tr>
                  <tr>
                    <td valign='top' nowrap='nowrap'>
                      <strong>Contact Person</strong>
                    </td>
                    <td valign='top'>{billPerson}</td>
                  </tr>
                </tbody>
              </table>

              <div className='mt-4'>
                <table className='table table-borderless table-striped'>
                  <colgroup>
                    <col width='50' />
                    <col width='*' />
                    <col width='50' />
                  </colgroup>
                  <thead className='thead-white'>
                    <tr>
                      <th className='border-0' />
                      <th className='border-0'>Product Name</th>
                      <th className='text-right border-0'>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map(({ orderItem, quantity }, index) => {
                      const {
                        productVariant: {
                          attributes = [],
                          product: { name } = {}
                        } = {}
                      } = orderItem;
                      return (
                        <tr>
                          <td>{index + 1}.</td>
                          <td>
                            {name}
                            {attributes.length > 0 && ' - '}
                            {_.map(attributes, 'value').join(' ')}
                          </td>
                          <td className='text-right'>{quantity}</td>
                        </tr>
                      );
                    })}
                    {hasPrice && (
                      <tr>
                        <td colSpan={99} className='text-right'>
                          {items
                            .reduce((prev, { orderItem, quantity }) => {
                              const {
                                unitPrice: { amount = 0 } = {}
                              } = orderItem;
                              return (prev += amount * quantity);
                            }, 0)
                            .toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='mt-5'>
                <p>Received By:</p>
                <p>Name:</p>
                <p>Date:</p>
                <h4 className='mt-4'>
                  <img
                    height={25}
                    width={`auto`}
                    className={`align-baseline`}
                    src={require('../../assets/src_omniwe-logo.svg')}
                  />{' '}
                  OmniWe
                </h4>
                <p>Copyright © omniwe.com All Rights Reserved</p>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
