import React, { Component } from 'react';

import { translate } from './../../shared/translate';

import Card from './../layout/card/Card';
import DatetimeCardFooter from './../layout/card/DatetimeCardFooter';
import ActionButton from './ActionButton';

class FormToolbar extends Component {
  leftControls = (onCancel = () => console.warn('onCancel not provided')) => (
    <div>
      <ActionButton theme="btn-outline-secondary text-dark border-0" onClick={onCancel}>
        {translate.cancel}
      </ActionButton>
    </div>
  );

  rightControls = (onSave = () => console.warn('onCancel not provided'), type = 'submit') => (
    <div>
      {!!type && (
        <ActionButton type={type} onClick={onSave}>
          {translate.save}
        </ActionButton>
      )}
    </div>
  );

  render() {
    const { onCancel, onSave, updatedAt, submitBtnType } = this.props;
    return (
      <Card>
        <div className="card-body d-flex justify-content-between">
          {!!onCancel ? this.leftControls(onCancel) : <div />}
          {this.rightControls(onSave, submitBtnType)}
        </div>
        <DatetimeCardFooter updatedAt={updatedAt} />
      </Card>
    );
  }
}

export default FormToolbar;
