import React from 'react';
import { Icon } from '../iconRender';
import imageUpload from '../../shared/imageUpload';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';

export default ({ value, handleChange = _ => _, onFile = _ => _ }) => {
  const uploadImage = async file => {
    try {
      if (file) {
        let reader = new FileReader();
        reader.onload = e => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = async function() {
            try {
              await onFile(file, this);
              const result = await imageUpload(file, false);
              handleChange(result);
            } catch (e) {
              toast.error(errorParser(e));
            }
          };
        };
        reader.readAsDataURL(file);

        // await onFile(file);
        // const result = await imageUpload(file, false);
        // handleChange(result);
      }
    } catch (e) {
      toast.error(errorParser(e));
    }
  };

  return (
    <div
      className="embed-responsive embed-responsive-1by1"
      style={{
        width: 120,
      }}
    >
      <div className="embed-responsive-item border d-flex justify-content-center align-items-center">
        {!value && <Icon icon="faImage" size="4x" className="text-muted" />}
        {value && <img src={value} alt="Shop Logo" className="h-100 w-100 object-fit-contain" />}
        <input
          type="file"
          className="position-absolute w-100 h-100 cursor-pointer opacity-0"
          onChange={e => uploadImage(e.target.files[0])}
        />
      </div>
    </div>
  );
};
