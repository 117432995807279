import React, { useState } from 'react';
import SearchCombo from '../../components/SearchCombo';
import SearchComboProduct from '../../components/SearchComboProduct';

export default ({}) => {
  const [value, setValue] = useState(undefined);

  return (
    <div className={'p-5'}>
      <SearchCombo
        value={value}
        onChange={setValue}
        options={[
          { label: 'option1', value: 1 },
          { label: 'option2', value: 2 },
          { label: 'option3', value: 3 },
          { label: 'option4', value: 4 },
          { label: 'option5', value: 5 },
        ]}
      />
      <SearchComboProduct onChange={console.log} />
    </div>
  );
};
