import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate } from 'shared/translate';
import AddressBody from 'components/layout/card/address/AddressBody';

export default ({ values: { shippingAddress, billingAddress } = {} }) => {
  return (
    <Card>
      <CardHeader>{translate.address}</CardHeader>
      <CardBody>
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-muted mb-3'}>{translate.card_title_ship_to_address}</div>
            <AddressBody span={4} data={shippingAddress || {}} />
          </div>
          <div className={'col'}>
            <div className={'text-muted mb-3'}>{translate.card_title_bill_to_address}</div>
            <AddressBody span={4} data={billingAddress || {}} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
