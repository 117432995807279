import { gql } from 'apollo-boost';

export const GET_ORDERS = gql`
  query($id: ID, $offset: OffsetInput, $filter: FilterInput, $otherFilter: OrderFilterInput, $sorter: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Coupon {
        orders(offset: $offset, filter: $filter, otherFilter: $otherFilter, sorter: $sorter) {
          id
          referenceNo
          total {
            amount
            locale
          }
          subtotal {
            amount
            locale
          }
          shippingFee {
            amount
            locale
          }
          tax {
            amount
            locale
          }
          discount
          createdAt
        }
        ordersCount(filter: $filter, otherFilter: $otherFilter)
      }
    }
  }
`;

export const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Coupon {
        handle
        name
        attributes {
          key
          value
        }
        discardSubsequent
        amountBeforeDiscount
        membersOnly
        ordersCount
        discount
        discountType
        publishLimit
        publishAt
        publishThru
        validAt
        validThru
        usage
        used
        active
        createdAt
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation couponSet($id: ID, $input: CouponInput!) {
    response: couponSet(id: $id, input: $input) {
      id
      handle
      name
      discountType
      publishAt
      publishThru
      publishLimit
      validAt
      validThru
      membersOnly
      active
      usage
      createdAt
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation couponUnset($id: ID!) {
    couponUnset(id: $id) {
      id
    }
  }
`;
