import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Loading from '../../components/loading';
import { translate } from '../../shared/translate';
import ShopCard from './_shopCard';
import { client } from 'shared/apollo';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { GET_ME, GET_QUERY } from './query';
import DebounceInput from 'components/DebounceInput';
import { Icon } from 'components/iconRender';

export default class extends Component {
  loading = false;
  state = {
    skip: 0,
    limit: 20,
    shops: [],
    search: '',
  };

  onAllShopClick = () => {
    const { history } = this.props;

    localStorage.removeItem('shopId');
    history.replace('/');
  };

  onShopClick = ({ id, stockWarehouse, company: { id: companyId } }) => {
    const { history } = this.props;

    localStorage.setItem('shopId', id);
    !!stockWarehouse && localStorage.setItem('stockWarehouseId', stockWarehouse.id);
    localStorage.setItem('companyId', companyId);
    history.replace('/');
  };

  onSearchInput = search => {
    this.setState({ search, skip: 0, shops: [], done: false });
    this.loadMore();
  };

  componentDidMount() {
    window.addEventListener('ReachEnd', this.loadMore);
    window.dispatchEvent(new Event('ReachEndTrigger'));
  }

  componentWillUnmount() {
    window.removeEventListener('ReachEnd', this.loadMore);
  }

  loadMore = async () => {
    const { skip, limit, shops, done, search } = this.state;
    if (done === true) return;
    if (this.loading) return;
    this.loading = true;
    this.setState({});

    const { data } = await client.query({
      query: GET_QUERY,
      variables: {
        offset: { skip, limit },
        otherFilter: {
          title: search || undefined,
        },
      },
      fetchPolicy: 'network-only',
    });
    const { shops: newShops = [] } = data || {};
    this.loading = false;
    this.setState({});

    if (newShops.length !== 0) {
      this.setState({ skip: skip + limit, shops: [...shops, ...newShops] });
    } else {
      this.setState({ done: true });
    }
  };

  componentDidUpdate() {
    window.dispatchEvent(new Event('ReachEndTrigger'));
  }

  render() {
    const { shops, search } = this.state;
    return (
      <Query query={GET_ME}>
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { me } = data || {},
            { groups } = me || {};
          const isAgent = !!groups.find(({ shop }) => !shop);
          return (
            <div className="container-fluid mb-5">
              <ul className="breadcrumb">
                <li className="breadcrumb-item active">{translate['your_shops']}</li>
              </ul>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType={'prepend'}>
                    <InputGroupText>
                      <Icon icon={'faSearch'} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <DebounceInput value={search} onChange={this.onSearchInput} placeholder={translate['search_shop']} />
                </InputGroup>
              </FormGroup>
              <div className="row">
                {isAgent && (
                  <ShopCard
                    title={translate['shop_deselected']}
                    description={translate['select_no_shop']}
                    onClick={this.onAllShopClick}
                  />
                )}
                {shops.map((shop, i) => (
                  <ShopCard
                    key={i}
                    title={shop.title}
                    description={shop.description}
                    onClick={() => this.onShopClick(shop)}
                  />
                ))}
                <div className="col-xl-3 justify-content-center ">{this.loading ? <Loading /> : ''}</div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
