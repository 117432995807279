import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { gql } from 'apollo-boost';
import { translate } from '../../shared/translate';
import _ from 'lodash';
import { client } from '../../shared/apollo';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Collection {
        name
        attributes {
          key
          value
        }
        thumbnail
        sortIndex
        createdAt
        updatedAt
      }
    }
  }
`;

const POST_QUERY = gql`
  mutation collectionSet($id: ID, $input: CollectionInput!) {
    collectionSet(id: $id, input: $input) {
      id
      name
      thumbnail
      attributes {
        key
        value
      }
      sortIndex
      updatedAt
      createdAt
    }
  }
`;

const REMOVE_QUERY = gql`
  mutation collectionUnset($id: ID!) {
    collectionUnset(id: $id) {
      id
    }
  }
`;
export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    breadcrumb: [
      {
        link: '/product_collections',
        name: translate.product_collections,
      },
      {
        name: this.state.id || translate.new_collection,
      },
    ],
    cards: [
      {
        name: translate.collection_details,
        fields: [
          {
            label: translate.name,
            type: 'text',
            name: 'name',
            maxLength: 255,
            required: true,
          },
          {
            label: translate.description,
            type: 'html',
            name: 'description',
            required: true,
          },
          {
            label: translate.sort_index,
            type: 'number',
            name: 'sortIndex',
            required: true,
          },
          {
            label: translate.image,
            type: 'image',
            name: 'thumbnail',
          },
        ],
      },
    ],
  };

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const { name, description, thumbnail, sortIndex } = values;
    const shopId = await localStorage.getItem('shopId');

    if (!description) throw new Error(translate.description_is_required);

    const input = {
      name,
      shopId,
      sortIndex,
      attributes: [
        {
          key: 'description',
          value: description,
        },
      ],
    };

    if (typeof thumbnail !== 'string') {
      input.thumbnailUpload = thumbnail;
    }

    const {
      data: { collectionSet },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });
    return collectionSet.id;
  };

  getInitialData = data => {
    const { node } = data || {},
      { name = '', thumbnail = '', updatedAt, attributes = [], sortIndex = 0 } = node || {};
    const { description: [{ value: description = '' } = {}] = [] } = _.groupBy(attributes, 'key');

    return {
      name,
      description,
      updatedAt,
      thumbnail,
      sortIndex,
    };
  };
}
