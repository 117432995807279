import React from 'react';
import { Button } from 'reactstrap';
import _ from 'lodash';
import RRule from 'rrule';
import { translate } from '../../../shared/translate';

const WeekItems = [
  {
    text: translate.sunday,
    value: RRule.SU.weekday
  },
  {
    text: translate.monday,
    value: RRule.MO.weekday
  },
  {
    text: translate.tuesday,
    value: RRule.TU.weekday
  },
  {
    text: translate.wednesday,
    value: RRule.WE.weekday
  },
  {
    text: translate.thursday,
    value: RRule.TH.weekday
  },
  {
    text: translate.friday,
    value: RRule.FR.weekday
  },
  {
    text: translate.saturday,
    value: RRule.SA.weekday
  }
];


export default ({ days, onChange }) => {
  const handleChange = day => {
    onChange(day);
  };

  const getColor = value => {
    const found = _.find(days, x => parseInt(x) === parseInt(value));
    return _.isNil(found) ? 'secondary' : 'success';
  };

  return (
    <div>
      {WeekItems.map(({ text, value }) => (
        <Button
          className={`border-0 mr-3 shadow-none`}
          key={value}
          onClick={() => handleChange(value)}
          color={getColor(value)}
        >
          {text}
        </Button>
      ))}
    </div>
  );
};
