import React, { Component } from 'react';
import { translate } from '../../shared/translate';

import BreadCrumbs from '../../components/layout/BreadCrumbs';
import TableList from '../../components/table/TableList';

class ReplacementsList extends Component {
  state = {
    trail: [{ to: null, label: translate.page_title_replacements }],
    filters: [
      {
        title: translate.order_date,
        fieldName: 'created',
        type: 'datetime',
        visible: false
      }
    ],
    fields: [
      { title: 'RMA', align: 'left', type: 'link' },
      { title: 'Reference', align: 'left', type: 'link' },
      { title: 'Status', align: 'center', type: 'text' },
      { title: 'Created At', align: 'right', type: 'datetime' },
      { title: 'Updated', align: 'right', type: 'datetime' }
    ],
    bodydata: [
      [
        { display: '5cf4c3eef13c9a7c4daf017d', to: '/replacements' },
        { display: '5cf4c3f91709728e604270cd', to: '/replacements' },
        'Pending',
        '2019-05-31T03:25:31.232Z',
        '2019-05-31T03:25:31.232Z'
      ]
    ]
  };
  render() {
    const { fields, bodydata, filters } = this.state;
    return (
      <div className='container-fluid mb-5'>
        <BreadCrumbs trail={this.state.trail} />
        <TableList fields={fields} data={bodydata} filters={filters} />
      </div>
    );
  }
}

export default ReplacementsList;
