import React, { Fragment, useRef } from 'react';
import Card from '../../layout/card/Card';
import { translate } from '../../../shared/translate';
import CardHeader from '../../layout/card/CardHeader';
import CardBody from '../../layout/card/CardBody';
import ImagesField from '../Fields/_imagesField';
import RemoveToggle from 'components/form/RemoveToggle';

export default ({
  extra,
  onRemoveClick,
  name,
  field: { name: fieldName },
  actions: { values, isSubmitting, setFieldValue },
}) => {
  const imagesField = useRef(null);
  const disabled = isSubmitting;
  const images = values[fieldName] || [];
  return (
    <Card>
      <CardHeader
        title={name}
        extra={
          <Fragment>
            {extra}
            {!!onRemoveClick && <RemoveToggle title={translate.confirm_to_delete} onClick={onRemoveClick} />}
            <button
              disabled={disabled}
              type="button"
              className="btn btn-light btn-sm pull-right ml-2"
              onClick={() => {
                try {
                  imagesField.current.imageUpload.click();
                } catch (e) {}
              }}
            >
              <i className="fa fa-upload fa-fw" /> {translate.upload_image}
            </button>
          </Fragment>
        }
      />
      <CardBody>
        <ImagesField
          ref={imagesField}
          showUploadButton={false}
          images={images}
          onChange={images => setFieldValue(fieldName, images)}
        />
      </CardBody>
      <CardBody className="text-center">
        <p className="lead font-italic text-muted mb-0">{translate.upload_image_description}</p>
      </CardBody>
    </Card>
  );
};
