import React, { Component } from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { Container } from 'reactstrap';
import Loading from '../../components/loading';
import LeftSideBar from './leftSideBar';
import { client } from '../../shared/apollo';
import AgentMenu from './_agentMenu';
import OwnerMenu from './_ownerMenu';
import CommonPage from './_commonPages';
import Wrapper from './_wrapper';
import _ from 'lodash';

const GET_QUERY = gql`
  query($shopId: ID) {
    me {
      id
      credentials {
        id
        identity
      }
      groups {
        name
        permissions
        shop {
          id
        }
        company {
          id
        }
      }
    }
    shop: node(id: $shopId) {
      id
      ... on Shop {
        title
      }
    }
  }
`;

export default class extends Component {
  getMenu = () => {
    const shopId = localStorage.getItem('shopId');
    const hasShopSelected = !!shopId;
    return hasShopSelected ? OwnerMenu : AgentMenu;
  };

  getPermissions = groups => {
    const shopId = localStorage.getItem('shopId');
    return [
      ...new Set(
        groups.reduce((reducer, { shop, permissions }) => {
          if (!shop) reducer.push(...permissions);
          else if (shop.id === shopId) reducer.push(...permissions);
          else if (!shopId) reducer.push(...permissions);

          return reducer;
        }, []),
      ),
    ];
  };

  filterPermittedPage = (pages, permissions) => {
    const permissionsStr = permissions.join(',');
    return pages.filter(group => {
      const { permission, children } = group;
      children && (group.children = this.filterPermittedPage(group.children, permissions));
      if (!permission) return true;
      if (Array.isArray(permission)) {
        return permission.reduce((reducer, perm) => {
          if (reducer) return reducer;
          return !!permissionsStr.match(new RegExp(`${perm}`));
        }, false);
      } else return !!permissionsStr.match(new RegExp(`${permission}`));
    });
  };

  toggleMenu = () => {
    try {
      this.sideBar.toggle();
    } catch (e) {}
  };

  onHeaderClick = () => {
    const { history } = this.props;
    history.push('/shops');
  };

  onMenuClick = ({ link }) => {
    const { history } = this.props;
    history.push(link);
  };

  onUserClick = () => {
    const { history } = this.props;
    history.push('/profile');
  };

  render() {
    const shopId = localStorage.getItem('shopId');
    const { history, location } = this.props;

    return (
      <Query query={GET_QUERY} variables={{ shopId }}>
        {({ data, loading }) => {
          if (loading) return <Loading />;
          const { me, shop } = data || {};
          if (!me) {
            client
              .resetStore()
              .then()
              .catch();
            history.replace('/login');
            return null;
          }
          const {
            groups,
            credentials: [credential],
          } = me || {};
          const { identity } = credential || {};
          const permissions = this.getPermissions(groups || []);
          const menu = this.getMenu();
          const pages = CommonPage.concat(_.flatten(_.map(menu, 'children')));

          const title = (shop || {}).title;

          return (
            <Container fluid={true} className={'d-flex p-0 h-100'}>
              <LeftSideBar
                ref={ref => (this.sideBar = ref)}
                identity={identity}
                title={title}
                menu={this.filterPermittedPage(menu, permissions)}
                location={location}
                onHeaderClick={this.onHeaderClick}
                onMenuClick={this.onMenuClick}
              />

              <Wrapper
                pages={this.filterPermittedPage(pages, permissions)}
                onMenuClick={this.toggleMenu}
                onUserClick={this.onUserClick}
              />
            </Container>
          );
        }}
      </Query>
    );
  }
}
