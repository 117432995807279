import React, { Component } from 'react';
import ActionButton from './ActionButton';
import { AST_False } from 'terser';

class CollapseRegion extends Component {
  getRegionStyle = show => ({
    display: show ? 'block' : 'none'
  });

  render() {
    const { children, show = false } = this.props;
    return <div style={this.getRegionStyle(show)}>{children}</div>;
  }
}

export default CollapseRegion;
