import React from 'react';
import { translate } from 'shared/translate';
import { Link } from 'react-router-dom';
import getDiscountContent from 'shared/getDiscountContent';

const TableColumnDefinition = () => (
  <colgroup>
    <col width="*" />
    <col width="*" />
    <col width="*" />
  </colgroup>
);

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>{translate.coupon_no}</th>
        <th>{translate.coupon_name}</th>
        <th>{translate.coupon_function}</th>
      </tr>
    </thead>
  );
};

export default ({ values: { coupons: items = [] } }) => {
  return (
    <div className="card mb-5 shadow">
      <div className="card-header d-flex">
        <h6 className="mb-0 flex-fill">{translate.coupons}</h6>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover">
          <TableColumnDefinition />
          <TableHeader />
          <tbody>
            {items.map(({ id, name, handle, discountType, discount, amountBeforeDiscount }, i) => (
              <tr key={i}>
                <td>
                  <Link to={`/coupons/${id}`}>{handle}</Link>
                </td>
                <td>{name}</td>
                <td>{getDiscountContent({ discountType, discount, amountBeforeDiscount })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {items.length === 0 && (
        <div className="card-body text-center">
          <p className="lead font-italic text-muted mb-0">{translate.no_data}</p>
        </div>
      )}
    </div>
  );
};
