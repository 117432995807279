import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Shop {
        credentials(otherFilter: { platforms: [MANUAL], types: [PAYMENT] }) {
          id
          name
          active
          platform
          identity
          secret
          type
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation($id: ID!) {
    shopCredentialUnset(id: $id) {
      id
      credentials(otherFilter: { platforms: [MANUAL], types: [PAYMENT] }) {
        id
        name
        active
        platform
        identity
        secret
        type
        attributes {
          key
          value
        }
      }
    }
  }
`;

export const SET_QUERY = gql`
  mutation($shopId: ID!, $input: ShopCredentialInput!) {
    credentialManualSet(shopId: $shopId, input: $input) {
      id
      name
      active
      platform
      identity
      secret
      type
      attributes {
        key
        value
      }
    }
  }
`;
