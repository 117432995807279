import React, { Fragment, useState } from 'react';
import { translate } from 'shared/translate';
import Card from 'components/layout/card/Card';
import CardHeader from 'components/layout/card/CardHeader';
import { CardBody } from 'reactstrap';
import { uuid } from 'uuidv4';
import ConfigHTML from './ConfigModule/ConfigHTML';
import ConfigBanner from './ConfigModule/ConfigBanner';
import ConfigProduct from './ConfigModule/ConfigProduct';
import ConfigSeparator from './ConfigModule/ConfigSeparator';
import ConfigGallery from './ConfigModule/ConfigGallery';
import PreviewBanner from './Preview/PreviewBanner';
import PreviewSeparator from './Preview/PreviewSeparator';
import PreviewProduct from './Preview/PreviewProduct';
import PreviewGallery from './Preview/PreviewGallery';
import PreviewHTML from './Preview/PreviewHTML';
import PhoneFrame from './_phoneFrame';
import RemoveToggle from 'components/form/RemoveToggle';
import FormRow from 'components/form/FormRow';

const editableModules = [
  {
    name: 'html',
    label: translate.customizable_html,
    module: ConfigHTML,
    preview: PreviewHTML,
  },
  {
    name: 'banner',
    label: translate.banner,
    module: ConfigBanner,
    preview: PreviewBanner,
  },
  {
    name: 'product_collections',
    label: translate.product_collections,
    preview: require('assets/preview-category.jpg'),
  },
  {
    name: 'product',
    label: translate.product,
    module: ConfigProduct,
    preview: PreviewProduct,
  },
  {
    name: 'separator',
    label: translate.separator,
    module: ConfigSeparator,
    preview: PreviewSeparator,
  },
  {
    name: 'gallery',
    label: translate.gallery,
    module: ConfigGallery,
    preview: PreviewGallery,
  },
  {
    name: 'search',
    label: translate.search,
    preview: require('assets/preview-search.jpg'),
  },
  {
    name: 'subscribe',
    label: translate.subscribe,
    preview: require('assets/preview-subscribe.jpg'),
  },
];

export default ({ values, setFieldValue, isSubmitting }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const { name, body } = values;

  const addComponent = () => {
    const section = {
      id: uuid(),
      name: 'html',
      config: {},
    };
    body.push(section);
    setFieldValue('body', body);
    setSelectedSection(section);
  };

  const removeComponent = section => {
    const index = body.findIndex(_section => _section === section);
    if (~index) {
      body.splice(index, 1);
      setFieldValue('body', body);
      setSelectedSection(null);
    }
  };

  const moveComponentUp = section => {
    const index = body.findIndex(_section => _section === section);
    if (index > 0) {
      body[index] = body[index - 1];
      body[index - 1] = section;
      setFieldValue('body', body);
    }
  };

  const moveComponentDown = section => {
    const index = body.findIndex(_section => _section === section);
    if (index < body.length - 1) {
      body[index] = body[index + 1];
      body[index + 1] = section;
      setFieldValue('body', body);
    }
  };

  return (
    <div className={'d-flex flex-row'}>
      <div className={'mr-3'}>
        <PhoneFrame className={'mb-5'} title={name || translate.custom_pages}>
          {body.map((section = {}, i) => {
            const { name = '', config = {} } = section;
            const find = editableModules.find(({ name: _name }) => _name === name);
            if (!find) return null;

            const selected = section === selectedSection;
            const Preview = find.preview;
            return (
              <div
                key={i}
                className={`position-relative ${selected ? 'shadow' : ''}`}
                style={{
                  transition: 'all 0.25s',
                  opacity: !selectedSection || selected ? 1 : 0.25,
                  minHeight: 47,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  !isSubmitting && setSelectedSection(section === selectedSection ? null : section);
                }}
              >
                {!!Preview && typeof Preview === 'string' ? (
                  <img src={Preview} className={'img-fluid'} />
                ) : (
                  <Preview config={config} />
                )}
              </div>
            );
          })}
          <div className={'p-3'}>
            <button type="button" className="btn btn-light btn-block" onClick={addComponent} disabled={isSubmitting}>
              <i className={'fa fa-plus'} />
            </button>
          </div>
        </PhoneFrame>
      </div>
      <div style={{ flex: 1 }}>
        {(() => {
          if (!selectedSection) return null;
          const { id = '', name = '', config = {} } = selectedSection;
          const configurationProps = {
            config,
            onChange: config => {
              selectedSection.config = config;
              setFieldValue('body', body);
            },
            disabled: isSubmitting,
          };
          const i = body.findIndex(section => section === selectedSection);
          const find = editableModules.find(({ name: _name }) => name === _name);
          const { module: Module, label } = find || {};
          return (
            <Card key={i}>
              <CardHeader
                title={label}
                extra={
                  <Fragment>
                    <button
                      type="button"
                      className="btn btn-light btn-sm mr-2"
                      onClick={() => moveComponentUp(selectedSection)}
                      disabled={isSubmitting}
                    >
                      <i className={'fa fa-arrow-up'} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btn-sm mr-2"
                      onClick={() => moveComponentDown(selectedSection)}
                      disabled={isSubmitting}
                    >
                      <i className={'fa fa-arrow-down'} />
                    </button>
                    <RemoveToggle
                      title={translate.delete}
                      onClick={() => {
                        removeComponent(selectedSection);
                      }}
                      disabled={isSubmitting}
                    />
                  </Fragment>
                }
              />
              <CardBody className={'p-0'}>
                <div className={'p-3'}>
                  <FormRow title={translate.component_type}>
                    <select
                      className={'form-control'}
                      required
                      value={name}
                      onChange={e => {
                        selectedSection.name = e.target.value;
                        setFieldValue('body', body);
                      }}
                      disabled={isSubmitting}
                    >
                      {editableModules.map(({ label, name }, i) => (
                        <option key={i} value={name}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </FormRow>
                </div>
                <div className={'flex-fill d-flex flex-column'}>
                  {!!Module && <Module key={id} {...configurationProps} />}
                </div>
              </CardBody>
            </Card>
          );
        })()}
      </div>
    </div>
  );
};
