import React from 'react';
import Card from '../../../components/layout/card/Card';
import CardHeader from 'components/layout/card/CardHeader';
import { translate } from '../../../shared/translate';
import { CardBody, CardFooter } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';

export default ({ values, setFieldValue, isSubmitting }) => {
  const handleKeyPress = (e, i) => {
    const opt = values.remarkSet[i];
    const newValue = e.target.value;

    if (e.keyCode === 13 || e.keyCode === 188) {
      e.preventDefault();
      if (newValue === '') return;

      let list = _.filter(
        _.split(newValue, ',').reduce((prev, value) => {
          return [...prev, value.trim()];
        }, []),
        value => value !== '',
      );

      if (!opt.options) opt.options = [];

      if (_.intersection(opt.options, list).length > 0) {
        toast.error(translate.same_option_content_error);
        return;
      }
      opt.options = _.concat(opt.options, list);
      e.target.value = '';
      setFieldValue('remarkSet', values.remarkSet);
    }
  };
  return (
    <Card>
      <CardHeader
        title={translate.product_remarks}
        extra={
          <button
            type="button"
            className="btn btn-light btn-sm pull-right ml-3"
            onClick={() => {
              if (!values.remarkSet) {
                values.remarkSet = [];
              }
              values.remarkSet.push({});
              setFieldValue('remarkSet', values.remarkSet);
            }}
          >
            <i className="fa fa-plus fa-fw" /> {translate.new_remark}
          </button>
        }
      />
      <CardBody className={'p-0'}>
        <table className="table table-borderless">
          <colgroup>
            <col width="15%" />
            <col width="*" />
            <col width="1%" />
          </colgroup>
          <thead>
            <tr>
              <th>{translate.variant_remark}</th>
              <th>{translate.variant_content}</th>
              <th />
            </tr>
          </thead>
          {(values.remarkSet || []).map(({ label = '', options = [] }, i) => {
            return (
              <tr key={i}>
                <th>
                  <input
                    disabled={isSubmitting}
                    className={`form-control`}
                    required={true}
                    type="text"
                    value={label}
                    onChange={e => {
                      values.remarkSet[i].label = e.target.value;
                      setFieldValue('remarkSet', values.remarkSet);
                    }}
                    placeholder={translate.remark_name_placeholder}
                  />
                </th>
                <th>
                  <div className="form-control h-auto">
                    {options.map((opt, i) => (
                      <button
                        disabled={isSubmitting}
                        key={i}
                        type="button"
                        className="btn btn-primary btn-sm mr-1 mb-3"
                        onClick={() => {
                          options.splice(i, 1);
                          setFieldValue('remarkSet', values.remarkSet);
                        }}
                      >
                        <span className="mr-2">{opt}</span>
                        <i className="fa fa-times" />
                      </button>
                    ))}
                    <input
                      disabled={isSubmitting}
                      className={`border-0 shadow-none p-0 w-100`}
                      style={{ outline: 'none' }}
                      type="text"
                      onKeyDown={e => handleKeyPress(e, i)}
                      placeholder={translate.remark_option_placeholder}
                    />
                  </div>
                </th>
                <th>
                  <button
                    disabled={isSubmitting}
                    type="button"
                    className="btn btn-link text-danger btn-sm"
                    onClick={() => {
                      values.remarkSet.splice(i, 1);
                      setFieldValue('remarkSet', values.remarkSet);
                    }}
                  >
                    <i className="fa fa-times" />
                  </button>
                </th>
              </tr>
            );
          })}
        </table>
      </CardBody>
      <CardFooter>
        <span className="text-muted">{translate.new_variant_remark}</span>
      </CardFooter>
    </Card>
  );
};
