import React, { Component } from 'react';
import { translate } from '../../shared/translate';
import Loading from '../../components/loading';

const AVAILABLE_LANGS = {
  zh_hk: "繁體中文",
  zh_cn: "简体中文",
  en_us: "English"
};

export default class extends Component {
  state = {
    toggle: false,
  };
  toggle = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };
  onLanguageClick = key => {
    translate.setLanguage(key);
    localStorage.setItem('lang', key);
    window.location.reload();

  };
  render() {
    if (!translate) return <Loading />;
    const options = translate.getAvailableLanguages();

    return (
      <select
        {...this.props}
        className="form-control"
        onChange={this.props.handleChange}
      >
        {options.map((lang, i) => (
          <option key={i} value={lang}>
            {AVAILABLE_LANGS[lang]}
          </option>
        ))}
      </select>
    );
  }
}
