import React, { Fragment } from 'react';
import { css } from 'glamor';
import { ToastContainer } from 'react-toastify';
import Router from './routes';
import ActivityIndicator from './components/ActivityIndicator';

export default () => (
  <Fragment>
    <Router />
    <ToastContainer
      hideProgressBar={true}
      newestOnTop={true}
      toastClassName={css({
        borderRadius: '0.25rem'
      })}
    />
    <ActivityIndicator />
  </Fragment>
);
