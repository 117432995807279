import { translate } from 'shared/translate';

export const CredentialPlatformType = {
  SF_EXPRESS: 'SF_EXPRESS',
  CPCB: 'CPCB',
};

export const ShopCredentialType = {
  payment: 'PAYMENT',
  authentication: 'AUTHENTICATION',
  shipment: 'SHIPMENT',
};

export const InvoiceStatus = {
  PENDING: translate['status_pending'],
  OVERDUE: translate['status_overdue'],
  COMPLETED: translate['status_completed'],
  VOIDED: translate['status_voided'],
  REFUNDED: translate['status_refunded'],
};

export const CreditNoteStatus = {
  PENDING: translate['status_pending'],
  COMPLETED: translate['status_completed'],
};

export const OrderStatus = {
  OPEN: translate['status_open'],
  PROCESSING: translate['status_processing'],
  COMPLETED: translate['status_completed'],
  CANCELLED: translate['status_cancelled'],
};

export const DeliveryNoteStatus = {
  PENDING: translate['status_pending'],
  PREPARING: translate['status_preparing'],
  TRANSIT: translate['status_transit'],
  DELIVERED: translate['status_delivered'],
  RETURNED: translate['status_returned'],
  LOST: translate['status_lost'],
  VOIDED: translate['status_voided'],
};

export const ShipmentProvider = {
  IN_SITU: '-',
  PICKUP: translate['pick_up'],
};
