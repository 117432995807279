import React from 'react';
import ImagesField from 'components/formPageMaker/Fields/_imagesField';

export default ({ config = {}, onChange = _ => _, disabled }) => {
  const { images = [] } = config || {};
  return (
    <ImagesField
      className={'p-3 pt-0'}
      disabled={disabled}
      images={images}
      onChange={images => {
        config.images = images;
        onChange(config);
      }}
    />
  );
};
