import { gql } from 'apollo-boost';

export const LIST_QUERY = gql`
  # query vendor list
  query($shopId: ID, $offset: OffsetInput, $filter: FilterInput, $sorter: [SorterInput!]) {
    node(id: $shopId) {
      id
      ... on Shop {
        vendorsCount(filter: $filter)
        vendors(offset: $offset, filter: $filter, sorter: $sorter) {
          id
          createdAt
          updatedAt
          attributes {
            key
            value
          }
          groups {
            name
          }
        }
      }
    }
  }
`;
