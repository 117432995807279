import React, { Component } from 'react';
import EditableProviderCard from './EditableProviderCard';

class ProviderList extends Component {
  render() {
    const { providers } = this.props;
    return (
      <div className='list-providers'>
        {providers.map(provider => (
          <EditableProviderCard key={provider.title} data={provider} />
        ))}
      </div>
    );
  }
}

export default ProviderList;
