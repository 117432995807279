import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { translate } from 'shared/translate';
import gql from 'graphql-tag';
import SearchComboCountry from 'components/SearchComboCountry';
import { client } from 'shared/apollo';
import { Query } from 'react-apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [
      { name: translate.settings, link: '/settings' },
      {
        name: translate.settings_title_payment_providers,
        link: '/settings/payments',
      },
      { name: translate.settings_title_merchant_info },
    ],
    gql: {
      prepare: gql`
        query($id: ID!) {
          node(id: $id) {
            id
            ... on Shop {
              id
              title
              autoComplete
              autoConfirm
              companyVerifyData {
                merchant_name
                merchant_name_en
                mcc
                business_description
                address {
                  person
                  tel
                  email
                  lines
                  country
                }
                bank_account {
                  country
                  currency
                  routing_number
                  branch_number
                  account_number
                  account_holder_name
                  account_holder_type
                }
              }
            }
          }
        }
      `,
      submit: gql`
        mutation($id: ID, $input: ShopInput!) {
          companyShopSet(id: $id, input: $input) {
            id
          }
        }
      `,
    },
    cards: [
      {
        name: translate.basic_info,
        fields: [
          {
            label: translate.merchant_name,
            type: 'text',
            name: 'merchant_name',
            required: true,
            maxLength: 255,
          },
          {
            label: translate.merchant_name + ' (EN)',
            type: 'text',
            name: 'merchant_name_en',
            maxLength: 255,
          },
          {
            label: translate.business_class,
            type: 'select',
            name: 'mcc',
            options: [
              {
                label: 'A/C, Refrigeration Repair',
                value: '7623',
              },
            ],
            required: true,
          },
          {
            label: translate.business_description,
            type: 'textarea',
            name: 'business_description',
            maxLength: 1000,
            text: translate.please_describe_your_product,
          },
        ],
      },
      {
        name: translate.business_address,
        fields: [
          {
            label: translate.contact_person,
            name: 'address.person',
            type: 'text',
            maxLength: 255,
          },
          {
            label: translate.email,
            name: 'address.email',
            type: 'email',
            maxLength: 500,
          },
          {
            label: translate.phone_no,
            name: 'address.tel',
            type: 'tel',
            maxLength: 20,
          },
          {
            label: translate.address,
            name: 'address.lines[0]',
            type: 'text',
            maxLength: 255,
          },
          {
            label: ' ',
            name: 'address.lines[1]',
            type: 'text',
            maxLength: 255,
          },
          {
            label: ' ',
            name: 'address.lines[2]',
            type: 'text',
            maxLength: 255,
          },
          {
            label: translate.country,
            render: ({ values: { address = {} }, setFieldValue }) => (
              <SearchComboCountry
                value={address.country}
                onChange={country => setFieldValue('address', { ...address, country })}
              />
            ),
          },
        ],
      },
      {
        name: translate.stripe_bank_details,
        fields: [
          {
            label: translate.bank_account_holder_type,
            type: 'select',
            name: 'bank_account.account_holder_type',
            required: true,
            options: [
              {
                label: translate.individual_account,
                value: 'individual',
              },
              {
                label: translate.company_account,
                value: 'company',
              },
            ],
          },
          {
            label: translate.country,
            required: true,
            render: ({ values: { bank_account = {} }, setFieldValue }) => (
              <SearchComboCountry
                required
                value={bank_account.country}
                onChange={country => setFieldValue('bank_account', { ...bank_account, country })}
              />
            ),
          },
          {
            label: translate.tran_merchant_currency,
            required: true,
            type: 'select',
            name: 'bank_account.currency',
            options: [{ label: 'HKD', value: 'HKD' }],
          },
          {
            label: translate.bank_routing_number,
            type: 'text',
            name: 'bank_account.routing_number',
            maxLength: 20,
            numberOnly: true,
            required: true,
          },
          {
            label: translate.bank_branch_number,
            type: 'text',
            name: 'bank_account.branch_number',
            maxLength: 20,
            numberOnly: true,
            required: true,
          },
          {
            label: translate.bank_account_number,
            type: 'text',
            name: 'bank_account.account_number',
            maxLength: 50,
            numberOnly: true,
            required: true,
          },
          {
            label: translate.bank_account_holder_name,
            type: 'text',
            name: 'bank_account.account_holder_name',
            maxLength: 50,
          },
        ],
      },
    ],
  };

  submit = async ({ title, autoComplete, autoConfirm, address, bank_account, ...values }) => {
    delete address.__typename;
    delete bank_account.__typename;
    delete values.__typename;

    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id: localStorage.getItem('shopId'),
        input: {
          autoComplete,
          autoConfirm,
          title,
          companyId: localStorage.getItem('companyId'),
          companyVerifyData: {
            ...values,
            address,
            bank_account,
          },
        },
      },
    });
    return true;
  };

  getInitialData(data) {
    const { node } = data || {};
    const { title, autoComplete, autoConfirm, companyVerifyData } = node || {};

    const { address, bank_account, ...others } = companyVerifyData || {};
    return {
      autoComplete,
      autoConfirm,
      title,
      address: address || {},
      bank_account: bank_account || {},
      ...others,
    };
  }

  render() {
    const {
      gql: { prepare },
    } = this.state;
    return (
      <Query query={prepare} variables={{ id: localStorage.getItem('shopId') }}>
        {this.renderContent.bind(this)}
      </Query>
    );
  }
}
