import React, { Component } from 'react';
import ProviderCard from './ProviderCard';

import { ShippingProviderField } from './fields';
import { ShopCredentialType } from './../../../shared/omniwe-types';

import { translate } from './../../../shared/translate';
import { toast } from 'react-toastify';
import { client } from './../../../shared/apollo';
import gql from 'graphql-tag';

const SET_CREDENTIAL = gql`
  mutation companyShopCredentialSet(
    $shopId: ID!
    $credentials: [ShopCredentialInput!]!
  ) {
    companyShopCredentialSet(shopId: $shopId, credentials: $credentials) {
      id
      credentials {
        active
        identity
        platform
        secret
        type
      }
    }
  }
`;

class EditableProviderCard extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    for (const field in ShippingProviderField) {
      const fieldName = ShippingProviderField[field];
      this.state[fieldName] = null;
    }
  }

  handleUpdate = (field, newValue) => {
    this.setState({ [field]: newValue });
  };

  getData = () => {
    const { data } = this.props;
    const id = this.state[ShippingProviderField.id];
    const secret = this.state[ShippingProviderField.secret];
    const active = this.state[ShippingProviderField.enable];

    return {
      icon: data.icon,
      title: data.title,
      description: data.description,
      platform: data.platform,
      identity: id !== null ? id : data.identity || '',
      secret: secret !== null ? secret : data.secret || '',
      active: active !== null ? active : data.active || false
    };
  };

  async handleSubmit() {
    const data = this.getData();

    this.setState({ loading: true });
    try {
      await client.mutate({
        mutation: SET_CREDENTIAL,
        variables: {
          shopId: localStorage.getItem('shopId'),
          credentials: [
            {
              active: data.active,
              identity: data.identity,
              platform: data.platform,
              secret: data.secret,
              type: ShopCredentialType.shipment
            }
          ]
        }
      });
      toast.success(translate.update_success_msg);
      client.resetStore();
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <ProviderCard
        disable={this.state.loading}
        data={this.getData()}
        onUpdate={this.handleUpdate}
        onSubmit={this.handleSubmit.bind(this)}
      />
    );
  }
}

export default EditableProviderCard;
