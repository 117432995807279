import React from 'react';
import { translate } from './../../../shared/translate';
import DataFormatter from './../../../shared/data-formatter';

export default ({ updatedAt }) => {
  if (!updatedAt) return null;
  return (
    <div className='card-footer d-flex justify-content-end'>
      <i className='small text-muted'>
        {`${translate.updated_at}: ${DataFormatter.fromIsoDate(updatedAt)}`}
      </i>
    </div>
  );
};
