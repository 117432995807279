import React, { Component } from 'react';
import { translate } from '../../shared/translate';
import DataFormatter from '../../shared/data-formatter';

import StatusCard from '../../components/layout/card/StatusCard';
import SingleLineTextField from '../../components/form/SingleLineTextField';

class TicketStatusCard extends Component {
  render() {
    const {
      initialValues: {
        status,
        updatedAt,
        orderId,
        customer,
        contact,
        createdAt
      },
      onEvents
    } = this.props;

    const statusOptions = [
      {
        value: 'PENDING',
        label: translate.status_pending
      },
      {
        value: 'COMPLETED',
        label: translate.status_completed
      }
    ];

    const statusCardData = {
      status,
      updatedAt,
      statusOptions
    };

    return (
      <StatusCard
        title={'Ticket Details'}
        data={statusCardData}
        onEvents={onEvents}
      >
        <SingleLineTextField
          label={translate.order_number}
          value={orderId}
          disabled
        />
        <SingleLineTextField
          label={translate.customer_fullname}
          value={customer}
          disabled
        />
        <SingleLineTextField
          label={translate.contact}
          value={contact}
          disabled
        />
        <SingleLineTextField
          label={translate.created_at}
          value={DataFormatter.fromIsoDate(createdAt)}
          disabled
        />
      </StatusCard>
    );
  }
}

export default TicketStatusCard;
