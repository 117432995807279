import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate } from 'shared/translate';
import { Link } from 'react-router-dom';

export default ({ values: { amount: { amount = 0 } = {}, items = [], order } }) => {
  const { id: orderId } = order || {};
  return (
    <Card>
      <CardHeader>
        <span>{translate.order_number}</span>
        <Link className={`ml-2`} to={`/orders/${orderId}`}>
          {orderId}
        </Link>
      </CardHeader>
      <CardBody className={'p-0'}>
        <table className="table table-hover">
          <colgroup>
            <col width="*" />
            <col width="120" />
            <col width="120" />
            <col width="120" />
          </colgroup>
          <thead>
            <tr>
              <th>{translate.name}</th>
              <th className="d-none d-sm-table-cell text-right">{translate.price}</th>
              <th className="d-none d-sm-table-cell text-right">{translate.quantity}</th>
              <th className={`text-right`}>{translate.sub_total}</th>
            </tr>
          </thead>
          <tbody>
            {items.map(({ orderItem, quantity = 0 }, i) => {
              const { description, productVariant, unitPrice: { amount: unitPrice, locale } = {}, remarks = '' } =
                  orderItem || {},
                { product: { name } = {}, attributes: productVariantAttributes = [] } = productVariant || {},
                variantNames = productVariantAttributes
                  .filter(({ value }) => !!value && value !== '-')
                  .map(({ key, value }) => `${key}: ${value}`)
                  .concat((remarks || '').split('\n'))
                  .join(' / ')
                  .trim();

              let displayName = name || description;

              return (
                <tr key={i}>
                  <td>
                    <div>{displayName}</div>
                    <div className={'text-muted small'}>{variantNames}</div>
                  </td>
                  <td className="d-none d-sm-table-cell text-right">
                    {locale} {unitPrice.toFixed(2)}
                  </td>
                  <td className="d-none d-sm-table-cell text-right">{quantity}</td>
                  <td className={`text-right`}>{(unitPrice * quantity).toFixed(2)}</td>
                </tr>
              );
            })}
            <tr>
              <td className="font-weight-bold text-right" colSpan={3} style={{ verticalAlign: 'middle' }}>
                {translate.total}
              </td>
              <td className="text-right">
                <span>{amount.toFixed(2)}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};
