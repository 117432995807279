import React from 'react';
import { translate } from '../../shared/translate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import RemoveToggle from '../../components/form/RemoveToggle';

export default ({ status, createdAt, expiredAt, onLogoutClick }) => (
  <div className="card shadow">
    <div className="card-header d-flex">
      <h6 className="mb-0 flex-fill">{translate.account_status}</h6>
      <RemoveToggle onClick={onLogoutClick} title={translate.logout}>
        <i className="fa fa-fw fa-sign-out" />
      </RemoveToggle>
    </div>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-2 col-form-label text-muted">{translate.subscription_since}</label>
        <div className="col-sm-10">
          <input
            className="form-control-plaintext"
            type="text"
            disabled={true}
            defaultValue={moment(createdAt).format('YYYY/MM/DD')}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label text-muted">{translate.status}</label>
        <div className="col-sm-10">
          <input
            className="form-control-plaintext"
            type="text"
            disabled={true}
            defaultValue={
              {
                TRIAL: translate.trial,
                ACTIVATED: translate.activated,
                EXPIRED: translate.expired,
              }[status || "ACTIVATED"]
            }
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label text-muted">{translate.expire_on}</label>
        <div className="col-sm-10">
          <input
            className="form-control-plaintext"
            type="text"
            disabled={true}
            defaultValue={moment(expiredAt).format('YYYY/MM/DD')}
          />
        </div>
      </div>
    </div>
    {status === 'TRIAL' && (
      <div className="card-footer text-right">
        <Link to={'/plans'} className="btn btn-primary">
          {translate.upgrade_account}
        </Link>
      </div>
    )}
  </div>
);
