import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Order {
        referenceNo
        shipment {
          provider
        }
        user {
          id
          attributes {
            key
            value
          }
        }
        coupons {
          id
          name
          handle
          discountType
          discount
          amountBeforeDiscount
        }
        checkout {
          id
          status
        }
        billingAddress {
          person
          tel
          email
          lines
          country
        }
        shippingAddress {
          person
          tel
          email
          lines
          country
        }
        items {
          id
          description
          remarks
          productVariant {
            id
            product {
              id
              name
            }
            attributes {
              key
              value
            }
          }
          quantity
          unitPrice {
            amount
            locale
          }
        }
        invoices {
          id
          status
          paymentCredential {
            id
            platform
            name
          }
          paymentMethod
          refundedAmount {
            amount
            locale
          }
          amount {
            amount
            locale
          }
          createdAt
          updatedAt
        }
        deliveryNotes {
          id
          status
        }

        attributes {
          key
          value
        }
        status
        subtotal {
          amount
          locale
        }
        total {
          amount
          locale
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation orderCancel($id: ID!, $reason: String!) {
    orderCancel(id: $id, reason: $reason) {
      id
      status
      invoices {
        id
        status
      }
    }
  }
`;
export const CHECKOUT_CONFIRM_QUERY = gql`
  mutation($id: ID!) {
    checkoutConfirm(id: $id) {
      id
      status
    }
  }
`;
export const COMPLETE_QUERY = gql`
  mutation($id: ID!) {
    orderComplete(id: $id) {
      id
      status
    }
  }
`;
