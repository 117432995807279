import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate as t } from '../../shared/translate';
import { Link } from 'react-router-dom';
import { GET_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.taxes }],
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/taxes/new',
    fields: [
      {
        title: t.name,
        fieldName: 'name',
        type: 'text',
        render: item => (
          <Link
            to={{
              pathname: 'taxes/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            {item.name}
          </Link>
        ),
        filter: true,
        sortable: 'name',
      },
      {
        title: t.settings_title_tax,
        width: 120,
        align: 'center',
        render: item => `${item.taxRate} %`,
        sortable: 'taxRate',
      },
      {
        title: t.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        width: 160,
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: t.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        align: 'right',
        width: 160,
        filter: true,
        sortable: 'updatedAt',
      },
    ],
  };
  getData = ({ node } = {}) => {
    const { taxes = [], taxesCount = 0 } = node || {};
    return { list: taxes, count: taxesCount };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
