import React from 'react';
import _ from 'lodash';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { FormRow, Button, toast } from '../../../components/omniwe';
import { translate as t } from '../../../shared/translate';
import Unset from './Unset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import VenueComments from '../../../components/venue_comments';
import { Link } from 'react-router-dom';

export default class ClientAppointIndex extends React.Component {
  state = {
    open: false,
    currentSlot: undefined,
  };

  getTimeRange = (slots, dateOnly) => {
    slots.sort((a, b) => {
      return new Date(a.startedAt) - new Date(b.startedAt);
    });

    const firstSlot = _.first(slots);
    const lastSlot = _.last(slots);

    if (dateOnly) {
      return `${moment(firstSlot.startedAt).format('YYYY/MM/DD')}`;
    }

    return `${moment(firstSlot.startedAt).format('hh:mm A')} - ${moment(lastSlot.startedThru).format('hh:mm A')}`;
  };

  init = currentSlot => {
    this.setState({ currentSlot });
  };

  toggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { open, currentSlot } = this.state;
    const { onChange } = this.props;

    if (!currentSlot) return null;

    const { address: { person = '-', tel = '-', email = '-' } = {}, orderItem } = currentSlot || {},
      { order } = orderItem || {},
      { id: orderId } = order || {};
    // const clientFullnameAttr = _.find(user.attributes, { key: 'name' });
    // const clientFullname = clientFullnameAttr.value || '-';
    // const addr = _.first(user.addresses) || {};
    // const phoneNo = addr.tel || '-';
    // const credential = _.find(user.credentials, { platform: 'PASSWORD' });

    return (
      <Modal isOpen={open}>
        <ModalHeader className="omniwe-modal-title">
          <span>{t.appointment_details}</span>
          <div>
            <Unset
              slotId={currentSlot.id}
              afterDeleted={() => {
                this.toggle();
                onChange();
              }}
            />
            <Button theme={'link'} className={'text-dark'} onClick={this.toggle}>
              <FontAwesomeIcon className={'ml-2'} icon={faTimes} />
            </Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormRow useFormRow labelMd={3} title={t.customer_fullname}>
            <input className="form-control-plaintext" type="text" disabled={true} defaultValue={person} />
          </FormRow>
          <FormRow useFormRow labelMd={3} title={t.contact_phone_no}>
            <input className="form-control-plaintext" type="text" disabled={true} defaultValue={tel} />
          </FormRow>
          <FormRow useFormRow labelMd={3} title={t.email}>
            <input className="form-control-plaintext" type="text" disabled={true} defaultValue={email} />
          </FormRow>
          <FormRow useFormRow labelMd={3} title={t.service_name}>
            <input className="form-control-plaintext" type="text" disabled={true} defaultValue={currentSlot.name} />
          </FormRow>
          <FormRow useFormRow labelMd={3} title={t.time_range}>
            <input
              className="form-control-plaintext"
              type="text"
              disabled={true}
              defaultValue={this.getTimeRange(currentSlot.slots, true)}
            />
            <input
              className="form-control-plaintext"
              type="text"
              disabled={true}
              defaultValue={this.getTimeRange(currentSlot.slots, false)}
            />
          </FormRow>
          <FormRow useFormRow labelMd={3} title={t.status}>
            <input
              className="form-control-plaintext"
              type="text"
              disabled={true}
              defaultValue={t[`status_${currentSlot.status.toLowerCase()}`]}
            />
          </FormRow>
          <FormRow useFormRow labelCol={true} labelMd={3} title={t.order_number}>
            <div className={'h-100 d-flex align-items-center'}>
              <Link to={`/orders/${orderId}`} target={'_blank'}>
                {orderId}
              </Link>
            </div>
          </FormRow>
        </ModalBody>

        <div>
          <VenueComments serviceName={currentSlot.name} orderId={orderId} />
        </div>
      </Modal>
    );
  }
}
