import React from 'react';
import { translate } from '../../shared/translate';
import Form from '../../components/form';
import { Switch, Route } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Loading from '../../components/loading';
import PageError from '../../components/pageComponents/PageError';
import ObjectNotFound from '../../components/pageComponents/ObjectNotFound';
import { BreadcrumbProxy as Breadcrumb } from '../../components/pageComponents/CrumbNavi';
import * as PU from '../../components/pageComponents/utility';
import ApplicationDetails from './application_details';
import ApplicationForm from './application_form';
import ApplicationHistory from './ApplicationHistory';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      createdAt
      updatedAt
      ... on AgentService {
        id
        title
        active
        description
        applicationsCount
      }
      attributes {
        key
        value
      }
    }
  }
`;

const _formHeader = props => (
  <div className='card-header d-flex'>
    <h6 className='mb-0 flex-fill'>{translate.service_market_item}</h6>
    {props.children}
  </div>
);

export default class MarketServiceItemForOwner extends Form {
  constructor(props) {
    super(props);

    const {
      match: { params = {} }
    } = this.props;
    const currentId = params.id;
    this.state = {
      loading: false,
      currentId,
      attributes: [],
      /** applicaiton details id which is currently viewing */
      detailsId: null
    };
  }

  viewApplicationDetails = appId => {
    this.setState({ detailsId: appId });
  };

  updateBreadcrumb(serviceName) {
    if (!serviceName) {
      serviceName = '*';
    }

    let breadcrumb = [
      { url: '/market_services', text: translate.service_market_list }
    ];
    breadcrumb.push(serviceName);
    Breadcrumb.update(breadcrumb);
  }

  render() {
    const { history } = this.props;
    return (
      <div className='container-fluid mb-5'>
        <Breadcrumb />
        <Query query={GET_QUERY} variables={{ id: this.state.currentId }}>
          {({ loading, error, data: { node = {} } = {} }) => {
            if (error) return <PageError errors={error} />;
            if (loading) return <Loading />;
            if (!node && this.state.currentId)
              return <ObjectNotFound msg={'ID ' + this.state.currentId} />;

            node = node || {};
            this.updateBreadcrumb(node.title);
            return (
              <React.Fragment>
                <Card>
                  <_formHeader />
                  <CardBody>
                    <Row className='mb-3'>
                      <Col sm={2}>{translate.name}</Col>
                      <Col>{node.title}</Col>
                    </Row>
                    <Row className='mb-3'>
                      <Col sm={2}>{translate.description}</Col>
                      <Col>
                        {' '}
                        {node.description && (
                          <PU.Html content={node.description} />
                        )}
                        {!node.description && <PU.DefaultText />}
                      </Col>
                    </Row>
                    <Row className='mb-3'>
                      <Col sm={2} />
                      <Col>
                        {node.active && (
                          <React.Fragment>
                            <PU.Activation active={true} />{' '}
                            {
                              translate.agent_service_open_to_merchant_for_applying
                            }
                          </React.Fragment>
                        )}
                        {!node.active && <PU.Activation active={false} />}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <ApplicationHistory
                  serviceId={node.id}
                  view={this.viewApplicationDetails}
                  history={history}
                />
                <Switch>
                  <Route
                    path='/market_services/:serviceId/:appId'
                    component={ApplicationDetails}
                    exact
                  />
                  <Route
                    path='/market_services/:serviceId'
                    component={ApplicationForm}
                    exact
                  />
                </Switch>
                {/* {!this.state.detailsId && (
                  <ApplicationForm serviceId={node.id} />
                )}
                {this.state.detailsId && (
                  <ApplicationDetails
                    id={this.state.detailsId}
                    key={this.state.detailsId}
                  />
                )} */}
              </React.Fragment>
            ); //end return
          }}
        </Query>
      </div>
    ); // end return
  } // end render
} // end class
