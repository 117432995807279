import React, { Component } from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { translate } from '../../shared/translate';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const GET_QUERY = gql`
  query($id: ID, $offset: OffsetInput, $filter: FilterInput, $otherFilter: OrderFilterInput, $sorter: [SorterInput!]) {
    node(id: $id) {
      id
      ... on User {
        orders(offset: $offset, otherFilter: $otherFilter, filter: $filter, sorter: $sorter) {
          id
          status
          referenceNo
          total {
            amount
          }
          attributes {
            key
            value
          }
          createdAt
        }
        ordersCount(otherFilter: $otherFilter)
      }
    }
  }
`;

export default class extends ListPageMaker {
  state = {
    ...this.state,
    title: translate.orders,
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.order_number,
        width: 150,
        render: item => <Link to={{ pathname: '/orders/' + item.id }}>{`#${item.referenceNo.slice(-3)}`}</Link>,
      },
      {
        title: translate.order_date,
        headerClassName: 'text-right',
        fieldName: 'createdAt',
        type: 'datetime',
        width: 160,
        sortable: 'createdAt',
      },
      {
        title: translate.status,
        headerClassName: 'text-right',
        render: ({ status }) => {
          return {
            OPEN: translate.pending,
            PROCESSING: translate.processing,
            COMPLETED: translate.completed,
            CANCELLED: translate.cancelled,
          }[status];
        },
        width: 90,
        sortable: 'status',
      },
      {
        title: translate.bill_to,
        headerClassName: 'text-right',
        render: ({ buyer }) => buyer.person,
        width: 120,
      },
      {
        title: translate.ship_to,
        headerClassName: 'text-right',
        render: ({ receiver }) => receiver.person,
        width: 120,
      },
      {
        title: translate.amount,
        headerClassName: 'text-right',
        render: ({ total: { amount, locale = 'HKD' } = {} }) => `${locale} ${(amount || 0).toFixed(2)}`,
        width: 120,
        sortable: 'amount',
      },
    ],
  };

  getData = (data = {}) => {
    const { node } = data || {},
      { orders = [], ordersCount = 0 } = node || {};

    const list = orders.map(item => {
      const { attributes = [] } = item || {};
      let attrs = _.groupBy(attributes, 'key');
      console.log(attrs);
      console.log(123);
      let { buyer: [{ value: buyer } = {}] = [], receiver: [{ value: receiver } = {}] = [] } = attrs;

      buyer = JSON.parse(buyer || '{}');
      receiver = JSON.parse(receiver || '{}');
      return { ...item, buyer, receiver };
    });
    return { list, count: ordersCount };
  };

  getExtraFetchVariables() {
    const { userId } = this.props;
    return { id: userId };
  }
}
