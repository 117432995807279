import React, { Fragment } from 'react';
import ListPageTemplate from 'components/listPageMaker';
import { GET_QUERY } from './query';
import { Link } from 'react-router-dom';

export default class extends ListPageTemplate {
  state = {
    ...this.state,
    breadcrumb: [
      {
        label: 'Billing',
      },
    ],
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/billings/new',
    fields: [
      {
        title: 'Brand',
        width: 120,
        render: ({ brand }) => brand,
      },
      {
        title: 'Card Number',
        render: ({ id, last4, isDefault }) => (
          <Fragment>
            <Link to={'/billings/' + id}>**** **** **** {last4}</Link>
            {isDefault && <span className="ml-2 badge badge-pill badge-primary">Default</span>}
          </Fragment>
        ),
      },
      {
        title: 'Expires',
        render: ({ exp_month, exp_year }) => (
          <span>
            {exp_month}/{exp_year}
          </span>
        ),
      },
      {
        title: 'Holder',
        render: ({ name }) => name,
      },
    ],
  };

  getData = ({ me } = {}) => {
    const { billings = [] } = me || {};
    return { list: billings, count: billings.length };
  };
}
