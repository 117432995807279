import React, { Component } from 'react';
import ActionButton from './ActionButton';
import { Icon } from './../iconRender';

const ExpandedButton = ({ onClick, label, hover }) => {
  const hoverStyle = { position: 'absolute', right: '100%' };
  return (
    <div className='btn-group' style={hover ? hoverStyle : {}}>
      <ActionButton onClick={onClick} theme='btn-danger btn-sm no-animate'>
        {/* <Icon icon='faExclamationCircle' /> &nbsp; */}
        {label}
      </ActionButton>
    </div>
  );
};

const ToggleButton = ({ icon, onClick, toggle }) => {
  let caret = '';
  toggle ? (caret = 'faCaretRight') : (caret = 'faCaretLeft');
  return (
    <ActionButton onClick={onClick} theme='btn-danger'>
      <Icon icon={caret} fixedWidth={false} className='mr-1' />
      <Icon icon={icon} fixedWidth={false} />
    </ActionButton>
  );
};

class DangerButton extends Component {
  state = { expand: false };

  toggleExpansion = () => this.setState({ expand: !this.state.expand });

  render() {
    const { onClick, label, icon, hover } = this.props;
    const { expand } = this.state;
    return (
      /* 
      Bootstrap btn-group class adds -1px to eliminate the button radius in 
      adjacent buttons but this behaviour causes spacing to 'jump'. 
      HACK : So we add the 1px  back when our button expands.
      */
      <div style={{ marginLeft: expand ? '1px' : '0px' }}>
        <div className='btn-group btn-group-sm'>
          {expand && (
            <ExpandedButton onClick={onClick} label={label} hover={hover} />
          )}
          <ToggleButton
            icon={icon}
            onClick={this.toggleExpansion}
            toggle={expand}
          />
        </div>
      </div>
    );
  }
}

export default DangerButton;
