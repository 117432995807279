import { gql } from 'apollo-boost';

export const LIST_QUERY = gql`
  query(
    $shopId: ID
    $offset: OffsetInput
    $filter: FilterInput
    $otherFilter: CustomPagesFilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $shopId) {
      id
      ... on Shop {
        id
        count: customPagesCount(otherFilter: $otherFilter, filter: $filter)
        items: customPages(offset: $offset, otherFilter: $otherFilter, filter: $filter, sorter: $sorter) {
          id
          name
          href
          active
          display
          createdAt
          updatedAt
        }
      }
    }
  }
`;
