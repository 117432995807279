import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate } from 'shared/translate';
import { GET_ORDERS } from './_gql';
import { Link } from 'react-router-dom';

export default class CouponCard extends ListPageMaker {
  static defaultProps = {
    className: 'p-0',
  };
  state = {
    ...this.state,
    offset: { limit: 10, skip: 0 },
    title: translate.orders,
    gql: {
      get: GET_ORDERS,
    },
    fields: [
      {
        title: translate.order_number,
        width: 150,
        render: item => <Link to={{ pathname: '/orders/' + item.id }}>{`#${item.referenceNo.slice(-3)}`}</Link>,
      },
      {
        title: translate.amount,
        headerClassName: 'text-right',
        render: ({ total: { amount, locale = 'HKD' } = {} }) => `${locale} ${(amount || 0).toFixed(2)}`,
        width: 120,
        sortable: 'amount',
      },
      {
        title: translate.order_date,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        headerClassName: 'text-right',
        width: 160,
        filter: true,
        sortable: 'createdAt',
      },
    ],
  };
  getExtraFetchVariables() {
    const { values: { id } = {} } = this.props;
    return { id };
  }
  getData = ({ node } = {}) => {
    const { orders = [], ordersCount = 0 } = node || {};
    return { list: orders, count: ordersCount };
  };
}
