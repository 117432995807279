// 中介服务商服務表单编辑页面
// src/routers/item_agent_service.jsx
// created on april-18, 2019, by Tony Day
import React from 'react';
import { Card, CardBody, Col, Row, FormGroup, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { translate as t } from '../../shared/translate';
import Form from '../../components/form';
import Loading from '../../components/loading';
import PageError from '../../components/pageComponents/PageError';
import ObjectNotFound from '../../components/pageComponents/ObjectNotFound';
import CrumbNavi from '../../components/pageComponents/CrumbNavi';
import * as pageUtility from '../../components/pageComponents/utility';
import Route from '../../components/route';
import TinyComponent from '../../components/tinyComponent';

const GET_QUERY = gql`
  query($id: ID, $countFilter: AgentServiceFilter) {
    node(id: $id) {
      id
      createdAt
      updatedAt
      ... on AgentService {
        title
        active
        description
        applicationsCount
        openingCount: applicationsCount(otherFilter: $countFilter)
      }
      attributes {
        key
        value
      }
    }
  }
`;
const POST_QUERY = gql`
  mutation($id: ID, $input: AgentServiceInput!) {
    agentServiceSet(id: $id, input: $input) {
      id
      title
      description
      attributes {
        key
        value
      }
    }
  }
`;

const STATUS_UPDATE_QUERY = gql`
  # SET ACTIVE OF AGENT SERVICE TO FALSE
  mutation($serviceId: ID!) {
    agentServiceUnset(id: $serviceId) {
      id
      active
      title
    }
  }
`;
const _formHeader = props => (
  <div className='card-header d-flex'>
    <h6 className='mb-0 flex-fill'>{t.agent_service_details}</h6>
    {props.children}
  </div>
);

const _formAdditionalInfo = ({ updatedAt }) => (
  <div className='card-footer d-flex justify-content-end'>
    <i className='small text-muted'>
      {t.updated_at}:{moment(updatedAt).format(' YYYY-MM-DD HH:mm:ss')}
    </i>
  </div>
);

const _bottom = ({ props, id, node, loading = false }) => (
  <div className='card'>
    <div className='card-body'>
      {!loading && <Route {...props} text={t.cancel} />}
      <button
        type='submit'
        className='btn btn-dark shadow pull-right'
        disabled={loading}
      >
        {loading && <pageUtility.Spinner />}
        {t.save}
      </button>
    </div>
    {id && <_formAdditionalInfo updatedAt={node.updatedAt} />}
  </div>
);

const _delete = ({ removeItem, loading, showRemoveBtn, toggle }) => (
  <div className='btn-group btn-group-sm'>
    {showRemoveBtn && (
      <button
        className='btn btn-danger no-animate'
        type='button'
        onClick={removeItem}
        disabled={loading}
      >
        <i className='fa fa-fw fa-exclamation-circle' />
        <span>{t.confirm_stop_to_close}</span>
      </button>
    )}
    <button
      className='btn btn-danger'
      type='button'
      disabled={loading}
      onClick={toggle}
    >
      {!showRemoveBtn && <i className='fa fa-caret-left' />}
      {showRemoveBtn && <i className='fa fa-caret-right' />}
      <i className='fa fa-check' style={{ marginLeft: '5px' }} />
    </button>
  </div>
);

const formLoading = false;

/**中介服务商表单编辑component */
export default class FormAgentService extends Form {
  constructor(props) {
    super(props);

    const {
      match: { params = {} }
    } = this.props;
    const currentId = params.id === 'new' ? undefined : params.id;
    const breadcrumb = this.getBreadcrumb(currentId);
    this.state = {
      loading: false,
      currentId,
      attributes: [],
      breadcrumb,
      showRemoveBtn: false
    };
  }

  getBreadcrumb(currentId) {
    let breadcrumb = [{ url: '/agent_service', text: t.agent_service_list }];
    if (currentId) {
      // breadcrumb.push(t.edit);
      breadcrumb.push(currentId);
    } else {
      breadcrumb.push(t.create);
    }

    return breadcrumb;
  }

  async removeItem(client) {
    this.loading(true);
    try {
      await client.mutate({
        mutation: STATUS_UPDATE_QUERY,
        variables: {
          serviceId: this.state.currentId
        }
      });
      client.resetStore();
      toast.success(t.change_status_success);
      this.props.history.goBack();
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      } else {
        alert('ERROR: ' + JSON.stringify(e));
      }
    } finally {
      this.loading(false);
    }
  }

  async submit(e, client) {
    e.preventDefault();
    this.loading(true);
    try {
      const { data: { agentServiceSet: result } = {} } = await client.mutate({
        mutation: POST_QUERY,
        variables: {
          id: this.state.currentId,
          input: {
            title: this.getCurrentRef('title').value,
            active: this.getCurrentRef('active').checked,
            description: this.content,
            attributes: this.state.attributes
          }
        }
      });
      toast.success(
        !this.state.currentId ? t.create_success : t.update_success
      );

      if (!this.state.currentId) {
        this.props.history.replace('/agent_service/' + result.id, {
          id: result.id
        });
        const breadcrumb = this.getBreadcrumb(result.id);
        this.setState({
          currentId: result.id,
          breadcrumb
        });
        client.resetStore();
      }
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      } else {
        alert('ERROR: ' + JSON.stringify(e));
      }
    } finally {
      this.loading(false);
    }
  }

  render() {
    const { currentId } = this.state;
    const { history } = this.props;
    return (
      <div className='container-fluid mb-5'>
        <CrumbNavi items={this.state.breadcrumb} />
        <Query
          query={GET_QUERY}
          variables={{
            id: this.state.currentId,
            countFilter: {
              statuses: ['PENDING', 'PROCESSING']
            }
          }}
        >
          {({ client, loading, error, data: { node = {} } = {} }) => {
            if (error) return <PageError errors={error} />;
            if (loading) return <Loading />;
            if (!node && this.state.currentId)
              return <ObjectNotFound msg={'ID ' + this.state.currentId} />;

            node = node || {};
            return (
              <form onSubmit={e => this.submit(e, client)}>
                <div className='card mb-5 shadow'>
                  <_formHeader>
                    {currentId && node.active && (
                      <_delete
                        removeItem={() => this.removeItem(client)}
                        loading={this.state.loading}
                        showRemoveBtn={this.state.showRemoveBtn}
                        toggle={() =>
                          this.setState({
                            showRemoveBtn: !this.state.showRemoveBtn
                          })
                        }
                      />
                    )}
                    {currentId && (
                      <Button
                        onClick={() =>
                          history.push('/agent_service_application/' + node.id)
                        }
                        color='secondary'
                        size='sm'
                        className='ml-3'
                      >
                        {t.agent_service_all_application}
                      </Button>
                    )}
                  </_formHeader>
                  <div className='card-body'>
                    <div className='form-group row'>
                      <label
                        htmlFor='title'
                        className='col-sm-2 col-form-label'
                      >
                        {t.name}
                      </label>
                      <div className='col-sm'>
                        <input
                          ref={this.getRef('title')}
                          type='text'
                          className='form-control'
                          id='title'
                          defaultValue={node.title}
                          maxLength={255}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='description'
                        className='col-sm-2 col-form-label'
                      >
                        {t.description}
                      </label>
                      <div className='col-sm'>
                        <div className='border'>
                          <TinyComponent
                            innerRef={this.getRef('tinymce')}
                            value={node.description}
                            disabled={formLoading}
                            onChange={e =>
                              (this.content = e.target.getContent())
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='active'
                        className='col-sm-2 col-form-label'
                      >
                        {t.opening_for_app}
                      </label>
                      <div className='col-sm'>
                        <label>
                          <input
                            className='form-control-check'
                            ref={this.getRef('active')}
                            type='checkbox'
                            defaultChecked={node.active}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.currentId && (
                  <Card>
                    <CardHeader>{t.agent_misc_info}</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm={2}>{t.agent_applied_times}</Col>
                        <Col>{node.applicationsCount}</Col>
                        <Col sm={2}>{t.agent_unclosed_count}</Col>
                        <Col>{node.openingCount}</Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
                <_bottom
                  props={this.props}
                  loading={this.state.loading}
                  id={this.state.currentId}
                  node={node}
                />
              </form>
            ); //end return
          }}
        </Query>
      </div>
    ); // end return
  } // end render
} // end class
