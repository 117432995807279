import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on CustomPage {
        name
        href
        group
        active
        display
        body {
          name
          config
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation($id: ID, $input: CustomPageInput!) {
    customPageSet(id: $id, input: $input) {
      id
      name
      href
      group
      active
      display
      body {
        name
        config
      }
      updatedAt
      createdAt
    }
  }
`;
