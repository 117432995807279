import React from 'react';
import { gql } from "apollo-boost";
import { toast } from 'react-toastify';
import _ from "lodash";

export default class Model extends React.Component {
  constructor(props) {
    super(props);

    let {
      location: {
        state: {
          offset = {
            skip: 0,
            limit: 1000
          }
        } = {}
      } = {}
    } = this.props;

    this.offset = offset;
    this.form = {};
  }

  getRef(name) {
    if (!this.form[name]) {
      this.form[name] = React.createRef();
    }
    return this.form[name];
  }

  getCurrentRef(name) {
    return this.getRef(name).current;
  }

  removeRef(name) {
    delete this.form[name];
  }

  async createCompany(client,
    data = {
      input: {
        attributes: []
      }
    }) {
    try {
      const { data: { companySet } = {} } = await client.mutate({
        mutation: gql`
          mutation companySet($id: ID, $input: CompanyInput!) {
            companySet(id: $id, input: $input) {
              id
            }
          }
        `,
        variables: data
      });
      client.resetStore();
      return companySet;
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  async setAttributes(attributesKeys = []) {
    let attributes = [];
    _.each(attributesKeys, (key) => {
      const { value, checked, type } = this.getCurrentRef(key) || {};
      if (!_.isUndefined(type) &&
        (type === 'text' ||
          type === 'date' ||
          type === 'email' ||
          type === 'password' ||
          type === 'number' ||
          type === 'select-one' ||
          type === 'textarea')) {
        if (!_.isUndefined(value) && value.length !== 0) {
          attributes.push({
            key: key,
            value: value
          })
        }
      } else if (!_.isUndefined(type) && type === 'checkbox') {
        if (!_.isUndefined(checked) && checked.length !== 0) {
          attributes.push({
            key: key,
            value: checked
          })
        }
      } else {
        console.log(this.getRef(key));
      }

    });
    return attributes;
  }

  next(refetch) {
    this.offset.skip += this.offset.limit;
    this.props.history.push(this.props.match.path, { offset: this.offset });
    refetch();
  }

  prev(refetch) {
    if (this.offset.skip > 0) {
      this.offset.skip -= this.offset.limit;
      this.props.history.push(this.props.match.path, { offset: this.offset });
      refetch();
    }
  }
}
