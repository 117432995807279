import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { translate as t } from '../../shared/translate';
import { H6CardHeader, FormRow } from '../../components/omniwe';
import TinyComponent from '../../components/tinyComponent';
import { Checkbox } from '../../components/omniwe';

export default ({ contentHtml, visible, loading, onChange, getRef }) => {
  return (
    <Card>
      <H6CardHeader text={t.promotion_content_details} />
      <CardBody>
        <FormRow title={t.visible}>
          <Checkbox
            label={t.yes}
            id='promotion_details_visible'
            innerRef={getRef('promotionDetails_showTerms')}
            defaultChecked={visible}
          />
        </FormRow>
        <FormRow title={t.content}>
          <div className='border'>
            <TinyComponent
              value={contentHtml}
              disabled={loading}
              onChange={onChange}
            />
          </div>
        </FormRow>
      </CardBody>
    </Card>
  );
};
