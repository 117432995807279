import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

export default class DebounceInput extends Component {
  state = {
    value: this.props.value,
    handler: undefined,
  };
  componentDidUpdate({ value: _value }) {
    const { value } = this.props;
    if (value !== _value) {
      this.setState({ value });
    }
  }
  componentWillUnmount() {
    const { handler } = this.state;
    clearTimeout(handler);
  }

  onChange = e => {
    const { debounceTime } = this.props,
      { handler } = this.state;
    handler && clearTimeout(handler);
    this.setState({ value: e.target.value, handler: setTimeout(this.onDebounce, debounceTime) });
  };
  onDebounce = () => {
    const { onChange } = this.props,
      { value, handler } = this.state;
    handler && clearTimeout(handler);
    onChange && onChange(value);
  };
  render() {
    const { ...props } = this.props,
      { value } = this.state;
    return <Input {...props} value={value} onChange={this.onChange} />;
  }
}
DebounceInput.propTypes = {
  debounceTime: PropTypes.number,
  ...Input.propTypes,
};
DebounceInput.defaultProps = {
  debounceTime: 1000,
  ...Input.defaultProps,
};
