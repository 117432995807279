import React, { Component } from 'react';
import { Formik, Form } from 'formik/dist/index';
import { translate } from '../../shared/translate';

import ContentPageLayout from '../../components/layout/ContentPageLayout';
import MessagingCard from '../../components/layout/card/message/MessagingCard';
import TicketStatusCard from './TicketStatusCard';
class SupportTicketItem extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params = {} }
    } = this.props;
    const currentId = params.id === 'new' ? undefined : params.id;

    this.state = {
      currentId,
      trail: [
        {
          to: '/support_tickets/',
          label: translate.page_title_support_ticket
        },
        { to: null, label: currentId }
      ],
      chatData: [
        {
          author: 'David Blaine',
          sender: true,
          message: 'There is a problem with my product, can you help?',
          date: '2019-05-31T03:25:31.232Z'
        },
        {
          author: 'Owner',
          message: 'Are you looking for a refund or replacement?',
          date: '2019-05-31T03:25:31.232Z'
        }
      ]
    };
  }

  handleReply = message => {
    this.setState({
      chatData: [
        ...this.state.chatData,
        { author: 'Owner', message, date: new Date() }
      ]
    });
  };

  render() {
    return (
      <ContentPageLayout trail={this.state.trail}>
        <Formik
          initialValues={{
            status: 'PENDING',
            updatedAt: '2019-05-28T08:42:06.787Z',
            createdAt: '2019-05-28T08:42:06.787Z',
            orderId: '1234541',
            customer: 'David Blaine',
            contact: '9123 1234'
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <TicketStatusCard
                initialValues={values}
                onEvents={{
                  onStatusChanged: newValue => setFieldValue('status', newValue)
                }}
              />
              <MessagingCard
                data={this.state.chatData}
                onReply={this.handleReply}
              />
            </Form>
          )}
        </Formik>
      </ContentPageLayout>
    );
  }
}

export default SupportTicketItem;
