import moment from 'moment';

export default class DataFormatter {
  static singleLineWithSeparator(lines = [], separator = ', ') {
    if (typeof lines !== 'object' && lines.length !== undefined)
      throw new Error('Expected an array');
    return lines.join(separator);
  }

  static fromOrderItems(raw) {
    return raw.map(orderItem => {
      const { id, productVariant, quantity, unitPrice, description } = orderItem || {};
      const { amount, locale } = unitPrice || {};
      const { attributes = [], product: { name } = '' } = productVariant || {};

      const variantNames = attributes
        .filter(({ value }) => !!value && value !== '-')
        .map(({ key, value }) => `${key}: ${value}`).join(' / ')
        .trim();

      let productName;
      if (!productVariant) {
        productName = description;
      } else if (variantNames.length === 0) {
        productName = name;
      } else {
        productName = `${name} / ${variantNames}`;
      }

      return {
        id,
        name: productName, //variantNames.length === 0 ? name : `${name} - ${variantNames}`,
        quantity,
        unitPrice: amount,
        currency: locale,
        subTotal: quantity * amount
      };
    });
  }

  static fromIsoDate(datetime) {
    return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
  }
}
