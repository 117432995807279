import React from 'react';
import CardHeader from 'components/layout/card/CardHeader';
import AddressBody from 'components/layout/card/address/AddressBody';
import Card from 'components/layout/card/Card';
import { translate } from 'shared/translate';
import _ from 'lodash';
import { JSONParseSafely } from 'shared';

export default ({ values: { order } }) => {
  const { attributes = [] } = order || {},
    { buyer: [{ value: buyer } = {}] = [] } = _.groupBy(attributes, 'key');
  if (!buyer) return null;
  return (
    <Card>
      <CardHeader title={translate.card_title_bill_to_address} />
      <div className="card-body">
        <AddressBody data={JSONParseSafely(buyer, {})} />
      </div>
    </Card>
  );
};
