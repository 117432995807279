import React from 'react';
import SearchComboProduct from './SearchComboProduct';
import { client } from 'shared/apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

const SEARCH_QUERY = gql`
  query($id: ID, $filter: CollectionFilterInput) {
    node(id: $id) {
      id
      ... on Shop {
        title
        collections(otherFilter: $filter) {
          id
          name
        }
      }
    }
  }
`;

export default class SearchComboCollection extends SearchComboProduct {
  update = async search => {
    if (!this._mounted) return;
    const {
      data: { node },
    } = await client.query({
      query: SEARCH_QUERY,
      variables: {
        id: await localStorage.getItem('shopId'),
        filter: {
          name: search,
        },
      },
      fetchPolicy: 'network-only',
    });
    const { collections = [] } = node || {};
    this.setState({ options: collections.map(collection => ({ label: collection.name, value: collection })) });
  };
}
SearchComboCollection.propTypes = {
  selected: PropTypes.any /* Collection */,
  onChange: PropTypes.func,
};
SearchComboCollection.defaultProps = {
  onChange: _ => _,
};
