import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import DataFormatter from './../../shared/data-formatter'
import merge from 'lodash/merge';
class TableRow extends Component {
  getClasses = data => {
    const { align = 'center' } = data;
    return `text-truncate text-${align}`;
  };

  createLink = ({ display = '', to = '' }) => {
    return <Link to={`${to}/${display}`}>{display}</Link>;
  };

  renderType = data => {
    switch (data.type) {
      case 'link':
        return this.createLink(data.value);
      case 'datetime':
        return DataFormatter.fromIsoDate(data.value)
      default:
        return data.value;
    }
  };

  formatData = (data, datatypes, colAlignments) => {
    const values = data.map(d => ({ value: d }));
    const types = datatypes.map(t => ({ type: t }));
    const aligns = colAlignments.map(a => ({ align: a }));

    const formattedData = merge(values, types, aligns);
    return formattedData;
  };

  render() {
    const { data = [], datatypes = [], colAlignments = [] } = this.props;
    const formattedData = this.formatData(data, datatypes, colAlignments);
    return (
      <tr>
        {formattedData.map((columnData, idx) => (
          <td key={idx} className={this.getClasses(columnData)}>
            {this.renderType(columnData)}
          </td>
        ))}
      </tr>
    );
  }
}

export default TableRow;
