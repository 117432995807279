import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchCombo from './SearchCombo';
import { client } from 'shared/apollo';
import gql from 'graphql-tag';

const SEARCH_QUERY = gql`
  query($id: ID, $filter: ProductFilterInput) {
    node(id: $id) {
      id
      ... on Shop {
        id
        title
        products(otherFilter: $filter, offset: { skip: 0, limit: 10 }) {
          id
          name
          variations {
            id
          }
        }
      }
    }
  }
`;

export default class SearchComboProduct extends Component {
  state = {
    selected: this.props.selected,
    options: [],
  };
  async componentDidMount() {
    this._mounted = true;
    await this.update();
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  updateDebounce = (...args) => {
    clearTimeout(this.handler);
    this.handler = setTimeout(() => this.update(...args), 500);
  };
  update = async search => {
    if (!this._mounted) return;
    const {
      data: { node },
    } = await client.query({
      query: SEARCH_QUERY,
      variables: {
        id: await localStorage.getItem('shopId'),
        filter: {
          name: search,
        },
      },
      fetchPolicy: 'network-only',
    });
    const { products = [] } = node || {};
    this.setState({ options: products.map(product => ({ label: product.name, value: product })) });
  };
  getItemDisplay = item => {
    return item.name;
  };

  render() {

    const { onChange, ...props } = this.props;
    const { options, selected } = this.state;
    return (
      <SearchCombo
        {...props}
        selected={selected ? this.getItemDisplay(selected) : ''}
        onSearchChange={search => this.updateDebounce(search)}
        onChange={selected => {
          this.setState({ selected });
          onChange(selected);
        }}
        options={options}
      />
    );
  }
}
SearchComboProduct.propTypes = {
  selected: PropTypes.any /* Product */,
  onChange: PropTypes.func,
};
SearchComboProduct.defaultProps = {
  onChange: _ => _,
};
