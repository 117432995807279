import React, { Component } from 'react';
import { translate } from './../../../../shared/translate';
import TextAreaField from './../../../form/TextAreaField';
import ActionButton from './../../../form/ActionButton';

class MessageReply extends Component {
  state = { reply: '' };

  handleReply = () => {
    const { reply } = this.state;
    if (reply.trim()) {
      this.props.onReply(reply);
      this.setState({ reply: '' });
    }
  };
  render() {
    return (
      <div>
        <TextAreaField
          label={translate.label_reply}
          value={this.state.reply}
          onValueChanged={newValue => this.setState({ reply: newValue })}
        />
        <div className='text-right'>
          <ActionButton onClick={this.handleReply}>
            {translate.label_reply}
          </ActionButton>
        </div>
      </div>
    );
  }
}

export default MessageReply;
