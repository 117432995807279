import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query(
    $id: ID!
    $offset: OffsetInput
    $filter: FilterInput
    $otherFilter: ProductFilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Shop {
        id
        products(offset: $offset, filter: $filter, otherFilter: $otherFilter, sorter: $sorter) {
          id
          name
          images
          createdAt
          updatedAt
          sortIndex
          collection {
            id
            name
          }
        }
        productsCount(filter: $filter, otherFilter: $otherFilter)
      }
    }
  }
`;
