import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Icon } from 'components/iconRender';

export default ({ value, onChange }) => {
  const [str, setStr] = useState(value);
  const [editing, setEditing] = useState(false);

  const submit = () => {
    if (str !== value) onChange(str);
    setEditing(false);
  };

  if (!editing) {
    return (
      <div className={'d-flex align-items-center'}>
        <Button
          color={'link'}
          onClick={() => {
            setStr(value);
            setEditing(true);
          }}
        >
          <Icon icon={'faEdit'} />
        </Button>
        <span>{str}</span>
      </div>
    );
  }

  return (
    <div className={'d-flex align-items-center'}>
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
        className={'flex-fill'}
      >
        <input
          className={'form-control'}
          required
          value={str}
          onChange={e => {
            const newStr = e.target.value;
            setStr(newStr);
          }}
        />
      </form>
      <Button color={'link'} onClick={submit}>
        <Icon icon={'faCheck'} />
      </Button>
    </div>
  );
};
