import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query(
    $id: ID!
    $offset: OffsetInput
    $filter: FilterInput
    $otherFilter: CollectionFilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Shop {
        id
        collectionsCount(filter: $filter, otherFilter: $otherFilter)
        collections(offset: $offset, filter: $filter, otherFilter: $otherFilter, sorter: $sorter) {
          id
          name
          thumbnail
          sortIndex
          attributes {
            key
            value
          }
          updatedAt
          createdAt
        }
      }
    }
  }
`;
