import React from 'react';

const defaultInputColumnClass = 'col';

const getInputColumnClass = size => {
  if (typeof size !== 'number') return defaultInputColumnClass;
  if (size > 0 && size <= 10) return `col-${size}`;
  else return defaultInputColumnClass;
};

const HorizontalField = ({
  label,
  children,
  hint,
  colWidth,
  labelWidth = 2
}) => (
  <div className='form-group row'>
    <label className={`col-sm-${labelWidth} col-form-label text-muted`}>{label}</label>
    <div className={getInputColumnClass(colWidth)}>
      {children}
      {hint && <p className='form-text small text-muted'>{hint}</p>}
    </div>
  </div>
);

export default HorizontalField;
