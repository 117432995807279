import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate, translate as t } from '../../shared/translate';
import { Link } from 'react-router-dom';
import DataFormatter from '../../shared/data-formatter';
import { GET_QUERY } from './query';
import { ShipmentProvider } from 'shared/omniwe-types';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    breadcrumb: [
      {
        name: t.delivery_notes,
      },
    ],
    fields: [
      {
        title: t.delivery_note_number,
        width: 160,
        render: item => (
          <Link
            to={{
              pathname: '/delivery_notes/' + item.id,
            }}
          >
            {item.id}
          </Link>
        ),
      },
      {
        title: 'Tracking Number',
        fieldName: 'trackingNo',
        sortable: 'trackingNo',
        width: 200,
      },
      {
        title: translate.shipment,
        align: 'center',
        render: item => ShipmentProvider[(item.shipment || {}).provider || 'IN_SITU'],
        width: 150,
        sortable: 'shipment.provider',
        fieldName: 'shipmentProvider',
        type: 'option',
        options: Object.keys(ShipmentProvider).map(key => ({
          name: ShipmentProvider[key],
          value: key,
        })),
        filter: true,
      },
      {
        title: t.ship_to,
        render: ({ shippingAddress: { person } = {} }) => person,
      },
      {
        title: t.address,
        render: ({ shippingAddress: { lines: address = [] } = {} }) => DataFormatter.singleLineWithSeparator(address),
      },
      {
        title: t.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        headerClassName: 'text-right',
        width: 160,
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: t.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        headerClassName: 'text-right',
        width: 160,
        filter: true,
        sortable: 'updatedAt',
      },
    ],
  };
  getData = ({ node } = {}) => {
    const { deliveryNotes = [], deliveryNotesCount = 0 } = node || {};
    return { list: deliveryNotes, count: deliveryNotesCount };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
