import React from 'react';
import Loading from './../../components/loading';

const ActionButton = ({ children, disabled, onClick, className = '', theme = 'btn-dark', type = 'button' }) => (
  <button type={type} className={`btn shadow ${className} ${theme}`} onClick={onClick} disabled={disabled}>
    {disabled && <Loading spacing="" size="fa-lg" />}
    {!disabled && children}
  </button>
);

export default ActionButton;
