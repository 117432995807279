import React from 'react';
import VenueControl from './_venueControl';

export default ({ values, setFieldValue }) => {
  const { venuesId = [] } = values;
  return (
    <VenueControl
      selectedVenueIds={venuesId}
      onChange={val => {
        setFieldValue('venuesId', val);
      }}
    />
  );
};
