import React from 'react';
import { Icon } from './../iconRender';

const ActionTag = ({ title, children, onClick }) => (
  <button
    type='button'
    className='btn btn-primary btn-sm mr-1 mb-1 shadow-none'
    onClick={() => {
      if (onClick) onClick();
    }}
    title={title}
  >
    {children}
    <Icon icon='faTimes' />
  </button>
);

export default ActionTag;
