import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import { translate } from 'shared/translate';
import { POST_QUERY } from './query';
import NewInvoiceItemCard from './NewInvoiceItemCard';
import { client } from 'shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ link: '/invoices', label: translate.invoice_details }, { label: translate.new_invoice }],
    gql: {
      submit: POST_QUERY,
    },
    cards: [NewInvoiceItemCard],
  };

  submit = async ({ orderId, items = [], amount }) => {
    const {
      gql: { submit },
    } = this.state;
    const { data: { orderInvoiceCreate } = {} } = await client.mutate({
      mutation: submit,
      variables: {
        orderId,
        input: {
          items: items.map(item => ({ orderItemId: item.id, quantity: item.quantity - 0 })),
          amount: amount - 0,
          paymentMethod: 'MANUAL',
        },
      },
    });
    return orderInvoiceCreate.id;
  };

  getInitialData() {
    const { location: { state: { order } = {} } = {} } = this.props,
      { id, items } = order || {};
    return {
      orderId: id,
      items: (items || []).map(item => ({ ...item, maxQuantity: item.quantity - 0 })),
      amount: 0,
    };
  }
}
