import React from "react";

export default class FormBase extends React.Component {

  handleInputChanged = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
}