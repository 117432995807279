import React from "react";
import { translate } from "../../shared/translate";
import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import _ from "lodash";
import * as RS from "reactstrap";
import PageError from "../../components/pageComponents/PageError";
import * as PU from "../../components/pageComponents/utility";
import FormBase from "../../components/pageComponents/FormBase";

const POST_QUERY = gql`
  # ADD COMMENT TO APPLICATION
  mutation($id: ID!, $input: ApplicationCommentInput!) {
    serviceApplicationCommentAdd(id: $id, input: $input) {
      id
      comments {
        content
        createdAt
        user {
          id
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

export default class CommentForm extends FormBase {
  constructor(props) {
    super(props);
    const { id: currentId } = props;
    this.state = {
      currentId,
      content: ""
    };
  }

  onSubmit = async (event, post) => {
    event.preventDefault();
    const { content, currentId } = this.state;
    const input = {
      content
    };
    const variables = {
      id: currentId,
      input
    };
    await post({ variables });
    this.setState({ content: "" });
  };

  render() {
    return (
      <Mutation mutation={POST_QUERY}>
        {(post, { loading, error }) => (
          <RS.Form onSubmit={e => this.onSubmit(e, post)}>
            <RS.FormGroup>
              <RS.Input
                type="textarea"
                style={{ height: "180px" }}
                value={this.state.content}
                name="content"
                onChange={this.handleInputChanged}
                required
              />
            </RS.FormGroup>
            <RS.FormGroup className='d-flex justify-content-end'>
              {error && <PageError errors={error} />}
              <RS.Button color="dark" disabled={loading}>
                {loading && <PU.Spinner />}
                {translate.submit}
              </RS.Button>
            </RS.FormGroup>
          </RS.Form>
        )}
      </Mutation>
    ); // end return
  } // end render
} // end class
