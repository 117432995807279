import React, { Component } from 'react';
import _ from 'lodash';
import { ButtonDropdown, DropdownToggle } from 'reactstrap';

import { translate as t } from '../../shared/translate';
import * as PU from '../pageComponents/utility';
import Header from './Header';
import DateTime from './DateTime';
import Text from './Text';
import UserComponent from './UserComponent';
import Options from './Options';
import MultipleOptions from './MultipleOptions';

export default class Filter extends Component {
  constructor(props) {
    // console.log("fields props", props);
    super(props);
    const { fields } = props;
    this.state = {
      dropdownOpen: false,
      fields: [...fields],
      filterActived: this.filterActived(fields)
    };

    this.fieldValues = [];
  }

  filterActived = fields => {
    return _.filter(fields, { visible: true }).length > 0;
  };

  createFilterRow = (filterInfo, i) => {
    let row;
    switch (filterInfo.type) {
      case 'datetime':
        row = (
          <DateTime
            key={i}
            title={filterInfo.title}
            visible={filterInfo.visible}
            toggleVisible={() => this.toggleVisible(filterInfo.fieldName)}
            onValueChanged={values =>
              this.onValueChanged(
                filterInfo.fieldName,
                filterInfo.nestedAttr,
                filterInfo.type,
                values
              )
            }
            disabled={filterInfo.disabled}
            defaultValue={filterInfo.defaultValue}
          />
        );
        break;

      case 'text':
        row = (
          <Text
            key={i}
            title={filterInfo.title}
            visible={filterInfo.visible}
            toggleVisible={() => this.toggleVisible(filterInfo.fieldName)}
            onValueChanged={values =>
              this.onValueChanged(
                filterInfo.fieldName,
                filterInfo.nestedAttr,
                filterInfo.type,
                values
              )
            }
            defaultValue={filterInfo.defaultValue}
          />
        );
        break;
      case 'bool':
        row = (
          <Options
            key={i}
            title={filterInfo.title}
            visible={filterInfo.visible}
            toggleVisible={() => this.toggleVisible(filterInfo.fieldName)}
            options={[
              {
                name: t.yes,
                value: true
              },
              {
                name: t.no,
                value: false
              }
            ]}
            defaultValue={filterInfo.defaultValue}
            onValueChanged={values =>
              this.onValueChanged(
                filterInfo.fieldName,
                filterInfo.nestedAttr,
                filterInfo.type,
                values
              )
            }
          />
        );
        break;

      case 'option':
        row = (
          <Options
            key={i}
            title={filterInfo.title}
            visible={filterInfo.visible}
            toggleVisible={() => this.toggleVisible(filterInfo.fieldName)}
            options={filterInfo.options}
            defaultValue={filterInfo.defaultValue}
            onValueChanged={values =>
              this.onValueChanged(
                filterInfo.fieldName,
                filterInfo.nestedAttr,
                filterInfo.type,
                values
              )
            }
          />
        );
        break;

      case 'multiple-option':
        row = (
          <MultipleOptions
            key={i}
            title={filterInfo.title}
            visible={filterInfo.visible}
            toggleVisible={() => this.toggleVisible(filterInfo.fieldName)}
            options={filterInfo.options}
            defaultValue={filterInfo.defaultValue}
            onValueChanged={values =>
              this.onValueChanged(
                filterInfo.fieldName,
                filterInfo.nestedAttr,
                filterInfo.type,
                values
              )
            }
          />
        );
        break;

      default:
        {
          if (!filterInfo.component) {
            return <div key={i}>*unknown filter type*</div>;
          }

          row = (
            <UserComponent
              key={i}
              title={filterInfo.title}
              visible={filterInfo.visible}
              toggleVisible={() => this.toggleVisible(filterInfo.fieldName)}
              component={filterInfo.component}
              onValueChanged={values =>
                this.onValueChanged(
                  filterInfo.fieldName,
                  filterInfo.nestedAttr,
                  null,
                  values
                )
              }
            />
          );
        }
        break;
    }

    return row;
  };

  toggle = () => {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  };

  reset = () => {
    const { fields } = this.state;
    const newFields = fields.map(item => ({ ...item, visible: false }));
    const actived = this.filterActived(newFields);
    this.setState({
      fields: newFields,
      filterActived: actived
    });
  };

  // getFieldValues = ()=>{
  //   return this.fieldValues;
  // }

  done = () => {
    const { filterChanged } = this.props;
    console.log('done field values', this.fieldValues);
    // return;
    filterChanged(this.fieldValues);
    this.toggle();
  };

  toggleVisible = fieldName => {
    const { fields } = this.state;
    const newFields = fields.map(item => {
      if (item.fieldName === fieldName) {
        return { ...item, visible: !item.visible };
      }
      return { ...item };
    });
    const actived = this.filterActived(newFields);
    this.setState({
      fields: newFields,
      filterActived: actived
    });
  };

  onValueChanged = (name, nestedAttr, type, values) => {
    let found = _.find(this.fieldValues, { fieldName: name });
    if (found) {
      found.values = values;
    } else {
      found = {
        fieldName: name,
        type,
        values,
        nestedAttr
      };
      this.fieldValues.push(found);
    }
  };

  render() {
    const { fields, filterActived } = this.state;
    return (
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={() => this.toggle()}
      >
        <DropdownToggle
          caret
          color='light'
          className='btn-light btn-sm shadow d-flex align-items-center'
        >
          <i className={`fa fa-filter fa-fw`} /> {t.filter}
          {filterActived && <PU.RedDot spaceLeft />}
        </DropdownToggle>
        <div
          className={
            'text-left dropdown-menu p-0' +
            (this.state.dropdownOpen ? ' show' : '')
          }
        >
          <div
            className='list-group list-group-flush'
            style={{ width: '351px' }}
          >
            <Header done={this.done} reset={this.reset} />
            {fields.map((item, i) => this.createFilterRow(item, i))}
          </div>
        </div>
      </ButtonDropdown>
    );
  }
}
