import React from "react";

import { translate as t } from "../../shared/translate";

export default ({ reset, done }) => {
  return (
    <div className="list-group-item bg-transparent">
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <span className="d-flex align-items-center">{t.filter}</span>
        <div>
          <button
            type={'button'}
            className="btn btn-sm btn-light"
            onClick={reset}
            style={{ marginRight: "1rem" }}
          >
            {t.clear}
          </button>
          <button
            type={'button'}
            className="btn btn-sm btn-primary"
            onClick={done}
            style={{ minWidth: "4rem" }}
          >
            {t.done}
          </button>
        </div>
      </div>
    </div>
  );
};
