import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { translate as t } from 'shared/translate';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { GET_QUERY, SET_QUERY, UNSET_QUERY } from './_gql';
import { client } from 'shared/apollo';
import ServiceItemCard from './_serviceItemCard';
import VenueCard from './_venueCard';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [
      {
        label: t.hc_service,
        route: '/hc_services',
      },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: SET_QUERY,
      // remove: UNSET_QUERY,
    },
    cards: [
      {
        name: t.hc_service_details,
        fields: [
          {
            type: 'text',
            label: t.name,
            name: 'name',
            maxLength: 200,
            required: true,
          },
          {
            type: 'textarea',
            label: t.description,
            name: 'description',
            maxLength: 1000,
          },
          {
            type: 'text',
            label: t.hc_service_price_group,
            name: 'group',
            maxLength: 200,
            required: true,
          },
          {
            type: 'number',
            label: t.price,
            render: ({ values, handleChange }) => (
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Input
                  value={values.price}
                  onChange={handleChange}
                  type="number"
                  name="price"
                  step={0.01}
                  min={0}
                  required
                />
              </InputGroup>
            ),
          },
          {
            type: 'checkbox',
            label: t.hc_service_available,
            name: 'active',
            text: t.yes,
          },
          {
            type: 'checkbox',
            label: t.visible,
            name: 'display',
            text: t.yes,
          },
        ],
      },
      {
        name: t.image,
        type: 'images',
        field: {
          name: 'images',
        },
      },
      ServiceItemCard,
      VenueCard,
    ],
  };

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const { name, description, group, price, display, active, images, items, venuesId = [] } = values;
    const newImages = images.filter(image => typeof image === 'string');
    const imageUploads = images.filter(image => typeof image !== 'string');

    const {
      data: { serviceSet = {} },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          shopId: await localStorage.getItem('shopId'),
          venuesId,
          name,
          description,
          group,
          price,
          display,
          active,
          newImages,
          imageUploads,
          items: items
            .map(item => {
              const { productVariant, since, active, valid, duration, summary } = item || {},
                { id } = productVariant || {};
              return (
                !!id && {
                  productVariantId: id,
                  since: since - 0 || 0,
                  active: active - 0 || 0,
                  valid: valid - 0 || 0,
                  duration: duration - 0 || 0,
                  summary,
                }
              );
            })
            .filter(_ => _),
          attributes: [],
        },
      },
    });
    return serviceSet.id;
  };

  getInitialData = data => {
    const { node } = data || {},
      {
        name = '',
        description = '',
        group = '',
        price = 0,
        display = true,
        active = true,
        images = [],
        venues = [],
        items = [],
      } = node || {};
    return {
      name,
      description,
      group,
      price,
      display,
      active,
      images,
      items,
      venuesId: venues.map(venue => venue.id),
    };
  };
}
