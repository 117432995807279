import React from "react";
import FilterRowBase from "./FilterRowBase";

export default class FilterRowBaseComponent extends React.Component {
  // constructor(props) {
  //   super(props);
  //   const { visible } = props;
  //   this.state = {
  //     visible
  //   };
  // }

  // toggleVisible = event => {
  //   const {
  //     target: { checked: visible }
  //   } = event;
  //   this.setState({ visible });
  // };

  render() {
    const { title, children, visible, toggleVisible, disabled } = this.props;
    return (
      <div>
        <div className="list-group-item bg-transparent">
          <div className="form-check">
            <label className="form-check-label w-100">
              <input
                onChange={toggleVisible}
                className="form-check-input"
                type="checkbox"
                disabled={disabled}
                checked={visible}
              />
              {title}
            </label>
          </div>
        </div>
        {visible && (
          <div className="list-group-item bg-secondary">{children}</div>
        )}
      </div>
    );
  }
}
