import React from 'react';
import { translate } from 'shared/translate';

export default ({
  source,
  title,
  description,
  register,
  disabled,
  link,
  onRegister = _ => _,
  onClick = _ => _
}) => (
  <div className={'d-flex flex-lg-row flex-column'}>
    <img className={'rounded img-fluid border'} src={source} />
    <div className={'flex-fill d-flex flex-column'}>
      <div className={'flex-fill d-flex flex-column p-4'}>
        <h2>{title}</h2>
        <p className={'lead'}>{description}</p>
      </div>
      <div className={'text-right'}>
        {!!link && (
          <a href={link} target={'_blank'}>
            <button className={'btn btn-light mr-3'}>
              {translate.know_more}
            </button>
          </a>
        )}
        <button
          disabled={disabled}
          className={'btn btn-dark'}
          onClick={!!register ? onRegister : onClick}
        >
          {!!register ? translate.open_immediately : translate.setting}
        </button>
      </div>
    </div>
  </div>
);
