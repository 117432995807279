import React, { Component } from 'react';

export default class Route extends Component {

  render() {
    const {
      className,
      route,
      icon,
      disabled,
      text,
      history = {},
      params,
    } = this.props;
    return (
      <button
        disabled={ disabled }
        type="button"
        className={ className || "btn btn-outline-secondary text-dark border-0 shadow" }
        onClick={ () => {
          route ? history.push(route, params) : history.goBack()
        } }>
        { icon &&
        <i className={ `fa ${icon} fa-fw` }/>
        }
        { text || 'Back' }
      </button>
    );
  }
}