import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { useDebounce } from '@react-hook/debounce';
import { GET_SALES_REPORT } from './query';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate as t } from 'shared/translate';
import moment from 'moment';
import { Icon } from 'components/iconRender';
import { Loading } from 'components/omniwe';

export default ({}) => {
  const [createdSince, setCreatedSince] = useDebounce(moment().format('YYYY-MM-DD'), 1000),
    [createdUntil, setCreatedUntil] = useDebounce(
      moment()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      1000,
    );
  return (
    <Query
      query={GET_SALES_REPORT}
      variables={{
        id: localStorage.getItem('shopId'),
        filter: {
          createdSince,
          createdUntil,
        },
      }}
    >
      {({ loading, data: { node } = {} }) => {
        const { salesReport } = node || {},
          {
            totalAmount = 0,
            totalDiscount = 0,
            totalRefund = 0,
            totalCancelled = 0,
            totalChargePerPaymentMethodByCredential = [],
          } = salesReport || {};
        return (
          <div className={'col-md-6 mb-3 h-100'}>
            <Card>
              <CardHeader className={'d-flex p-0'}>
                <div style={{ padding: '0.75rem 1.25rem' }}>
                  <span>{t.sales_report}</span>
                </div>
                <div className={'flex-fill row no-gutters align-items-center justify-content-end px-2'}>
                  <div className={'col-auto'}>
                    <input
                      type={'date'}
                      className={'form-control form-control-sm'}
                      value={moment(createdSince).format('YYYY-MM-DD')}
                      onChange={e => setCreatedSince(e.target.value)}
                    />
                  </div>
                  <Icon className={'mx-2'} icon={'faArrowRight'} />
                  <div className={'col-auto'}>
                    <input
                      type={'date'}
                      className={'form-control form-control-sm'}
                      value={moment(createdUntil).format('YYYY-MM-DD')}
                      onChange={e => setCreatedUntil(e.target.value)}
                    />
                  </div>
                </div>
              </CardHeader>
              <div className={'embed-responsive embed-responsive-16by9'}>
                {loading ? (
                  <div className={'embed-responsive-item'}>
                    <Loading />
                  </div>
                ) : (
                  <div className={'embed-responsive-item d-flex flex-column'}>
                    <div
                      style={{
                        padding: '1.25rem',
                      }}
                    >
                      <div className={'row'}>
                        <div className={'col-lg-3 col-6'}>
                          <small>{t.total}</small>
                          <br />
                          <span className={'lead'}>${(totalAmount - 0).toFixed(2)}</span>
                        </div>
                        <div className={'col-lg-3 col-6'}>
                          <small>{t.discount_off}</small>
                          <br />
                          <span className={'lead'}>${(totalDiscount - 0).toFixed(2)}</span>
                        </div>
                        <div className={'col-lg-3 col-6'}>
                          <small>{t.refund}</small>
                          <br />
                          <span className={'lead'}>${(totalRefund - 0).toFixed(2)}</span>
                        </div>
                        <div className={'col-lg-3 col-6'}>
                          <small>{t.status_cancelled}</small>
                          <br />
                          <span className={'lead'}>${(totalCancelled - 0).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className={'flex-fill'}>
                      <div
                        className={'list-group list-group-flush w-100 border-top'}
                        style={{
                          overflowY: 'auto',
                        }}
                      >
                        {(totalChargePerPaymentMethodByCredential || []).map(({ name, amount }, i) => (
                          <div key={i} className={'list-group-item d-flex justify-content-between'}>
                            <span>{name}</span>
                            <span>${(amount - 0).toFixed(2)}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        );
      }}
    </Query>
  );
};
