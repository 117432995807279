import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import { GET_QUERY } from './query';
import getDiscountContent from 'shared/getDiscountContent';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: translate.coupon_list }],
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/coupons/new',
    fields: [
      {
        title: translate.coupon_no,
        render: item => (
          <Link
            to={{
              pathname: '/coupons/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            {item.handle}
          </Link>
        ),
        sortable: 'handle',
      },
      {
        title: translate.coupon_name,
        fieldName: 'name',
        type: 'text',
        filter: true,
        sortable: 'name',
      },
      {
        title: translate.coupon_function,
        render: item => getDiscountContent(item),
        sortable: 'discountType',
      },
      {
        title: translate.coupon_receiving_upper_limit,
        fieldName: 'publishLimit',
        align: 'center',
        width: 90,
        sortable: 'publishLimit',
      },
      {
        title: translate.coupon_use_times,
        fieldName: 'used',
        align: 'right',
        width: 50,
      },
      {
        title: translate.availability,
        fieldName: 'active',
        type: 'bool',
        align: 'center',
        width: 50,
      },
      {
        title: translate.availability,
        fieldName: 'active',
        type: 'option',
        options: [
          {
            name: translate.yes,
            value: true,
          },
          {
            name: translate.no,
            value: false,
          },
        ],
        filter: true,
        column: false,
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        filter: true,
        align: 'right',
        width: 120,
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        filter: true,
        align: 'right',
        width: 120,
        sortable: 'updatedAt',
      },
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    const { coupons = [], couponsCount = 0 } = node || {};
    return { list: coupons, count: couponsCount };
  };
}
