import React, { useRef, useEffect, useState } from 'react';

const scale = 0.194;
export default ({ config: { content = '' } = {} }) => {
  const wrapper = useRef(null);
  const [handler, setHandler] = useState(undefined);
  const [height, setHeight] = useState(50);

  useEffect(() => {
    setHandler(
      setInterval(() => {
        const clientHeight = (wrapper.current || {}).clientHeight;
        setHeight(clientHeight || height);
      }, 1000),
    );
    return () => {
      clearInterval(handler);
    };
  }, [0]);

  return (
    <div className={'p-3'}>
      <div
        style={{
          height: height * scale,
          overflow: 'hidden',
        }}
      >
        <div
          ref={wrapper}
          style={{
            width: 1000,
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            pointerEvents: 'none',
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};
