import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import { GET_QUERY, REMOVE_QUERY, CREATE_QUERY, UPDATE_QUERY } from './query';
import NewCardCard from 'routes/item_Billing/NewCardCard';
import { Elements, injectStripe } from 'react-stripe-elements';
import { client } from 'shared/apollo';

class ItemBilling extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: REMOVE_QUERY,
    },
    breadcrumb: [
      { link: '/billings', label: 'Billing' },
      {
        label: this.state.id || 'New Billing',
      },
    ],
    cards: [
      this.state.id
        ? {
            name: 'Card Info',
            fields: [
              {
                label: 'Card Number',
                type: 'text',
                name: 'last4',
                disabled: true,
              },
              {
                label: 'Expires Month',
                type: 'text',
                name: 'exp_month',
                disabled: true,
              },
              {
                label: 'Expires Year',
                type: 'text',
                name: 'exp_year',
                disabled: true,
              },
            ],
          }
        : NewCardCard,
      {
        name: 'Billing Address',
        fields: [
          {
            label: 'Card Holder',
            name: 'name',
            type: 'text',
            required: true,
          },
          {
            label: 'Country',
            type: 'country',
            name: 'address_country',
            required: true,
          },
          {
            label: 'Address',
            name: 'address_line1',
            type: 'text',
            required: true,
          },
        ],
      },
    ],
  };

  submit = async values => {
    const { stripe } = this.props;
    const { id } = this.state;
    if (!id) {
      const { token, error } = await stripe.createToken({
        name: values.name,
        address_line1: values.address_line1,
        address_country: values.address_country,
      });
      if (error) throw new Error(error.message);
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: token.id,
        },
      });
      return true;
    } else {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input: {
            exp_month: values.exp_month - 0,
            exp_year: values.exp_year - 0,
            name: values.name,
            address_country: values.address_country,
            address_line1: values.address_line1,
          },
        },
      });
      return true;
    }
  };

  getInitialData({ me } = {}) {
    const { billings } = me || {},
      [billing] = billings || [],
      { name, brand, exp_month, exp_year, last4, isDefault, address_country, address_line1 } = billing || {};
    return {
      name,
      brand,
      exp_month,
      exp_year,
      last4: `**** **** **** ${last4}`,
      isDefault,
      address_country,
      address_line1,
    };
  }
}

const InjectedStripeForm = injectStripe(ItemBilling);

export default props => (
  <Elements>
    <InjectedStripeForm {...props} />
  </Elements>
);
