import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '../../layout/card/Card';
import RemoveToggle from '../../form/RemoveToggle';
import { translate } from '../../../shared/translate';
import CardHeader from '../../layout/card/CardHeader';
import CardBody from '../../layout/card/CardBody';
const OMNIWE_LOGO = require('../../../assets/src_omniwe-logo.svg');

export default class ImageField extends Component {
  state = {
    url: undefined,
  };

  handleImageChange = e => {
    const { onChange } = this.props;

    e.preventDefault();
    if (!e) {
      onChange(undefined);
      return;
    }

    if (e.target.files.length < 1) return;

    let reader = new FileReader();
    let file = e.target.files[0];

    onChange(file);
    reader.onloadend = () => {
      this.setState({ url: reader.result });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { value, className, disabled, required, ...props } = this.props,
      { url } = this.state;
    return (
      <div
        className={`embed-responsive embed-responsive-1by1 ${className}`}
        style={{
          maxWidth: 150,
          maxHeight: 150,
        }}
      >
        <div className="embed-responsive-item">
          <button
            type={`button`}
            disabled={disabled}
            className={`btn btn-light h-100 w-100 border`}
            onClick={() => this.imageUpload.click()}
          >
            <img className="object-fit-contain" src={url || value || OMNIWE_LOGO} />
            <input
              {...props}
              style={{ visibility: 'hidden', display: 'contents' }}
              ref={ref => (this.imageUpload = ref)}
              type="file"
              disabled={disabled}
              required={required}
              onChange={this.handleImageChange}
            />
          </button>
        </div>
      </div>
    );
  }
}
ImageField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
ImageField.defaultProps = {
  onChange: _ => _,
  className: '',
};
