import React from 'react';
import TinyComponent from 'components/tinyComponent';

export default ({ config = {}, onChange = _ => _, disabled }) => {
  const { content = '' } = config || {};
  return (
    <div className={'border'}>
      <TinyComponent
        value={content}
        onChange={c => {
          config.content = c;
          onChange(config);
        }}
        disabled={disabled}
      />
    </div>
  );
};
