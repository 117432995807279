import { gql } from 'apollo-boost';

export const GET_SLOTS = gql`
  query($id: ID, $otherFilter: EventFilterInput, $offset: OffsetInput) {
    node(id: $id) {
      id
      ... on Venue {
        events(otherFilter: $otherFilter, offset: $offset) {
          id
          name
          status
          createdAt
          updatedAt
          orderItem {
            id
            order {
              id
            }
          }
          address {
            person
            tel
            email
            lines
            country
          }
          slots {
            id
            startedAt
            startedThru
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
