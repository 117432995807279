import React from 'react';
import { Label, Col } from 'reactstrap';
export default ({
  useFormRow,
  required,
  htmlFor,
  labelMd = 2,
  inputLg,
  title,
  className = '',
  children,
  labelCol = false,
}) => {
  return (
    <div
      className={['position-relative', !useFormRow && 'form-group row', !!useFormRow && 'form-row', className]
        .filter(_ => _)
        .join(' ')}
    >
      {!!title && (
        <Label md={labelMd} className="text-muted col-form-label" htmlFor={htmlFor}>
          {title} {!!required && '*'}
        </Label>
      )}
      {labelCol && (
        <Col lg={inputLg} className={'col-form-label'} md={12 - labelMd}>
          {children}
        </Col>
      )}
      {!labelCol && (
        <Col lg={inputLg} md={12 - labelMd}>
          {children}
        </Col>
      )}
    </div>
  );
};
