import React from 'react';
import errorParser from '../shared/errorParser';
import imageUpload from 'shared/imageUpload';
import { Editor } from '@tinymce/tinymce-react';

export default ({
  innerRef,
  value,
  onChange,
  plugins = 'paste code image textcolor media link',
  toolbar = 'code | undo redo | formatselect removeformat | bold underline italic strikethrough underline | alignleft aligncenter alignright | link image media | forecolor',
  disabled = false,
  ...props
}) => (
  <Editor
    ref={innerRef}
    initialValue={value}
    init={{
      menubar: false,
      readonly: disabled,
      plugins,
      images_upload_handler: async (blobInfo, success, failure) => {
        try {
          success(await imageUpload(blobInfo.blob()));
        } catch (e) {
          failure(errorParser(e));
        }
      },
      toolbar,
      ...props,
    }}
    onEditorChange={onChange}
    {...props}
  />
);
