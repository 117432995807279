// 中介服務商列表
// src/routers/list_agent_service.jsx
// created on april-17, 2019, by Tony Day
import React from 'react';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import { Card } from 'reactstrap';
import * as PU from '../../components/pageComponents/utility';
import ListBase from '../../components/ListBase';
import ListComponent from '../../components/ListComponent';
import Breadcrumb from '../../components/pageComponents/CrumbNavi';

const _tableHeader = () => (
  <thead>
    <tr>
      {/* <th style={{ width: 100 }} className="border-top-0 text-truncate tc">
        {translate.operations}
      </th> */}
      <th className='border-top-0 text-truncate'>{translate.name}</th>
      <th style={{ width: 100 }} className='border-top-0 text-truncate'>
        {translate.opening}
      </th>
      <th
        style={{ width: 140 }}
        className='border-top-0 text-right text-truncate'
      >
        {translate.created_at}
      </th>
      <th
        style={{ width: 140 }}
        className='border-top-0 text-right text-truncate'
      >
        {translate.updated_at}
      </th>
    </tr>
  </thead>
);

const _item = item => (
  <tr>
    <td>
      <Link to={'/agent_service/' + item.id}>{item.title}</Link>
    </td>
    <td>{item.active ? <PU.Active /> : <PU.Inactive />}</td>
    <td className='text-right text-truncate'>{PU.DateTime(item.createdAt)}</td>
    <td className='text-right text-truncate'>{PU.DateTime(item.updatedAt)}</td>
  </tr>
);

const PageHeader = () => (
  <PU.H6CardHeader text={translate.agent_service_list}>
    <Link
      to={{ pathname: '/agent_service/new' }}
      className='btn btn-light btn-sm'
    >
      <i className='fa fa-plus fa-fw' /> {translate.create}
    </Link>
  </PU.H6CardHeader>
);

const GET_QUERY = gql`
  query($offset: OffsetInput, $filter: FilterInput) {
    agent: me {
      id
      agentServices(offset: $offset, filter: $filter) {
        id
        title
        description
        active
        createdAt
        updatedAt
      }
      agentServicesCount
    }
  }
`;

export default class ListAgentService extends ListBase {
  constructor(props) {
    super(props);
    this.breadcrumbs = [translate.agent_service_list];
  }

  getData = data => {
    const { agent: { agentServices = [], agentServicesCount } = {} } =
      data || {};
    return { items: agentServices, count: agentServicesCount };
  };

  render() {
    return (
      <PU.PageBody>
        <Breadcrumb items={this.breadcrumbs} />
        <Card>
          <PageHeader />
          <ListComponent
            title={translate.agent_service_list}
            query={GET_QUERY}
            getData={this.getData}
            renderItem={_item}
            tableHeader={_tableHeader}
            offset={this.offset}
          />
        </Card>
      </PU.PageBody>
    );
  } // end render()
} // end class
