import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Invoice {
        status
        refundedAmount {
          amount
          locale
        }
        creditNotes {
          id
          status
          amount {
            amount
            locale
          }
          paymentMethod
          invoice {
            id
          }
        }
        order {
          id
          attributes {
            key
            value
          }
        }
        items {
          orderItem {
            id
            productVariant {
              id
              product {
                id
                name
              }
              attributes {
                key
                value
              }
            }
            description
            remarks
            quantity
            unitPrice {
              amount
              locale
            }
          }
          quantity
        }
        # deliveryNotes {
        #  id
        #  status
        # }
        amount {
          amount
          locale
        }
        paymentCredential {
          id
          platform
          name
        }
        paymentMethod
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation orderInvoiceCreate($orderId: ID!, $input: OrderInvoiceInput!) {
    orderInvoiceCreate(input: $input, orderId: $orderId) {
      id
    }
  }
`;

export const INVOICE_COMPLETE = gql`
  mutation orderInvoiceComplete($id: ID!) {
    orderInvoiceComplete(id: $id) {
      id
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation orderInvoiceVoid($id: ID!) {
    orderInvoiceVoid(id: $id) {
      id
      status
    }
  }
`;
