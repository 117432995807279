import React from 'react';
import LOGO from '../../../assets/HomePage-Menu-Logo@2x.png';
import { translate } from '../../../shared/translate';
import { Icon } from '../../../components/iconRender';

export default ({ onClick, title = translate['shop_deselected'], active, description = 'test@123.com' }) => (
  <button
    onClick={onClick}
    className={`list-group-item list-group-item-action text-truncate rounded-0 d-flex flex-row align-items-center text-light ${
      active ? 'active' : ''
    }`}
    style={{ backgroundColor: 'initial' }}
  >
    <img src={LOGO} width="64" className="mr-2" />
    <div className="text-truncate">
      <h5 className="font-weight-normal m-0 text-light text-truncate">
        <span>{title}</span>
      </h5>
      <small className="text-secondary text-truncate">{description}</small>
    </div>
    <div className={'flex-fill'} />
    <Icon icon={'faCaretRight'} className={'text-white'} />
  </button>
);
