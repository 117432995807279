import React from 'react';

export default ({ config: { images = [] } = {} }) => {
  const { image, title } = images[0] || {};
  return (
    <div className={'position-relative'}>
      <div className={'embed-responsive embed-responsive-16by9'}>
        <img
          className={'embed-responsive-item object-fit-contain'}
          src={image || require('../../../../assets/preview_banner_2.png')}
          style={{
            background: `url(${image || require('../../../../assets/preview_banner_2.png')})`,
          }}
        />
      </div>
      {!!title && (
        <small
          className={'position-absolute text-white px-2'}
          style={{
            top: 15,
            left: 15,
            background: 'rgba(0,0,0,0.5)',
          }}
        >
          {title}
        </small>
      )}
    </div>
  );
};
