import React, { Component } from 'react';
import { translate } from '../../../shared/translate';
import _ from 'lodash';
import { toast } from 'react-toastify';

export default class extends Component {
  onNameChange = (e, i) => {
    const { onOptionsChange, options } = this.props;
    options[i].name = e.target.value;
    onOptionsChange && onOptionsChange(options);
  };

  removeOption = i => {
    const { onOptionsChange, options } = this.props;
    options.splice(i, 1);
    onOptionsChange && onOptionsChange(options);
  };

  removeOptionValue = (values, i) => {
    const { onOptionsChange, options } = this.props;
    values.splice(i, 1);
    onOptionsChange && onOptionsChange(options);
  };

  handleKeyPress = (e, i) => {
    const { onOptionsChange, options } = this.props;
    const option = options[i];
    const newValue = e.target.value;

    if (e.keyCode === 13 || e.keyCode === 188) {
      e.preventDefault();
      if (newValue === '') return;

      let list = _.filter(
        _.split(newValue, ',').reduce((prev, value) => {
          return [...prev, value.trim()];
        }, []),
        value => value !== '',
      );

      if (!option.values) option.values = [];

      if (_.intersection(option.values, list).length > 0) {
        toast.error(translate.same_option_error);
        return;
      }

      option.values = _.concat(option.values, list);
      e.target.value = '';
    }

    onOptionsChange && onOptionsChange(options);
  };

  render() {
    const { options, onAddOptionClick, isSubmitting } = this.props;
    return (
      <table className="table table-borderless">
        <colgroup>
          <col width="15%" />
          <col width="*" />
          <col width="1%" />
        </colgroup>
        <thead>
          <tr>
            <th>{translate.variant_name}</th>
            <th>{translate.variant_option}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {options.map(({ name = '', values = [] }, i) => {
            return (
              <tr key={i}>
                <th>
                  <input
                    disabled={isSubmitting}
                    className={`form-control`}
                    required={true}
                    type="text"
                    value={name}
                    onChange={e => this.onNameChange(e, i)}
                    placeholder={translate.variant_name_placeholder}
                  />
                </th>
                <th>
                  <div className={`form-control h-auto`}>
                    {values.map((value, i) => (
                      <button
                        key={i}
                        type="button"
                        disabled={isSubmitting}
                        className="btn btn-primary btn-sm mr-1 mb-3"
                        onClick={() => this.removeOptionValue(values, i)}
                      >
                        <span className={`mr-2`}>{value}</span>
                        <i className="fa fa-times" />
                      </button>
                    ))}
                    <input
                      disabled={isSubmitting}
                      className={`border-0 shadow-none p-0 w-100`}
                      style={{ outline: 'none' }}
                      type="text"
                      onKeyDown={e => this.handleKeyPress(e, i)}
                      placeholder={translate.variant_option_placeholder}
                    />
                  </div>
                </th>
                <th>
                  <button
                    disabled={isSubmitting}
                    type="button"
                    className="btn btn-link text-danger btn-sm"
                    onClick={() => this.removeOption(i)}
                  >
                    <i className="fa fa-times" />
                  </button>
                </th>
              </tr>
            );
          })}
          <tr>
            <td colSpan={99}>
              <button type="button" className="btn btn-outline-secondary" onClick={onAddOptionClick}>
                {translate.new_option}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
