import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate as t } from '../../shared/translate';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LIST_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.vendor }],
    gql: { get: LIST_QUERY },
    createUrl: '/vendors/new',
    fields: [
      {
        title: t.customer_fullname,
        fieldName: 'name',
        type: 'text',
        render: item => {
          const nameAttr = _.find(item.attributes, { key: 'name' });
          return <Link to={'/vendors/' + item.id}>{nameAttr ? nameAttr.value : '-'}</Link>;
        },
        nestedAttr: true,
        filter: true,
        sortable: 'name',
      },
      {
        title: t.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        filter: true,
        width: 140,
        sortable: 'createdAt',
      },
      {
        title: t.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        align: 'right',
        filter: true,
        width: 140,
        sortable: 'updatedAt',
      },
    ],
  };
  getExtraFetchVariables() {
    return { shopId: localStorage.getItem('shopId') };
  }
  getData = (data = {}) => {
    const { node: { vendors = [], vendorsCount = 0 } = {} } = data || {};
    return { list: vendors, count: vendorsCount };
  };
}
