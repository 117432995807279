// src/components/list.js
import React from 'react';
import sharedService from '../service/sharedService';


export default class List extends React.Component {
  constructor(props) {
    super(props);

    let {
      location: {
        state: {
          offset = {
            skip: 0,
            limit: 20
          }
        } = {}
      } = {}
    } = this.props;

    this.offset = offset;
    this.filterInput = {};
    this.otherFilter = {};
  }

  getFilterObject = fieldValues => {
    return sharedService.buildFilter(fieldValues);
  };

  handleFilter = fieldValues => {
    const filterComposite = this.getFilterObject(fieldValues);
    this.offset = {
      skip: 0,
      limit: 20
    };
    this.filterInput = filterComposite.filter;
    this.otherFilter = filterComposite.otherFilter;
    this.setState({});
  };

  next(refetch) {
    this.offset.skip += this.offset.limit;
    // this.props.history.push(this.props.match.path, { offset: this.offset });
    refetch();
    this.setState({});
  }

  prev(refetch) {
    if (this.offset.skip > 0) {
      this.offset.skip -= this.offset.limit;
      // this.props.history.push(this.props.match.path, { offset: this.offset });
      refetch();
      this.setState({});
    }
  }
}
