import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Shop {
        products {
          id
          name
        }
        annualSales
        annualSalesLast
        monthlySales
        monthlySalesLast
        weeklySales
        weeklySalesLast
        dailySales
        dailySalesLast
      }
    }
  }
`;

export const GET_SALES_REPORT = gql`
  query($id: ID!, $filter: FilterInput) {
    node(id: $id) {
      id
      ... on Shop {
        salesReport(filter: $filter) {
          totalAmount
          totalDiscount
          totalRefund
          totalCancelled
          totalChargePerPaymentMethodByCredential {
            name
            amount
          }
        }
      }
    }
  }
`;
