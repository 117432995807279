import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { ApolloProvider } from 'react-apollo';
import { StripeProvider } from 'react-stripe-elements';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'grapesjs/dist/css/grapes.min.css';
import './css/App.css';
import './index.css';



import { client } from './shared/apollo';

const {
  REACT_APP_STRIPE_KEY,
  REACT_APP_VERSION = 'dev'
  // REACT_APP_CHANGELOG,
} = process.env;

ReactDOM.render(
  <ApolloProvider client={client}>
    <StripeProvider apiKey={REACT_APP_STRIPE_KEY}>
      <App />
    </StripeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

registerServiceWorker();

localStorage.setItem('version', REACT_APP_VERSION);
