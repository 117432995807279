import React, { Component } from 'react';
import { Formik } from 'formik';
import { Form } from 'reactstrap';

import { translate } from '../../shared/translate';
import StatusMapper from '../../shared/status-mapper/returns';

import BreadCrumbs from '../../components/layout/BreadCrumbs';
import StatusCard from '../../components/layout/card/StatusCard';
import SingleLineTextField from '../../components/form/SingleLineTextField';
import TextAreaField from '../../components/form/TextAreaField';
import FormToolbar from '../../components/form/FormToolbar';

class ReplacementsItem extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;

    const currentId = params.id || undefined;
    this.state = {
      currentId,
      trail: [
        { to: '/replacements', label: translate.page_title_replacements },
        { to: null, label: currentId }
      ],
      status: 'PENDING'
    };
  }

  handleSubmit = (values, { setSubmitting }) => {
    alert(JSON.stringify(values));
    setSubmitting(false);
  };

  render() {
    const statusOptions = StatusMapper(this.state.status);

    return (
      <div className='container-fluid mb-5'>
        <BreadCrumbs trail={this.state.trail} />
        <Formik
          initialValues={{
            description: 'Type description here',
            orderId: '1234123',
            status: 'PENDING',
            updatedAt: '2019-05-31T03:25:31.232Z'
          }}
          onSubmit={this.handleSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <React.Fragment>
                <StatusCard
                  title='Replacement detail'
                  status={values.status}
                  onEvents={{
                    onStatusChanged: newValue =>
                      setFieldValue('status', newValue)
                  }}
                  data={{ statusOptions }}
                >
                  <Form onSubmit={handleSubmit}>
                    <SingleLineTextField
                      colWidth={5}
                      label={translate.order_number}
                      value={values.orderId}
                      onValueChanged={newValue =>
                        setFieldValue('orderId', newValue)
                      }
                      disabled
                    />
                    <TextAreaField
                      label={translate.description}
                      value={values.description}
                      onValueChanged={newValue =>
                        setFieldValue('description', newValue)
                      }
                    />
                  </Form>
                </StatusCard>
                <FormToolbar
                  onSave={handleSubmit}
                  updatedAt={values.updatedAt}
                />
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default ReplacementsItem;
