import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'shared/translate';
import { DeliveryNoteStatus } from 'shared/omniwe-types';

export default ({ values: { deliveryNotes }, values }) => {
  return (
    <div className="card mb-5 shadow">
      <div className="card-header d-flex">
        <h6 className="mb-0 flex-fill">{translate.delivery_notes}</h6>
        <Link
          to={{
            pathname: '/delivery_notes/new',
            state: { order: values },
          }}
          className="btn btn-light btn-sm"
        >
          <i className="fa fa-plus fa-fw" /> {translate.new_delivery_note}
        </Link>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover">
          <colgroup>
            <col width="*" />
            <col width="120" />
            <col width="120" />
            <col width="120" />
          </colgroup>
          <thead>
            <tr>
              <th>{translate.delivery_note_number}</th>
              <th className="d-none d-sm-table-cell">{translate.status}</th>
            </tr>
          </thead>
          <tbody>
            {deliveryNotes.map(({ id, status }) => (
              <tr key={id}>
                <td>
                  <Link to={{ pathname: `/delivery_notes/${id}` }}>{id}</Link>
                </td>
                <td className="d-none d-sm-table-cell">{DeliveryNoteStatus[status]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {deliveryNotes.length === 0 && (
          <div className="card-body text-center">
            <p className="lead font-italic text-muted mb-0">{translate.no_data}</p>
          </div>
        )}
      </div>
    </div>
  );
};
