import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate } from 'shared/translate';

export default ({ values: { items = [] } }) => {
  return (
    <Card>
      <CardHeader>{translate.card_title_packing_list}</CardHeader>
      <CardBody className={'p-0'}>
        <table className={'table table-hover'}>
          <colgroup>
            <col width="*" />
            <col width="150" />
          </colgroup>
          <thead>
            <tr>
              <th>{translate.name}</th>
              <th className="text-center">{translate.quantity}</th>
            </tr>
          </thead>
          {items.map(({ orderItem, quantity }, i) => {
            const { productVariant, description, remarks } = orderItem || {},
              { attributes = [], product: { name } = '' } = productVariant || {};

            const variantNames = attributes
              .filter(({ value }) => !!value && value !== '-')
              .map(({ key, value }) => `${key}: ${value}`)
              .concat((remarks || '').split('\n'))
              .join(' / ')
              .trim();

            const productName = !productVariant ? description : name;
            return (
              <tr key={i}>
                <td>
                  <div>{productName}</div>
                  <div className={'text-muted small'}>{variantNames}</div>
                </td>
                <td className={'text-center'}>
                  <span>{quantity}</span>
                </td>
              </tr>
            );
          })}
        </table>
      </CardBody>
    </Card>
  );
};
