import React, { Component } from 'react';
import HorizontalField from './horizontal-field';

class SingleLineTextField extends Component {
  state = {};
  render() {
    const {
      label,
      value,
      onValueChanged,
      colWidth = null,
      required = false,
      disabled = false
    } = this.props;

    return (
      <HorizontalField label={label} colWidth={colWidth}>
        <input
          className={`${disabled ? 'form-control-plaintext' : 'form-control'}`}
          type='text'
          required={required}
          value={value}
          readOnly={disabled}
          disabled={disabled}
          onChange={e => onValueChanged(e.target.value)}
        />
      </HorizontalField>
    );
  }
}

export default SingleLineTextField;
