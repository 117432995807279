import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query(
    $shopId: ID
    $offset: OffsetInput
    $filter: FilterInput
    $otherFilter: VenueFilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $shopId) {
      id
      ... on Shop {
        id
        count: venuesCount(filter: $filter, otherFilter: $otherFilter)
        items: venues(offset: $offset, filter: $filter, otherFilter: $otherFilter, sorter: $sorter) {
          id
          name
          district
          attributes {
            key
            value
          }
          updatedAt
          createdAt
        }
      }
    }
  }
`;
