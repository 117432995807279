import React from "react";
import { translate } from "../../shared/translate";
import * as pageUtility from "../../components/pageComponents/utility";
import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import * as _consts from "../../shared/_consts";
import { ServiceApplicationStatus } from "../../shared/_consts";
import sharedService from "../../service/sharedService";
import {Icon} from '../../components/pageComponents/utility';
import {faTrash} from '@fortawesome/pro-solid-svg-icons';

const POST_QUERY = gql`
  # OWNER CANCEL APPLICATION
  mutation($id: ID!, $input: ServiceApplicationStatus!) {
    serviceApplicationStatusUpdate(id: $id, input: $input) {
      id
      status
      updatedAt
    }
  }
`;

export default class ChangeStatus extends React.Component {
  submit = async (post, status) => {
    const { id } = this.props;
    const variables = {
      id,
      input: status
    };
    console.log("new status", status);
    await post({ variables });
  };

  showError = errors => {
    pageUtility.toastError(JSON.stringify(errors));
  };

  render() {
    return (
      <Mutation mutation={POST_QUERY}>
        {(post, { loading, error }) => {
          if (error) {
            this.showError(error);
          }

          if (loading) {
            return (
              <span>
                <pageUtility.Spinner /> {translate.cancelling}
              </span>
            );
          }

          const { currentStatus } = this.props;
          return (
            <React.Fragment>
              {sharedService.serviceApplicationCompletable(currentStatus) && (
                <pageUtility.ToggleButton
                  immutable={translate.done}
                  side={translate.confirm_done}
                  color="primary"
                  action={() =>
                    this.submit(post, ServiceApplicationStatus.COMPLETED)
                  }
                  disabled={loading}
                />
              )}
              {sharedService.serviceApplicationCancelable(currentStatus) && (
                <pageUtility.ToggleButton
                  immutable={<Icon icon={faTrash} />}
                  side={translate.confirm_application}
                  color="danger"
                  action={() =>
                    this.submit(post, ServiceApplicationStatus.CANCELLED)
                  }
                  disabled={loading}
                  className="ml1rem"
                />
              )}
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}
