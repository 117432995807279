import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { translate as t } from 'shared/translate';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './_gql';
import moment from 'moment';
import _ from 'lodash';
import { setAttributes } from 'shared';
import { client } from 'shared/apollo';
import CouponCard from './CouponCard';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [
      {
        route: '/coupons',
        label: t.coupon_list,
      },
      {
        label: this.state.id || t.coupon_add,
      },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    cards: [
      {
        name: t.coupon_list,
        fields: [
          {
            type: 'checkbox',
            label: t.availability,
            name: 'active',
            text: t.yes,
          },
          {
            type: 'text',
            label: t.coupon_no,
            name: 'handle',
            required: true,
            maxLength: 100,
            disabled: !!this.state.id,
          },
          {
            type: 'text',
            label: t.coupon_name,
            name: 'name',
            required: true,
            maxLength: 255,
          },
          {
            type: 'textarea',
            label: t.desc_of_use,
            name: 'description',
            multiline: true,
            maxLength: 255,
          },
          {
            type: 'text',
            label: t.remark_for_backend,
            name: 'remark',
            maxLength: 255,
          },
          {
            type: 'select',
            label: t.type,
            name: 'discountType',
            required: true,
            options: [
              {
                label: t.coupon_off_when,
                value: 'AMOUNT',
              },
              {
                label: t.coupon_discount,
                value: 'PERCENTAGE',
              },
            ],
            disabled: !!this.state.id,
          },
          {
            label: ' ',
            render: ({ values, handleChange }) => {
              if (values.discountType === 'AMOUNT')
                return (
                  <div className={'row'}>
                    <div className={'input-group col-6'}>
                      <div className="input-group-prepend">
                        <label className="input-group-text">{t.coupon_when}</label>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="amountBeforeDiscount"
                        onChange={handleChange}
                        min={0}
                        step={0.01}
                        disabled={!!this.state.id}
                        required={true}
                        value={values.amountBeforeDiscount}
                      />
                    </div>
                    <div className={'input-group col-6'}>
                      <div className="input-group-prepend">
                        <label className="input-group-text">{t.coupon_minus}</label>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="discount"
                        onChange={handleChange}
                        min={0}
                        step={0.01}
                        disabled={!!this.state.id}
                        required={true}
                        value={values.discount}
                      />
                    </div>
                  </div>
                );
              else if (values.discountType === 'PERCENTAGE')
                return (
                  <div className={'input-group'}>
                    <input
                      type={'number'}
                      className={'form-control'}
                      name={'discount'}
                      onChange={handleChange}
                      min={0}
                      max={100}
                      step={1}
                      disabled={!!this.state.id}
                      required={true}
                      value={values.discount}
                    />
                    <div className="input-group-append">
                      <label className="input-group-text">%</label>
                    </div>
                  </div>
                );
            },
          },
          {
            type: 'date',
            label: t.available_date,
            render: ({ values, handleChange }) => (
              <div className={'row'}>
                <div className="input-group col-6">
                  <div className="input-group-prepend">
                    <label className="input-group-text">{t.available_date_from}</label>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    name="validAt"
                    required={true}
                    onChange={handleChange}
                    value={moment(values.validAt).format('YYYY-MM-DD')}
                  />
                </div>
                <div className="input-group col-6">
                  <div className="input-group-prepend">
                    <label className="input-group-text">{t.available_date_to}</label>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    name="validThru"
                    required={true}
                    onChange={handleChange}
                    value={moment(values.validThru).format('YYYY-MM-DD')}
                  />
                </div>
              </div>
            ),
          },
          {
            type: 'number',
            label: t.coupon_number_of_total,
            name: 'publishLimit',
            step: 1,
            min: 1,
            required: true,
          },
          {
            type: 'date',
            label: t.coupon_sending_date,
            render: ({ values, handleChange }) => (
              <div className={'row'}>
                <div className="input-group col-6">
                  <div className="input-group-prepend">
                    <label className="input-group-text">{t.available_date_from}</label>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    name="publishAt"
                    required={true}
                    onChange={handleChange}
                    value={moment(values.publishAt).format('YYYY-MM-DD')}
                  />
                </div>
                <div className="input-group col-6">
                  <div className="input-group-prepend">
                    <label className="input-group-text">{t.available_date_to}</label>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    name="publishThru"
                    required={true}
                    onChange={handleChange}
                    value={moment(values.publishThru).format('YYYY-MM-DD')}
                  />
                </div>
              </div>
            ),
          },
          {
            type: 'checkbox',
            label: t.for_membership_only,
            name: 'membersOnly',
            text: t.yes,
          },
          {
            type: 'checkbox',
            label: t.coupon_discount_after_expiry,
            name: 'discardSubsequent',
            text: t.yes,
          },
          {
            type: 'number',
            label: t.coupon_use_times,
            render: ({ values }) => (
              <input
                type="number"
                className="form-control"
                name="ordersCount"
                value={values.used || 0}
                disabled={true}
              />
            ),
          },
        ],
      },
      CouponCard,
    ],
  };

  submit = async values => {
    let attributes = await setAttributes(values, ['description', 'remark']);
    const {
      id,
      gql: { submit },
    } = this.state;

    const {
      data: { response },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          shopId: localStorage.getItem('shopId'),
          productsId: [],
          handle: (values.handle || '').trim(),
          name: values.name,
          discount: values.discount - 0,
          discountType: values.discountType,
          amountBeforeDiscount: values.amountBeforeDiscount - 0,
          publishAt: values.publishAt,
          publishThru: values.publishThru,
          publishLimit: values.publishLimit,
          validAt: values.validAt,
          validThru: values.validThru,
          active: values.active,
          membersOnly: values.membersOnly,
          discardSubsequent: values.discardSubsequent,
          usage: values.usage - 0 || 0,
          attributes,
        },
      },
    });
    return response.id;
  };

  getInitialData(data) {
    const { node } = data || {};
    const now = moment().toISOString();
    const { attributes, usage = 0, ..._node } = node || {};
    const { description: [{ value: description } = {}] = [], remark: [{ value: remark } = {}] = [] } = _.groupBy(
      attributes,
      'key',
    );
    return {
      validAt: now,
      validThru: now,
      publishAt: now,
      publishThru: now,
      active: true,
      membersOnly: false,
      discardSubsequent: false,
      description,
      remark,
      usage,
      ..._node,
    };
  }
}
