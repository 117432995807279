import React, { Component } from 'react';

class TableHeader extends Component {
  getClasses = data => {
    const { align = 'center' } = data;
    return `d-none d-sm-table-cell text-${align}`;
  };
  render() {
    const { fields = [] } = this.props;
    return (
      <thead>
        <tr>
          {fields.map(data => (
            <th key={data.title} className={this.getClasses(data)}>
              {data.title}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
