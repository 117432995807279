import React from 'react';
import * as RS from 'reactstrap';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../shared/translate';
import { Query } from "react-apollo"
import * as PU from '../../components/pageComponents/utility';
import styled from 'styled-components';

const { Icon } = PU;
const { Card } = RS;

const Comment = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px #ccc;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: #efefef;
`;

const CommentMySession = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: solid 1px #ccc;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

const Info = styled.div`
  width: 10rem;
  margin-left: 1rem;
  margin-right: 1rem;

  p:last-child {
    color: gray;
    font-size: 80%;
  }
`;

const Bubble = styled.div`
  width: auto;
  white-space: pre-line;
`;

const OwnerSession = ({ name, content, createdAt }) => (
  <Comment>
    <Info>
      <b>{name}</b>
      <p>
        <Icon icon={icons.faClock} /> {PU.DateTime(createdAt)}
      </p>
    </Info>
    <Bubble>{content}</Bubble>
  </Comment>
);

const MySession = ({ content, createdAt }) => (
  <CommentMySession>
    <Bubble>{content}</Bubble>
    <Info style={{ textAlign: 'right' }}>
      <b>{translate.me}</b>
      <p>
        <Icon icon={icons.faClock} /> {PU.DateTime(createdAt)}
      </p>
    </Info>
  </CommentMySession>
);

export default class CommentList extends React.Component {

  isMe = user => {
    const { userId } = this.props;
    return user.id === userId;
  };

  getName(user) {
    const { attributes, credentials } = user;
    if(attributes[0])
      return attributes[0].value;
    if(credentials[0])
      return credentials[0].identity;
    return '?';
  }

  render() {
    const { comments } = this.props;
    return comments && comments.length ? (
      <Card>
        <RS.CardHeader className='mb10'>
          {translate.comment_session}
        </RS.CardHeader>
        {comments &&
          comments.map((item, i) =>
            this.isMe(item.user) ? (
              <MySession {...item} name={this.getName(item.user)} key={i} />
            ) : (
              <OwnerSession {...item} name={this.getName(item.user)} key={i} />
            )
          )}
      </Card>
    ) : null; // end return
  } // end render
} // end class
