import React from 'react';
import { translate } from '../../shared/translate';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default ({ data }) => (

  <div className="col-md-6 mb-3">
    <div className="card">
      <div className="card-header text-center">{translate.customer_browsing_device}</div>
      <div className="card-body">
        <div className="embed-responsive embed-responsive-16by9">
          <div className="embed-responsive-item">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  dataKey={'value'}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
                    const RADIAN = Math.PI / 180;
                    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (
                      <React.Fragment>
                        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                          {name}
                        </text>
                        <text
                          x={x}
                          y={y}
                          dy={18}
                          fill="white"
                          textAnchor={x > cx ? 'start' : 'end'}
                          dominantBaseline="central"
                        >
                          {`${(percent * 100).toFixed(0)}%`}
                        </text>
                      </React.Fragment>
                    );
                  }}
                  outerRadius="100%"
                  fill="#8884d8"
                  nameKey="name"
                >
                  {data.map((entry, i) => (
                    <Cell key={i} nameKey="name" fill={COLORS[i % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  </div>
);
