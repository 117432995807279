import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { GET_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [
      {
        name: translate.discount_list,
      },
    ],
    gql: { get: GET_QUERY },
    createUrl: '/discounts/new',
    fields: [
      {
        title: translate.name,
        filter: true,
        fieldName: 'name',
        type: 'text',
        render: item => (
          <Link
            to={{
              pathname: '/discounts/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            {item.name || '-'}
          </Link>
        ),
        sortable: 'name',
      },
      {
        title: translate.available_date,
        width: 250,
        render: item => (
          <div>
            {moment(item.publishAt).format('YYYY-MM-DD')}
            {' - '}
            {moment(item.publishThru).format('YYYY-MM-DD')}
          </div>
        ),
        type: 'datetime',
        filter: true,
        sortable: 'publishAt',
      },
      {
        title: translate.coupon_sending_date,
        column: false,
        align: 'right',
        fieldName: 'publish',
        type: 'datetime',
      },
      {
        title: translate.discount_repeat_times,
        width: 90,
        align: 'center',
        fieldName: 'repeat',
        sortable: 'repeat',
      },
      {
        title: translate.status,
        width: 120,
        fieldName: 'valid',
        align: 'center',
        type: 'bool',
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        width: 160,
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        align: 'right',
        width: 160,
        sortable: 'updatedAt',
      },
    ],
  };
  getData = ({ node } = {}) => {
    const { discounts = [], discountsCount = 0 } = node || {};
    return { list: discounts, count: discountsCount };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
