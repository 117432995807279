import LocalizedStrings from 'react-localization';

const translate = new LocalizedStrings(
  formatLangs({
    ...require('./lang/main'),
    countries: formatLangs(require('./lang/countries')),
    districts: formatLangs(require('./lang/hkDistricts')),
    errors: formatLangs(require('./lang/errors')),
    paymentPlatforms: formatLangs(require('./lang/paymentPlatforms')),
  }),
);
translate.setLanguage(localStorage.getItem('lang') || 'zh_hk');

function formatLangs(langs = {}, langOptions = ['en_us', 'zh_hk', 'zh_cn']) {
  return langOptions.reduce((prev, option) => {
    prev[option] = Object.entries(langs).reduce((prev, [key, value]) => {
      prev[key] = value[option];
      return prev;
    }, {});

    return prev;
  }, {});
}

export { translate };
