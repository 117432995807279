import React from 'react';
import { client } from 'shared/apollo';
import { gql } from 'apollo-boost';
import { toast } from 'react-toastify';

const createCompanySet = async (
  client,
  data = {
    input: {
      attributes: [],
    },
  },
) => {
  try {
    const { data: { companySet } = {} } = await client.mutate({
      mutation: gql`
        mutation companySet($id: ID, $input: CompanyInput!) {
          companySet(id: $id, input: $input) {
            id
          }
        }
      `,
      variables: data,
    });

    client.resetStore();
    return companySet;
  } catch (e) {
    if (e.graphQLErrors) {
      toast.error(e.message);
    }
  }
};
export default createCompanySet;
