import React from 'react';
import { Link } from 'react-router-dom';
import { GET_QUERY } from './query';
import ListPageMaker from '../../components/listPageMaker';
import { translate } from '../../shared/translate';
import Export from './Export';
import { ShipmentProvider, OrderStatus } from 'shared/omniwe-types';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: translate.orders }],
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.order_number,
        width: 150,
        render: item => <Link to={{ pathname: '/orders/' + item.id }}>{`#${item.referenceNo.slice(-3)}`}</Link>,
      },
      {
        title: translate.order_date,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        headerClassName: 'text-right',
        width: 160,
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: translate.status,
        fieldName: 'status',
        type: 'option',
        headerClassName: 'text-right',
        width: 90,
        options: Object.keys(OrderStatus).map(key => ({
          name: OrderStatus[key],
          value: key,
        })),
        render: item => OrderStatus[item.status],
        filter: true,
        sortable: 'status',
      },
      {
        title: translate.bill_to,
        headerClassName: 'text-right',
        render: item => (item.buyer || {}).person || '-',
        width: 120,
      },
      {
        title: translate.ship_to,
        headerClassName: 'text-right',
        render: item => (item.receiver || {}).person || '-',
        width: 120,
      },
      {
        title: translate.shipment,
        headerClassName: 'text-right',
        render: item => ShipmentProvider[(item.shipment || {}).provider || 'IN_SITU'],
        width: 120,
        sortable: 'shipment.provider',
        fieldName: 'shipmentProvider',
        type: 'option',
        options: Object.keys(ShipmentProvider).map(key => ({
          name: ShipmentProvider[key],
          value: key,
        })),
        filter: true,
      },
      {
        title: translate.amount,
        headerClassName: 'text-right',
        render: ({ total: { amount, locale = 'HKD' } = {} }) => `${locale} ${(amount || 0).toFixed(2)}`,
        width: 120,
        sortable: 'total',
      },
    ],
  };

  renderCreateButton = () => {
    return <Export />;
  };

  getData = ({ node } = {}) => {
    const { orders = [], ordersCount = 0 } = node || {};

    const list = orders.map(item => {
      return {
        ...item,
        buyer: item.billingAddress || {},
        receiver: item.shippingAddress || {},
      };
    });
    return {
      list,
      count: ordersCount,
    };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
