import React from 'react';
import { translate } from '../../../shared/translate';
import { Icon } from '../../../components/iconRender';

export default ({ name, icon, onClick, active }) => (
  <button
    onClick={onClick}
    className={`list-group-item list-group-item-action text-truncate rounded-0 text-light ${
      active ? 'active' : ''
    }`}
    style={{ backgroundColor: 'initial' }}
  >
    {!!icon && <Icon icon={icon} className={`mr-1 fa-fw`} />}
    <span>{translate[name]}</span>
  </button>
);
