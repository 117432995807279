// 市场服务-留言表單
// src/routers/market_service/comment_form.jsx
// created on may-9, 2019, by Tony Day
import React from "react";
import { translate } from "../../shared/translate";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { Query, Mutation } from "react-apollo";
import _ from "lodash";
import moment from "moment";
import * as RS from "reactstrap";
import PageError from "../../components/pageComponents/PageError";
import * as pageUtility from "../../components/pageComponents/utility";

const POST_QUERY = gql`
  # ADD COMMENT TO APPLICATION
  mutation($id: ID!, $input: ApplicationCommentInput!) {
    serviceApplicationCommentAdd(id: $id, input: $input) {
      id
      comments {
        content
        createdAt
        user {
          id
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    const { id: currentId } = props;
    this.state = {
      currentId,
      content: ""
    };
  }

  handleChange = event => {
    const {
      target: { name, value }
    } = event;
    this.setState({ [name]: value });
  };

  onSubmit = async (event, post) => {
    event.preventDefault();
    const input = {
      content: this.state.content
    };
    try {
      const variables = {
        id: this.state.currentId,
        input
      };
      await post({ variables });
      this.setState({
        content: ""
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <Mutation mutation={POST_QUERY}>
        {(post, { loading, error, data }) => (
          <RS.Form onSubmit={e => this.onSubmit(e, post)}>
            <RS.FormGroup>
              <RS.Input
                type="textarea"
                style={{ height: "180px" }}
                value={this.state.content}
                name="content"
                onChange={this.handleChange}
                required
              />
            </RS.FormGroup>
            <RS.FormGroup>
              {error && <PageError errors={error} />}
              <RS.Button color="primary" className="btn-100" disabled={loading}>
                {loading && <pageUtility.Spinner />}
                {translate.submit}
              </RS.Button>
            </RS.FormGroup>
          </RS.Form>
        )}
      </Mutation>
    ); // end return
  } // end render
} // end class
