import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!, $filter: FilterInput, $offset: OffsetInput, $sorter: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Shop {
        count: cashFlowStatementsCount(filter: $filter)
        list: cashFlowStatements(filter: $filter, offset: $offset, sorter: $sorter) {
          id
          deviceId
          remark
          direction
          amount {
            amount
            locale
          }
          createdAt
        }
      }
    }
  }
`;
