import React, { Component } from 'react';

import BreadCrumbs from '../../../components/layout/BreadCrumbs';
import ShippingProviderList from './ShippingProviderList';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { translate } from './../../../shared/translate';

const GET_QUERY = gql`
  query GetShopById($shopId: ID!) {
    shop: node(id: $shopId) {
      ... on Shop {
        credentials(otherFilter: { types: [SHIPMENT] }) {
          active
          platform
          identity
          secret
          type
        }
      }
    }
  }
`;

class ShippingProviders extends Component {
  state = {
    trail: [
      { to: '/settings', label: translate.settings },
      { to: null, label: translate.settings_title_shipping_providers }
    ]
  };

  getQueryVariables = {
    shopId: localStorage.getItem('shopId')
  };

  render() {
    return (
      <div className='container mb-5'>
        <BreadCrumbs trail={this.state.trail} />
        <Query
          query={GET_QUERY}
          variables={this.getQueryVariables}
          fetchPolicy='network-only'
        >
          {({ data, loading }) => {
            if (loading) return <p> ... waiting </p>;
            const { shop = {} } = data || {};
            return <ShippingProviderList shop={shop} />;
          }}
        </Query>
      </div>
    );
  }
}

export default ShippingProviders;
