import React from 'react';
import Form from '../../components/form';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { toast } from 'react-toastify';

import { client } from '../../shared/apollo';
import { translate } from '../../shared/translate';

import { ShopCredentialType } from '../../shared/omniwe-types';
import _ from 'lodash';
import GenericForm from '../../components/GenericForm';

const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Shop {
        id
        credentials {
          active
          identity
          platform
          secret
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

const SET_CREDENTIAL = gql`
  mutation companyShopCredentialSet($shopId: ID!, $input: ShopCredentialInput!) {
    companyShopCredentialSet(shopId: $shopId, input: $input) {
      id
      identity
      platform
      secret
      attributes {
        key
        value
      }
    }
  }
`;

export default class Connections extends Form {
  async submit(platform) {
    const attrs = await this.setAttributes(['wechat_encrypt', 'wechat_token']);
    try {
      await client.mutate({
        mutation: SET_CREDENTIAL,
        variables: {
          shopId: localStorage.getItem('shopId'),
          input: {
            active: this.getCurrentRef(`wechatActive`).checked,
            platform,
            type: ShopCredentialType.authentication,
            identity: this.getCurrentRef(`wechat_appid`).value,
            secret:
              this.getCurrentRef(`wechat_secret`).value === '' ? undefined : this.getCurrentRef(`wechat_secret`).value,
            attributes: attrs,
          },
        },
      });
      toast.success(translate.update_success_msg);
      this.setState({});
    } catch (e) {}
  }

  render() {
    return (
      <Query query={GET_QUERY} variables={{ id: localStorage.getItem('shopId') }}>
        {({ data: { node } = {} }) => {
          const { credentials = [] } = node || {};

          const { identity: wechatId, active: wechatActive, attributes: wechatAttr = [] } =
            _.find(credentials, { platform: 'WECHAT_AUTH' }) || {};

          const {
            wechat_token: [{ value: wechat_token } = {}] = [],
            wechat_encrypt: [{ value: wechat_encrypt } = {}] = [],
          } = _.groupBy(wechatAttr, 'key');

          return (
            <GenericForm breadcrumb={[{ name: translate.connections }]}>
              <form
                className={`my-3`}
                onSubmit={e => {
                  e.preventDefault();
                  this.submit('WECHAT_AUTH');
                }}
              >
                <div className="card">
                  <div className="card-header d-flex">
                    <h6>
                      <i className="fa fa-fw fa-weixin text-primary mr-1" />
                      {translate.wechat_settings}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label htmlFor="name" className="col-sm-2 col-form-label">
                        {translate.availability}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <div className="form-check pt-2">
                          <input
                            ref={this.getRef('wechatActive')}
                            className="form-check-input"
                            type="checkbox"
                            id="valid"
                            defaultChecked={wechatActive}
                          />
                          <label className="form-check-label" htmlFor="valid">
                            {translate.yes}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.wechat_app_id}</label>
                      <div className="col">
                        <input
                          className="form-control"
                          type="text"
                          name="key"
                          defaultValue={wechatId}
                          ref={this.getRef('wechat_appid')}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.wechat_secret}</label>
                      <div className="col">
                        <input className="form-control" type="password" name="key" ref={this.getRef('wechat_secret')} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.wechat_token}</label>
                      <div className="col">
                        <input
                          className="form-control"
                          type="text"
                          name="key"
                          ref={this.getRef('wechat_token')}
                          defaultValue={wechat_token}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.wechat_encryption_key}</label>
                      <div className="col">
                        <input
                          className="form-control"
                          type="text"
                          name="key"
                          ref={this.getRef('wechat_encrypt')}
                          defaultValue={wechat_encrypt}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-dark shadow pull-right">
                      {translate.save}
                    </button>
                  </div>
                </div>
              </form>

              {false && (
                <div className="card mb-3">
                  <div className="card-header d-flex">
                    <h6>
                      <i className="fa fa-fw fa-paypal text-success mr-1" />
                      {translate.paypal}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.paypal_environment}</label>
                      <div className="col-sm-10 col-form-label">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="enumPayPalSandbox"
                            name="paypal.mode"
                            className="custom-control-input"
                            value="sandbox"
                          />
                          <label className="custom-control-label" htmlFor="enumPayPalSandbox">
                            {translate.sandbox}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="enumPayPalLive"
                            name="paypal.mode"
                            className="custom-control-input"
                            value="live"
                          />
                          <label className="custom-control-label" htmlFor="enumPayPalLive">
                            {translate.live}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.client_id}</label>
                      <div className="col">
                        <input className="form-control" type="text" name="key" ref={this.getRef('title')} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.paypal_secret}</label>
                      <div className="col">
                        <input className="form-control" type="text" name="key" ref={this.getRef('title')} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {false && (
                <div className="card mb-3">
                  <div className="card-header d-flex">
                    <h6>{translate.china_mail}</h6>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.china_mail_app_id}</label>
                      <div className="col">
                        <input className="form-control" type="text" name="key" ref={this.getRef('title')} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label text-muted">{translate.china_mail_secret}</label>
                      <div className="col">
                        <input className="form-control" type="text" name="key" ref={this.getRef('title')} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </GenericForm>
          );
        }}
      </Query>
    );
  }
}
