import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import React from 'react';
import Model from './model';
import { client } from '../shared/apollo';
import { toast } from 'react-toastify';
import { translate } from '../shared/translate';

import moment from 'moment';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Order {
        comments {
          content
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const COMMENT_SET = gql`
  mutation orderCommentSet($id: ID!, $comments: [CommentInput!]!) {
    orderCommentSet(id: $id, comments: $comments) {
      id
      comments {
        content
        createdAt
      }
    }
  }
`;

export default class Comments extends Model {
  async commentsAdd() {
    try {
      const { data: { orderCommentSet: { comments = [] } = {} } = {} } = await client.mutate({
        mutation: COMMENT_SET,
        variables: {
          id: this.props.orderId,
          comments: [
            ...this.comments,
            {
              content: this.getCurrentRef(`commentInput`).value,
            },
          ],
        },
      });

      this.comments = comments.reduce((prev, { content, createdAt }) => {
        return [
          ...prev,
          {
            content,
            createdAt,
          },
        ];
      }, []);

      this.getCurrentRef(`commentInput`).value = '';
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  async removeComment(index) {
    try {
      const { data: { orderCommentSet: { comments = [] } = {} } = {} } = await client.mutate({
        mutation: COMMENT_SET,
        variables: {
          id: this.props.orderId,
          comments: this.comments.reduce((prev, comment, commentIndex) => {
            return index === commentIndex ? prev : [...prev, comment];
          }, []),
        },
      });

      this.comments = comments.reduce((prev, { content, createdAt }) => {
        return [
          ...prev,
          {
            content,
            createdAt,
          },
        ];
      }, []);
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  render() {
    return (
      <Query query={GET_QUERY} variables={{ id: this.props.orderId }}>
        {({ loading, data: { node } = {} }) => {
          const { comments = [] } = node || {};

          this.comments = comments.reduce((prev, { content, createdAt }) => {
            return [
              ...prev,
              {
                content,
                createdAt,
              },
            ];
          }, []);

          return (
            <div className="card">
              <div className="card-header">
                <h6>{translate.comments}</h6>
              </div>
              <ul className="list-group list-group-flush">
                {this.comments.map(({ content, createdAt }, index) => (
                  <li className="list-group-item" key={index}>
                    <div className={`d-flex`}>
                      <p className={`flex-fill`} style={{ whiteSpace: 'pre-line' }}>
                        {content}
                      </p>
                      <div>
                        <button className="btn btn-link text-danger" onClick={() => this.removeComment(index)}>
                          <i className="fa fa-times fa-fw" />
                        </button>
                      </div>
                    </div>
                    <div className={`d-flex`}>
                      <small className={`flex-fill text-muted align-items-center`}>
                        {moment(createdAt).format('HH:mm DD/MM/YYYY')}
                      </small>
                    </div>
                  </li>
                ))}
              </ul>

              <div className="card-footer">
                <form
                  className="d-flex"
                  onSubmit={async e => {
                    e.preventDefault();
                    await this.commentsAdd();
                  }}
                >
                  <div className="input-group">
                    <textarea
                      type="text"
                      className="form-control form-control-lg flex-fill"
                      ref={this.getRef(`commentInput`)}
                      placeholder={translate.comments_placeholder}
                    />
                    <div className="input-group-append">
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-pencil" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
