import React from 'react';
import { translate as t } from '../../../shared/translate';

export default ({ showActionColumn = false }) => (
  <thead>
    <tr>
      <th>{t.name}</th>
      <th style={{ width: '120px' }}>{t.sort_index}</th>
      <th className='text-center' style={{ width: '80px' }}>{t.required}</th>
      <th className='text-center' style={{ width: '80px' }}>{t.visible}</th>
      {showActionColumn && <th style={{ width: '50px' }}>&nbsp;</th>}
    </tr>
  </thead>
);
