import React from 'react';
import { Card, CardBody, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
// import _ from 'lodash';

import { translate as t } from '../../shared/translate';
import { H6CardHeader, FormRow, Checkbox } from '../../components/omniwe';
import { Icon } from '../../components/iconRender';
import RemoveToggle from '../../components/form/RemoveToggle';
import { translate } from 'shared/translate';
// import ActionDelete from './ActionDelete';

export default ({
  name,
  promotionRecordsCount,
  onDownloadClick,
  onRemoveClick,
  href,
  getRef,
  active,
  display,
  currentId,
}) => {
  return (
    <Card>
      <H6CardHeader text={t.promotion_details}>
        {currentId && (
          <div>
            {onRemoveClick && <RemoveToggle title={translate.confirm_to_delete} onClick={onRemoveClick} />}
            <button type="button" className="ml-2 btn btn-primary btn-sm rounded-0 shadow" onClick={onDownloadClick}>
              <Icon icon={'faFileExport'} className={'mr-2'} />
              {t.records_download} ({promotionRecordsCount || 0})
            </button>
          </div>
        )}
      </H6CardHeader>
      <CardBody>
        <FormRow title={t.name} htmlFor="name">
          <Input defaultValue={name} type="text" id="name" maxLength={200} innerRef={getRef('name')} required />
        </FormRow>
        <FormRow title={t.href} htmlFor="href">
          <Input defaultValue={href} type="text" id="href" maxLength={1000} innerRef={getRef('href')} required />
        </FormRow>
        <FormRow title={t.activation} htmlFor="active">
          <Checkbox id="active" defaultChecked={active} innerRef={getRef('active')} label={t.yes} />
        </FormRow>
        <FormRow title={t.visible} htmlFor="display">
          <Checkbox id="display" defaultChecked={display} innerRef={getRef('display')} label={t.yes} />
        </FormRow>
      </CardBody>
    </Card>
  );
};
