export default [
  {
    children: [
      {
        name: 'homepage',
        icon: 'faHome',
        link: '/',
        component: require('../Dashboard').default,
        permission: 'company.shop.read',
      },
    ],
  },
  {
    name: 'frontend_settings',
    children: [
      {
        name: 'custom_pages',
        icon: 'faEdit',
        link: '/custom_pages',
        component: require('../list_CustomPage').default,
        permission: 'shop.custom_page.read',
      },
      {
        link: '/custom_pages/:id',
        component: require('../item_CustomPage').default,
      },
      {
        name: 'custom_pages_grapes',
        icon: 'faStoreAlt',
        link: '/custom_pages_grapes',
        component: require('../item_CustomPageGrape').default,
        permission: 'shop.custom_page.read',
      },
    ],
  },
  {
    name: 'product_settings',
    children: [
      {
        name: 'product_collections',
        icon: 'faTag',
        link: '/product_collections',
        component: require('../list_Collection/index').default,
        permission: 'shop.collection.read',
      },
      {
        link: '/product_collections/:id?',
        component: require('../item_Collection/index').default,
      },
      {
        name: 'products',
        icon: 'faBarcode',
        link: '/products',
        component: require('../list_Product').default,
        permission: 'shop.product.read',
      },
      {
        link: '/products/:id?',
        component: require('../item_Product/index').default,
      },
      // {
      //   name: 'warehouse',
      //   icon: 'faTape',
      //   link: '/stock_items',
      //   component: require('../list_StockItems').default,
      //   permission: 'shop.product.read',
      // },
      // {
      //   link: '/stock_items/:id?',
      //   component: require('../item_StockItems').default,
      // },
    ],
  },
  {
    name: 'order_details',
    children: [
      {
        name: 'customers',
        icon: 'faUsers',
        link: '/customers',
        component: require('../list_Customer/index').default,
        permission: 'shop.user.read',
      },
      {
        link: '/customers/:id',
        component: require('../item_Customer/index').default,
      },
      {
        name: 'orders',
        icon: 'faFileInvoice',
        link: '/orders',
        component: require('../list_Order/index').default,
        permission: 'shop.order.read',
      },
      {
        link: '/orders/:id',
        component: require('../item_Order').default,
      },
      {
        name: 'invoices',
        icon: 'faFileInvoiceDollar',
        link: '/invoices',
        component: require('../list_Invoice/index').default,
        permission: 'shop.invoice.read',
      },
      {
        link: '/invoices/new',
        component: require('../item_Invoice/new').default,
      },
      {
        link: '/invoices/:id',
        component: require('../item_Invoice').default,
      },
      {
        name: 'delivery_notes',
        icon: 'faTruck',
        link: '/delivery_notes',
        component: require('../list_DeliveryNote/index').default,
        permission: 'shop.delivery_note.read',
      },
      {
        link: '/delivery_notes/new',
        component: require('../item_DeliveryNote/new').default,
      },
      {
        link: '/delivery_notes/:id',
        component: require('../item_DeliveryNote').default,
      },
      {
        name: 'page_title_credit_notes',
        icon: 'faFileInvoice',
        link: '/credit_notes',
        component: require('../list_CreditNote').default,
        permission: 'shop.credit_note',
      },
      {
        link: '/credit_notes/:id',
        component: require('../item_CreditNote').default,
        permission: 'shop.credit_note',
      },
    ],
  },
  {
    name: 'menu_title_rma',
    children: [
      {
        name: 'page_title_returns',
        icon: 'faFileInvoice',
        link: '/returns',
        component: require('../list_Return').default,
        permission: 'shop.return_note',
      },
      {
        link: '/returns/:id',
        component: require('../item_Return').default,
        permission: 'shop.return_note',
      },
      {
        name: 'page_title_replacements',
        icon: 'faTruck',
        link: '/replacements',
        component: require('../list_Replacement').default,
        permission: 'shop.refund',
      },
      {
        link: '/replacements/:id',
        component: require('../item_Replacement').default,
        permission: 'shop.refund',
      },
    ],
  },
  {
    name: 'menu_title_crm',
    children: [
      {
        name: 'page_title_support_ticket',
        icon: 'faFileInvoice',
        link: '/support_tickets',
        component: require('../list_SupportTicket').default,
        permission: 'shop.support_ticket',
      },
      {
        link: '/support_tickets/:id',
        component: require('../item_SupportTicket').default,
        permission: 'shop.support_ticket',
      },
    ],
  },
  {
    name: 'marketing',
    children: [
      {
        name: 'coupons',
        icon: 'faTicketAlt',
        link: '/coupons',
        component: require('../list_Coupon').default,
        permission: 'shop.coupon.read',
      },
      {
        link: '/coupons/:id',
        component: require('../item_Coupon').default,
      },
      {
        name: 'discounts',
        icon: 'faArrowToBottom',
        link: '/discounts',
        component: require('../list_Discount').default,
        permission: 'shop.discount.read',
      },
      {
        link: '/discounts/:id',
        component: require('../item_Discount').default,
      },
      {
        name: 'promotion',
        icon: 'faTags',
        link: '/promotions',
        component: require('../list_Promotion').default,
        permission: 'hc.promotion.read',
      },
      {
        link: '/promotions/:id',
        component: require('../item_Promotion').default,
      },
    ],
  },
  {
    name: 'other_settings',
    children: [
      {
        name: 'dm_plan',
        icon: 'faRss',
        link: '/dm_plans',
        component: require('../list_DMPlan').default,
        permission: 'dm.plans.read',
      },
      {
        link: '/dm_plans/:id',
        component: require('../item_DMPlan').default,
      },
      {
        name: 'venue',
        icon: 'faAmbulance',
        link: '/venues',
        component: require('../list_Venue/index').default,
        permission: 'hc.venue.read',
      },
      {
        link: '/venues/:id',
        component: require('../item_Venue').default,
      },
      {
        name: 'vendor_logins',
        icon: 'faUserCog',
        link: '/vendors',
        component: require('../list_Vendor/index').default,
        permission: 'hc.vendor.read',
      },
      {
        link: '/vendors/:id',
        component: require('../item_Vendor/index').default,
      },
      {
        name: 'hc_service',
        icon: 'faNotesMedical',
        link: '/hc_services',
        component: require('../list_Service/index').default,
        permission: 'hc.service.read',
      },
      {
        link: '/hc_services/:id',
        component: require('../item_Service/index').default,
      },
      {
        name: 'cash_flow_statement',
        icon: 'faMoneyBill',
        link: '/cash_flow_statement',
        component: require('../list_CashFlowStatement').default,
        permission: 'company.shop.read',
      },
      {
        name: 'delivery_options',
        icon: 'faBox',
        link: '/delivery_options',
        component: require('../list_DeliveryOption').default,
        permission: 'shop.delivery_option.read',
      },
      {
        link: '/delivery_options/:id',
        component: require('../item_DeliveryOption').default,
      },
      {
        name: 'taxes',
        icon: 'faDollarSign',
        link: '/taxes',
        component: require('../list_Tax').default,
        permission: 'shop.tax.read',
      },
      {
        link: '/taxes/:id',
        component: require('../item_Tax/index').default,
      },
      {
        name: 'connections',
        icon: 'faPlug',
        link: '/connections',
        component: require('../Connection').default,
        permission: 'shop.shop_credential.read',
      },
      {
        name: 'quick_links',
        icon: 'faLink',
        link: '/settings',
        component: require('../settings/index').default,
        includedPathnames: ['/market_services', '/settings/shop_info', '/profile'],
        permission: [
          'shop.shop_credential.read',
          'shop.tax.read',
          'company.shop.read',
          'user.billing.read',
          'shop.custom_page.read',
          'shop.agent.read',
        ],
      },
    ],
  },
];
