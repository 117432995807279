import React from 'react';
import { translate } from 'shared/translate';
import { Link } from 'react-router-dom';

const TableColumnDefinition = () => (
  <colgroup>
    <col width="*" />
    <col width="120" />
    <col width="120" />
    <col width="120" />
  </colgroup>
);

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>{translate.items}</th>
        <th className="d-none d-sm-table-cell text-right">{translate.price}</th>
        <th className="d-none d-sm-table-cell text-right">{translate.quantity}</th>
        <th className={`text-right`}>{translate.sub_total}</th>
      </tr>
    </thead>
  );
};

export default ({
  values: { orderId, items = [], total: { amount: total = 0 } = {}, subtotal: { amount: subtotal = 0 } = {} },
}) => {
  return (
    <div className="card mb-5 shadow">
      <div className="card-header">
        <span>{translate.order_number}</span> <Link to={`/orders/${orderId}`}>{orderId}</Link>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover">
          <TableColumnDefinition />
          <TableHeader />
          <tbody>
            {items.map(({ id, productVariant, quantity, unitPrice, description, remarks }, i) => {
              const { amount } = unitPrice || {};
              const { attributes = [], product: { name } = '' } = productVariant || {};

              const variantNames = attributes
                .filter(({ value }) => !!value && value !== '-')
                .map(({ key, value }) => `${key}: ${value}`)
                .concat((remarks || '').split('\n'))
                .join(' / ')
                .trim();

              const productName = !productVariant ? description : name;

              return (
                <tr key={i}>
                  <td>
                    <div>{productName}</div>
                    <div className={'text-muted small'}>{variantNames}</div>
                  </td>
                  <td className={'d-none d-sm-table-cell text-right'}>{(amount - 0).toFixed(2)}</td>
                  <td className={'d-none d-sm-table-cell text-right'}>{quantity}</td>
                  <td className={'text-right'}>{((amount - 0) * quantity).toFixed(2)}</td>
                </tr>
              );
            })}
            <tr>
              <td className="font-weight-bold text-right" colSpan={3}>
                {translate.sub_total}
              </td>
              <td className={`text-right`}>{(subtotal - 0).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="font-weight-bold text-right" colSpan={3}>
                {translate.discounts} / {translate.coupons}
              </td>
              <td className={`text-right text-danger`}>-{Math.max(0, subtotal - total).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="font-weight-bold text-right" colSpan={3}>
                {translate.total}
              </td>
              <td className={`text-right lead font-weight-bold`}>{(total - 0).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
