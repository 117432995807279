import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import * as RS from 'reactstrap';
import { toast } from 'react-toastify';
import { CardHeader, Button } from 'reactstrap';
import { translate as t } from '../../shared/translate';

export const PageError = require('./PageError');

export const WhiteButton = ({ onClick, children, className }) => {
  return (
    <Button
      color='default'
      size='sm'
      className={`rounded-0 shadow text-dark ${className}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const RedDot = ({ spaceLeft }) => {
  let styles = {
    display: 'inline-block',
    height: '6px',
    width: '6px',
    borderRadius: '3px'
  };
  if (spaceLeft) {
    styles = { ...styles, marginLeft: '5px' };
  }
  return <i className='bg-danger' style={styles} />;
};

export const EmptyList = () => (
  <tr>
    <td style={{ textAlign: 'center', color: 'gray' }} colSpan='99'>
      <FontAwesomeIcon icon={icons.faFolderOpen} />{' '}
      <span style={{ fontStyle: 'italic' }}>{t.no_data}</span>
    </td>
  </tr>
);
export const H6CardHeader = ({ text, children }) => (
  <CardHeader className={children ? 'd-flex' : null}>
    <h6 className={children ? 'flex-fill' : null}>{text}</h6>
    {children}
  </CardHeader>
);

export const TableHeader = ({ children }) => (
  <CardHeader className='text-right d-flex align-items-center bg-white'>
    {children}
  </CardHeader>
);

export const DefaultText = () => <span>-</span>;

export const PageBody = ({ children }) => (
  <div className='container-fluid mb-5'>{children}</div>
);

export const Html = ({ content }) => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
);

export const SmallLoading = ({ color, size }) => {
  if (size) {
    size = 'fa-' + size;
  }
  return <i className={`fa fa-spinner fa-spin ${size} ${color}`} />;
};

export const Loading = ({ color, size = '2x' }) => {
  if (size) {
    size = 'fa-' + size;
  }
  return (
    <div className='tc' style={{ padding: '1rem' }}>
      <i className={`fa fa-spinner fa-spin ${size} ${color}`} />
    </div>
  );
};

export const toastWarn = msg => {
  toast.warn(msg);
};

export const toastError = msg => {
  toast.error(msg);
};

export const toastSuccess = msg => {
  toast.success(msg);
};

export const Icon = ({ style, icon, size, margin = false }) => (
  <React.Fragment>
    <FontAwesomeIcon style={style} icon={icon} size={size} />
    {margin && ' '}
  </React.Fragment>
);
//export const Active = ()=>(<FontAwesomeIcon icon={["fa", "check-circle"]} />);
export const Active = () => (
  <FontAwesomeIcon className='text-primary' icon={icons.faCheckCircle} />
);
export const Inactive = () => (
  <FontAwesomeIcon className='text-muted' icon={icons.faMinusCircle} />
);
export const Activation = ({ active }) => {
  return active ? <Active /> : <Inactive />;
};
export const Spinner = () => (
  <FontAwesomeIcon icon={icons.faSpinner} spin style={{ marginRight: 10 }} />
);

export const Error = ({ errors }) => (
  <div>
    <p>ERROR: </p>
    <pre>{JSON.stringify(errors)}</pre>
  </div>
);

export const DateTime = ({ datetime, format = 'YYYY-MM-DD HH:mm' }) =>
  moment(datetime).format(format);

export class ToggleButton extends React.Component {
  state = {
    showSide: false
  };

  toggle = () => {
    const showSide = !this.state.showSide;
    this.setState({ showSide });
  };

  render() {
    const {
      immutable,
      side,
      color = 'secondary',
      action,
      disabled,
      className,
      style
    } = this.props;
    return (
      <RS.ButtonGroup className={className} style={style}>
        {this.state.showSide && (
          <RS.Button
            size='sm'
            color={color}
            onClick={action}
            disabled={disabled}
          >
            {side}
          </RS.Button>
        )}
        <RS.Button
          size='sm'
          onClick={this.toggle}
          color={color}
          disabled={disabled}
        >
          {this.state.showSide && (
            <Icon icon={icons.faCaretRight} style={{ marginRight: '5px' }} />
          )}
          {!this.state.showSide && (
            <Icon icon={icons.faCaretLeft} style={{ marginRight: '5px' }} />
          )}
          {immutable}
        </RS.Button>
      </RS.ButtonGroup>
    );
  }
}
