import React from 'react';

export default ({ id, defaultChecked, value, onChange, label, innerRef }) => (
  <div className='form-check pt-2'>
    <input
      ref={innerRef}
      className='form-check-input'
      type='checkbox'
      id={id}
      defaultChecked={defaultChecked}
      value={value}
      onChange={onChange}
    />
    {label && (
      <label className='form-check-label' htmlFor={id}>
        {label}
      </label>
    )}
  </div>
);
