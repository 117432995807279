import React, { useState, Fragment } from 'react';
import { Icon } from 'components/iconRender';
import { translate as t } from 'shared/translate';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Formik, Form } from 'formik';
import { EXPORT_QUERY } from './query';
import { client } from 'shared/apollo';
import Loading from 'components/loading';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';
import { OrderStatus } from 'shared/omniwe-types';
import moment from 'moment';

export default () => {
  const [isOpen, setIsOpen] = useState(false),
    toggle = () => setIsOpen(!isOpen);
  return (
    <Fragment>
      <button className="btn btn-light btn-sm" onClick={toggle}>
        <Icon icon="faFileExport" className="mr-1" fixedWidth />
        {t.export}
      </button>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t.export}</ModalHeader>
        <Formik
          initialValues={{
            createdSince: moment().format('YYYY-MM-DD'),
            createdUntil: moment().format('YYYY-MM-DD'),
            status: 'COMPLETED',
          }}
          onSubmit={async (values, { setFieldValue, setSubmitting }) => {
            if (values.downloadLink) {
              toggle();
              return true;
            }
            try {
              const { data: { ordersExport } = {} } = await client.mutate({
                mutation: EXPORT_QUERY,
                variables: {
                  shopId: localStorage.getItem('shopId'),
                  otherFilter: {
                    status: values.status,
                  },
                  filter: {
                    createdSince: values.createdSince,
                    createdUntil: values.createdUntil,
                  },
                },
              });

              if (ordersExport) {
                /*! Note: Try to avoid recursive functions in javascript, heap
                 *  size and stack limit is a thing. - Vicary
                 */
                let fileAvailable = false;

                while (!fileAvailable) {
                  try {
                    const { status } = await fetch(ordersExport, { method: 'HEAD' });
                    fileAvailable = status === 200;
                  } catch (e) {}

                  if (!fileAvailable) {
                    await new Promise(r => setTimeout(r, (8 + Math.random() * 5) * 1000));
                  }
                }

                setFieldValue('downloadLink', ordersExport);
                window.open(ordersExport, '_blank');
              }
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                {!isSubmitting && !values.downloadLink && (
                  <React.Fragment>
                    <div className="form-group">
                      <label htmlFor="date_range" className="control-label">
                        {t.date_range}
                      </label>

                      <div className="input-group input-group-sm">
                        <input
                          type="date"
                          className="form-control"
                          value={values.createdSince}
                          onChange={e => setFieldValue('createdSince', e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">{t.to}</span>
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          value={values.createdUntil}
                          onChange={e => setFieldValue('createdUntil', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="status" className="control-label">
                        {t.status}
                      </label>
                      {Object.keys(OrderStatus).map(key => (
                        <div className={'form-check'} key={key}>
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={key}
                              checked={values.status === key}
                              onChange={handleChange}
                            />
                            <span>{OrderStatus[key]}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                )}
                {isSubmitting && (
                  <React.Fragment>
                    <Loading spacing="mt-5 mb-2" />
                    <h6 className="text-center mb-5">{t.preparing}</h6>
                  </React.Fragment>
                )}
                {!isSubmitting && !!values.downloadLink && (
                  <React.Fragment>
                    <div>{t.export_success_msg}</div>
                    <a target="_blank" href={values.downloadLink}>
                      {t.export_success_msg2}
                    </a>
                  </React.Fragment>
                )}
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <Button color="light" onClick={toggle} className="shadow" disabled={isSubmitting}>
                  {t.close}
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t.submit}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  );
};
