import React from 'react';
import ListPageTemplate from '../../components/listPageMaker';
import { translate } from '../../shared/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { GET_QUERY } from './query';

export default class extends ListPageTemplate {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/product_collections/new',
    breadcrumb: [
      {
        name: translate.product_collections,
      },
    ],
    fields: [
      {
        title: translate.image,
        fieldName: 'thumbnail',
        width: 80,
        render: item => (
          <a className="thumbnail text-muted d-flex align-items-center justify-content-center m-auto">
            {item.thumbnail ? (
              <img className={`object-fit-contain`} src={item.thumbnail} />
            ) : (
              <FontAwesomeIcon icon={faImage} size="2x" />
            )}
          </a>
        ),
      },
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        render: item => (
          <Link
            to={{
              pathname: '/product_collections/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            {item.name}
          </Link>
        ),
        filter: true,
        sortable: 'name',
      },
      {
        width: 100,
        title: translate.sort_index,
        fieldName: 'sortIndex',
        type: 'text',
        sortable: 'sortIndex',
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        width: 180,
        headerClassName: 'text-right',
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        width: 180,
        headerClassName: 'text-right',
        filter: true,
        sortable: 'updatedAt',
      },
    ],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  getData = ({ node } = {}) => {
    const { collections: list = [], collectionsCount: count } = node || {};
    return { list, count };
  };
}
