import React from 'react';
import FormPageTemplate from '../../components/formPageMaker';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import { JSONParseSafely } from 'shared';
import { GET_QUERY, POST_QUERY } from './query';
import BodyCard from './_bodyCard';

export default class extends FormPageTemplate {
  state = {
    ...this.state,
    breadcrumb: [
      { link: '/custom_pages', name: translate.custom_pages },
      { name: this.state.id || translate.new_custom_page },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
    },
    cards: [
      !this.props.grape && {
        name: translate.custom_page_details,
        fields: [
          {
            label: translate.name,
            type: 'text',
            name: 'name',
            required: true,
            maxLength: 255,
          },
          {
            label: `${translate.group} / ${translate.href} *`,
            render: ({ values, handleChange }) => (
              <div className="form-control d-flex flex-row p-0">
                <input
                  type={'text'}
                  className={'form-control border-0 col-lg-2 col-5'}
                  placeholder={translate.group}
                  value={values.group}
                  name={'group'}
                  onChange={handleChange}
                  maxLength={255}
                  required={true}
                  autoComplete={'off'}
                  list={'group'}
                />
                <datalist id="group">
                  <option>pages</option>
                  <option>articles</option>
                </datalist>
                <div className={'d-flex align-items-center'}>
                  <h4 className={'m-0'}>/</h4>
                </div>
                <input
                  type="text"
                  className={'form-control border-0'}
                  name="href"
                  value={values.href}
                  onChange={handleChange}
                  maxLength={255}
                  placeholder="aboutUs, promotions, series.html... etc."
                  required={true}
                />
              </div>
            ),
          },
          {
            label: translate.activation,
            type: 'checkbox',
            name: 'active',
            text: translate.yes,
          },
          {
            label: translate.visible,
            type: 'checkbox',
            name: 'display',
            text: translate.yes,
          },
        ],
      },
      BodyCard,
    ].filter(_ => _),
  };

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const { name, href, group, active, display, body } = values;

    const input = {
      shopId: localStorage.getItem('shopId'),
      name,
      href,
      group,
      active,
      display,
      body: body.map(({ name, config }) => ({ name, config: JSON.stringify(config) })),
    };

    const {
      data: { customPageSet },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });
    return customPageSet.id;
  };
  getInitialData({ node } = {}) {
    const { name = '', href = '', group = '', active = true, display = true, body = [], updatedAt } = node || {};
    return {
      name,
      href,
      group,
      active,
      display,
      body: body.map(({ config, ...others }) => ({
        ...others,
        config: JSONParseSafely(config, {}),
      })),
      updatedAt,
    };
  }
}
