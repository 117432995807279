import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Type = {
  'pro-solid': require('@fortawesome/pro-solid-svg-icons'),
  'pro-regular': require('@fortawesome/pro-regular-svg-icons'),
  'pro-light': require('@fortawesome/pro-light-svg-icons'),
  'free-brands': require('@fortawesome/free-brands-svg-icons')
};

export const Icon = ({
  icon,
  fixedWidth = true,
  type = 'pro-regular',
  ...props
}) => {
  if (typeof icon === 'object') {
    return <FontAwesomeIcon icon={icon} fixedWidth={fixedWidth} {...props} />;
  }

  return (
    <FontAwesomeIcon
      icon={Type[type][icon]}
      fixedWidth={fixedWidth}
      {...props}
    />
  );
};
