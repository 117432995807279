import React from 'react';
import { Query } from 'react-apollo';
import { translate as t } from 'shared/translate';
import { gql } from 'apollo-boost';
import SearchCombo from './SearchCombo';

export const GET_COUNTRIES = gql`
  query {
    __type(name: "Country") {
      name
      enumValues {
        name
      }
    }
  }
`;

export default ({
  disabled,
  required,
  limitedCountries,
  filteredCountries = [],
  value,
  onChange = _ => _,
  className = '',
  style = {},
}) => {
  return (
    <Query query={GET_COUNTRIES}>
      {({ data }) => {
        const { __type } = data || {},
          { enumValues } = __type || {};
        const options = (enumValues || [])
          .map(({ name }) => {
            let displayName = t.countries[name];
            if (!!limitedCountries && !~limitedCountries.indexOf(name)) return null;
            if (~filteredCountries.indexOf(name)) return null;
            return {
              label: displayName,
              value: name,
            };
          })
          .filter(_ => _);

        return (
          <SearchCombo
            required={required}
            className={className}
            style={style}
            disabled={disabled}
            options={options}
            selected={(options.find(c => c.value === value) || {}).label}
            onChange={onChange}
          />
        );
      }}
    </Query>
  );
};
