import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import * as RS from 'reactstrap';
import * as icons from '@fortawesome/free-solid-svg-icons';
import * as PU from '../../components/pageComponents/utility';
import PageError from '../../components/pageComponents/PageError';
import Loading from '../../components/loading';
import ObjectNotFound from '../../components/pageComponents/ObjectNotFound';
import { translate } from '../../shared/translate';
import _ from 'lodash';
import sharedService from '../../service/sharedService';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import ChangeStatus from './ChangeStatus';
import { BreadcrumbProxy as Breadcrumb } from '../../components/pageComponents/CrumbNavi';

const { Input } = RS;

const APP_DETAILS_QUERY = gql`
  query($id: ID) {
    me {
      id
    }
    node(id: $id) {
      id
      ... on ServiceApplication {
        id
        service {
          id
          title
        }
        comments {
          content
          createdAt
          user {
            id
            credentials {
              identity
            }
            attributes {
              key
              value
            }
          }
        }
        createdAt
        updatedAt
        status
        attributes {
          key
          value
        }
      }
    }
  }
`;

export default class ApplicationDetails extends React.Component {
  updateBreadCrumb = node => {
    const {
      id: appId,
      service: { id: serviceId, title: serviceTitle }
    } = node;
    const items = [
      {
        url: '/agent_service',
        text: translate.agent_service_list
      },
      {
        url: '/agent_service/' + serviceId,
        text: serviceTitle
      },
      {
        url: '/agent_service_application/' + serviceId,
        text: translate.all_applications
      },
      appId
    ];
    Breadcrumb.update(items);
  };

  getAttrValue = (attributes, key) => {
    const found = _.find(attributes, { key });
    if (!found) {
      return '-';
    }

    return found.value;
  };

  render() {
    const {
      history,
      match: {
        params: { appId }
      }
    } = this.props;
    return (
      <Query query={APP_DETAILS_QUERY} variables={{ id: appId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          else if (error) return <PageError errors={error} />;

          const { me, node } = data || {};
          if (!node || !me) return <ObjectNotFound msg={'ID ' + appId} />;

          if (node && node.id) {
            this.updateBreadCrumb(node);
          }

          return (
            <React.Fragment>
              <RS.Card>
                <RS.CardHeader className='d-flex'>
                  <h6 className='mb-0 flex-fill'>
                    {translate.application_details}
                  </h6>
                  <ChangeStatus id={appId} currentStatus={node.status} />
                  <RS.Button
                    onClick={() => {
                      history.goBack();
                    }}
                    size='sm'
                    className='ml1rem'
                  >
                    <PU.Icon icon={icons.faArrowAltCircleLeft} margin />
                    {translate.back}
                  </RS.Button>
                </RS.CardHeader>
                <RS.CardBody>
                  <RS.FormGroup row>
                    <RS.Col sm={1}>{translate.status}</RS.Col>
                    <RS.Col>{node.status}</RS.Col>
                    <RS.Col sm={1}>{translate.created_at}</RS.Col>
                    <RS.Col>
                      <PU.DateTime datetime={node.createdAt} />
                    </RS.Col>
                  </RS.FormGroup>
                  <RS.FormGroup row>
                    <RS.Col sm={1}>{translate.contact_name}</RS.Col>
                    <RS.Col>
                      {this.getAttrValue(node.attributes, 'contact_name')}
                    </RS.Col>
                    <RS.Col sm={1}>{translate.contact_number}</RS.Col>
                    <RS.Col>
                      {this.getAttrValue(node.attributes, 'contact_number')}
                    </RS.Col>
                  </RS.FormGroup>
                  <RS.FormGroup row>
                    <RS.Col sm={1}>{translate.application_remark}</RS.Col>
                    <RS.Col>
                      <div style={{ whiteSpace: 'pre-line' }}>
                        {this.getAttrValue(node.attributes, 'remark')}
                      </div>
                    </RS.Col>
                  </RS.FormGroup>
                  <RS.FormGroup className='tr gray'>
                    {translate.updated_at}{' '}
                    <PU.DateTime datetime={node.updatedAt} />
                  </RS.FormGroup>
                </RS.CardBody>
              </RS.Card>
              <CommentList userId={me.id} comments={node.comments} />
              {sharedService.serviceApplicationCommentable(node.status) && (
                <RS.Card>
                  <RS.CardHeader className='mb10'>
                    {translate.add_comment}
                  </RS.CardHeader>
                  <RS.CardBody>
                    <CommentForm id={node.id} />
                  </RS.CardBody>
                </RS.Card>
              )}
            </React.Fragment>
          );
        }}
      </Query>
    ); // end return
  } // end render
} // end class
