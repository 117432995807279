import React from 'react';
import Model from '../../components/model';
import { translate } from '../../shared/translate';
import { Icon } from '../../components/iconRender';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { Loading } from '../../components/pageComponents/utility';
import GenericForm from 'components/GenericForm';

const ME = gql`
  query {
    me {
      id
      groups {
        name
        permissions
      }
    }
  }
`;

export default class Records extends Model {
  list = [
    {
      icon: 'faDollarSign',
      key: 'payment_providers',
      href: '/settings/payments',
      perm: 'shop.shop_credential.read',
    },
    {
      icon: 'faTruck',
      key: 'shipping_providers',
      href: '/settings/shipping',
      perm: 'shop.shop_credential.read',
    },
    {
      icon: 'faDollarSign',
      key: 'tax',
      href: '/taxes',
      perm: 'shop.tax.read',
    },
    {
      icon: 'faCommentAlt',
      key: 'notifications',
      href: '/settings/notifications',
      perm: 'shop.tax.read',
    },
    {
      icon: 'faUser',
      key: 'account',
      href: '/profile',
      perm: 'company.shop.read',
    },
    {
      icon: 'faFileInvoiceDollar',
      key: 'billing',
      href: '/billings',
      perm: 'user.billing.read',
    },
    {
      icon: 'faEdit',
      key: 'custom_pages',
      href: '/custom_pages',
      perm: 'shop.custom_page.read',
    },
    {
      icon: 'faHandshakeAlt',
      key: 'market_service',
      href: '/market_services',
      perm: 'shop.agent.read',
    },
    {
      icon: 'faEdit',
      key: 'shop_info',
      href: '/settings/shop_info',
      perm: 'company.shop.read',
    },
  ];

  route(href) {
    if (href) {
      this.props.history.push(href);
    }
  }

  getPermissions = groups => {
    const shopId = localStorage.getItem('shopId');
    return [
      ...new Set(
        groups.reduce((reducer, { shop, permissions }) => {
          if (!shop) reducer.push(...permissions);
          else if (shop.id === shopId) reducer.push(...permissions);
          else if (!shopId) reducer.push(...permissions);
          return reducer;
        }, []),
      ),
    ];
  };

  valid(permission, permissionsStr) {
    if (!permission) return true;
    return !!permissionsStr.match(new RegExp(`${permission}`));
  }

  render() {
    return (
      <Query query={ME}>
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { me } = data || {},
            { groups } = me || {};
          const permissions = this.getPermissions(groups),
            permissionsStr = permissions.join(',');

          return (
            <GenericForm breadcrumb={[{ name: translate.quick_links }]}>
              <div className="row">
                {this.list.map(
                  ({ key, href, desc, perm, icon = 'faCog' }) =>
                    this.valid(perm, permissionsStr) && (
                      <div className="col-md-6 col-lg-3 mb-4 d-flex" key={key}>
                        <a href="javascript:" className="card w-100 text-dark" onClick={() => this.route(href)}>
                          <div className="card-header">
                            <h5 className="mb-0">
                              <Icon icon={icon} className="mr-2" />
                              {translate[`settings_title_${key}`] || key}
                            </h5>
                          </div>
                          <div className="card-body d-flex flex-column">
                            <p style={{ flexGrow: 1 }}>{translate[`settings_desc_${key}`] || desc}</p>
                          </div>
                        </a>
                      </div>
                    ),
                )}
              </div>
            </GenericForm>
          );
        }}
      </Query>
    );
  }
}
