import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { GET_QUERY, CREATE_QUERY, UPDATE_QUERY } from './query';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import _ from 'lodash';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ link: '/dm_plans', name: translate.dm_plan }, { name: this.state.id || translate.new_dm_plan }],
    gql: {
      prepare: GET_QUERY,
      submit: this.state.id ? UPDATE_QUERY : CREATE_QUERY,
    },
    cards: [
      {
        name: translate.dm_plan_details,
        fields: [
          {
            label: translate.name,
            type: 'text',
            maxLength: 255,
            name: 'name',
            required: true,
          },
          {
            label: translate.amount,
            name: 'amount',
            type: 'number',
            required: true,
            render: ({ values, handleChange, isSubmitting }) => (
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">HK$</span>
                </div>
                <input
                  name="amount"
                  step={0.1}
                  type="number"
                  className="form-control"
                  required
                  value={values.amount}
                  onChange={handleChange}
                  disabled={!!this.state.id || isSubmitting}
                />
              </div>
            ),
          },
          {
            label: translate.repeat_interval,
            name: 'interval',
            type: 'select',
            options: [
              {
                label: translate.repeat_daily,
                value: 'DAY',
              },
              {
                label: translate.repeat_weekly,
                value: 'WEEK',
              },
              {
                label: translate.repeat_monthly,
                value: 'MONTH',
              },
              {
                label: translate.repeat_yearly,
                value: 'YEAR',
              },
            ],
            disabled: !!this.state.id,
            required: true,
          },
          {
            label: translate.description,
            type: 'html',
            name: 'description',
            required: true,
          },
        ],
      },
    ],
  };
  submit = async ({ name, description, amount, interval }) => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const shopId = localStorage.getItem('shopId');
    const attributes = [{ key: 'description', value: description }];

    const { data: { result } = {} } = await client.mutate({
      mutation: submit,
      variables: {
        input: !!id ? { shopId, name, attributes } : { shopId, name, amount: amount - 0, attributes, interval },
      },
    });
    return (result || {}).id;
  };
  getInitialData(data) {
    const { node } = data || {},
      { name, amount, interval, currency, updatedAt, attributes } = node || {},
      { description: [{ value: description = '' } = {}] = [] } = _.groupBy(attributes || [], 'key');

    return {
      name,
      description,
      amount,
      interval,
      currency,
      updatedAt,
    };
  }
}
