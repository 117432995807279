import React, { Component } from 'react';
import ListPageMaker from '../../../components/listPageMaker';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { translate as t } from '../../../shared/translate';
import _ from 'lodash';
import moment from 'moment';
import { Icon } from '../../../components/iconRender';
import { client } from '../../../shared/apollo';
import { toast } from 'react-toastify';
import errorParser from '../../../shared/errorParser';
import Filter from '../../../components/filter';
import AppointmentModal from 'routes/item_Venue/_Schedule/_appointmentModal';

const GET_QUERY = gql`
  # QUERY CLIENT EVENT LIST
  query($id: ID, $otherFilter: EventFilterInput, $offset: OffsetInput) {
    node(id: $id) {
      id
      ... on Venue {
        id
        eventsCount(otherFilter: $otherFilter)
        events(otherFilter: $otherFilter, offset: $offset) {
          id
          name
          status
          createdAt
          updatedAt
          orderItem {
            id
            order {
              id
            }
          }
          address {
            person
            tel
            email
            lines
            country
          }
          slots {
            id
            startedAt
            startedThru
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

const EXPORT_QUERY = gql`
  mutation($id: ID!, $eventFilter: EventFilterInput) {
    eventExport(id: $id, eventFilter: $eventFilter)
  }
`;

export default class extends ListPageMaker {
  constructor(props) {
    super(props);
    const startedSince = moment(props.startedSince).format('YYYY-MM-DD');
    const startedUntil = moment(props.startedUntil).format('YYYY-MM-DD');
    const defaultFilter = props.defaultFilter || {};
    this.state = {
      ...this.state,
      gql: {
        get: GET_QUERY,
      },
      fields: [
        {
          title: t.hc_service_name,
          render: ({ name, event }) => {
            return (
              <a
                href={`javascript:void(0);`}
                onClick={e => {
                  e.preventDefault();
                  try {
                    this.appointmentModal.init(event);
                    this.appointmentModal.toggle();
                  } catch (e) {}
                }}
              >
                <span>{name}</span>
              </a>
            );
          },
        },
        {
          title: t.client_name,
          fieldName: 'person',
          type: 'text',
          defaultValue: defaultFilter.person,
          visible: !!defaultFilter.person,
          filter: true,
        },
        {
          title: t.email,
          fieldName: 'email',
          type: 'text',
          defaultValue: defaultFilter.email,
          visible: !!defaultFilter.email,
          filter: true,
        },
        {
          title: t.phone_no,
          fieldName: 'tel',
          type: 'text',
          align: 'center',
          width: 120,
          defaultValue: defaultFilter.tel,
          visible: !!defaultFilter.tel,
          filter: true,
        },
        {
          title: t.datetime_range,
          fieldName: 'started',
          type: 'datetime',
          column: false,
          filter: true,
          defaultValue: {
            since: startedSince,
            until: startedUntil,
          },
          disabled: true,
          visible: true,
        },
        {
          title: t.status,
          fieldName: 'status',
          type: 'option',
          filter: true,
          align: 'center',
          defaultValue: defaultFilter.status,
          visible: !!defaultFilter.status,
          width: 100,
          options: [
            {
              name: t.status_pending,
              value: 'PENDING',
            },
            {
              name: t.status_confirmed,
              value: 'CONFIRMED',
            },
            {
              name: t.status_completed,
              value: 'COMPLETED',
            },
            {
              name: t.status_cancelled,
              value: 'CANCELLED',
            },
          ],
        },
        {
          title: t.time,
          align: 'right',
          fieldName: 'time',
          render: item => <div style={{ whiteSpace: 'pre' }}>{item.time}</div>,
          width: 200,
        },
      ],
    };
  }

  handleFilter2 = fieldValues => {
    const { onFilterChange } = this.props;

    const filterComposite = this.getFilterObject(fieldValues);
    onFilterChange && onFilterChange(filterComposite.otherFilter);
    return this.handleFilter(fieldValues);
  };
  onExportClick = async () => {
    const { venueId, startedSince, startedUntil, defaultFilter } = this.props;

    try {
      const {
        data: { eventExport },
      } = await client.mutate({
        mutation: EXPORT_QUERY,
        variables: {
          id: venueId,
          eventFilter: {
            ...this.otherFilter,
            ...(defaultFilter || {}),
            startedSince,
            startedUntil,
          },
        },
      });
      window.open(eventExport);
    } catch (e) {
      toast.error(errorParser(e));
    }
  };

  getData = (data = {}) => {
    let { node } = data || {},
      { eventsCount = 0, events = [] } = node || {};

    const list = events.map(event => {
      const { name, address, status, slots } = event || {};
      let { person, tel, email } = address || {};

      const genEventTitle = (start, end) => {
        let date = moment(start).format('YYYY/MM/DD');
        let startText = `${moment(start).format('hh:mm A')}`;
        let endText = '';

        if (end) {
          endText = `${moment(end).format('hh:mm A')}`;
        }

        if (endText) {
          return `${date}\n${startText} - ${endText}`;
        }

        return `${date}\n${startText}`;
      };
      const getTimeText = () => {
        slots.sort((a, b) => {
          return new Date(a.startedAt) - new Date(b.startedAt);
        });

        const firstSlot = _.first(slots);
        const lastSlot = _.last(slots);
        const start = new Date(firstSlot.startedAt);
        const end = lastSlot.startedThru === null ? null : new Date(lastSlot.startedThru);
        return genEventTitle(start, end);
      };

      return {
        name,
        person,
        tel,
        email,
        status: t[`status_${status.toLowerCase()}`],
        time: getTimeText(),
        event,
      };
    });
    return { list, count: eventsCount };
  };
  render() {
    const { venueId, defaultFilter, startedSince, startedUntil } = this.props;
    const {
      gql: { get },
    } = this.state;
    return (
      <Query
        query={get}
        variables={{
          id: venueId,
          offset: this.offset,
          otherFilter: {
            ...this.otherFilter,
            ...(defaultFilter || {}),
            startedSince,
            startedUntil,
          },
        }}
      >
        {this.renderContent.bind(this)}
      </Query>
    );
  }
  renderCreateButton() {
    return (
      <a className={'btn btn-light btn-sm'} onClick={this.onExportClick}>
        <Icon icon={'faFileExport'} className={'mr-2'} /> {t.export}
      </a>
    );
  }
  renderFilter() {
    const { fields } = this.state;
    const filterFields = fields.filter(field => field.filter);
    if (filterFields.length === 0) return null;
    return <Filter filterChanged={values => this.handleFilter2(values)} fields={filterFields} />;
  }
  renderModal() {
    const { venueId } = this.props;
    return (
      <AppointmentModal
        ref={ref => (this.appointmentModal = ref)}
        onChange={() => {
          try {
            this.refetch();
          } catch (e) {}
        }}
        venueId={venueId}
      />
    );
  }
}
