import React, { useState } from 'react';
import Card from 'components/layout/card/Card';
import { translate as t } from 'shared/translate';
import CardHeader from 'components/layout/card/CardHeader';
import CardBody from 'components/layout/card/CardBody';
import { CardFooter, Input, Table } from 'reactstrap';
import { GET_VENUES } from './query';
import { Query } from 'react-apollo';
import { DateTime } from 'components/omniwe';
import _ from 'lodash';
import { PaginationWithDiv } from 'components/pagination';

export default ({ values: { venues = [] }, setFieldValue, isSubmitting }) => {
  const [offset, setOffset] = useState({ skip: 0, limit: 10 });
  return (
    <Query query={GET_VENUES} variables={{ id: localStorage.getItem('shopId'), offset }}>
      {({ loading, data: { node } = {} }) => {
        const { venues: items = [], venuesCount: count = 0 } = node || {};

        const isAllSelected = items.reduce((reducer, { id }) => {
          if (!reducer) return reducer;
          return !!_.find(venues, { id });
        }, true);
        return (
          <Card>
            <CardHeader title={t.vendor_list_for_login} />
            <CardBody className={'p-0'}>
              <Table hover>
                <tbody>
                  <tr>
                    <th style={{ width: 100 }} className="border-top-0 text-center pb-0">
                      <Input
                        type="checkbox"
                        disabled={isSubmitting}
                        checked={isAllSelected}
                        onChange={() => {
                          if (isAllSelected) {
                            venues = venues.filter(({ id }) => !_.find(items, { id }));
                          } else {
                            venues = _.uniqBy(venues.concat(items), 'id');
                          }
                          setFieldValue('venues', venues);
                        }}
                      />
                    </th>
                    <th className="border-top-0 text-truncate">{t.venue_name}</th>
                    <th style={{ width: 140 }} className="border-top-0 text-right text-truncate">
                      {t.created_at}
                    </th>
                    <th style={{ width: 140 }} className="border-top-0 text-right text-truncate">
                      {t.updated_at}
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  {items.map(({ id, name, createdAt, updatedAt }) => {
                    const selectIndex = _.findIndex(venues, { id }),
                      isSelected = !!~selectIndex;
                    return (
                      <tr key={id}>
                        <td className="text-center">
                          <Input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => {
                              if (isSelected) {
                                venues.splice(selectIndex, 1);
                              } else {
                                venues.push({ id, name });
                              }
                              setFieldValue('venues', venues);
                            }}
                          />
                        </td>
                        <td>{name}</td>
                        <td className="text-right text-truncate">
                          <DateTime datetime={createdAt} />
                        </td>
                        <td className="text-right text-truncate">
                          <DateTime datetime={updatedAt} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter className={'p-0'}>
              <PaginationWithDiv
                numberOfCount={count}
                loading={loading}
                offset={offset}
                prev={() => {
                  const { skip, limit } = offset;
                  setOffset({ skip: skip - limit, limit });
                }}
                next={() => {
                  const { skip, limit } = offset;
                  setOffset({ skip: skip + limit, limit });
                }}
              />
            </CardFooter>
          </Card>
        );
      }}
    </Query>
  );
};
