import React from 'react';
import SearchComboProduct from 'components/SearchComboProduct';
import gql from 'graphql-tag';
import { client } from 'shared/apollo';
import PropTypes from 'prop-types';

const SEARCH_QUERY = gql`
  query($id: ID, $filter: StockItemFilterInput) {
    node(id: $id) {
      id
      ... on Company {
        stockItems(otherFilter: $filter, offset: { skip: 0, limit: 10 }) {
          id
          name
        }
      }
    }
  }
`;

export default class SearchComboStockItem extends SearchComboProduct {
  update = async search => {
    if (!this._mounted) return;
    const {
      data: { node },
    } = await client.query({
      query: SEARCH_QUERY,
      variables: {
        id: await localStorage.getItem('companyId'),
        filter: {
          name: search,
        },
      },
      fetchPolicy: 'network-only',
    });
    const { stockItems = [] } = node || {};
    this.setState({ options: stockItems.map(stockItem => ({ label: stockItem.name, value: stockItem })) });
  };
}
SearchComboStockItem.propTypes = {
  selected: PropTypes.any /* Stock Item */,
  onChange: PropTypes.func,
};
SearchComboStockItem.defaultProps = {
  onChange: _ => _,
};
