import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Shop {
        id
        locale
        credentials(otherFilter: { platforms: [STRIPE_CREDIT_CARD] }) {
          id
          active
          identity
          platform
          secret
          type
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

export const SUBMIT_QUERY = gql`
  mutation companyShopCredentialSet($shopId: ID!, $input: ShopCredentialInput!) {
    credentialStripeSet(shopId: $shopId, input: $input) {
      id
    }
  }
`;
