import React from "react";
import { translate } from "../../shared/translate";
import * as pageUtility from "../../components/pageComponents/utility";
import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import * as _consts from "../../shared/_consts";

const POST_QUERY = gql`
  # OWNER CANCEL APPLICATION
  mutation($id: ID!, $input: ServiceApplicationStatus!) {
    serviceApplicationStatusUpdate(id: $id, input: $input) {
      id
      status
      updatedAt
    }
  }
`;

export default class Cancel extends React.Component {
  cancel = async post => {
    const variables = {
      id: this.props.id,
      input: _consts.ServiceApplicationStatus.CANCELLED
    };
    console.log("cancel");
    await post({ variables });
  };

  showError = errors => {
    pageUtility.toastError(JSON.stringify(errors));
  };

  render() {
    const { className } = this.props;
    return (
      <Mutation mutation={POST_QUERY}>
        {(serviceApplicationStatusUpdate, { loading, error }) => {
          if (error) {
            this.showError(error);
          }

          if (loading) {
            return (
              <span>
                <pageUtility.Spinner /> {translate.cancelling}
              </span>
            );
          }

          return (
            <pageUtility.ToggleButton
              immutable={translate.cancel_application}
              side={translate.confirm_application}
              color="danger"
              action={() => this.cancel(serviceApplicationStatusUpdate)}
              disabled={loading}
              className={className}
            />
          );
        }}
      </Mutation>
    );
  }
}
