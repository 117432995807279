import React from 'react';
import FormRow from 'components/form/FormRow';
import SearchComboCollection from 'components/SearchComboCollection';
import { translate } from 'shared/translate';

export default ({ config = {}, onChange = _ => _, disabled }) => {
  const { title } = config || {};
  return (
    <div className={'p-3'}>
      <FormRow title={translate.text}>
        <input
          type={'text'}
          value={title}
          className={'form-control'}
          disabled={disabled}
          onChange={e => {
            config.title = e.target.value;
            onChange(config);
          }}
        />
      </FormRow>
    </div>
  );
};
