import React from 'react';
import { translate } from '../../shared/translate';

export default ({ products }) => (
  <div className='col-md-6 d-flex mb-3'>
    <div className='card w-100'>
      <div className='card-header text-center'>
        {translate.most_viewed_products}
      </div>
      <div className='embed-responsive embed-responsive-16by9'>
        <div className='embed-responsive-item'>
          <div className='list-group list-group-flush'>
            {products.map(({ name }, i) => (
              <div
                key={i}
                className='list-group-item d-flex justify-content-between'
              >
                <span>{name}</span>
                <span>0</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);
