// React component of Object not found notification
// src/components/pageComponents/ObjectNotFound.jsx
// created on april-18, 2019, by Tony Day
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/pro-regular-svg-icons';
import { translate } from "../../shared/translate";
import * as rs from "reactstrap";

/** React component of Object not found notification  */
export default ({ msg }) => {
  return (
    <rs.Alert color="warning">
      <p>
        <FontAwesomeIcon icon={icons.faMeh} />
        {translate.object_found_found}
      </p>
      {msg && <p>{msg}</p>}
    </rs.Alert >
  );
}
