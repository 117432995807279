import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import React from 'react';
import Model from './model';
import { client } from '../shared/apollo';
import { toast } from 'react-toastify';
import { translate } from '../shared/translate';
import { Formik } from 'formik';
import moment from 'moment';
import { Button } from 'reactstrap';
import { ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import Loading from './loading';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Order {
        comments {
          content
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const COMMENT_SET = gql`
  mutation orderCommentSet($id: ID!, $comments: [CommentInput!]!) {
    orderCommentSet(id: $id, comments: $comments) {
      id
      comments {
        content
        createdAt
      }
    }
  }
`;
function compare(key) {
  return (a, b) => {
    if (a[key] < b[key]) return 1;
    if (a[key] > b[key]) return -1;
    return 0;
  };
}
export default class Comments extends Model {
  async commentsAdd() {
    try {
      const { data: { orderCommentSet: { comments = [] } = {} } = {} } = await client.mutate({
        mutation: COMMENT_SET,
        variables: {
          id: this.props.orderId,
          comments: [
            ...this.comments,
            {
              content: '【' + this.props.serviceName + '】' + '\n' + this.getCurrentRef(`commentInput`).value,
            },
          ],
        },
      });

      this.comments = comments.reduce((prev, { content, createdAt }) => {
        return [
          ...prev,
          {
            content,
            createdAt,
          },
        ];
      }, []);

      this.getCurrentRef(`commentInput`).value = '';
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  render() {
    return (
      <Formik initialValues={{ comment: '' }} onSubmit={() => {}}>
        {({ values, setFieldValue, handleChange }) => {
          return (
            <Query query={GET_QUERY} variables={{ id: this.props.orderId }}>
              {({ loading, data: { node } = {} }) => {
                if (loading) return <Loading />;
                const { comments = [] } = node || {};
                let sortObj = comments.sort(compare('createdAt'));
                this.comments = sortObj.reduce((prev, { content, createdAt }) => {
                  return [
                    ...prev,
                    {
                      content,
                      createdAt,
                    },
                  ];
                }, []);

                return (
                  <div>
                    <form
                      onSubmit={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        await this.commentsAdd();
                      }}
                    >
                      <div>
                        <div className="p-2 mb-3">
                          <div className="input-group">
                            <textarea
                              value={values.comment}
                              onChange={handleChange}
                              style={{ minHeight: 'auto', marginTop: '0px', marginBottom: '0px', height: '39px' }}
                              className="form-control flex-fill"
                              ref={this.getRef(`commentInput`)}
                              placeholder={translate.comments_placeholder}
                              name={'comment'}
                            />
                            <div className="input-group-append">
                              <button type="submit" className="btn btn-primary">
                                <i className="fa fa-pencil" />
                              </button>
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <button
                              type={'button'}
                              className={'badge badge-pill mr-2'}
                              onClick={() => {
                                setFieldValue('comment', '已出席');
                              }}
                            >
                              已出席
                            </button>
                            <button
                              type={'button'}
                              className={'badge badge-pill mr-2'}
                              onClick={() => {
                                setFieldValue('comment', '缺席');
                              }}
                            >
                              缺席
                            </button>
                            <button
                              type={'button'}
                              className={'badge badge-pill mr-2'}
                              onClick={() => {
                                setFieldValue('comment', '其他');
                              }}
                            >
                              其他
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    {sortObj.map(({ content, createdAt }, index) => {
                      return (
                        <ModalFooter className="d-block" key={index}>
                          <div className={`d-flex`}>
                            <p className={`flex-fill`} style={{ whiteSpace: 'pre-line' }}>
                              {content}
                            </p>
                            <div>
                              <Mutation mutation={COMMENT_SET}>
                                {(upvote, { data, loading }) => {
                                  if (loading) return <Loading />;
                                  return (
                                    <button
                                      className="btn btn-link text-danger"
                                      onClick={() => {
                                        upvote({
                                          variables: {
                                            id: this.props.orderId,
                                            comments: this.comments.reduce((prev, comment, commentIndex) => {
                                              return index === commentIndex ? prev : [...prev, comment];
                                            }, []),
                                          },
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                  );
                                }}
                              </Mutation>
                            </div>
                          </div>
                          <div className={`d-flex`}>
                            <small className={`flex-fill text-muted align-items-center`}>
                              {moment(createdAt).format('HH:mm DD/MM/YYYY')}
                            </small>
                          </div>
                        </ModalFooter>
                      );
                    })}
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Formik>
    );
  }
}
