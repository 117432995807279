import React from 'react';
import _ from 'lodash';
import { translate as t } from '../../../shared/translate';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { FormRow, ActionTag, toast } from '../../../components/omniwe';

const OptionList = ({ items, onRemoveAll, onRemove }) => (
  <div>
    {items.map((item, i) => (
      <ActionTag
        title={t.click_to_remove}
        key={i}
        onClick={() => onRemove(item)}
      >
        {item}
      </ActionTag>
    ))}
    {_.size(items) > 1 && (
      <button onClick={onRemoveAll} className='btn btn-link'>
        {t.remove_all}
      </button>
    )}
  </div>
);

export default class PromotionContentItemOptions extends React.Component {
  constructor(props) {
    super(props);
    const { items = [], onChange } = this.props;
    this.onChange = onChange;
    this.state = {
      items: [...items],
      nextItem: ''
    };
  }

  handleRemoveAll = () => {
    let newItems = [];
    this.setState({
      items: newItems
    });
    this.onChange(newItems);
  };

  handleRemoveItem = item => {
    const { items } = this.state;
    let newItems = [...items];
    _.remove(newItems, x => x === item);
    this.setState({
      items: newItems
    });
    this.onChange(newItems);
  };

  handleAddItem = () => {
    console.log('handleAddItem');
    const { items, nextItem } = this.state;
    const item = _.trim(nextItem);

    if (!item.length) {
      return;
    }

    if (_.find(items, x => x === item)) {
      toast.warn(t.entry_exists);
      return;
    }

    const newItems = [...items, item];
    this.setState({
      items: newItems,
      nextItem: ''
    });
    this.onChange(newItems);
  };

  handleInputChange = e => {
    const {
      target: { value }
    } = e;
    this.setState({ nextItem: value });
  };

  render() {
    const { items, nextItem } = this.state;
    return (
      <div>
        <FormRow title={t.options} inputLg={5}>
          <InputGroup className='mb-2'>
            <Input value={nextItem} onChange={this.handleInputChange} />
            <InputGroupAddon addonType='append'>
              <Button onClick={this.handleAddItem} color='primary'>
                {t.add}
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <OptionList
            items={items}
            onRemove={this.handleRemoveItem}
            onRemoveAll={this.handleRemoveAll}
          />
        </FormRow>
      </div>
    );
  }
}
