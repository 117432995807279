import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on DeliveryNote {
        trackingNo
        shipment {
          provider
        }
        shippingAddress {
          person
          tel
          email
          lines
          country
        }
        items {
          orderItem {
            id
            description
            productVariant {
              id
              product {
                id
                name
              }
              attributes {
                key
                value
              }
            }
            quantity
            unitPrice {
              amount
              locale
            }
          }
          quantity
        }
        order {
          id
          attributes {
            key
            value
          }
          items {
            id
            description
            productVariant {
              id
              product {
                id
                name
              }
              attributes {
                key
                value
              }
            }
            quantity
            unitPrice {
              amount
              locale
            }
          }
          total {
            amount
            locale
          }
          subtotal {
            amount
            locale
          }
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation CreateDeliveryNote($orderId: ID!, $input: DeliveryNoteInput!) {
    orderDeliveryNoteCreate(orderId: $orderId, input: $input) {
      id
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation orderDeliveryNoteUpdate($id: ID!, $status: DeliveryNoteStatus, $trackingNo: String) {
    orderDeliveryNoteUpdate(id: $id, trackingNo: $trackingNo, status: $status) {
      id
      trackingNo
      status
      updatedAt
      createdAt
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation couponShareholderUnset($id: ID!) {
    couponShareholderUnset(id: $id) {
      id
    }
  }
`;
