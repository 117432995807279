import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { translate as t } from 'shared/translate';

export default ({ disabled, onChange, ...props }) => {
  const [show, setShow] = useState(false);
  if (!show)
    return (
      <Button color={'light'} disabled={disabled} onClick={() => setShow(true)}>
        <span>{t.change_password}</span>
      </Button>
    );
  return (
    <div className={'d-flex flex-row'}>
      <input
        type={'password'}
        className="form-control flex-fill"
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        {...props}
      />
      <Button
        color={'danger'}
        className={'ml-3'}
        disabled={disabled}
        onClick={() => {
          onChange(undefined);
          setShow(false);
        }}
      >
        <span>{t.cancel_to_change}</span>
      </Button>
    </div>
  );
};
