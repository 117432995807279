import React from 'react';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { translate as t } from 'shared/translate';
import SearchComboCountry from 'components/SearchComboCountry';
import HorizontalField from 'components/form/horizontal-field';

const AddressCard = props => {
  const { values, handleChange, setFieldValue } = props;

  return (
    <React.Fragment>
      <Card>
        <CardHeader>{t.address}</CardHeader>
        <CardBody>
          <Row>
            <Col lg={6}>
              <HorizontalField label={t.contact_person}>
                <Input name="person" value={values.person} onChange={handleChange} maxLength={100} />
              </HorizontalField>
            </Col>
            <Col lg={6}>
              <HorizontalField label={t.contact_number}>
                <Input name="tel" value={values.tel} onChange={handleChange} maxLength={20} />
              </HorizontalField>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <HorizontalField label={t.email}>
                <Input name="email" value={values.email} onChange={handleChange} maxLength={100} />
              </HorizontalField>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <HorizontalField label={t.address}>
                {Array(3)
                  .fill('')
                  .map((v, index) => (
                    <p key={index}>
                      <Input
                        maxLength={100}
                        type="text"
                        value={values.lines[index]}
                        onChange={e => {
                          values.lines[index] = e.target.value;
                          setFieldValue('lines', values.lines);
                        }}
                        name={`lines${index}`}
                      />
                    </p>
                  ))}
              </HorizontalField>
            </Col>
            <Col lg={6}>
              <HorizontalField label={t.country}>
                <div className="col-xl-5 col-lg-6 col-md-5">
                  <SearchComboCountry value={values.country} onChange={val => setFieldValue('country', val)} />
                </div>
              </HorizontalField>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AddressCard;
