import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGoogle,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCommentAlt,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons'

import { Tooltip } from 'reactstrap';

import { translate as __ } from "../../shared/translate";

export default class SettingsNotifications extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false
    };
  }

  render() {
    const { isOpen } = this.state;

    return (
      <div className="container mb-5">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/settings">
              {__.settings}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            {__.settings_notifications}
          </li>
        </ol>

        <div className="card">
          <b className="card-header">
            <FontAwesomeIcon fixedWidth={true} icon={faGoogle} className="text-danger mr-2" />
            {__.settings_notifications_ga}
          </b>
          <div className="card-body">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label text-muted">
                {__.settings_notifications_ga_key}
              </label>
              <div className="col">
                <input className="form-control" type="text" name="ga_key" />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <b className="card-header">
            <FontAwesomeIcon fixedWidth={true} icon={faFacebookF} className="text-success mr-2" />
            {__.settings_notifications_pixel}
          </b>
          <div className="card-body">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label text-muted">
                {__.settings_notifications_pixel_key}
              </label>
              <div className="col">
                <input className="form-control" type="text" name="pixel_key" />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <FontAwesomeIcon fixedWidth={true} icon={faCommentAlt} className="mr-2" />
            {__.settings_notifications_sms}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm">
                <label>{__.settings_notifications_sms_remainder} :</label>
                <span className="text-info"> {86}</span>
              </div>
              <div className="col-sm">
                <label>
                  {__.settings_notifications_sms_missed}
                  <FontAwesomeIcon fixedWidth={true} icon={faExclamationCircle} className="text-danger ml-1" id="sms-missed"
                    onMouseEnter={() => this.setState({ isOpen: true })}
                    onMouseLeave={() => this.setState({ isOpen: false })}
                  />
                  :
                </label>
                <span className="text-danger"> {3}</span>
                <Tooltip placement="top" target="sms-missed" isOpen={isOpen}>
                  {__.settings_notifications_sms_missed_tooltip}
                </Tooltip>
              </div>
            </div>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              1,000 {__.settings_notifications_sms_package} @HK$0.42

              <button className="btn btn-primary">
                {__.settings_notifications_sms_buy}
              </button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              10,000 {__.settings_notifications_sms_package} @HK$0.36

              <button className="btn btn-primary">
                {__.settings_notifications_sms_buy}
              </button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <span>
                100,000 {__.settings_notifications_sms_package} @HK$0.30{" "}
                <span className="badge badge-pill badge-primary" style={{ fontSize: "1rem" }}>
                  {__.settings_notifications_sms_package_best_offer}
                </span>
              </span>

              <button className="btn btn-primary">
                {__.settings_notifications_sms_buy}
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
