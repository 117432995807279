import React, { Component } from 'react';
import HorizontalField from './horizontal-field';

class SingleCheckbox extends Component {
  state = {};
  render() {
    const { label, checked, onValueChanged, checkboxLabel, tips } = this.props;

    return (
      <HorizontalField label={label}>
        <div className="form-check pt-2">
          <input
            className="form-check-input"
            type="checkbox"
            id={label}
            checked={checked}
            onChange={e => onValueChanged(e.target.checked)}
          />
          {checkboxLabel && (
            <label className="form-check-label" htmlFor={label}>
              {checkboxLabel}
            </label>
          )}
          {tips && <p className="form-text small text-muted">{tips}</p>}
        </div>
      </HorizontalField>
    );
  }
}

export default SingleCheckbox;
