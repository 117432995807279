import React from 'react';
import { translate } from '../../shared/translate';
import { Icon } from '../../components/iconRender';

export default ({ title, description, onClick }) => (
    <div className="col-xl-3 col-md-4 col-sm-6 mb-5">
      <div className="card h-100">
        <div className="card-header d-flex align-items-center">
          <h6 className="flex-fill mb-0">{title}</h6>
          <a href="javascript:">
            <Icon icon={'faEye'} />
          </a>
        </div>
        <div className="card-body d-flex flex-column justify-content-between">
          <p className="card-text small text-muted">{description}</p>
          <div className="text-center pt-2" onClick={onClick}>
            <button className="btn btn-primary">{translate['select']}</button>
          </div>
        </div>
      </div>
    </div>
);
