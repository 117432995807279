// 市场服务-申请记录详情-评论对话
// src/routers/market_service/application_details.jsx
// created on may-8, 2019, by Tony Day
import React from 'react';
import { translate as t } from '../../shared/translate';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import _ from 'lodash';
import { Button } from 'reactstrap';
import moment from 'moment';
import { Col, FormGroup, Row, Label, CardHeader } from 'reactstrap';
import PageError from '../../components/pageComponents/PageError';
import Loading from '../../components/loading';
import ObjectNotFound from '../../components/pageComponents/ObjectNotFound';
import CommentForm from './comment_form';
import CommentList from './comment_list';
import sharedService from '../../service/sharedService';
import Cancel from './cancel';

const _formHeader = props => (
  <div className='card-header d-flex'>
    <h6 className='mb-0 flex-fill'>{t.application_details}</h6>
    {props.children}
  </div>
);

const APP_DETAILS_QUERY = gql`
  # OWNER QUERY APPLICATIN DETAILS AND COMMENTS
  query($id: ID) {
    node(id: $id) {
      id
      ... on ServiceApplication {
        id
        comments {
          content
          createdAt
          user {
            id
            attributes {
              key
              value
            }
            credentials {
              identity
            }
          }
        }
        createdAt
        updatedAt
        status
        attributes {
          key
          value
        }
      }
    }
  }
`;

export default class ApplicationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: this.getAppId()
    };
  }

  getAppId = () => {
    const {
      match: {
        params: { appId }
      }
    } = this.props;
    return appId;
  };

  componentDidUpdate() {
    const appId = this.getAppId();
    const { appId: currentAppId } = this.state;

    if (currentAppId !== appId) {
      this.setState({
        appId
      });
    }
  }

  getAttrValue = (attributes, key) => {
    const found = _.find(attributes, { key });
    if (!found) {
      return '-';
    }

    return found.value;
  };

  close = () => {
    const {
      match: {
        params: { serviceId }
      },
      history
    } = this.props;
    history.push('/market_services/' + serviceId);
  };

  render() {
    const { appId } = this.state;
    // const {close} = this.close;
    return (
      <div className='card mb-5 shadow'>
        <Query query={APP_DETAILS_QUERY} variables={{ id: appId }}>
          {({ loading, error, data: { node = {} } = {} }) => {
            const pageLoading = loading;
            return (
              <React.Fragment>
                <_formHeader>
                  <Button onClick={this.close} size='sm'>
                    {t.close}
                  </Button>
                  {!pageLoading &&
                    node &&
                    sharedService.serviceApplicationCancelable(node.status) && (
                      <Cancel className='ml-3' id={appId} />
                    )}
                </_formHeader>
                {error && <PageError errors={error} />}
                {pageLoading && <Loading />}
                {!node && appId && <ObjectNotFound msg={'ID ' + appId} />}
                {!loading && node && (
                  <div className='card-body'>
                    <FormGroup row>
                      <Col md={1}>
                        <Label>{t.status}</Label>
                      </Col>
                      <Col>{node.status}</Col>
                      <Col md={1}>
                        <Label>{t.created_at}</Label>
                      </Col>
                      <Col>
                        {moment(node.createdAt).format('YYYY-MM-DD HH:mm')}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md={1}>
                        <Label>{t.contact_name}</Label>
                      </Col>
                      <Col>
                        {this.getAttrValue(node.attributes, 'contact_name')}
                      </Col>
                      <Col md={1}>
                        <Label>{t.contact_number}</Label>
                      </Col>
                      <Col>
                        {this.getAttrValue(node.attributes, 'contact_number')}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md={1}>
                        <Label>{t.application_remark}</Label>
                      </Col>
                      <Col>
                        <pre>
                          {this.getAttrValue(node.attributes, 'remark')}
                        </pre>
                      </Col>
                    </FormGroup>
                    <CommentList comments={node.comments} />
                    {sharedService.serviceApplicationCommentable(
                      node.status
                    ) && (
                      <React.Fragment>
                        <CardHeader className='mb10'>
                          {t.add_comment}
                        </CardHeader>
                        <CommentForm id={node.id} />
                      </React.Fragment>
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          }}
        </Query>
      </div>
    ); // end return
  } // end render
} // end class
