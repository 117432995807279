import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import jwt_code from 'jwt-decode';
import { translate } from 'shared/translate';
import { SUBMIT_QUERY } from './query';
import { client } from 'shared/apollo';
import { Formik } from 'formik';
import { Card, CardBody } from 'reactstrap';
import { Icon } from 'components/iconRender';
import FormToolbar from 'components/form/FormToolbar';

export default class extends FormPageMaker {
  static defaultProps = {
    className: 'my-5',
  };
  state = {
    ...this.state,
    id: 1,
    breadcrumb: [],
    gql: {
      submit: SUBMIT_QUERY,
    },
    cards: [
      {
        name: translate['reset_password'],
        fields: [
          {
            label: translate['email'],
            type: 'text',
            name: 'identity',
            disabled: true,
          },
          {
            label: translate['new_password'],
            type: 'password',
            name: 'password',
          },
          {
            label: translate['re_enter_password'],
            type: 'password',
            name: 'password2',
          },
        ],
      },
    ],
  };

  submit = async ({ token, password, password2 }, { setFieldValue }) => {
    if (password !== password2) throw new Error('Error: Confirm password fail');
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: { token, secret: password },
    });
    setFieldValue('success', true);
    return false;
  };

  getInitialData(data) {
    const { token } = this.getQueryParams();
    let identity = '';
    try {
      const decode = jwt_code(token);
      identity = decode.identity;
    } catch (e) {}
    return { success: false, token, identity, password: '', password2: '' };
  }

  renderForm(initialValues) {
    const { cards } = this.state;
    return (
      <div className={'row justify-content-center'}>
        <div className={'col-12 col-md-6 col-lg-4'}>
          <Formik initialValues={initialValues} onSubmit={this.onSubmitClick}>
            {actions => {
              const { values: { success } = {} } = actions;
              if (success)
                return (
                  <Card>
                    <CardBody className={'text-center py-5'}>
                      <Icon icon={'faCheckCircle'} className={'text-success fa-4x mb-3'} />
                      <h2>{translate['password_update_success_msg']}</h2>
                    </CardBody>
                  </Card>
                );
              return (
                <form onSubmit={actions.handleSubmit}>
                  {cards.map((card, i) => this.renderCard(card, actions, i))}
                  {this.renderOthers(initialValues, actions)}
                  {this.renderFormToolBar(initialValues, actions)}
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
  renderFormToolBar(initialValues) {
    return <FormToolbar onSave={null} submitBtnType={'submit'} updatedAt={initialValues.updatedAt} />;
  }
}
