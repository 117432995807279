import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!, $filter: FilterInput, $offset: OffsetInput, $sorter: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Shop {
        creditNotes(filter: $filter, offset: $offset, sorter: $sorter) {
          id
          status
          amount {
            amount
            locale
          }
          paymentMethod
          invoice {
            id
          }
          createdAt
          updatedAt
        }
        creditNotesCount(filter: $filter)
      }
    }
  }
`;
