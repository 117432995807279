// src/components/pagination.jsx
// 列表頁分頁component

import React from "react";

/**帶有div.card-footer 容器的分頁組件 */
export class PaginationWithDiv extends React.Component {
  render() {
    return (
      <div className="card-footer">
        <Pagination  {...this.props} />
      </div>
    );
  }
}

export default class Pagination extends React.Component {
  getEndIndex() {
    let index = this.props.offset.skip + this.props.offset.limit;
    if (index > this.props.numberOfCount) {
      index = this.props.numberOfCount;
    }

    return index;
  }

  render() {
    const { offset, numberOfCount, loading } = this.props;
    const currentEndIndex = offset.skip + offset.limit;
    return (
      <div>
        <span className="pull-right">{offset.skip + 1}-{this.getEndIndex()} / {numberOfCount}</span>
        <ul className="pagination pagination-sm mb-0">
          <li className={
            `page-item ${(offset.skip === 0 || loading) && "disabled"}`
          }>
            <a className="page-link" onClick={() => this.props.prev()}>
              <i className="fa fa-fw fa-caret-left" />
            </a>
          </li>
          <li className={
            `page-item ${(currentEndIndex >= numberOfCount || loading) && "disabled"}`
          }>
            <a className="page-link" onClick={() => this.props.next()}>
              <i className="fa fa-fw fa-caret-right" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}