import React from 'react';
import { Icon } from '../../components/iconRender';

export default ({ amount, label, diff }) => (
  <div className="col-12 col-lg-6 col-xl-3">
    <div className="card mb-3">
      <div className="card-body d-flex flex-row justify-content-between align-items-center">
        <p className="lead mb-0 ng-binding">${amount}</p>
        <div className="text-right">
          <small>{label}</small>
          {diff === undefined ? (
            <div className={`text-muted`}>N/A</div>
          ) : (
            <div className={`text-muted`}>
              <Icon
                icon={diff === 0 ? 'faChevronRight' : diff > 0 ? 'faChevronUp' : 'faChevronDown'}
                className={`fa-fw mr-1 ${diff > 0 && 'text-primary'} ${diff < 0 && 'text-danger'} ${diff === 0 &&
                  'text-muted'}`}
              />
              {Math.abs(diff).toFixed(2)}%
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
