import React, { Fragment } from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { gql } from 'apollo-boost';
import { translate } from '../../shared/translate';
import CollectionSearch from '../../components/search_collection';
import _ from 'lodash';
import { Icon } from '../../components/iconRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faFileImport, faImage } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import ModalImport from './ModalImport';
import ModalExport from './ModalExport';
import SearchComboCollection from 'components/SearchComboCollection';
import { GET_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: translate.products }],
    createUrl: '/products/new',
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.image,
        width: 80,
        render: item => (
          <a className="thumbnail text-muted d-flex align-items-center justify-content-center m-auto">
            {item.images[0] ? (
              <img className={`object-fit-contain`} src={item.images[0]} />
            ) : (
              <FontAwesomeIcon icon={faImage} size="2x" />
            )}
          </a>
        ),
      },
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        render: item => (
          <Link
            to={{
              pathname: '/products/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            {item.name}
          </Link>
        ),
        filter: true,
        sortable: 'name',
      },
      {
        title: translate.product_collections,
        fieldName: 'collectionId',
        component: onValueChanged => {
          return (
            <SearchComboCollection
              onChange={collection => {
                onValueChanged(collection ? collection.id : '');
              }}
              className={'form-control-sm'}
            />
          );
          return (
            <CollectionSearch
              select={(item = {}) => {
                onValueChanged(item.id || '');
              }}
              defaultValue=""
              inputClass="form-control-sm"
            />
          );
        },
        render: item => (item.collection ? item.collection.name : '-'),
        filter: true,
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        width: 50,
        sortable: 'sortIndex',
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        type: 'datetime',
        dataKey: 'createdAt',
        width: 180,
        headerClassName: 'text-right',
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        fieldName: 'updated',
        type: 'datetime',
        dataKey: 'updatedAt',
        width: 180,
        headerClassName: 'text-right',
        filter: true,
        sortable: 'updatedAt',
      },
    ],
    modalImportOpen: false,
    modalExportOpen: false,
  };

  toggleModal = modalName => {
    const name = `modal${modalName}Open`;
    this.setState(prevState => ({
      [name]: !prevState[name],
    }));
  };

  getData = ({ node } = {}) => {
    const { products = [], productsCount = 0 } = node || {};
    return { list: products, count: productsCount };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  renderModal({ count }) {
    const { modalImportOpen, modalExportOpen } = this.state;
    const shopId = localStorage.getItem('shopId');
    return (
      <Fragment>
        <ModalImport modalOpen={modalImportOpen} toggle={() => this.toggleModal('Import')} shopId={shopId} />
        <ModalExport
          modalOpen={modalExportOpen}
          toggle={() => this.toggleModal('Export')}
          shopId={shopId}
          numberOfReocrd={count}
          filterObject={() => ({
            filter: this.filterInput,
            otherFilter: this.otherFilter,
          })}
        />
      </Fragment>
    );
  }

  renderCreateButton() {
    const { createUrl } = this.state;
    return (
      <Fragment>
        <a className={'btn btn-light btn-sm mr-3'} onClick={() => this.toggleModal('Export')}>
          <Icon icon={faFileExport} margin />
          {translate.export}
        </a>
        <a className={'btn btn-light btn-sm mr-3'} onClick={() => this.toggleModal('Import')}>
          <Icon icon={faFileImport} margin />
          {translate.import}
        </a>
        {!!createUrl && (
          <Link to={{ pathname: createUrl }} className="btn btn-light btn-sm">
            <i className="fa fa-plus fa-fw" /> {translate.create}
          </Link>
        )}
      </Fragment>
    );
  }
}
