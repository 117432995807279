import { gql } from 'apollo-boost';

export const SET_QUERY = gql`
  #vendor set
  mutation($id: ID, $input: VendorInput!, $credential: UserCredentialInput) {
    vendorSet(id: $id, input: $input, credential: $credential) {
      id
      createdAt
      updatedAt
      attributes {
        key
        value
      }
    }
  }
`;

export const UNSET_QUERY = gql`
  #vendor unset
  mutation($id: ID!) {
    vendorUnset(id: $id) {
      id
      attributes {
        key
        value
      }
    }
  }
`;

export const DETAILS_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on User {
        id
        createdAt
        updatedAt
        venuesCount
        addresses {
          id
          tel
          email
          person
          lines
          country
        }
        venues {
          id
          name
        }
        groups {
          name
        }
        credentials {
          platform
          identity
        }
        attributes {
          key
          value
        }
      }
    }
  }
`;

export const GET_VENUES = gql`
  query($id: ID, $filter: FilterInput, $offset: OffsetInput) {
    node(id: $id) {
      id
      ... on Shop {
        venuesCount(filter: $filter)
        venues(filter: $filter, offset: $offset) {
          id
          createdAt
          updatedAt
          name
        }
      }
    }
  }
`;
