import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query {
    me {
      id
      billings {
        id
        name
        brand
        exp_month
        exp_year
        last4
        isDefault
      }
    }
  }
`;
