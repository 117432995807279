import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Shop {
        list: dm_plans {
          id
          name
          amount
          interval
          currency
          attributes {
            key
            value
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
