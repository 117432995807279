import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import FormRow from '../../components/form/FormRow';
import Button from '../../components/form/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from '../../shared/translate';
import gql from 'graphql-tag';
import { client } from '../../shared/apollo';
import errorParser from '../../shared/errorParser';
import { toast } from 'react-toastify';
import { Loading } from '../../components/pageComponents/utility';

const POST_QUERY = gql`
  mutation($id: ID!, $amount: Float) {
    orderInvoiceRefund(id: $id, amount: $amount) {
      id
      invoice {
        id
        refundedAmount {
          amount
          locale
        }
        creditNotes {
          id
          status
          amount {
            amount
            locale
          }
          paymentMethod
          invoice {
            id
          }
        }
      }
    }
  }
`;

export default class RefundModal extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  submit = async (values, { setSubmitting }) => {
    const { onSuccess, invoice: { id } = {} } = this.props;
    try {
      const variables = { id, amount: values.amount - 0 };
      const {
        data: { orderInvoiceRefund: creditNote },
      } = await client.mutate({
        mutation: POST_QUERY,
        variables,
      });
      this.toggle();
      toast.success(translate.create_success);
      onSuccess && onSuccess(creditNote);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    const {
      invoice,
      invoice: { id, refundedAmount: { amount: refunded } = {}, amount: { amount, locale } = {} } = {},
    } = this.props;
    const { isOpen } = this.state;
    const maxRefundableAmount = amount - refunded;

    return (
      <Modal isOpen={isOpen} size={'md'} centered={true}>
        <Formik initialValues={{ amount: maxRefundableAmount }} onSubmit={this.submit}>
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <ModalHeader>{translate.new_refund}</ModalHeader>
              <ModalBody>
                <FormRow title={translate.invoice_number} labelMd={4}>
                  <input type={'text'} className={'form-control'} value={id} disabled={true} />
                </FormRow>
                <FormRow title={translate.amount} labelMd={4}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{locale}</span>
                    </div>
                    <input type="number" className="form-control" value={amount} disabled={true} />
                  </div>
                </FormRow>
                <FormRow title={translate.refunded_amount} labelMd={4}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{locale}</span>
                    </div>
                    <input type="number" className="form-control" value={refunded} disabled={true} />
                  </div>
                </FormRow>
                <FormRow title={translate.refund_amount} labelMd={4}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{locale}</span>
                    </div>
                    <input
                      min={0.01}
                      max={maxRefundableAmount}
                      step={0.01}
                      type={'number'}
                      className={'form-control'}
                      name={'amount'}
                      value={values.amount}
                      disabled={maxRefundableAmount === 0 || isSubmitting}
                      required={true}
                      onChange={e => setFieldValue('amount', e.target.value)}
                    />
                  </div>
                </FormRow>
              </ModalBody>
              <ModalFooter className={`justify-content-between`}>
                <button
                  className="btn btn-outline-secondary text-dark border-0 shadow"
                  type="button"
                  disabled={isSubmitting}
                  onClick={this.toggle}
                >
                  {translate.cancel}
                </button>
                <button type={'submit'} className={'btn btn-dark pull-right'} disabled={maxRefundableAmount === 0}>
                  {translate.submit}
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}
RefundModal.propTypes = {
  invoice: PropTypes.any,
  onSuccess: PropTypes.func,
};
