import React, { useRef } from 'react';
import { translate } from 'shared/translate';
import ActivityIndicator from 'components/ActivityIndicator';
import imageUpload from 'shared/imageUpload';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';
import { Input } from 'reactstrap';
import { FormRow } from 'components/omniwe';

export default ({ banner = {}, onChange = _ => _, onRemove = _ => _, disabled }) => {
  const uploader = useRef(null);
  const { title, content, image, href } = banner || {};
  return (
    <div>
      <div className={'embed-responsive embed-responsive-16by9 position-relative mb-3'}>
        <img className={'embed-responsive-item img-fluid'} src={image} alt={''} />
        <div
          className={'position-absolute p-2'}
          style={{
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <button
            disabled={disabled}
            type={'button'}
            onClick={onRemove}
            className={'btn btn-danger mr-2 fa fa-trash'}
          />
          <button
            disabled={disabled}
            type={'button'}
            onClick={() => uploader.current.click()}
            className={'btn btn-dark fa fa-upload'}
          />
        </div>
        <div
          className={'position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center'}
          style={{ top: 0, left: 0 }}
        >
          <input
            className={'form-control border-0 bg-transparent text-center m-0'}
            style={{ fontSize: '2.5rem', boxShadow: 'none' }}
            placeholder={translate.title}
            value={title}
            onChange={e => {
              banner.title = e.target.value;
              onChange(banner);
            }}
            maxLength={100}
            disabled={disabled}
          />
          <input
            className={'form-control border-0 bg-transparent text-center m-0'}
            style={{ boxShadow: 'none' }}
            placeholder={translate.content}
            value={content}
            onChange={e => {
              banner.content = e.target.value;
              onChange(banner);
            }}
            maxLength={100}
            disabled={disabled}
          />
        </div>
        <input
          style={{
            visibility: 'none',
          }}
          ref={uploader}
          type="file"
          onChange={async e => {
            const file = e.target.files[0];
            if (!file) return;
            try {
              ActivityIndicator.show(translate.upload_image);
              banner.image = await imageUpload(file);
              onChange(banner);
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              ActivityIndicator.hide();
            }
          }}
          disabled={disabled}
        />
      </div>
      <FormRow title={translate.href}>
        <Input
          value={href}
          placeholder={'/pages/aboutus'}
          onChange={e => {
            banner.href = e.target.value;
            onChange(banner);
          }}
        />
      </FormRow>
    </div>
  );
};
