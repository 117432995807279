import React, { Fragment } from 'react';
import FormPageMaker from 'components/formPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY, INVOICE_COMPLETE } from './query';
import { InvoiceStatus } from 'shared/omniwe-types';
import AddressCard from './AddressCard';
import InvoiceItemCard from './InvoiceItemCard';
import CreditNoteCard from './CreditNoteCard';
import OrderComments from 'components/order_comments';
import ActionButton from 'components/form/ActionButton';
import { client } from 'shared/apollo';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ link: '/invoices', label: translate.invoice_details }, { label: this.state.id }],
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    cards: [
      {
        name: translate.invoice_details,
        fields: [
          {
            type: 'select',
            label: translate.status,
            name: 'status',
            options: Object.keys(InvoiceStatus).map(key => ({ label: InvoiceStatus[key], value: key })),
            disabled: true,
          },
          {
            label: translate.amount,
            render: ({ values: { amount: { locale = 'HKD', amount = 0 } = {} } }) => (
              <span>
                {locale} {amount.toFixed(2)}
              </span>
            ),
          },
          {
            label: translate.payment_method,
            render: ({ values: { paymentCredential, paymentMethod } }) => {
              const { name } = paymentCredential || {};
              return <span>{name || paymentMethod}</span>;
            },
          },
        ],
      },
      AddressCard,
      InvoiceItemCard,
      CreditNoteCard,
    ],
  };

  getInitialData({ node } = {}) {
    const { id, status, order, amount, refundedAmount, paymentMethod, paymentCredential, items, creditNotes } =
      node || {};
    return {
      id,
      status,
      order: order || {},
      amount,
      refundedAmount,
      paymentMethod,
      paymentCredential,
      items: items || [],
      creditNotes: creditNotes || [],
    };
  }

  renderExtraButtons({ values: { id, status } }) {
    return (
      <Fragment>
        {status === 'PENDING' && (
          <ActionButton
            className={'mr-2'}
            theme="btn-primary btn-sm"
            onClick={async () => {
              try {
                await client.mutate({
                  mutation: INVOICE_COMPLETE,
                  variables: { id },
                  optimisticResponse: {
                    __typename: 'Mutation',
                    orderInvoiceComplete: {
                      __typename: 'Invoice',
                      id,
                      status: 'COMPLETED',
                    },
                  },
                });
                await client.reFetchObservableQueries(false);
              } catch (e) {
                toast.error(errorParser(e));
              }
            }}
          >
            {translate.status_completed}
          </ActionButton>
        )}
        <ActionButton
          className={'mr-2'}
          onClick={() => {
            window && window.open(`/print_invoices/${id}`, '_blank');
          }}
          theme="btn-dark btn-sm"
        >
          {translate.button_print}
        </ActionButton>
      </Fragment>
    );
  }

  renderForm(initialValues) {
    const { order } = initialValues || {},
      { id: orderId } = order || {};
    return (
      <div className={'row'}>
        <div className={'col'}>{super.renderForm(initialValues)}</div>
        <div className="col-md-4">
          <OrderComments orderId={orderId} />
        </div>
      </div>
    );
  }

  renderFormToolBar(initialValues) {
    return null;
  }
}
