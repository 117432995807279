import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { translate as t } from 'shared/translate';
import { DETAILS_QUERY, SET_QUERY, UNSET_QUERY } from './query';
import VenueCard from './VenueCard';
import _ from 'lodash';
import { client } from 'shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.vendor, link: '/vendors' }, { name: this.state.id || t.create }],
    gql: {
      prepare: DETAILS_QUERY,
      submit: SET_QUERY,
      remove: UNSET_QUERY,
    },
    cards: [
      {
        name: t.vendor_details,
        fields: [
          {
            label: t.account,
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
            disabled: !!this.state.id,
            text: `${t.account_form_tips} ${t.could_not_be_changed}`,
          },
          {
            label: t.password,
            type: !!this.state.id ? 'password_update': 'password',
            name: 'secret',
            required: true,
            maxLength: 100,
          },
          {
            label: t.customer_fullname,
            type: 'text',
            name: 'name',
            required: true,
            maxLength: 100,
          },
        ],
      },
      {
        name: t.address,
        fields: [
          {
            label: t.contact_person,
            type: 'text',
            name: 'person',
            required: true,
            maxLength: 100,
          },
          { label: t.contact_number, type: 'number', step: 1, name: 'tel', required: true, maxLength: 20 },
          { label: t.email, type: 'email', maxLength: 200, name: 'email' },
          { label: t.address, type: 'text', maxLength: 200, name: 'line_1', required: true },
          {
            label: ' ',
            type: 'text',
            maxLength: 200,
            name: 'line_2',
          },
          {
            label: ' ',
            type: 'text',
            maxLength: 200,
            name: 'line_3',
          },
          {
            label: t.country,
            type: 'country',
            name: 'country',
            required: true,
          },
        ],
      },
      VenueCard,
    ],
  };
  submit = async ({ identity, secret, venues = [], name, person, tel, email, line_1, line_2, line_3, country }) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const { data: { vendorSet } = {} } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          shopId: localStorage.getItem('shopId'),
          venueIds: venues.map(({ id }) => id).filter(_ => _),
          attributes: [{ key: 'name', value: name }],
          addresses: [
            {
              person,
              tel,
              email,
              lines: [line_1, line_2, line_3].filter(_ => _),
              country,
            },
          ],
        },
        credential: secret
          ? {
              identity,
              secret,
              platform: 'PASSWORD',
            }
          : undefined,
      },
    });
    return vendorSet.id;
  };
  getInitialData({ node } = {}) {
    const { addresses = [], credentials = [], attributes = [], venues = [] } = node || {};

    const [{ person, tel, email, lines = [], country } = {}] = addresses;
    const { identity } = _.find(credentials, { platform: 'PASSWORD' }) || {};
    const { name: [{ value: name } = {}] = [] } = _.groupBy(attributes, 'key');

    return {
      identity,
      secret: undefined,
      name,
      person,
      tel,
      email,
      line_1: lines[0],
      line_2: lines[1],
      line_3: lines[2],
      country,
      venues,
    };
  }
}
