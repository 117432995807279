import React from 'react';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import WebSocket from 'isomorphic-ws';

const { REACT_APP_API_URL, REACT_APP_WS_URL } = process.env;

const httpLink = createUploadLink({
  uri: REACT_APP_API_URL,
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'Node',
          possibleTypes: [
            { name: 'Shop' },
            { name: 'Company' },
            { name: 'User' },
            { name: 'Warehouse' },
            { name: 'StockItem' },
            { name: 'StockMovement' },
            { name: 'Product' },
            { name: 'ProductCategory' },
            { name: 'Image' },
            { name: 'ProductVariant' },
            { name: 'Coupon' },
            { name: 'CouponShareholder' },
            { name: 'Order' },
            { name: 'DeliveryNote' },
            { name: 'Invoice' },
            { name: 'UserGroup' },
            { name: 'Checkout' },
            { name: 'CheckoutItem' },
            { name: 'UserCredential' },
          ],
        },
      ],
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  if (!token) {
    client.resetStore();
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: REACT_APP_WS_URL,
  webSocketImpl: WebSocket,
});

// const link = authLink.split(
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query);
//     return kind === 'OperationDefinition' && operation === 'subscription';
//   },
//   wsLink,
//   httpLink
// );

const link = ApolloLink.from([
  onError(({ networkError, graphQLErrors /*response*/ }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      let msg = graphQLErrors[0].message || '';
      let need_logout = false;
      if (msg.toLowerCase().match(/token is invalid/g)) {
        need_logout = true;
      } else if (msg.toLowerCase().match(/session/g)) {
        need_logout = true;
      }

      if (need_logout) {
        localStorage.clear();
        window.location.href = '/login';
      }
    }
  }),
  authLink,
  httpLink,
  wsLink,
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    // errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    // errorPolicy: 'all',
  },
  // mutate: {
  //   errorPolicy: 'all'
  // }
};

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({ fragmentMatcher }),
  defaultOptions,
});
