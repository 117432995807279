import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RemoveToggle extends Component {
  state = {
    toggle: false
  };
  toggle = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };
  render() {
    const { toggle } = this.state,
      { onClick, disabled, title, children } = this.props;
    return (
      <div className={'btn-group btn-group-sm'}>
        {toggle && (
          <button
            className='btn btn-danger no-animate ng-scope'
            type='button'
            onClick={onClick}
            disabled={disabled}
          >
            <i className='fa fa-fw fa-exclamation-circle' />
            <span>{title}</span>
          </button>
        )}
        <button
          className='btn btn-danger'
          type='button'
          disabled={disabled}
          onClick={this.toggle}
        >
          {!toggle ? (
            <i className='fa fa-caret-left' />
          ) : (
            <i className='fa fa-caret-right' />
          )}
          {children || <i className='fa fa-fw fa-trash' />}
        </button>
      </div>
    );
  }
}
RemoveToggle.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string
};
