import React from 'react';
import { Icon } from './iconRender';

export default ({
  color = '',
  inner = false,
  spacing = 'my-5',
  size = 'fa-2x'
}) => {
  if (inner) {

    return <Icon icon='faSpinner' className={`fa-spin ${size} ${color}`} />;
  }

  if (!inner) {
    return (
      <div className={`text-center ${spacing}  `}>
        <Icon icon='faSpinner' className={`fa-spin ${size} ${color}`} />
      </div>
    );
  }
};
