import {gql} from "apollo-boost";

export const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Tax {
        name
        taxRate
        countries
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation taxSet($id: ID, $input: TaxInput!) {
    taxSet(id: $id, input: $input) {
      id
      name
      taxRate
      countries
      attributes {
        key
        value
      }
      updatedAt
      createdAt
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation taxUnset($id: ID!) {
    taxUnset(id: $id) {
      id
    }
  }
`;