import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import { LIST_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: translate.promotion }],
    gql: { get: LIST_QUERY },
    createUrl: '/promotions/new',
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        filter: true,
        render: item => <Link to={`/promotions/${item.id}`}>{item.name}</Link>,
        sortable: 'name',
      },
      {
        title: translate.href,
        fieldName: 'href',
        type: 'text',
        filter: true,
        sortable: 'href',
      },
      {
        title: translate.activation,
        type: 'bool',
        align: 'center',
        fieldName: 'active',
        filter: true,
        width: 50,
      },
      {
        title: translate.visible,
        type: 'bool',
        align: 'center',
        fieldName: 'display',
        filter: true,
        width: 50,
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        filter: true,
        align: 'right',
        width: 120,
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        filter: true,
        align: 'right',
        width: 120,
        sortable: 'updatedAt',
      },
    ],
  };

  getData = (data = {}) => {
    const { node: { items = [], count = 0 } = {} } = data || {};
    return { list: items, count };
  };

  getExtraFetchVariables() {
    return {
      shopId: localStorage.getItem('shopId'),
      filter: {
        ...this.filterInput,
        attributes: [{ key: 'hc_promotion', value: 'true' }],
      },
    };
  }
}
