import React from 'react';
import PropTypes from 'prop-types';
import ListPageMaker from '../../../components/listPageMaker';
import { translate as t } from 'shared/translate';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

export default class extends ListPageMaker {
  static propTypes = {
    className: PropTypes.string,
    selectedVenueIds: PropTypes.array,
    onChange: PropTypes.func,
  };
  static defaultProps = {
    className: 'p-0 mt-5',
    selectedVenueIds: [],
    onChange: _ => _,
  };

  state = {
    ...this.state,
    title: t.venue,
    gql: {
      get: gql`
        query($shopId: ID, $filter: FilterInput, $offset: OffsetInput, $otherFilter: VenueFilterInput) {
          node(id: $shopId) {
            id
            ... on Shop {
              venuesCount(filter: $filter, otherFilter: $otherFilter)
              venues(filter: $filter, offset: $offset, otherFilter: $otherFilter) {
                id
                name
              }
            }
          }
        }
      `,
    },
    fields: [
      {
        title: (_, venues) => {
          const { selectedVenueIds, onChange } = this.props;
          const isAllVenuesSelected = venues.reduce((reducer, { id }) => {
            if (!reducer) return reducer;
            return !!~selectedVenueIds.indexOf(id);
          }, true);
          return (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isAllVenuesSelected}
                onChange={() => {
                  if (!isAllVenuesSelected) {
                    onChange([...new Set([...selectedVenueIds, ...venues.map(venue => venue.id)])]);
                  } else {
                    const tmp = [...selectedVenueIds];
                    venues.forEach(venue => {
                      tmp.splice(tmp.indexOf(venue.id), 1);
                    });
                    onChange(tmp);
                  }
                }}
              />
            </div>
          );
        },
        align: 'center',
        width: 50,
        render: ({ id } = {}) => {
          const { selectedVenueIds, onChange } = this.props;
          const selected = !!~selectedVenueIds.indexOf(id);
          return (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selected}
                onChange={() => {
                  if (!selected) {
                    if (!~selectedVenueIds.indexOf(id)) {
                      onChange([...selectedVenueIds, id]);
                    }
                  } else {
                    const tmp = [...selectedVenueIds];
                    tmp.splice(tmp.indexOf(id), 1);
                    onChange(tmp);
                  }
                }}
              />
            </div>
          );
        },
      },
      {
        title: t.venue_name,
        fieldName: 'name',
        type: 'text',
        filter: true,
      },
    ],
  };

  getData = data => {
    const { node } = data || {},
      { venuesCount = 0, venues = [] } = node || {};
    return { list: venues, count: venuesCount };
  };

  render() {
    const {
      gql: { get },
    } = this.state;
    return (
      <Query
        query={get}
        fetchPolicy={'network-only'}
        variables={{
          offset: this.offset,
          otherFilter: this.otherFilter,
          filter: this.filterInput,
          shopId: localStorage.getItem('shopId'),
        }}
      >
        {(...args) => this.renderContent(...args)}
      </Query>
    );
  }
}
