import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

import _ from 'lodash';
import moment from 'moment';
import Loading from '../../components/loading';
import LocaleCurrency from 'locale-currency';
import { translate } from '../../shared/translate';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Order {
        shippingAddress {
          person
          tel
          email
          lines
          country
        }
        billingAddress {
          person
          tel
          email
          lines
          country
        }
        subtotal {
          amount
        }
        total {
          amount
        }
        tax {
          amount
        }
        shippingFee {
          amount
        }
        coupon {
          id
          name
        }
        shop {
          id
          title
          locale
          logo
        }
        attributes {
          key
          value
        }
        items {
          id
          productVariant {
            id
            product {
              id
              name
            }
            attributes {
              key
              value
            }
          }
          quantity
          unitPrice {
            amount
          }
        }
        attributes {
          key
          value
        }
        invoices {
          id
          paymentMethod
          amount {
            amount
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export default ({ match: { params: { id } = {} } = {} }) => {
  if (!id) return null;
  return (
    <Query query={GET_QUERY} variables={{ id }}>
      {({ loading, data: { node } }) => {
        if (!!loading) return <Loading />;
        const {
            id,
            shop,
            coupon,
            items = [],
            createdAt,
            subtotal: { amount: subtotalAmount },
            tax: { amount: taxAmount },
            shippingFee: { amount: shippingFeeAmount },
            total: { amount: totalAmount },
            attributes: orderAttrs = [],
            invoices = [],
            billingAddress,
            shippingAddress
          } = node || {},
          { name: couponName } = coupon || {},
          { title, locale, logo } = shop || {},
          receiver = shippingAddress || {},
          { lines: billLines = [], person: billPerson } = billingAddress || {},
          currency = LocaleCurrency.getCurrency(locale);
        const discount = Math.abs(
          totalAmount - subtotalAmount - shippingFeeAmount - taxAmount
        );

        return (
          <div className="container d-flex flex-column h-100">
            <h3 className="text-center my-2">Order</h3>
            <table className="dn" width="100%">
              <tbody>
                <tr>
                  <td width="130" valign="top" nowrap="nowrap">
                    <strong>Order No.</strong>
                  </td>
                  <td width="555" valign="top">
                    {id}
                  </td>
                </tr>
                <tr>
                  <td valign="top" nowrap="nowrap">
                    <strong>Order Date</strong>
                  </td>
                  <td valign="top">{moment(createdAt).format('YYYY-MM-DD')}</td>
                </tr>
                <tr>
                  <td valign="top" nowrap="nowrap">
                    <strong>Company</strong>
                  </td>
                  <td valign="top">{title}</td>
                </tr>

                <tr>
                  <td valign="top" nowrap="nowrap">
                    <strong>Delivery Address</strong>
                  </td>
                  <td valign="top">{billLines.join(', ')}</td>
                </tr>
                <tr>
                  <td valign="top" nowrap="nowrap">
                    <strong>Contact Person</strong>
                  </td>
                  <td valign="top">{billPerson}</td>
                </tr>
              </tbody>
            </table>
            <table className="mt-4 table table-borderless table-striped mb-0">
              <col width="50" />
              <col width="*" />
              <col width="50" />
              <col width="150" />
              <col width="150" />
              <thead className="thead-white">
                <tr>
                  <th className="border-0" />
                  <th className="border-0">Product Name</th>
                  <th className="text-right border-0">Quantity</th>
                  <th className="text-right border-0">Price ({currency})</th>
                  <th className="text-right border-0">Amount ({currency})</th>
                </tr>
              </thead>
              <tbody>
                {items.map(
                  (
                    {
                      id,
                      productVariant,
                      unitPrice: { amount = 0 } = {},
                      quantity
                    },
                    i
                  ) => {
                    const {
                      attributes = [],
                      product: { name } = {}
                    } = productVariant || {};
                    return (
                      <tr>
                        <td>{i + 1}.</td>
                        <td>
                          {name}
                          {attributes.length > 0 && ' - '}
                          {_.map(attributes, 'value').join(' ')}
                        </td>
                        <td className="text-right">{quantity}</td>
                        <td className="text-right">
                          ${(amount - 0 || 0).toFixed(2)}
                        </td>
                        <td className="text-right">
                          ${(amount * quantity - 0 || 0).toFixed(2)}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
            <table className={'table table-borderless'}>
              <col width="*" />
              <col width="150" />
              <tbody>
                <tr>
                  <td className={'text-right'}>Sub-Total</td>
                  <td className={'text-right'}>
                    ${(subtotalAmount - 0 || 0).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={'text-right border-0'}>Discount</td>
                  <td className={'text-right border-0'}>
                    - ${(discount - 0 || 0).toFixed(2)}
                  </td>
                </tr>
                {!!taxAmount && (
                  <tr>
                    <td className={'text-right border-0'}>Tax</td>
                    <td className={'text-right border-0'}>
                      ${(taxAmount - 0 || 0).toFixed(2)}
                    </td>
                  </tr>
                )}
                {!!shippingFeeAmount && (
                  <tr>
                    <td className={'text-right border-0'}>Shipping Fee</td>
                    <td className={'text-right border-0'}>
                      ${(shippingFeeAmount - 0 || 0).toFixed(2)}
                    </td>
                  </tr>
                )}
                {invoices.length > 0 &&
                invoices.map(
                  (
                    { paymentMethod = 'MANUAL', amount: { amount } = {} },
                    i
                  ) => (
                    <tr key={i}>
                      <td className={`${i > 0 && 'border-0'}`}>
                        {translate[`PAYMENT_METHOD_${paymentMethod}`]}
                      </td>
                      <td className={`text-right ${i > 0 && 'border-0'}`}>
                        ${(amount - 0 || 0).toFixed(2)}
                      </td>
                    </tr>
                  )
                )}
                <tr>
                  <td className={'text-right'}>Total</td>
                  <td className={'text-right'}>
                    ${(totalAmount - 0 || 0).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={'flex-fill'} />
            <div className="mt-5">
              <p>Received By:</p>
              <p>Name:</p>
              <p>Date:</p>
              <h4 className="mt-4">{title}</h4>
              <p>Powered by Omniwe.com</p>
            </div>
          </div>
        );
      }}
    </Query>
  );
};
