import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from './../Card';
import CardHeader from './../CardHeader';
import MessageItem from './MessageItem';
import MessageReply from './MessageReply';

class MessagingCard extends Component {
  handleReply = message => {
    this.props.onReply(message);
  };

  scrollMessageToBottom = () => {
    const messageBox = document.getElementById('message-div');
    messageBox.scrollTop = messageBox.scrollHeight;
  };

  componentDidMount() {
    this.scrollMessageToBottom();
  }

  componentDidUpdate() {
    this.scrollMessageToBottom();
  }

  render() {
    const { data } = this.props;
    return (
      <Card>
        <CardHeader title='Messages' />
        <div className='card-body'>
          <div className='row'>
            <div className='col-10 offset-2 '>
              <div
                id='message-div'
                className='d-flex flex-column p-2 mb-3 border rounded-lg'
                style={{ height: '250px', overflow: 'auto' }}
              >
                {data.map(d => (
                  <MessageItem {...d} />
                ))}
              </div>
            </div>
          </div>
          <MessageReply onReply={this.handleReply} />
        </div>
      </Card>
    );
  }
}

MessagingCard.propTypes = {
  data: PropTypes.arrayOf(Object),
  onReply: PropTypes.func
};

MessagingCard.defaultProps = {
  data: [],
  onReply: () => console.warn('onReply not provided')
};

export default MessagingCard;
