import React from 'react';
import PromisifyModal from '../../components/promisifyModal';
import { translate } from 'shared/translate';

export default class CancelModal extends PromisifyModal {
  state = {
    ...this.state,
    reason: '',
  };

  onConfirm = async () => {
    return this.state.reason;
  };
  renderHeader() {
    return 'Cancel Reason';
  }
  renderBody() {
    const { reason } = this.state;
    return (
      <textarea
        value={reason}
        cols="30"
        rows="10"
        className={`form-control`}
        onChange={e => this.setState({ reason: e.target.value })}
      />
    );
  }
  renderConfirmText() {
    return translate.submit;
  }
}
