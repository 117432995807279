import React, { Component } from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { gql } from 'apollo-boost';
import { translate } from '../../shared/translate';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { GET_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    breadcrumb: [{ name: translate.customer_details }],
    fields: [
      {
        title: translate.avatar,
        render: item => {
          const attrs = _.groupBy(item.attributes, 'key');
          const { thumbnail: [{ value: thumbnail } = {}] = [] } = attrs;
          return (
            <a className="thumbnail text-muted d-flex align-items-center justify-content-center m-auto rounded-circle">
              {thumbnail ? (
                <img className={`object-fit-contain`} src={thumbnail} />
              ) : (
                <FontAwesomeIcon icon={faImage} size="2x" />
              )}
            </a>
          );
        },
        width: 50,
      },
      {
        title: translate.customer_fullname,
        fieldName: 'name',
        nestedAttr: true,
        type: 'text',
        filter: true,
        render: item => {
          const attrs = _.groupBy(item.attributes, 'key');
          const { name: [{ value: name } = {}] = [] } = attrs;
          return (
            <Link
              to={{
                pathname: '/customers/' + item.id,
                state: { variables: { id: item.id } },
              }}
            >
              {name}
            </Link>
          );
        },
        sortable: 'name',
      },
      {
        title: translate.phone_no,
        fieldName: 'tel',
        nestedAttr: true,
        type: 'text',
        filter: true,
        width: 160,
        render: item => {
          const attrs = _.groupBy(item.attributes, 'key');
          const { tel: [{ value } = {}] = [] } = attrs;
          return value;
        },
      },
      {
        title: translate.email,
        fieldName: 'email',
        nestedAttr: true,
        type: 'text',
        filter: true,
        width: 160,
        render: ({ credentials = [], attributes = [] }) => {
          const attrs = _.groupBy(attributes, 'key');
          const { email: [{ value: email } = {}] = [] } = attrs;
          const { identity } = credentials.find(cred => cred.platform === 'PASSWORD') || {};

          return identity || email;
        },
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        width: 160,
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        width: 160,
        filter: true,
        sortable: 'updatedAt',
      },
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    const { customers = [], customersCount = 0 } = node || {};
    return { list: customers, count: customersCount };
  };
}
