import React, { Component } from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { translate as t } from '../../shared/translate';
import Card from '../../components/layout/card/Card';
import CardBody from '../../components/layout/card/CardBody';
import CardHeader from '../../components/layout/card/CardHeader';
import { DETAILS_QUERY, UNSET_QUERY, SET_QUERY } from './query';
import { client } from '../../shared/apollo';
import Schedule from './_Schedule';
import _ from 'lodash';
import SearchComboCountry from 'components/SearchComboCountry';
import PermissionRendering from 'components/permissionRendering';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.venue, link: '/venues' }, { name: this.state.id || t.create }],
    gql: {
      prepare: DETAILS_QUERY,
      submit: SET_QUERY,
      remove: UNSET_QUERY,
    },
    cards: [
      {
        name: t.venue_details,
        fields: [
          {
            label: t.name,
            type: 'text',
            name: 'name',
            maxLength: 200,
            required: true,
          },
          {
            label: t.business_hours,
            type: 'text',
            name: 'businessHours',
            maxLength: 200,
          },
          {
            label: t.district,
            type: 'select',
            name: 'district',
            options: Object.keys(t.districts).map(value => ({
              label: t.districts[value],
              value,
            })),
          },
        ],
      },
      {
        name: t.image,
        type: 'images',
        field: {
          name: 'images',
        },
      },
      {
        name: t.address,
        fields: [
          {
            label: t.contact_person,
            require: true,
            type: 'text',
            name: 'person',
            maxLength: 100,
          },
          {
            label: t.contact_number,
            require: true,
            type: 'text',
            name: 'tel',
            maxLength: 20,
          },
          {
            label: t.email,
            type: 'email',
            name: 'email',
            maxLength: 200,
          },
          {
            label: t.address,
            require: true,
            type: 'text',
            name: 'line1',
            maxLength: 100,
          },
          {
            label: ' ',
            type: 'text',
            name: 'line2',
            maxLength: 100,
          },
          {
            label: ' ',
            type: 'text',
            name: 'line3',
            maxLength: 100,
          },
          {
            label: t.country,
            render: ({ values: { country }, setFieldValue }) => (
              <SearchComboCountry required={true} value={country} onChange={v => setFieldValue('country', v)} />
            ),
          },
        ],
      },
      !this.state.id && {
        fields: [
          {
            type: 'span',
            text: t.slot_tips_before_saving,
          },
        ],
      },
    ].filter(_ => _),
  };

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const shopId = localStorage.getItem('shopId');
    const variables = {
      id,
      input: {
        name: values.name,
        shopId,
        address: {
          person: values.person,
          tel: values.tel,
          email: values.email,
          lines: [values.line1, values.line2, values.line3],
          country: values.country,
          attributes: [],
        },
        district: values.district,
        newImages: values.images.filter(image => typeof image === 'string'),
        imageUploads: values.images.filter(image => typeof image !== 'string'),
        attributes: [
          {
            key: 'business_hours',
            value: values.businessHours,
          },
        ],
      },
    };

    const {
      data: { venueSet },
    } = await client.mutate({
      mutation: submit,
      variables,
    });
    return venueSet.id;
  };

  getInitialData(data) {
    const { node } = data || {},
      {
        name = '',
        district = 'C',
        images = [],
        address: { person = '', tel = '', email = '', lines = [], country = 'HKG' } = {},
        attributes = [],
      } = node || {};

    const businessHours = _.find(attributes, { key: 'business_hours' }) || {};
    return {
      name,
      businessHours: businessHours.value || '',
      district,
      images,
      person,
      tel,
      email,
      line1: lines[0] || '',
      line2: lines[1] || '',
      line3: lines[2] || '',
      country,
    };
  }

  renderOthers(data, actions) {
    const { id } = this.state;

    if (!id) return null;
    return (
      <PermissionRendering permission={['hc.venue.slot.read', 'hc.reservation.read']}>
        <Card>
          <CardHeader title={t.schedule} />
          <CardBody>
            <Schedule venueId={id} />
          </CardBody>
        </Card>
      </PermissionRendering>
    );
  }
}
