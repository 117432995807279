import React from 'react';
import IMG_IPHONE_FRAME from 'assets/iphone_frame.png';

export default ({ title, children, className = '', style = {} }) => {
  return (
    <div className={`position-relative ${className}`} style={{ width: 250, ...style }}>
      <img className={'img-fluid'} src={IMG_IPHONE_FRAME} />
      <div className={'position-absolute w-100 h-100'} style={{ top: 0, left: 0, padding: '56px 13px' }}>
        <div className={'position-relative w-100 h-100 bg-white border'} style={{ overflow: 'auto' }}>
          <p className={'bg-dark text-light p-1 m-0 text-center'}>
            <small
              style={{
                whiteSpace: 'pre',
              }}
            >
              {title}
            </small>
          </p>
          {children}
        </div>
      </div>
    </div>
  );
};
