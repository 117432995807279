import React, { Fragment } from 'react';
import RemoveToggle from '../../form/RemoveToggle';
import { translate } from 'shared/translate';
import FormRow from '../../form/FormRow';
import TinyComponent from '../../tinyComponent';
import Card from '../../layout/card/Card';
import CardHeader from '../../layout/card/CardHeader';
import CardBody from '../../layout/card/CardBody';
import SearchComboCountry from 'components/SearchComboCountry';
import PasswordField from '../Fields/_passwordField';
import ImageField from '../Fields/_imageField';
import ImagesField from '../Fields/_imagesField';
import { Icon } from 'components/iconRender';

export default ({ extra, name, fields, onRemoveClick, actions }) => {
  const { values, handleChange, setFieldValue, isSubmitting } = actions;
  return (
    <Card>
      {(!!name || !!onRemoveClick) && (
        <CardHeader
          title={name}
          extra={
            <Fragment>
              {extra}
              {onRemoveClick && <RemoveToggle title={translate.confirm_to_delete} onClick={onRemoveClick} />}
            </Fragment>
          }
        />
      )}
      <CardBody>
        {fields.map((field, i) => {
          if (
            typeof field === 'function' &&
            (!!field.prototype.isReactComponent || String(field).includes('createElement'))
          ) {
            const Field = field;
            return <Field key={i} {...actions} />;
          }

          const { label, render, name, type, options, text, required, disabled, numberOnly, ...props } = field;
          if (render)
            return (
              <FormRow key={i} title={label} required={required}>
                {render(actions)}
              </FormRow>
            );
          let _value = '';
          try {
            eval(`_value = values.${name};`);
          } catch (e) {}

          switch (type) {
            case 'span':
              return <span className={'form-control-plaintext'}>{text || _value}</span>;
            case 'date':
            case 'email':
            case 'text':
            case 'tel':
            case 'password':
            case 'number':
              return (
                <FormRow key={i} title={label} required={required}>
                  <input
                    type={type}
                    className="form-control"
                    name={name}
                    value={_value}
                    onChange={
                      !!numberOnly
                        ? e => {
                            setFieldValue(name, e.target.value.replace(/\D/g, ''));
                          }
                        : handleChange
                    }
                    required={required}
                    disabled={isSubmitting || disabled}
                    {...props}
                  />
                  {!!text && <small>{text}</small>}
                </FormRow>
              );
            case 'textarea':
              return (
                <FormRow key={i} title={label} required={required}>
                  <textarea
                    className={'form-control'}
                    name={name}
                    value={_value}
                    onChange={handleChange}
                    required={required}
                    disabled={isSubmitting || disabled}
                    {...props}
                  />
                  {!!text && <small>{text}</small>}
                </FormRow>
              );
            case 'select':
              return (
                <FormRow key={i} title={label} required={required}>
                  <select
                    className={'form-control'}
                    name={name}
                    value={_value}
                    required={required}
                    onChange={handleChange}
                    disabled={isSubmitting || disabled}
                    {...props}
                  >
                    <option value={''}>{translate.please_select}</option>
                    {options.map((opt, i) => (
                      <option key={i} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                  {!!text && <small>{text}</small>}
                </FormRow>
              );
            case 'checkbox':
              return (
                <FormRow key={i} title={label} required={required}>
                  <div className="form-check pt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={name}
                      required={required}
                      name={name}
                      checked={!!_value}
                      onChange={e => {
                        setFieldValue(name, e.target.checked);
                      }}
                      disabled={isSubmitting || disabled}
                      {...props}
                    />
                    <label className="form-check-label" htmlFor={name}>
                      {text}
                    </label>
                  </div>
                </FormRow>
              );
            case 'html':
              return (
                <FormRow key={i} title={label} required={required}>
                  <div className={'border'}>
                    <TinyComponent
                      value={_value}
                      required={required}
                      disabled={isSubmitting || disabled}
                      onChange={c => setFieldValue(name, c)}
                      {...props}
                    />
                  </div>
                  {!!text && <small>{text}</small>}
                </FormRow>
              );
            case 'country':
              return (
                <FormRow key={i} title={label} required={required}>
                  <SearchComboCountry
                    {...props}
                    disabled={isSubmitting || disabled}
                    value={_value}
                    required={required}
                    onChange={v => setFieldValue(name, v)}
                  />
                  {!!text && <small>{text}</small>}
                </FormRow>
              );
            case 'countries':
              const arr = _value || [];
              return (
                <FormRow key={i} title={label} required={required}>
                  <SearchComboCountry
                    {...props}
                    disabled={isSubmitting || disabled}
                    onChange={v => {
                      if (!~arr.indexOf(v)) {
                        arr.push(v);
                        setFieldValue(name, arr);
                      }
                    }}
                  />
                  {!!text && <small>{text}</small>}
                  {(arr || []).map((title, i) => (
                    <button
                      key={i}
                      type="button"
                      className="btn btn-primary btn-sm mr-1 my-1 shadow-none"
                      onClick={() => {
                        arr.splice(i, 1);
                        setFieldValue(name, arr);
                      }}
                      title={title}
                    >
                      {translate.countries[title]}
                      <Icon icon="faTimes" />
                    </button>
                  ))}
                  {arr.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-link my-1"
                      onClick={() => {
                        setFieldValue(name, []);
                      }}
                    >
                      {translate.button_remove_all}
                    </button>
                  )}
                </FormRow>
              );
            case 'password_update':
              return (
                <FormRow key={i} title={label} required={required}>
                  <PasswordField
                    {...props}
                    name={name}
                    disabled={isSubmitting || disabled}
                    value={_value}
                    required={required}
                    onChange={v => setFieldValue(name, v)}
                  />
                  {!!text && <small>{text}</small>}
                </FormRow>
              );
            case 'image':
              return (
                <FormRow key={i} title={label} required={required}>
                  <ImageField
                    {...props}
                    name={name}
                    disabled={isSubmitting || disabled}
                    value={_value}
                    required={required}
                    onChange={v => setFieldValue(name, v)}
                  />
                </FormRow>
              );
            case 'images':
              return (
                <FormRow key={i} title={label} required={required}>
                  <ImagesField
                    {...props}
                    name={name}
                    disabled={isSubmitting || disabled}
                    images={_value}
                    required={required}
                    onChange={v => setFieldValue(name, v)}
                  />
                </FormRow>
              );
            default:
              return null;
          }
        })}
      </CardBody>
    </Card>
  );
};
