import { gql } from 'apollo-boost';

export const LIST_QUERY = gql`
  # QUERY HC SERVICE
  query(
    $shopId: ID
    $otherFilter: ServiceFilterInput
    $filter: FilterInput
    $offset: OffsetInput
    $sorter: [SorterInput!]
  ) {
    node(id: $shopId) {
      id
      ... on Shop {
        servicesCount(otherFilter: $otherFilter, filter: $filter)
        services(otherFilter: $otherFilter, filter: $filter, offset: $offset, sorter: $sorter) {
          id
          createdAt
          updatedAt
          name
          price
          active
          display
        }
      }
    }
  }
`;
