import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on DeliveryOption {
        active
        name
        courier
        countries
        sortIndex
        weights {
          maxWeight
          unit
          unitPrice
        }
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation($id: ID, $input: DeliveryOptionInput!) {
    deliveryOptionSet(id: $id, input: $input) {
      id
      name
      active
      countries
      courier
      sortIndex
      attributes {
        key
        value
      }
      weights {
        maxWeight
        unit
        unitPrice
      }
      updatedAt
      createdAt
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation($id: ID!) {
    deliveryOptionUnset(id: $id) {
      id
    }
  }
`;
