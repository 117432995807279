import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query(
    $id: ID!
    $offset: OffsetInput
    $filter: FilterInput
    $otherFilter: CustomPagesFilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Shop {
        id
        customPagesCount(otherFilter: $otherFilter, filter: $filter)
        customPages(offset: $offset, otherFilter: $otherFilter, filter: $filter, sorter: $sorter) {
          id
          name
          href
          group
          active
          body {
            name
            config
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
