import React, { Fragment } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Query } from 'react-apollo';
import { GET_SLOTS } from './query';
import AppointmentModal from '../_appointmentModal';
import ClientAgenda from '../_clientAgenda';
import _ from 'lodash';
import './style.css';

const localizer = momentLocalizer(moment);
const HEIGHTS = {
  month: 700,
  week: 4000,
  agenda: 0,
};

export default class ClientCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      random: 0,
      currentView: 'month',
      agendaVisible: false,
      defaultFilter: {},
      startedSince: moment()
        .startOf('month')
        .startOf('week')
        .startOf('day')
        .toISOString(),
      startedUntil: moment()
        .endOf('month')
        .endOf('week')
        .endOf('day')
        .toISOString(),
    };
    this.view = 'month';
  }

  onView = viewName => {
    this.view = viewName;

    const agendaVisible = !(viewName === 'week' || viewName === 'month');
    this.setState({
      currentView: viewName,
      agendaVisible,
    });
  };

  onSelectEvent = ({ resource: venueSlot }) => {
    try {
      this.appointmentModal.init(venueSlot);
      this.appointmentModal.toggle();
    } catch (e) {}
  };

  genEventTitle({ startedAt, startedThru }) {
    return `${moment(startedAt).format('hh:mm')}-${moment(startedThru).format('hh:mm A')}`;
  }

  onFilterChange = defaultFilter => {
    this.setState({ defaultFilter });
  };

  handleRangeChange(ranges) {
    switch (this.view) {
      case 'agenda':
      case 'month':
        this.setState({
          random: Math.random(),
          startedSince: moment(ranges.start)
            .startOf('day')
            .toISOString(),
          startedUntil: moment(ranges.end)
            .endOf('day')
            .toISOString(),
        });
        return;
      case 'week':
        this.setState({
          random: Math.random(),
          startedSince: moment(ranges[0])
            .startOf('day')
            .toISOString(),
          startedUntil: moment(ranges[ranges.length - 1])
            .endOf('day')
            .toISOString(),
        });
        return;
    }
  }

  render() {
    const { currentView, startedSince, startedUntil, agendaVisible, defaultFilter, random } = this.state;
    const { venueId } = this.props;
    return (
      <Fragment>
        <Query
          query={GET_SLOTS}
          variables={{
            id: venueId,
            otherFilter: {
              startedSince,
              startedUntil,
            },
            offset: {
              limit: -1,
            },
          }}
        >
          {({ loading, data: { node } = {}, refetch }) => {
            let { events = [] } = node || {};

            events = events
              .map(event => {
                const { slots = [], orderItem, address } = event,
                  { tel } = address || {},
                  {
                    order: { id },
                  } = orderItem || {};

                if (slots.length === 0) return null;

                slots.sort((a, b) => {
                  return new Date(a.startedAt) - new Date(b.startedAt);
                });

                const firstSlot = _.first(slots);
                const lastSlot = _.last(slots);
                const start = new Date(firstSlot.startedAt);
                const end = lastSlot.startedThru === null ? null : new Date(lastSlot.startedThru);
                const title = this.genEventTitle({
                  startedAt: start,
                  startedThru: end,
                });
                return {
                  start,
                  end,
                  title,
                  allDay: false,
                  resource: event,
                };
              })
              .filter(_ => _);

            return (
              <div style={{ height: HEIGHTS[currentView] }}>
                <Calendar
                  step={15}
                  timeslots={4}
                  localizer={localizer}
                  events={events}
                  eventPropGetter={({ start, end, resource: { status } }) => {
                    const isPassed = moment(end).isBefore(moment.now());

                    let className = 'bg-primary';
                    if (isPassed) className = 'bg-danger';
                    return {
                      className,
                    };
                  }}
                  views={['week', 'month', 'agenda']}
                  defaultView="month"
                  startAccessor="start"
                  endAccessor="end"
                  selectable={true}
                  onView={this.onView}
                  onSelectEvent={this.onSelectEvent}
                  onRangeChange={e => this.handleRangeChange(e)}
                  popup={true}
                  tooltipAccessor={({ title, resource: { address } }) => {
                    const { person, tel } = address || {};
                    return `${title}\n${person} - ${tel}`;
                  }}
                  titleAccessor={({ title, resource: { address } }) => {
                    const { tel } = address || {};
                    return `${title}  (${tel})`;
                  }}
                  formats={{
                    agendaHeaderFormat: ({ start, end, ...args }) => {
                      return moment(start).format('YYYY/MM/DD') + ' - ' + moment(end).format('YYYY/MM/DD');
                    },
                  }}
                />
                <AppointmentModal ref={ref => (this.appointmentModal = ref)} onChange={refetch} venueId={venueId} />
              </div>
            );
          }}
        </Query>
        {!!agendaVisible && (
          <ClientAgenda
            key={random}
            className={'p-0 mt-5'}
            venueId={venueId}
            defaultFilter={defaultFilter}
            onFilterChange={this.onFilterChange}
            startedSince={startedSince}
            startedUntil={startedUntil}
          />
        )}
      </Fragment>
    );
  }
}
