import React from 'react';
import _ from 'lodash';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

const ME = gql`
  query {
    me {
      id
      groups {
        name
        permissions
      }
    }
  }
`;

export default ({ children, permission }) => (
  <Query query={ME}>
    {({ loading, data }) => {
      if (loading) return null;
      const { me } = data || {},
        { groups = [] } = me || {};

      const permissions = _.flatten(_.map(groups || [], 'permissions'));
      const permissionsStr = permissions.join(',');

      let permit;
      if (Array.isArray(permission)) {
        permit = permission.reduce((reducer, permission) => {
          if (reducer) return reducer;
          return !!permissionsStr.match(new RegExp(permission));
        }, false);
      } else {
        permit = !!permissionsStr.match(new RegExp(permission));
      }

      if (typeof children === 'function') return children({ permit });
      else return permit ? children : null;
    }}
  </Query>
);
