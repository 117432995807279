import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableList = ({
  direction,
  className = '',
  style,
  items = [],
  itemRender,
  onChange = _ => _,
  keyField = 'id',
  disabled,
}) => {
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);

    onChange(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction={direction}>
        {provided => (
          <div className={className} style={style} {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item[keyField]} draggableId={item[keyField]} index={index} isDragDisabled={disabled}>
                {provided => (
                  <div key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {itemRender({ item, index, ...provided })}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
