import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import GenericForm from '../../components/GenericForm';
import { Loading } from '../../components/pageComponents/utility';
import { translate } from '../../shared/translate';
import PlanCard from './_planCard';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment';
import errorParser from '../../shared/errorParser';
import { client } from '../../shared/apollo';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Shop {
        company {
          id
          status
          expiredAt
        }
        attributes {
          key
          value
        }
        title
        hostname
        description
      }
    }
    me {
      id
      billings {
        id
      }
    }
    plans {
      id
      nickname
      amount
      currency
      interval
    }
  }
`;

const POST_QUERY = gql`
  mutation subscribePlan($companyId: ID!, $id: String!) {
    subscribePlan(id: $id, companyId: $companyId) {
      id
    }
  }
`;

export default class extends Component {
  state = {
    selectedPlan: undefined
  };
  onPlanClick = async ({ plan, billings }) => {
    const { history } = this.props;
    if (billings.length === 0) {
      toast.warn(translate.please_add_billing_first);
      history.push('/billings/new', { popTo: '/plans' });
    } else {
      this.toggle(plan);
    }
  };
  toggle = (plan = null) => {
    this.setState({ selectedPlan: plan });
  };
  onSubscribeClick = async (companyId, { id }) => {
    const { history } = this.props;
    try {
      await client.mutate({
        mutation: POST_QUERY,
        variables: {
          companyId,
          id
        }
      });
      toast.success(translate.plan_subscribed);
      this.toggle();
      history.push('/profile');
    } catch (e) {
      toast.error(errorParser(e));
    }
  };

  render() {
    const shopId = localStorage.getItem('shopId');
    return (
      <Query query={GET_QUERY} variables={{ id: shopId }}>
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { node, me, plans } = data || {},
            { billings } = me || {},
            { company } = node || {};
          return (
            <GenericForm breadcrumb={[{ name: translate.plan }]}>
              <div className='row'>
                {plans.map(plan => (
                  <PlanCard
                    {...plan}
                    onClick={() => this.onPlanClick({ plan, billings })}
                  />
                ))}
              </div>
              {this.renderModal(company)}
            </GenericForm>
          );
        }}
      </Query>
    );
  }
  renderModal({ id, expiredAt }) {
    const { selectedPlan } = this.state;
    const { nickname, amount, currency, interval } = selectedPlan || {};
    return (
      <Modal
        isOpen={!!selectedPlan}
        size={'md'}
        centered={true}
        toggle={() => this.toggle()}
      >
        <ModalHeader>{nickname}</ModalHeader>
        <ModalBody>
          {moment(expiredAt)
            .endOf('day')
            .isAfter(moment(), 'day') && (
            <p>
              {translate.formatString(translate.subscription_desc, {
                amount: (amount / 100).toFixed(2),
                currency: (currency || '').toUpperCase()
              })}
            </p>
          )}
        </ModalBody>
        <ModalFooter className={`justify-content-between`}>
          <button
            className='btn btn-outline-secondary text-dark border-0 shadow'
            type='button'
            onClick={() => this.toggle()}
          >
            CANCEL
          </button>
          <button
            className='btn btn-danger pull-right'
            type='button'
            onClick={() => this.onSubscribeClick(id, selectedPlan)}
          >
            CONFIRM
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
