// 列表頁正在加載組件
// src/components/pageComponents/LoadingList.jsx
// created on april-18, 2019, by Tony Day
import React from "react";
import Loading from "../loading";
export default () => (
  <tr>
    <td colSpan={99}>
      <Loading />
    </td>
  </tr>
);