/**
 * seach_product is deprecated,
 * use SearchComboCollection instead
 */

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import React from 'react';
import { client } from '../shared/apollo';
import Model from './model';
import gql from 'graphql-tag';
import { translate } from '../shared/translate';

const GET_CUSTOMERS = gql`
  query($id: ID, $filter: ProductFilterInput) {
    node(id: $id) {
      id
      ... on Shop {
        id
        title
        products(otherFilter: $filter, offset: { skip: 0, limit: 10 }) {
          id
          name
          variations {
            id
          }
        }
      }
    }
  }
`;

export default class ShopSearch extends Model {
  state = {
    dropdownOpen: false
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  async search(value = this.getCurrentRef(`input`).value) {
    setTimeout(async () => {
      if (value === this.getCurrentRef(`input`).value) {
        const {
          data: { node: { products: list = [] } = {} } = {}
        } = await client.query({
          fetchPolicy: 'network-only',
          query: GET_CUSTOMERS,
          variables: {
            id: localStorage.getItem('shopId'),
            filter: {
              name: value
            }
          }
        });
        this.collections = list;
        this.setState({
          dropdownOpen: true
        });
      }
    }, 500);
  }

  async select(item) {
    this.selectedShop = item;
    this.getCurrentRef(`input`).value = `${item.name}`;

    this.items = [];
    this.setState({
      dropdownOpen: false
    });

    if (this.props.select) {
      this.props.select(item);
    }
  }

  render() {
    this.items = this.items || [];
    this.confirmdItems = this.confirmdItems || [];
    this.collections = this.collections || [];

    const {
      placeholder = translate.search_product,
      inputClass,
      defaultValue
    } = this.props;
    return (
      <ButtonDropdown
        className='w-100 combobox'
        isOpen={this.state.dropdownOpen}
        toggle={() => this.toggle()}
      >
        <DropdownToggle tag='div' className='w-100'>
          <input
            type='text'
            ref={this.getRef(`input`)}
            className={`form-control ${inputClass}`}
            onFocus={() => {
              this.getCurrentRef(`input`).value = '';
              this.search();
            }}
            onBlur={() => {
              this.getCurrentRef(`input`).value = this.selectedShop
                ? `${this.selectedShop.name}`
                : this.getCurrentRef(`input`).value;
            }}
            onChange={e => this.search(e.target.value)}
            placeholder={placeholder}
            defaultValue={defaultValue}
          />
        </DropdownToggle>
        {this.state.dropdownOpen && this.collections.length > 0 && (
          <DropdownMenu
            className='w-100'
            style={{ top: '100% !important', transform: 'none' }}
          >
            {this.collections.map(item => {
              return (
                <DropdownItem key={item.id} onClick={() => this.select(item)}>
                  {item.name}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        )}
      </ButtonDropdown>
    );
  }
}
