import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate as t } from '../../shared/translate';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { GET_QUERY } from './query';

const hkDistricts = t.districts;

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.venue }],
    gql: { get: GET_QUERY },
    createUrl: '/venues/new',
    fields: [
      {
        title: t.venue_name,
        fieldName: 'name',
        type: 'text',
        render: item => (
          <Link
            to={{
              pathname: '/venues/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            {item.name}
          </Link>
        ),
        filter: true,
        sortable: 'name',
      },
      {
        title: t.district,
        fieldName: 'districts',
        type: 'multiple-option',
        align: 'center',
        options: Object.keys(hkDistricts).map(key => ({
          name: hkDistricts[key],
          value: key,
        })),
        render: item => hkDistricts[item.district],
        filter: true,
        sortable: 'districts',
      },
      {
        title: t.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        filter: true,
        width: 120,
        sortable: 'createdAt',
      },
      {
        title: t.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        align: 'right',
        filter: true,
        width: 120,
        sortable: 'updatedAt',
      },
    ],
  };
  getExtraFetchVariables() {
    return { shopId: localStorage.getItem('shopId') };
  }
  getData = (data = {}) => {
    const { node } = data || {},
      { items = [], count = 0 } = node || {};
    return { list: items, count };
  };
}
