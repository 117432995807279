import React, { Fragment } from 'react';
import PromisifyModal from 'components/promisifyModal';
import { translate } from 'shared/translate';
import { Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

export default class _PriceCalculateModal extends PromisifyModal {
  state = {
    ...this.state,
    base: 0,
    variations: [],
    settings: [],
  };
  onExecute = variations => {
    const tmp = {};
    variations.forEach(({ attributes = [] }) => {
      attributes.forEach(({ key, value }) => {
        if (!tmp[key]) tmp[key] = [];
        if (!~tmp[key].indexOf(value)) tmp[key].push(value);
      });
    });
    const settings = Object.keys(tmp).map(key => ({
      key,
      option: tmp[key].reduce((reducer, tag) => ({ ...reducer, [tag]: 0 }), {}),
    }));
    this.setState({
      settings,
      variations,
    });
  };
  onConfirm = async () => {
    const { base, settings, variations } = this.state;

    return variations.map(variation => {
      const { attributes = [] } = variation;

      variation.price = attributes.reduce((reducer, { key, value }) => {
        const setting = settings.find(setting => setting.key === key);
        if (!!setting) {
          const adjustment = (setting.option || {})[value] - 0 || 0;
          reducer += adjustment;
        }
        return reducer;
      }, base - 0);

      return variation;
    });
  };

  renderHeader() {
    return translate.calculate_price;
  }
  renderBody() {
    const { base, settings } = this.state;
    return (
      <Fragment>
        <Form>
          <FormGroup>
            <Label>{translate.base_price}</Label>
            <InputGroup>
              <InputGroupAddon addonType={'prepend'}>$</InputGroupAddon>
              <Input
                type={'number'}
                step={0.01}
                min={0}
                value={base}
                onChange={e => this.setState({ base: e.target.value })}
              />
            </InputGroup>
          </FormGroup>
        </Form>
        <table className={'table'}>
          <colgroup>
            <col width="200" />
            <col width="200" />
            <col width="200" />
            <col width="200" />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={2}>{translate.product_variants}</th>
              <th>{translate.adjustment}</th>
              <th>{translate.price}</th>
            </tr>
          </thead>
          <tbody>
            {settings.map(({ key, option = {} }, i) =>
              (Object.keys(option) || []).map((_key, j) => {
                const value = option[_key];
                const isFirstRow = j === 0;
                return (
                  <tr key={`${i}-${j}`}>
                    <td className={!isFirstRow && 'border-0'}>{isFirstRow && key}</td>
                    <td className={!isFirstRow && 'border-0'}>{_key}</td>
                    <td className={!isFirstRow && 'border-0'}>
                      <InputGroup size={'sm'}>
                        <InputGroupAddon addonType={'prepend'}>$</InputGroupAddon>
                        <Input
                          type={'number'}
                          step={0.01}
                          value={value}
                          onChange={e => {
                            option[_key] = e.target.value;
                            this.setState({});
                          }}
                        />
                      </InputGroup>
                    </td>
                    <td className={!isFirstRow && 'border-0'}>$ {option[_key] - 0 + (base - 0)}</td>
                  </tr>
                );
              }),
            )}
          </tbody>
        </table>
      </Fragment>
    );
  }
}
