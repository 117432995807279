import React, { Component, Fragment } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { translate } from 'shared/translate';

export default class PromisifyModal extends Component {
  state = {
    show: false,
    resolve: undefined,
    reject: undefined,
  };

  /* You Need to overwrite this function */
  onExecute = () => {};

  /* You Need to overwrite this function */
  onConfirm = async () => {
    return true;
  };

  /* You Need to overwrite this function */
  onCancel = async () => {
    return new Error('User Cancelled');
  };

  /* You Need to overwrite this function */
  renderHeader() {
    return 'Modal Title';
  }

  /* You Need to overwrite this function */
  renderBody() {
    return null;
  }

  execute = (...args) => {
    return new Promise((resolve, reject) => {
      this.setState({ resolve, reject, show: true });
      this.onExecute(...args);
    });
  };

  onCancelClick = async () => {
    const { reject } = this.state;
    reject && reject(await this.onCancel());
    this.setState({ show: false, reject: undefined, resolve: undefined });
  };

  onConfirmClick = async () => {
    const { reject, resolve } = this.state;
    try {
      resolve && resolve(await this.onConfirm());
    } catch (e) {
      reject && reject(e);
    } finally {
      this.setState({ show: false, resolve: undefined, reject: undefined });
    }
  };

  render() {
    const { show } = this.state;
    return (
      <Modal isOpen={show}>
        <ModalHeader>{this.renderHeader()}</ModalHeader>
        <ModalBody>{this.renderBody()}</ModalBody>
        <ModalFooter className={'justify-content-between'}>{this.renderFooter()}</ModalFooter>
      </Modal>
    );
  }

  renderFooter() {
    return (
      <Fragment>
        <button type={'button'} className={'btn btn-light'} onClick={this.onCancelClick}>
          {this.renderCancelText()}
        </button>
        <button type={'button'} className={'btn btn-primary'} onClick={this.onConfirmClick}>
          {this.renderConfirmText()}
        </button>
      </Fragment>
    );
  }
  renderCancelText() {
    return translate.cancel;
  }
  renderConfirmText() {
    return translate.select;
  }
}
