import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { translate as t } from '../../shared/translate';
import { H6CardHeader, FormRow } from '../../components/omniwe';
import TinyComponent from '../../components/tinyComponent';

export default ({ contentHtml, loading, onChange }) => {
  // const handleContentChanged = e => {
  //   console.log('tinymce content changed', e);
  //   //this.content = e.target.getContent();
  // };

  return (
    <Card>
      <H6CardHeader text={t.promotion_details} />
      <CardBody className={'p-0'}>
        <TinyComponent
          value={contentHtml}
          disabled={loading}
          onChange={onChange}
        />
      </CardBody>
    </Card>
  );
};
