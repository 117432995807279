import React from 'react';
import { Query } from 'react-apollo';
import { Form } from 'reactstrap';
import _ from 'lodash';
import { translate as t } from '../../shared/translate';
import FormBase from '../../components/form';
import { Breadcrumb, FormToolbar, Loading, PageBody, toast } from '../../components/omniwe';
import Details from './Details';
import ContentItems from './ContentItems';
import TermItems from './TermItems';
import PromotionDetails from './PromotionDetails';
import Content from './Content';
import { DETAILS_QUERY, SET_QUERY, GET_PDF_DOWNLOAD_HREF, UNSET_CUSTOM_PAGE } from './query';
import ObjectNotFound from '../../components/pageComponents/ObjectNotFound';
import { client } from '../../shared/apollo';
import errorParser from 'shared/errorParser';

export default class PromotionItemIndex extends FormBase {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.listUrl = '/promotions';
    this.title = t.promotion;
    this.currentId = id === 'new' ? undefined : id;
    this.breadcrumbs = [{ text: this.title, url: this.listUrl }, this.currentId ? this.currentId : t.create];
    this.variables = { id: this.currentId };
    // this.venueSelectRef = React.createRef();
    this.promotionFormItems = null;
    this.termItems = null;

    // this.tinyMceHtmls = {
    //   content: null,
    //   promotionDetails: null
    // };
    this.bodyConfigs = {
      hc_promotion_form: {
        showTerms: false,
        terms: '',
        inputs: undefined,
        checkboxes: undefined,
      },
      htmls: { content: '' },
    };
    this.shopId = localStorage.getItem('shopId');
  }

  // handleTinyMceChanged = (e, name) => {
  //   console.log('handleTinyMceChanged', name);
  //   console.log('handleTinyMceChanged html', e.target.getContent());
  //   this.tinyMceHtmls[name] = e.target.getContent();
  // };

  onRemoveClick = async () => {
    const { history } = this.props;
    try {
      await client.mutate({
        mutation: UNSET_CUSTOM_PAGE,
        variables: { id: this.currentId },
      });
      toast.success('成功刪除優惠');
      history.push('/promotions');
    } catch (e) {
      toast.error(errorParser(e));
    }
  };

  handlePromotionFormItemsChanged = items => {
    console.log('handlePromotionFormItemsChanged', items);
    this.promotionFormItems = items;
  };

  handleTermItemsChanged = items => {
    console.log('handleTermItemsChanged', items);
    this.termItems = items;
  };

  genHtmlBody = () => {
    let configObj = {
      content: this.bodyConfigs.htmls.content,
    };
    return { name: 'html', config: JSON.stringify(configObj) };
  };

  genPromotionFormBody = () => {
    let configObj = {
      inputs: this.promotionFormItems,
      showTerms: this.getCurrentRef('promotionDetails_showTerms').checked,
      terms: this.bodyConfigs.hc_promotion_form.terms,
      checkboxes: this.termItems,
    };
    return { name: 'hc_promotion_form', config: JSON.stringify(configObj) };
  };

  genBody = () => {
    return [this.genHtmlBody(), this.genPromotionFormBody()];
  };

  validateBody = () => {
    const promFormNames = this.promotionFormItems.map(x => x.name);
    const termNames = this.termItems.map(x => x.name);
    let promFormDuplicateNames = [];
    let termDuplicateNames = [];
    let doubleDuplicateNames = [];

    // console.log('promFormNames', promFormNames);
    // console.log('uniq key', _.uniq(promFormNames));

    _.uniq(promFormNames).map(item => {
      console.log('map', item);
      if (_.filter(promFormNames, x => x === item).length > 1) {
        promFormDuplicateNames.push(item);
      }
    });

    _.uniq(termNames).map(item => {
      if (_.filter(termNames, x => x === item).length > 1) {
        termDuplicateNames.push(item);
      }

      if (_.filter(promFormNames, x => x === item).length > 0) {
        doubleDuplicateNames.push(item);
      }
    });

    if (!_.size(promFormDuplicateNames) && !_.size(termDuplicateNames) && !_.size(doubleDuplicateNames)) {
      return true;
    }

    toast.warn(
      <div>
        {_.size(promFormDuplicateNames) > 0 && <p>{t.prom_form_item_name_duplicate}</p>}
        {promFormDuplicateNames.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
        {_.size(termDuplicateNames) > 0 && <p>{t.term_item_name_duplicate}</p>}
        {termDuplicateNames.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
        {_.size(doubleDuplicateNames) > 0 && <p>{t.double_name_duplicate}</p>}
        {doubleDuplicateNames.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </div>,
    );
    return false;
  };

  handleSubmit = async (e, client) => {
    const { history } = this.props;
    e.preventDefault();
    console.log('handle submit');

    let input = {
      shopId: this.shopId,
      group: 'hc_promotion',
      ...this.getFormModel(['name', 'href', 'active', 'display']),
      attributes: [
        {
          key: 'hc_promotion',
          value: 'true',
        },
      ],
      body: this.genBody(),
    };

    if (!this.validateBody()) {
      return;
    }
    const variables = {
      id: this.currentId,
      input,
    };
    await client.mutate({
      mutation: SET_QUERY,
      variables,
    });
    if (this.currentId) {
      toast.success(t.update_success);
    } else {
      toast.success(t.create_success);
    }
    history.push(this.listUrl);
  };

  handleDownload = async () => {
    try {
      const {
        data: { promotionRecordExport },
      } = await client.mutate({
        mutation: GET_PDF_DOWNLOAD_HREF,
        variables: { id: this.currentId },
      });
      window.open(promotionRecordExport, '_blank');
    } catch (e) {
      toast.error(t.download_fail);
    }
  };

  pushBody = node => {
    const { body } = node || {};
    if (!body) {
      this.bodyConfigs.hc_promotion_form = {};
      this.bodyConfigs.htmls = {};
      return;
    }

    const hc_promotion_form = _.find(body, { name: 'hc_promotion_form' }) || {};
    this.bodyConfigs.hc_promotion_form = JSON.parse(hc_promotion_form.config) || {};

    const htmls = _.find(body, { name: 'html' }) || {};
    this.bodyConfigs.htmls = JSON.parse(htmls.config) || {};
  };

  // getPromFormItems = node => {
  //   const { body } = node || {};
  //   if (!body) {
  //     return null;
  //   }
  //
  //   const found = _.find(body, { name: 'hc_promotion_form' });
  // };

  render() {
    const { history, match } = this.props;
    return (
      <PageBody>
        <Breadcrumb items={this.breadcrumbs} />
        <Query query={DETAILS_QUERY} variables={this.variables} skip={!this.currentId}>
          {({ loading, client, data: { node } = {} }) => {
            if (loading) {
              return <Loading />;
            }
            if (!loading && !node && this.currentId) {
              return <ObjectNotFound />;
            }
            if (!loading) {
              node = node || {};
              this.pushBody(node);
              return (
                <Form onSubmit={e => this.handleSubmit(e, client)}>
                  <Details
                    {...node}
                    onRemoveClick={this.onRemoveClick}
                    onDownloadClick={this.handleDownload}
                    getRef={name => this.getRef(name)}
                    getFormModel={this.getFormModel}
                    currentId={this.currentId}
                  />
                  <Content
                    contentHtml={this.bodyConfigs.htmls.content || ''}
                    onChange={c => (this.bodyConfigs.htmls.content = c)}
                  />
                  <ContentItems
                    items={this.bodyConfigs.hc_promotion_form.inputs}
                    shopId={this.shopId}
                    onChange={this.handlePromotionFormItemsChanged}
                  />
                  <PromotionDetails
                    visible={this.bodyConfigs.hc_promotion_form.showTerms}
                    contentHtml={this.bodyConfigs.hc_promotion_form.terms || ''}
                    getRef={name => this.getRef(name)}
                    onChange={c => (this.bodyConfigs.hc_promotion_form.terms = c)}
                  />
                  <TermItems
                    items={this.bodyConfigs.hc_promotion_form.checkboxes}
                    shopId={this.shopId}
                    onChange={this.handleTermItemsChanged}
                  />
                  <FormToolbar
                    onCancel={() => history.push(this.listUrl)}
                    submitBtnType="submit"
                    updatedAt={node.updatedAt}
                  />
                </Form>
              );
            }
          }}
        </Query>
      </PageBody>
    );
  }
}
