import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import moment from 'moment';
import _ from 'lodash';

import { client } from '../../shared/apollo';
import Loading from '../../components/loading';
import Route from '../../components/route';
import { translate as t } from '../../shared/translate';
import GenericForm from '../../components/GenericForm';

import SingleCheckbox from '../../components/form/SingleCheckbox';
import HorizontalField from '../../components/form/horizontal-field';
import { setAttributes } from '../../shared';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on User {
        groups {
          shop {
            id
          }
          company {
            id
          }
          name
        }
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const POST_QUERY = gql`
  mutation userSetForAdmin($id: ID, $input: UserSetInput!) {
    response: userSetForAdmin(id: $id, input: $input) {
      id
      groups {
        shop {
          id
        }
        company {
          id
        }
        name
      }
      attributes {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;

const REMOVE_QUERY = gql`
  mutation userUnset($id: ID!) {
    response: userUnset(id: $id) {
      id
    }
  }
`;

const groupOptions = [
  'Administrators',
  'Agents',
  'AgentShops',
  'ApplicationAgents',
  'Customers',
  'Owners',
  'HC_Owners',
  'HC_Vendors',
];

const noNeedIds = ['Administrators', 'Agents'];

class UserItem extends React.Component {
  constructor(props) {
    super(props);

    console.log(this.props);

    const { match: { params = {} } = {} } = this.props;

    this.state = {
      showRemoveBtn: false,
      currentId: params.id === 'new' ? undefined : params.id,
    };
  }

  async removeItem(client) {
    const { history } = this.props;
    try {
      await client.mutate({
        mutation: REMOVE_QUERY,
        variables: {
          id: this.state.currentId,
        },
      });
      client.resetStore();
      toast.success(t.custom_page_removed_msg);
      history.replace('/custom_pages');
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  async submit(values, actions) {
    let attributes = await setAttributes(values, ['name']);
    actions.setSubmitting(true);

    try {
      const { data: { response } = {} } = await client.mutate({
        mutation: POST_QUERY,
        variables: {
          id: this.state.currentId,
          input: {
            userData: {
              attributes: attributes,
            },
            groups: values.groups.reduce((prev, { shop, company, name }) => {
              const { id: shopId } = shop || {};

              const { id: companyId } = company || {};

              return [
                ...prev,
                {
                  shopId: noNeedIds.indexOf(name) === -1 ? shopId : undefined,
                  companyId: noNeedIds.indexOf(name) === -1 ? companyId : undefined,
                  name,
                },
              ];
            }, []),
          },
        },
      });
      const { id } = response;
      toast.success(!this.state.currentId ? t.coupon_added_success : t.coupon_updated_success);
      if (!this.state.currentId) {
        this.props.history.replace('/users/' + id, { id });
        this.setState({ currentId: id });
      }
      client.resetStore();
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (e) {
      // GraphQL errors can be extracted here
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  render() {
    return (
      <Query query={GET_QUERY} variables={{ id: this.state.currentId }} fetchPolicy="network-only">
        {({ client, loading, error: { message } = {}, data, data: { node } = {} }) => {
          if (loading && data !== {}) return <Loading />;

          if (message) {
            return (
              <div>
                <h5>Error :(</h5>
                <p>{message}</p>
                <Route {...this.props} text={t.back} />
              </div>
            );
          }

          const { groups = [], credentiails = [], attributes = [], updatedAt } = node || {};

          const { name: [{ value: name } = {}] = [] } = _.groupBy(attributes, 'key');

          return (
            <GenericForm
              breadcrumb={[
                {
                  route: '/users',
                  label: t.user_list,
                },
                {
                  label: this.state.currentId || t.coupon_add,
                },
              ]}
            >
              <Formik
                initialValues={{
                  name,
                  groups: groups.reduce((prev, { shop, company, name }) => [...prev, { shop, company, name }], []),
                }}
                onSubmit={(values, actions) => this.submit(values, actions)}
                enableReinitialize={true}
              >
                {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="card mb-5 shadow">
                        <div className="card-header d-flex">
                          <h6 className="mb-0 flex-fill">{t.user_list}</h6>
                          {this.state.currentId && (
                            <div className="btn-group btn-group-sm">
                              {this.state.showRemoveBtn && (
                                <button
                                  className="btn btn-danger no-animate ng-scope"
                                  type="button"
                                  onClick={() => this.removeItem(client)}
                                >
                                  <i className="fa fa-fw fa-exclamation-circle" />
                                  <span className="ng-scope">{t.user_delete}</span>
                                </button>
                              )}
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() =>
                                  this.setState({
                                    showRemoveBtn: !this.state.showRemoveBtn,
                                  })
                                }
                              >
                                {!this.state.showRemoveBtn && <i className="fa fa-caret-left" />}
                                {this.state.showRemoveBtn && <i className="fa fa-caret-right" />}
                                <i className="fa fa-fw fa-trash" />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="card-body">
                          <HorizontalField label={t.name}>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                          </HorizontalField>
                        </div>
                      </div>

                      <div className="card mb-5 shadow">
                        <div className="card-header d-flex">
                          <h6 className="mb-0 flex-fill">{t.user_groups || 'User Groups'}</h6>
                          <button
                            className="btn btn-light"
                            type="button"
                            onClick={() =>
                              setFieldValue('groups', [
                                ...values.groups,
                                {
                                  name: groupOptions[0],
                                  company: {},
                                  shop: {},
                                },
                              ])
                            }
                          >
                            Add
                          </button>
                        </div>
                        {values.groups.map(({ shop, company, name }, index) => {
                          const { id: shopId } = shop || {};

                          const { id: companyId } = company || {};
                          return (
                            <div
                              className={`card-body ${values.groups.length - 1 !== index ? 'border-bottom' : ''}`}
                              key={`groups_${index}`}
                            >
                              <div className="row">
                                <div className="col">
                                  <HorizontalField label={'Company ID'}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Company ID"
                                      value={companyId}
                                      required={noNeedIds.indexOf(name) === -1}
                                      onChange={({ target }) => {
                                        values.groups[index].company = { id: target.value };
                                        setFieldValue('groups', values.groups);
                                      }}
                                      disabled={isSubmitting}
                                    />
                                  </HorizontalField>
                                  <HorizontalField label={'Shop ID'}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Shop ID"
                                      value={shopId}
                                      required={noNeedIds.indexOf(name) === -1}
                                      onChange={({ target }) => {
                                        values.groups[index].shop = { id: target.value };
                                        setFieldValue('groups', values.groups);
                                      }}
                                      disabled={isSubmitting}
                                    />
                                  </HorizontalField>
                                  <HorizontalField label={'Group Name'}>
                                    <select
                                      name="Group Name"
                                      id="Group Name"
                                      className="form-control"
                                      value={name}
                                      onChange={({ target }) => {
                                        values.groups[index].name = target.value;
                                        setFieldValue('groups', values.groups);
                                      }}
                                      disabled={isSubmitting}
                                    >
                                      {groupOptions.map((value, index) => (
                                        <option value={value} key={`group_option_${index}`}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                  </HorizontalField>
                                </div>
                                <div className="col-auto">
                                  <button
                                    className="btn text-danger btn-link"
                                    type="button"
                                    disabled={values.groups.length === 1}
                                    onClick={() => {
                                      const result = values.groups.reduce(
                                        (prev, group, groupIndex) => (groupIndex === index ? prev : [...prev, data]),
                                        [],
                                      );
                                      setFieldValue('groups', result);
                                    }}
                                  >
                                    <b>X</b>
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="card">
                        <div className="card-body">
                          <Route {...this.props} text="取消" />
                          <button type="submit" className="btn btn-dark shadow pull-right">
                            {t.save}
                          </button>
                        </div>
                        {this.state.currentId && (
                          <div className="card-footer d-flex justify-content-end">
                            <i className="small text-muted">
                              {t.updated_at}:{moment(updatedAt).format(' YYYY-MM-DD HH:mm:ss')}
                            </i>
                          </div>
                        )}
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </GenericForm>
          );
        }}
      </Query>
    );
  }
}

export default UserItem;
