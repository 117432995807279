import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { translate } from '../../shared/translate';

export default ({ name }) => (
  <div className='col-md-6 mb-3'>
    <div className='card'>
      <div className='card-header text-center'>{name}</div>
      <div className='card-body'>
        <div className='embed-responsive embed-responsive-16by9 blur'>
          <div className='embed-responsive-item'>
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart data={[]}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis width={30} />
                <Tooltip />
                <Line type='monotone' dataKey='value' stroke='#82ca9d' />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          className='position-absolute h-100 w-100 d-flex justify-content-center align-items-center'
          style={{ left: 0, top: 0 }}
        >
          {translate.setup_charts}
        </div>
      </div>
    </div>
  </div>
);
