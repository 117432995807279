export const Roles = {
  Agents: "Agents",
  Administrators: "Administrators",
  Owners: "Owners"
};

export const ServiceApplicationStatus = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED"
};
