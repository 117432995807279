import React, { Fragment } from 'react';
import { translate } from '../../../shared/translate';
import ListPageMaker from '../../../components/listPageMaker';
import { GET_QUERY, REMOVE_QUERY, SET_QUERY } from './query';
import { client } from 'shared/apollo';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';
import { Button } from 'reactstrap';
import ManualNameInput from './ManualNameInput';
import { Activation, Loading } from 'components/omniwe';
import ItemRenderer from 'components/listPageMaker/_itemRenderer';
import { Formik } from 'formik';
import { Icon } from 'components/iconRender';

export default class extends ListPageMaker {
  static defaultProps = {
    className: 'p-0 mt-5',
  };
  state = {
    ...this.state,
    title: translate['custom'],
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate['name'],
        render: (item = {}) => (
          <ManualNameInput
            value={item.name}
            onChange={async name => {
              if (name !== item.name) await this._updateCredential(item, { name });
            }}
          />
        ),
      },
      {
        width: 80,
        align: 'center',
        title: translate['activate_checkbox'],
        fieldName: 'active',
        type: 'bool',
        render: (item = {}) =>
          !!item.id && (
            <Button
              color={'link'}
              onClick={async () => {
                await this._updateCredential(item, { active: !item.active });
              }}
            >
              <Activation active={item.active} />
            </Button>
          ),
      },
      {
        width: 50,
        title: translate['operations'],
        render: ({ id }) => (
          <Fragment>
            {!!id && (
              <Button
                color={'link'}
                key={`remove-${id}`}
                onClick={async () => {
                  try {
                    await client.mutate({
                      mutation: REMOVE_QUERY,
                      variables: { id },
                    });
                  } catch (e) {
                    toast.error(errorParser(e));
                  }
                }}
              >
                <Icon icon={'faTimes'} className={'text-danger'} />
              </Button>
            )}
          </Fragment>
        ),
      },
    ],
  };
  _updateCredential = async ({ id, ...args }, update = {}) => {
    delete args.attributes;
    delete args.__typename;
    try {
      await client.mutate({
        mutation: SET_QUERY,
        variables: {
          shopId: localStorage.getItem('shopId'),
          input: {
            id,
            ...args,
            ...update,
          },
        },
      });
    } catch (e) {
      toast.error(errorParser(e));
    }
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    const { credentials = [] } = node || {};
    return { list: credentials, count: credentials.length };
  };

  renderList(list) {
    const { fields } = this.state;
    return (
      <Fragment>
        <tr>
          <td colSpan={999}>
            <Formik
              initialValues={{ name: '' }}
              onSubmit={async ({ name }, { setSubmitting, resetForm }) => {
                await this._updateCredential(
                  {},
                  { name, active: true, platform: 'MANUAL', identity: name, secret: name, type: 'PAYMENT' },
                );
                this.refetch();
                resetForm();
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
                <form
                  onSubmit={e => {
                    e.stopPropagation();
                    handleSubmit(e);
                  }}
                >
                  <div className={'w-100 d-flex'}>
                    <input
                      required
                      disabled={isSubmitting}
                      className={'form-control flex-fill'}
                      value={values.name}
                      onChange={e => setFieldValue('name', e.target.value)}
                    />
                    <Button color={'link'} type={'submit'} disabled={isSubmitting}>
                      {isSubmitting ? <Loading inner={true} size={'fa-1x'} /> : <Icon icon={'faPlus'} />}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </td>
        </tr>

        {list.map((item, i) => {
          const { id } = item || {};
          return (
            <tr key={id || i}>
              {fields.map(
                ({ column = true, ...field }, i) => column && <ItemRenderer key={i} item={item} field={field} />,
              )}
            </tr>
          );
        })}
      </Fragment>
    );
  }

  renderPagination() {
    return null;
  }
}
