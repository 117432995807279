import React from 'react';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import ListPageTemplate from '../../components/listPageMaker';
import { GET_QUERY } from './query';

export default class extends ListPageTemplate {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/custom_pages/new',
    breadcrumb: [{ name: translate.custom_pages }],
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        type: 'text',
        filter: true,
        sortable: 'name',
      },
      {
        title: translate.href,
        fieldName: 'href',
        type: 'text',
        filter: true,
        render: item => (
          <Link
            to={{
              pathname: '/custom_pages/' + item.id,
              state: { variables: { id: item.id } },
            }}
          >
            /{item.group}/{item.href}
          </Link>
        ),
        sortable: 'href',
      },
      {
        title: translate.activation,
        fieldName: 'active',
        type: 'bool',
        width: 100,
        headerClassName: 'text-center',
        sortable: 'active',
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        filter: true,
        width: 180,
        headerClassName: 'text-right',
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        type: 'datetime',
        filter: true,
        width: 180,
        headerClassName: 'text-right',
        sortable: 'updatedAt',
      },
    ],
  };
  getData = ({ node } = {}) => {
    const { customPages: list = [], customPagesCount: count = 0 } = node || {};
    return { list, count };
  };
  getExtraFetchVariables() {
    return {
      id: localStorage.getItem('shopId'),
      filter: {
        ...this.filterInput,
        attributes: [
          {
            key: 'is_main',
            value: 'false',
          },
        ],
      },
    };
  }
}
