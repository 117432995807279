import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY, REMOVE_QUERY, UPDATE_QUERY } from './query';
import { DeliveryNoteStatus, ShipmentProvider } from 'shared/omniwe-types';
import AddressCard from './AddressCard';
import OrderItemCard from '../item_Order/ItemsCard';
import DeliveryNoteItemCard from './DeliveryNoteItemCard';
import { client } from 'shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ link: '/delivery_notes', label: translate.delivery_notes }, { label: this.state.id }],
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
      remove: REMOVE_QUERY,
    },
    cards: [
      {
        name: translate.card_title_delivery_note,
        fields: [
          {
            type: 'select',
            label: translate.status,
            name: 'status',
            options: Object.keys(DeliveryNoteStatus).map(key => ({ label: DeliveryNoteStatus[key], value: key })),
          },
          {
            type: 'span',
            label: translate.shipment,
            render: ({ values: { shipment } }) => (
              <span className={'form-control-plaintext'}>{ShipmentProvider[(shipment || {}).provider] || '-'}</span>
            ),
          },
          {
            type: 'text',
            label: 'Tracking Number',
            name: 'trackingNo',
          },
        ],
      },
      AddressCard,
      ({ values: { order }, ...props }) => {
        return <OrderItemCard {...props} values={order} />;
      },
      DeliveryNoteItemCard,
    ],
  };

  submit = async ({ id, status, trackingNo }) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: { id, status, trackingNo },
    });
  };

  getInitialData({ node } = {}) {
    const { id, shipment, shippingAddress, items, order, status, updatedAt, trackingNo = '' } = node || {};
    return {
      id,
      shipment,
      shippingAddress,
      order,
      status,
      updatedAt,
      items: items || [],
      trackingNo,
    };
  }
}
