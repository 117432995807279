import React from 'react';
import { gql } from 'apollo-boost';
import FormPageTemplate from '../../components/formPageMaker';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on CustomPage {
        name
        href
        active
        body {
          name
          config
        }
        group
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;
const POST_QUERY = gql`
  mutation customPageSet($id: ID, $input: CustomPageInput!) {
    customPageSet(id: $id, input: $input) {
      id
      name
      href
      attributes {
        key
        value
      }
      updatedAt
      createdAt
    }
  }
`;
const REMOVE_QUERY = gql`
  mutation customPageUnset($id: ID!) {
    customPageUnset(id: $id) {
      id
    }
  }
`;

export default class extends FormPageTemplate {
  constructor(props) {
    super(props);

    const { id } = this.state;

    this.state = {
      ...this.state,
      breadcrumb: [{ link: '/custom_pages', name: translate.custom_pages }, { name: id || translate.new_custom_page }],
      gql: {
        prepare: GET_QUERY,
        submit: POST_QUERY,
        remove: REMOVE_QUERY,
      },
      cards: [
        {
          name: translate.custom_page_details,
          fields: [
            {
              label: translate.name,
              type: 'text',
              name: 'name',
              required: true,
              maxLength: 255,
            },
            {
              label: `${translate.group} / ${translate.href}`,
              render: ({ values, handleChange }) => (
                <div className="form-control d-flex flex-row p-0">
                  <input
                    type={'text'}
                    className={'form-control border-0 col-lg-2 col-5'}
                    placeholder={translate.group}
                    value={values.group}
                    name={'group'}
                    onChange={handleChange}
                    maxLength={255}
                    required={true}
                    autoComplete={'off'}
                    list={'group'}
                  />
                  <datalist id="group">
                    <option>pages</option>
                    <option>articles</option>
                  </datalist>
                  <div className={'d-flex align-items-center'}>
                    <h4 className={'m-0'}>/</h4>
                  </div>
                  <input
                    type="text"
                    className={'form-control border-0'}
                    name="href"
                    value={values.href}
                    onChange={handleChange}
                    maxLength={255}
                    placeholder="aboutUs, promotions, series.html... etc."
                    required={true}
                  />
                </div>
              ),
            },
            {
              label: translate.activation,
              type: 'checkbox',
              name: 'active',
              text: translate.yes,
            },
          ],
        },
        {
          name: translate.content,
          type: 'html',
          field: {
            name: 'content',
          },
        },
      ],
    };
  }

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const shopId = await localStorage.getItem('shopId');
    const { name, href, group, active, content } = values;
    const {
      data: { customPageSet },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          name,
          href,
          group,
          active,
          body: [
            {
              name: 'html',
              config: JSON.stringify({ content }),
            },
          ],
          shopId,
          attributes: [
            {
              key: 'is_main',
              value: 'false',
            },
          ],
        },
      },
    });
    return customPageSet.id;
  };
  getInitialData(data) {
    const { node } = data || {},
      { name, href, group, active, body: [{ config } = {}] = [], updatedAt } = node || {};
    const { content } = JSON.parse(config || '{}') || {};
    return {
      name,
      href,
      group,
      active: active || true,
      content,
      updatedAt,
    };
  }
}
