// 列表頁錯誤提示組件
// src/components/pageComponents/PageError.jsx
// created on april-18, 2019, by Tony Day
import React from 'react';
import * as rs from 'reactstrap';

export default errors => {
  if (!errors.errors) {
    return (
      <div className='pageError'>
        <rs.Alert color='danger'>Error</rs.Alert>
        <p>{JSON.stringify(errors)}</p>
      </div>
    );
  }

  // graphql api errors
  if (errors.errors && errors.errors.networkError) {
    const errorItems = errors.errors.networkError.result.errors;
    return (
      <div className='pageError'>
        <rs.Alert color='danger'>Api Error</rs.Alert>
        <rs.ListGroup>
          {errorItems.map((item, i) => (
            <rs.ListGroupItem key={i}>{item.message}</rs.ListGroupItem>
          ))}
        </rs.ListGroup>
      </div>
    );
  }

  return <div>ERROR</div>;
};
