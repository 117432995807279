import { gql } from 'apollo-boost';

export const SESSION_CREATE = gql`
  mutation sessionCreate($identity: String!, $secret: String!) {
    sessionCreate(identity: $identity, secret: $secret)
  }
`;
export const GET_GROUPS = gql`
  query {
    me {
      id
      groups {
        name
        permissions
        shop {
          id
          stockWarehouse {
            id
          }
        }
        company {
          id
        }
      }
    }
  }
`;
