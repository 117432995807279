import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import { GET_QUERY, REMOVE_QUERY } from './query';
import { translate } from 'shared/translate';
import DoubleAddressCard from './DoubleAddressCard';
import ItemsCard from './ItemsCard';
import CouponCard from './CouponCard';
import InvoiceCard from './InvoiceCard';
import DeliveryNoteCard from './DeliveryNoteCard';
import StatusCard from './StatusCard';
import OrderComments from 'components/order_comments';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ link: '/orders', label: translate.order_details }, { label: this.state.id || translate.new_order }],
    gql: {
      prepare: GET_QUERY,
      remove: REMOVE_QUERY,
    },
    cards: [StatusCard, DoubleAddressCard, ItemsCard, CouponCard, InvoiceCard, DeliveryNoteCard],
    enableReinitialize: true,
  };

  getInitialData({ node } = {}) {
    const {
      id,
      checkout,
      referenceNo,
      shipment,
      user,
      status,
      billingAddress,
      shippingAddress,
      items,
      total,
      subtotal,
      coupons,
      invoices,
      deliveryNotes,
    } = node || {};
    return {
      id,
      orderId: id,
      checkout,
      referenceNo,
      shipment,
      user,
      status,
      billingAddress: billingAddress || {},
      shippingAddress: shippingAddress || {},
      items: items || [],
      total: total || {},
      subtotal: subtotal || {},
      coupons: coupons || [],
      invoices: invoices || [],
      deliveryNotes: deliveryNotes || [],
    };
  }

  renderForm(initialValues) {
    const { id } = this.state;
    return (
      <div className={'row'}>
        <div className={'col'}>{super.renderForm(initialValues)}</div>
        <div className="col-md-4">
          <OrderComments orderId={id} />
        </div>
      </div>
    );
  }

  renderFormToolBar() {
    return null;
  }
}
