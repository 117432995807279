import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on DM_SubscriptionPlan {
        name
        amount
        interval
        currency
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_QUERY = gql`
  mutation($input: DM_PlanCreateInput!) {
    result: dm_planCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation($input: DM_PlanUpdateInput!) {
    result: dm_planUpdate(input: $input) {
      id
      name
    }
  }
`;
