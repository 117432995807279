import React from "react";
import FilterRowBase from "./FilterRowBase";
import FilterRowBaseComponent from "./FilterRowBaseComponent";

export default class UserComponent extends FilterRowBase {
  state = {
    componentValues: null,
  };

  componentDidUpdate() {
    // console.log("usercomponent did update");
    const { componentValues } = this.state;
    const { onValueChanged, visible } = this.props;
    onValueChanged(visible ? componentValues : null);
  }

  render() {
    const { title, visible, toggleVisible, component } = this.props;
    return (
      <FilterRowBaseComponent
        title={title}
        visible={visible}
        toggleVisible={toggleVisible}
      >
        {component(values =>
          this.handleValueChanged(values, "componentValues")
        )}
      </FilterRowBaseComponent>
    );
  }
}
