import React from 'react';
import PromisifyModal from 'components/promisifyModal';
import { translate } from '../../../shared/translate';
import SearchComboStockItem from '../../../components/SearchComboStockItem';

export default class _StockItemModal extends PromisifyModal {
  state = {
    ...this.state,
    stockItems: [],
  };
  onExecute = stockItems => {
    this.setState({ stockItems: stockItems.length === 0 ? [{}] : stockItems });
  };
  onCancel = () => {
    this.setState({ stockItems: [] });
  };
  onConfirm = () => {
    const { stockItems } = this.state;
    return stockItems
      .map(stockItem => ({ ...stockItem, stockItemId: stockItem.stockItemId || stockItem.id }))
      .filter(stockItem => stockItem.stockItemId);
  };
  renderHeader() {
    return translate.stock_items;
  }
  renderBody() {
    const { stockItems } = this.state;
    return (
      <table className="table">
        <colgroup>
          <col width="*" />
          <col width="120" />
          <col width="120" />
        </colgroup>
        <thead>
          <tr>
            <th>{translate.name}</th>
            <th colSpan={99}>{translate.cost}</th>
          </tr>
        </thead>
        <tbody>
          {stockItems.map((stockItem, i) => (
            <tr key={i}>
              <td>
                <SearchComboStockItem
                  selected={stockItem}
                  onChange={_stockItem => {
                    Object.assign(stockItem, _stockItem);
                  }}
                />
              </td>
              <td>
                <input
                  className={'form-control'}
                  value={stockItem.cost}
                  type={'number'}
                  onChange={e => {
                    stockItem.cost = e.target.value;
                    this.setState({});
                  }}
                />
              </td>
              <td>
                <button
                  className={'btn btn-link text-danger btn-sm'}
                  onClick={() => {
                    stockItems.splice(i, 1);
                    this.setState({});
                  }}
                >
                  <i className={'fa fa-times'} />
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={99}>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => {
                  stockItems.push({});
                  this.setState({});
                }}
              >
                {translate.add}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
_StockItemModal.propTypes = {};
