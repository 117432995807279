import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataFormatter from './../../../../shared/data-formatter';

class MessageItem extends Component {
  render() {
    const { author, message, date, sender } = this.props;
    return (
      <div
        className={`p-3 badge badge-${
          sender ? 'primary' : 'secondary'
        } mb-3 align-self-${sender ? 'start' : 'end'}`}
      >
        <div className='text-left'>{author}</div>
        <div className='my-3 text-left'>{message}</div>
        <div className='text-muted text-right'>
          {DataFormatter.fromIsoDate(date)}
        </div>
      </div>
    );
  }
}

MessageItem.propTypes = {
  sender: PropTypes.bool
};

MessageItem.defaultProps = {
  sender: false
};

export default MessageItem;
