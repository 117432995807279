import React from 'react';
import CardHeader from 'components/layout/card/CardHeader';
import AddressBody from 'components/layout/card/address/AddressBody';
import Card from 'components/layout/card/Card';
import { translate } from 'shared/translate';

export default ({ values: { shippingAddress } }) => {
  return (
    <Card>
      <CardHeader title={translate.card_title_ship_to_address} />
      <div className="card-body">
        <AddressBody data={shippingAddress} />
      </div>
    </Card>
  );
};
