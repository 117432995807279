import React, { useRef } from 'react';
import CardHeader from 'components/layout/card/CardHeader';
import { Card, CardBody } from 'reactstrap';
import { translate } from 'shared/translate';
import { FormRow } from 'components/omniwe';
import { OrderStatus, ShipmentProvider } from 'shared/omniwe-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ActionButton from 'components/form/ActionButton';
import DangerButton from 'components/form/DangerButton';
import CancelModal from './CancelModal';
import { client } from 'shared/apollo';
import { CHECKOUT_CONFIRM_QUERY, COMPLETE_QUERY, REMOVE_QUERY } from './query';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';

export default ({ values: { id, referenceNo, status, shipment, user, checkout } }) => {
  const cancelModal = useRef(null);
  const { id: checkoutId, status: checkoutStatus } = checkout || {};
  return (
    <Card>
      <CardHeader
        title={translate.order_details}
        extra={
          <div className="d-flex flex-row align-items-center">
            {!!~['READY_FOR_PAYMENT'].indexOf(checkoutStatus) && (
              <div className={'mr-2'}>
                <ActionButton
                  theme="btn-dark btn-sm"
                  onClick={async () => {
                    try {
                      await client.mutate({
                        mutation: CHECKOUT_CONFIRM_QUERY,
                        variables: { id: checkoutId },
                        optimisticResponse: {
                          __typename: 'Mutation',
                          checkoutConfirm: {
                            __typename: 'Checkout',
                            id: checkoutId,
                            status: 'CONFIRMED',
                          },
                        },
                      });
                      await client.reFetchObservableQueries(false);
                    } catch (e) {
                      toast.error(errorParser(e));
                    }
                  }}
                >
                  {translate.confirm}
                </ActionButton>
              </div>
            )}
            {!!~['OPEN', 'PROCESSING'].indexOf(status) && !!~['CONFIRMED'].indexOf(checkoutStatus) && (
              <div className={'mr-2'}>
                <ActionButton
                  theme="btn-primary btn-sm"
                  onClick={async () => {
                    try {
                      await client.mutate({
                        mutation: COMPLETE_QUERY,
                        variables: { id },
                        optimisticResponse: {
                          __typename: 'Mutation',
                          orderComplete: {
                            __typename: 'Order',
                            id,
                            status: 'COMPLETED',
                          },
                        },
                      });
                      await client.reFetchObservableQueries(false);
                    } catch (e) {
                      toast.error(errorParser(e));
                    }
                  }}
                >
                  {translate.status_completed}
                </ActionButton>
              </div>
            )}
            {!~['CANCELLED'].indexOf(status) && (
              <div className="mr-2">
                <DangerButton
                  onClick={async () => {
                    try {
                      const reason = await cancelModal.current.execute();
                      await client.mutate({
                        mutation: REMOVE_QUERY,
                        variables: {
                          id,
                          reason,
                        },
                      });
                      toast.success(translate.order_removed_msg);
                      await client.reFetchObservableQueries(false);
                    } catch (e) {
                      toast.error(errorParser(e));
                    }
                  }}
                  icon="faTrash"
                  label={translate.cancel}
                />
                <CancelModal ref={cancelModal} />
              </div>
            )}
            <div className="mr-2">
              <ActionButton
                onClick={() => {
                  window && window.open(`/print_orders/${id}`, '_blank');
                }}
                theme="btn-dark btn-sm"
              >
                {translate.button_print}
              </ActionButton>
            </div>
          </div>
        }
      />
      <CardBody>
        <FormRow title={translate.order_number}>{referenceNo}</FormRow>
        <FormRow title={translate.status}>
          <select className={'form-control'} value={status} disabled={true}>
            {Object.keys(OrderStatus).map(key => (
              <option key={key} value={key}>
                {OrderStatus[key]}
              </option>
            ))}
          </select>
        </FormRow>
        <FormRow title={translate.shipment}>{ShipmentProvider[(shipment || {}).provider] || '-'}</FormRow>
        <FormRow title={translate.customer_details}>
          {(() => {
            const { id, attributes = [] } = user || {};
            const { name: [{ value: name } = {}] = [] } = _.groupBy(attributes, 'key');

            return id ? (
              <Link to={`/customers/${id}`}>
                <span>{name}</span>
              </Link>
            ) : (
              <span>-</span>
            );
          })()}
        </FormRow>
      </CardBody>
    </Card>
  );
};
