import React from 'react';
import {
  Form,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label
} from 'reactstrap';
import _ from 'lodash';
import RRule from 'rrule';
import { Mutation } from 'react-apollo';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { translate as t } from '../../../shared/translate';
import {
  FormRow,
  ActionButton,
  Button,
  toast
} from '../../../components/omniwe';
import WeeklyRepeat from './WeeklyRepeat';
import UnsetVenueSlot from './UnsetVenueSlot';
import { gql } from 'apollo-boost';
import errorParser from '../../../shared/errorParser';

const SLOT_POST_QUERY = gql`
  # VENUE SLOT SET
  mutation($slotId: ID, $input: VenueSlotInput!, $updateAll: Boolean) {
    venueSlotSet(id: $slotId, input: $input, updateAll: $updateAll) {
      id
      createdAt
      updatedAt
      startedAt
      capacity
      startedThru
      recurrence
    }
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);

    const { venueId } = this.props;
    this.venueId = venueId;
    this.state = {
      open: false
    };
  }

  init = currentSlot => {
    this.currentSlot = currentSlot;
    if (currentSlot.id) {
      this.setState(this.reducerSlot(currentSlot));
    } else {
      this.setState({
        open: false,
        startedAt: currentSlot.startedAt,
        capacity: 1,
        repeat: 'no_repeat',
        showCustom: false,
        repeatInterval: '30',
        repeatOption: 'DAILY',
        weeklyRepeatVisible: false,
        monthlyRepeatVisible: false,
        byWeekday: [this.getCurrentWeekday()],
        byMonthDay: new Date(this.currentSlot.startedAt).getDate()
      });
    }
  };

  /* rrule sunday: 6, monday: 0
   but js system is: sunday: 0, monday:1
   so, transform js value to rrule value
   */
  getCurrentWeekday = () => {
    const weekday = new Date(this.currentSlot.startedAt).getDay();
    if (weekday === 0) {
      return 6;
    }

    return weekday - 1;
  };

  reducerSlot = rawSlot => {
    let obj = {
      open: false,
      startedAt:
        typeof rawSlot.startedAt === 'string'
          ? new Date(rawSlot.startedAt)
          : rawSlot.startedAt,
      capacity: rawSlot.capacity,
      repeat: 'no_repeat',
      showCustom: false,
      repeatInterval: '30',
      repeatOption: 'DAILY',
      weeklyRepeatVisible: false,
      monthlyRepeatVisible: false,
      byWeekday: [this.getCurrentWeekday()],
      byMonthDay: new Date(this.currentSlot.startedAt).getDate(),
      updateAll: false
    };

    const rruleStr = _.first(rawSlot.recurrence) || '';
    if (rruleStr) {
      const rruleObj = RRule.fromString(rruleStr);

      console.log('r rule obj', rruleObj.options);

      const byMonthdayFirst = _.first(rruleObj.options.bymonthday);
      if (byMonthdayFirst) {
        obj.byMonthDay = byMonthdayFirst;
      }

      if (_.size(rruleObj.options.byweekday)) {
        obj.byWeekday = rruleObj.options.byweekday;
      }

      // console.log('week', RRule.);

      obj.repeat = 'custom';
      obj.repeatInterval = rruleObj.options.interval;
      obj.repeatOption = RRule.FREQUENCIES[rruleObj.options.freq];
      this.updateRepeatState(obj);
      // switch (obj.repeatOption) {
      //   case 'WEEKLY':
      //     break;
      //   case 'MONTHLY':
      //     console.log('freq MONTHLY');
      //     break;
      // }
      // this.updateRepeatVisible(obj, 'repeatOption', obj.repeatOption);
      //
      // console.log(' RRule.FREQUENCIES', RRule.FREQUENCIES);
      //
      // console.log('rrule obj', rruleObj.options);
      // console.log('rrule obj', rruleObj.toText());
    }

    return obj;
  };

  handleWeeklyRepeatOptionChanged = day => {
    const { byWeekday } = this.state;
    const newItems = [...byWeekday];
    if (!_.isNil(_.find(newItems, x => x === day))) {
      _.remove(newItems, x => x === day);
    } else {
      newItems.push(day);
    }

    // console.log('new byWeekday', newItems);
    this.setState({ byWeekday: newItems });
  };

  handleTimeChange = (time, name) => {
    // console.log(time);
    this.setState({ [name]: time });
  };

  updateRepeatState = newState => {
    newState.showCustom = newState.repeat === 'custom';
    newState.weeklyRepeatVisible = newState.repeatOption === 'WEEKLY';
    newState.monthlyRepeatVisible = newState.repeatOption === 'MONTHLY';
  };

  handleChange = (e, name) => {
    const {
      target: { value }
    } = e;
    // let newState = { [name]: value };

    let newState = _.cloneDeep(this.state);
    newState[name] = value;
    this.updateRepeatState(newState);
    this.setState(newState);
  };

  toggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  genRecurrence = () => {
    const {
      repeatInterval: interval,
      repeatOption: freq,
      repeat,
      byWeekday,
      byMonthDay
    } = this.state;

    if (repeat !== 'custom') {
      return '';
    }

    let options = { interval, freq: RRule[freq], wkst: RRule.SU };

    if (freq === 'WEEKLY') {
      options.byweekday = byWeekday;
    }

    if (freq === 'MONTHLY') {
      options.bymonthday = [byMonthDay];
    }

    const rule = new RRule(options);
    const str = rule.toString();
    return str;
  };

  handleSubmit = async (e, post) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('slot handle submit');
    const { onChange } = this.props;
    const { startedAt, capacity, updateAll } = this.state;
    const recurrence = this.genRecurrence();

    const input = { venueId: this.venueId, startedAt, capacity: capacity - 0, recurrence };
    const variables = {
      slotId: this.currentSlot ? this.currentSlot.id : null,
      input,
      updateAll
    };

    try {
      await post({
        variables
      });

      if (this.currentSlot.id) {
        toast.success(t.update_success);
      } else {
        toast.success(t.create_success);
      }

      this.toggle();
      onChange && onChange();
    }catch(e){
      toast.error(errorParser(e));
    }
  };

  render() {
    const { currentSlot, onChange } = this.props;
    const {
      open,
      startedAt,
      capacity,
      repeat,
      showCustom,
      repeatInterval,
      repeatOption,
      weeklyRepeatVisible,
      monthlyRepeatVisible,
      byWeekday,
      byMonthDay,
      updateAll
    } = this.state;

    if (!this.currentSlot) return null;

    return (
      <Mutation mutation={SLOT_POST_QUERY}>
        {(post, { loading }) => (
          <Modal isOpen={open}>
            <Form onSubmit={e => this.handleSubmit(e, post)}>
              <ModalHeader className='omniwe-modal-title'>
                <span>{t.schedule_info}</span>
                {this.currentSlot && this.currentSlot.id && (
                  <UnsetVenueSlot
                    updateAll={updateAll}
                    slotId={this.currentSlot.id}
                    afterDeleted={() => {
                      this.toggle();
                      onChange && onChange();
                    }}
                  />
                )}
              </ModalHeader>
              <ModalBody>
                <FormRow labelMd={3} title={t.begin_time} htmlFor='startedAt'>
                  <DatePicker
                    className='form-control '
                    selected={startedAt}
                    onChange={time => this.handleTimeChange(time, 'startedAt')}
                    timeCaption={t.time}
                    showTimeSelect
                    timeFormat='HH:mm'
                    timeIntervals={15}
                    dateFormat='MMMM d, yyyy h:mm aa'
                    required
                  />
                </FormRow>
                <FormRow
                  labelMd={3}
                  title={t.people_capacity}
                  htmlFor='capacity'
                >
                  <Input
                    value={capacity}
                    onChange={e => this.handleChange(e, 'capacity')}
                    type='number'
                    id='capacity'
                    step={1}
                    maxLength={3}
                    required
                  />
                </FormRow>
                {!!this.currentSlot && !!this.currentSlot.id && (
                  <FormRow labelMd={3} title={' '}>
                    <div className='form-check pt-2'>
                      <Input
                        type='checkbox'
                        id={'updateAll'}
                        value={updateAll}
                        onChange={e =>
                          this.handleChange(
                            { target: { value: e.target.checked } },
                            'updateAll'
                          )
                        }
                      />
                      <Label htmlFor={'updateAll'}>{t.update_all}</Label>
                    </div>
                  </FormRow>
                )}
                <FormRow labelMd={3} title={t.repeat} htmlFor='repeat'>
                  <Label className='mr-3'>
                    <Input
                      defaultChecked={repeat === 'no_repeat'}
                      type='radio'
                      name='repeat'
                      value='no_repeat'
                      onChange={e => this.handleChange(e, 'repeat')}
                      style={{
                        position: 'static',
                        marginRight: '5px',
                        marginLeft: 0
                      }}
                    />
                    {t.no_repeat}
                  </Label>
                  <Label>
                    <Input
                      defaultChecked={repeat === 'custom'}
                      type='radio'
                      name='repeat'
                      value='custom'
                      onChange={e => this.handleChange(e, 'repeat')}
                      style={{
                        position: 'static',
                        marginRight: '5px',
                        marginLeft: 0
                      }}
                    />
                    {t.repeat}
                  </Label>
                </FormRow>
                {showCustom && (
                  <FormRow labelMd={3} title={t.repeat_interval}>
                    <div className='d-flex'>
                      <Input
                        value={repeatInterval}
                        onChange={e => this.handleChange(e, 'repeatInterval')}
                        style={{ width: '50%', marginRight: '1rem' }}
                        type='number'
                        required
                      />
                      <Input
                        style={{ width: '50%' }}
                        type='select'
                        value={repeatOption}
                        onChange={e => this.handleChange(e, 'repeatOption')}
                      >
                        <option value='DAILY'>{t.day}</option>
                        <option value='WEEKLY'>{t.week}</option>
                        <option value='MONTHLY'>{t.month}</option>
                        <option value='YEARLY'>{t.year}</option>
                      </Input>
                    </div>
                    <FormText>{t.repeat_interval_tips}</FormText>
                  </FormRow>
                )}
                {showCustom && weeklyRepeatVisible && (
                  <FormRow labelMd={3} title={t.repeat_on}>
                    <WeeklyRepeat
                      days={byWeekday}
                      onChange={this.handleWeeklyRepeatOptionChanged}
                    />
                  </FormRow>
                )}

                {/*{monthlyRepeatVisible && (*/}
                {/*  <FormRow labelMd={3} title={t.repeat_on}>*/}
                {/*    <MonthlyRepeat date={byMonthDay} />*/}
                {/*  </FormRow>*/}
                {/*)}*/}
              </ModalBody>
              <ModalFooter>
                <div className='d-flex justify-content-between w-100'>
                  <Button onClick={this.toggle} disabled={loading}>
                    {t.cancel}
                  </Button>
                  <ActionButton
                    type='submit'
                    theme='btn-primary'
                    loading={loading}
                  >
                    {t.save}
                  </ActionButton>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        )}
      </Mutation>
    );
  }
}
