import React from 'react';
import { Query } from 'react-apollo';
import Loading from 'components/loading';
import Route from 'components/route';
import { translate as t } from 'shared/translate';
import { Col, Input, Row, Label } from 'reactstrap';
import LanguageSelector from 'components/languageSelector';
import ImageUpload from 'components/ImageUpload';
import PermissionRendering from 'components/permissionRendering';
import { GET_QUERY, POST_QUERY } from './query';
import { Formik } from 'formik';
import HorizontalField from 'components/form/horizontal-field';
import SupportedLocale from 'shared/supported-locale';
import SingleCheckbox from 'components/form/SingleCheckbox';
import SeoCard from './SeoCard';
import AddressCard from './AddressCard';
import { client } from 'shared/apollo';
import { toast } from 'react-toastify';
import createCompanySet from './createCompantSet';
import _agentBindingPanel from './_agentBindingPanel';

const ShopInfo = () => {
  return (
    <React.Fragment>
      <Query query={GET_QUERY} variables={{ id: localStorage.getItem('shopId') }}>
        {({ loading, error: { message } = {}, data, data: { node } = {} }) => {
          if (loading && data !== {}) return <Loading />;

          if (message) {
            return (
              <div>
                <h5>Error :(</h5>
                <p>{message}</p>
                <Route {...this.props} text={t.back} />
              </div>
            );
          }
          const {
            id: shopId,
            allowGuestCheckout,
            autoComplete,
            autoConfirm,
            locale,
            customDomain,
            hostname,
            agent,
            logo,
            rasterLogo,
            attributes = [],
            title,
            keywords,
            description,
            address,
          } = node || {};

          const { id: selectedAgentId } = agent || {};
          const { person, tel, email, lines = ['', '', ''], country } = address || {};

          const locales = [SupportedLocale.CHINESE_TRAD_HK, SupportedLocale.ENGLISH_HK];
          const submit = async values => {
            let { company: { id: companyId } = {} } = node || {};

            await createCompanySet(companyId);

            try {
              const { data: { companyShopSet: result } = {} } = await client.mutate({
                mutation: POST_QUERY,
                variables: {
                  id: shopId,
                  input: {
                    companyId,
                    autoConfirm: values.autoConfirm,
                    autoComplete: values.autoComplete,
                    locale: values.locale,
                    hostname: values.hostname,
                    customDomain: values.customDomain,
                    logo: values.logo,
                    rasterLogo: values.rasterLogo,
                    allowGuestCheckout: values.allowGuestCheckout,
                    title: values.title,
                    description: values.description,
                    keywords: values.keywords,
                    address: {
                      person: values.person,
                      tel: values.tel,
                      email: values.email,
                      lines: values.lines,
                      country: values.country,
                    },
                  },
                },
              });
              await client.resetStore();
              console.log('result', result);

              if (t.getLanguage() !== values.defaultLang) {
                t.setLanguage(values.defaultLang);
                localStorage.setItem('lang', values.defaultLang);
              }

              toast.success(t.shop_update_success_msg);
              // window.location.reload();
              // this.setState({});
            } catch (e) {
              console.log('submit error', e);
            }
          };

          return (
            <Formik
              onSubmit={values => submit(values)}
              initialValues={{
                defaultLang: t.getLanguage(),
                language: null,
                locale,
                autoComplete,
                autoConfirm,
                hostname,
                customDomain,
                logo,
                rasterLogo,
                allowGuestCheckout,
                title,
                description,
                keywords,
                person,
                tel,
                email,
                lines,
                country,
              }}
            >
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <div className="container-fluid mb-5">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">{t.account_settings}</li>
                  </ul>
                  <form onSubmit={handleSubmit}>
                    <Row className="align-items-stretch">
                      <Col className="mb-5">
                        <div className="card mb-5 shadow h-100">
                          <div className="card-header d-flex">
                            <h6 className="mb-0 flex-fill">{t.shop_details}</h6>
                          </div>

                          <div className="card-body">
                            <HorizontalField label={t.interface_language}>
                              <LanguageSelector
                                name="defaultLang"
                                value={values.defaultLang}
                                handleChange={handleChange}
                              />
                            </HorizontalField>

                            <HorizontalField label={t.default_lang}>
                              <select
                                className="form-control"
                                value={values.locale}
                                onChange={handleChange}
                                name="locale"
                              >
                                {locales.map(lang => (
                                  <option key={lang.code} value={lang.code}>
                                    {lang.displayInNative}
                                  </option>
                                ))}
                              </select>
                            </HorizontalField>

                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label text-muted">{t.domain}</label>
                              <div className="col-lg-10">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text  d-none d-sm-block">https://</span>
                                  </div>
                                  <Input value={hostname || ''} disabled={hostname || ''} />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label text-muted">{t.admin_domain}</label>
                              <div className="col-lg-10">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text  d-none d-sm-block">https://</span>
                                  </div>
                                  <Input name="customDomain" value={values.customDomain} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label text-muted">{t.shop_logo}</label>
                              <div className="col-2">
                                <ImageUpload
                                  name="logo"
                                  value={values.logo}
                                  handleChange={v => setFieldValue('logo', v)}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label text-muted">{t.rasterLogo}</label>
                              <div className="col">
                                <ImageUpload
                                  name="rasterLogo"
                                  value={values.rasterLogo}
                                  handleChange={v => setFieldValue('rasterLogo', v)}
                                  onFile={(file, image) => {
                                    if (image.width !== 256 || image.height !== 256 || file.type !== 'image/jpeg')
                                      throw new Error('Error: invalid image');
                                  }}
                                />
                                <small>{t.rasterLogo_remark}</small>
                              </div>
                            </div>
                            <div>
                              <SingleCheckbox
                                label={t.enable_guest_checkout}
                                checked={values.allowGuestCheckout}
                                onValueChanged={value => setFieldValue('allowGuestCheckout', value)}
                                checkboxLabel={t.enable_guest_checkout_tips}
                              />
                            </div>
                            <div>
                              <SingleCheckbox
                                label={t.auto_confirm}
                                checked={values.autoConfirm}
                                onValueChanged={value => setFieldValue('autoConfirm', value)}
                                checkboxLabel={t.auto}
                              />
                            </div>
                            <div>
                              <SingleCheckbox
                                label={t.auto_complete}
                                checked={values.autoComplete}
                                onValueChanged={value => setFieldValue('autoComplete', value)}
                                checkboxLabel={t.auto}
                              />
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label text-muted">{t.close_shop}</label>
                              <div className="col">
                                <div className="form-inline">
                                  <div className="input-group">
                                    <input className="form-control" type="text" />
                                    <div className="input-group-append">
                                      <button className="btn btn-danger" type="button">
                                        {t.close_shop}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <PermissionRendering permission={'shop.agent.update'}>
                        {({ permit }) => {
                          return (
                            permit && (
                              <Col lg="5" md="12" className="mb-5">
                                <_agentBindingPanel selectedAgentId={selectedAgentId} />
                              </Col>
                            )
                          );
                        }}
                      </PermissionRendering>
                    </Row>
                    <Row className="mb-5">
                      <Col>{<SeoCard values={values} handleChange={handleChange} />}</Col>
                    </Row>
                    <Row className="mb-5">
                      <Col>
                        <AddressCard setFieldValue={setFieldValue} values={values} handleChange={handleChange} />
                      </Col>
                    </Row>
                    <div className="card">
                      <div className="card-body">
                        <button type="submit" className="btn btn-primary shadow pull-right">
                          {t.save}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          );
        }}
      </Query>
    </React.Fragment>
  );
};

export default ShopInfo;
