import React from 'react';
import { translate as t } from '../../shared/translate';
import FilterRowBase from './FilterRowBase';
import FilterRowBaseComponent from './FilterRowBaseComponent';

export default class Text extends FilterRowBase {
  state = {
    text: ''
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setState({ text: defaultValue || '' });
  }

  componentDidUpdate() {
    const { onValueChanged, visible } = this.props;
    const { text } = this.state;
    const filterValues = {
      text: visible ? text : null
    };
    onValueChanged(filterValues);
  }

  render() {
    const { text } = this.state;
    const { title, visible, toggleVisible } = this.props;
    return (
      <FilterRowBaseComponent
        title={title}
        visible={visible}
        toggleVisible={toggleVisible}
      >
        <div className='form-group m-0' style={{ display: 'flex' }}>
          <input
            type='text'
            className='form-control form-control-sm'
            onChange={e => this.handleValueChanged(e, 'text')}
            value={text}
          />
        </div>
      </FilterRowBaseComponent>
    );
  }
}
