import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Loading } from '../../components/pageComponents/utility';
import { translate } from '../../shared/translate';
import SaleChart from './_saleChart';
import ProductChart from './_productChart';
import BrowserChart from './_browserChart';
import EmptyChart from './_emptyChart';
import SalesReportChart from './SalesReportChart';
import { GET_QUERY } from './query';

export default class extends Component {
  state = {
    charts: [
      { name: translate.page_views },
      { name: translate.repeated_views },
      { name: translate.online_sessions },
      { name: translate.sales_conversion_rate },
      { name: translate.average_order_value },
      { name: translate.shipment_quantity },
      { name: translate.delivery_notes_coun },
    ],
  };
  render() {
    const shopId = localStorage.getItem('shopId');

    return (
      <Query query={GET_QUERY} variables={{ id: shopId }}>
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { node } = data || {},
            currentShop = node || {};

          return (
            <div className="container-fluid mb-5">
              <div className="row">{this.renderSaleCharts(currentShop)}</div>
              <div className="row">
                <SalesReportChart />
                {this.renderProductChart(currentShop)}
                {this.renderBrowserChart()}
                {this.renderOtherCharts()}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
  renderOtherCharts() {
    const { charts } = this.state;
    return charts.map(({ name }, i) => <EmptyChart key={i} name={name} />);
  }
  renderBrowserChart() {
    const data = [
      { name: translate.tablet, value: 400 },
      { name: translate.desktop, value: 300 },
      { name: translate.mobile, value: 300 },
      { name: translate.laptop, value: 300 },
    ];
    return <BrowserChart data={data} />;
  }
  renderProductChart({ products = [] }) {
    return <ProductChart products={products} />;
  }
  renderSaleCharts(currentShop) {
    return ['annual', 'monthly', 'weekly', 'daily'].map((unit, i) => {
      const diff =
        (((currentShop[`${unit}Sales`] || 0) - (currentShop[`${unit}SalesLast`] || 0)) /
          (currentShop[`${unit}SalesLast`] || 0)) *
          100 || 0;
      return (
        <SaleChart
          key={i}
          amount={(currentShop[`${unit}Sales`] || 0).toFixed(2)}
          label={translate[`${unit}_sales`]}
          diff={currentShop[`${unit}SalesLast`] > 0 ? diff : undefined}
        />
      );
    });
  }
}
