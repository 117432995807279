import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../shared/translate';
import DraggableList from 'components/DraggableList';
import imageUpload from '../../../shared/imageUpload';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';
import { Loading } from 'components/pageComponents/utility';

export default class ImagesField extends Component {
  state = {
    queue: [],
  };

  removeImage(index) {
    const { images, onChange } = this.props;
    images.splice(index, 1);
    onChange(images);
  }

  handleImageChange = async e => {
    const { images, onChange } = this.props;

    e.preventDefault();

    const newFiles = e.target.files;
    if (newFiles.length < 1) return;

    try {
      const queue = await Promise.all(
        [...newFiles].map((newFile, i) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = e => {
              reject(e);
            };
            reader.readAsDataURL(newFile);
          });
        }),
      );
      this.setState({ queue });

      const newImages = await Promise.all(
        [...newFiles]
          .filter(_ => _)
          .map(file => {
            return imageUpload(file, false);
          }),
      );
      onChange(images.concat(newImages));
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      this.setState({ queue: [] });
    }
  };

  render() {
    const { disabled, className = '', images = [], showUploadButton, onChange } = this.props,
      { queue } = this.state;
    return (
      <div className={className}>
        {!!showUploadButton && (
          <button
            disabled={disabled}
            type="button"
            className="btn btn-light btn-sm mb-3"
            onClick={() => this.imageUpload.click()}
          >
            <i className="fa fa-upload fa-fw" /> {translate.upload_image}
          </button>
        )}
        <input
          disabled={disabled}
          style={{ visibility: 'hidden', display: 'contents' }}
          ref={ref => (this.imageUpload = ref)}
          type="file"
          onChange={this.handleImageChange}
          multiple
        />
        <div className={'d-flex flex-wrap'} style={{ margin: '-0.5rem' }}>
          <DraggableList
            className={'d-flex flex-wrap'}
            items={images.map(url => ({ url, id: url }))}
            onChange={images => {
              onChange(images.map(({ url }) => url));
            }}
            direction={'horizontal'}
            disabled={disabled}
            itemRender={({ item: { url }, index }) => {
              return (
                <ImagePreview
                  loading={false}
                  disabled={disabled}
                  image={url}
                  onRemoveClick={() => this.removeImage(index)}
                />
              );
            }}
          />
          {queue.map(
            (url, i) =>
              !!url && (
                <ImagePreview
                  key={i}
                  loading={true}
                  disabled={disabled}
                  image={url}
                  onRemoveClick={() => this.removeImage(i)}
                />
              ),
          )}
        </div>
      </div>
    );
  }
}
const ImagePreview = ({ loading, image, onRemoveClick, disabled }) => (
  <div className="text-center" style={{ width: 120, padding: '0.5rem' }}>
    <div className="img-thumbnail">
      <div className="embed-responsive embed-responsive-1by1">
        <div className="embed-responsive-item">
          <div className={'position-relative w-100 h-100'}>
            <img className="object-fit-contain" src={image} />
            {loading && (
              <div
                className={'position-absolute w-100 h-100 d-flex align-items-center justify-content-center'}
                style={{ top: 0, left: 0, background: 'rgba(0,0,0,0.25)' }}
              >
                <Loading color={'text-white'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      disabled={disabled}
      className="btn btn-sm rounded-circle mt-1"
      style={{ width: 32, height: 32 }}
      onClick={onRemoveClick}
    >
      <i className="fa fa-times fa-fw" />
    </button>
  </div>
);
ImagesField.propTypes = {
  showUploadButton: PropTypes.bool,
  images: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
ImagesField.defaultProps = {
  showUploadButton: true,
};
