import React, { Fragment, useRef } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { translate } from 'shared/translate';
import RefundModal from 'routes/item_Invoice/_refundModal';
import { Link } from 'react-router-dom';
import { CreditNoteStatus } from 'shared/omniwe-types';
import { client } from 'shared/apollo';

export default ({
  values: {
    amount: { amount: invoiceAmount = 0 } = {},
    refundedAmount: { amount: refundedAmount = 0 } = {},
    creditNotes = [],
  },
  values,
}) => {
  const refundModal = useRef(null);
  const hasRefundableAmount = refundedAmount < invoiceAmount;

  return (
    <Card>
      <CardHeader className={'d-flex justify-content-between'}>
        <span>{translate.refund}</span>
        {!!hasRefundableAmount && (
          <Fragment>
            <button
              className="btn btn-danger btn-sm shadow"
              type={'button'}
              onClick={() => {
                try {
                  refundModal.current.toggle();
                } catch (e) {}
              }}
            >
              {translate.refund}
            </button>
            <RefundModal
              invoice={values || {}}
              ref={refundModal}
              onSuccess={async () => {
                await client.reFetchObservableQueries(false);
              }}
            />
          </Fragment>
        )}
      </CardHeader>
      {creditNotes.length > 0 && (
        <CardBody className={'p-0'}>
          <table className="table table-hover">
            <colgroup>
              <col width="*" />
              <col width="120" />
              <col width="200" />
              <col width="100" />
            </colgroup>
            <thead>
              <tr>
                <th>{translate.refund_number}</th>
                <th className="d-none d-sm-table-cell text-right">{translate.refund_status}</th>
                <th className="d-none d-sm-table-cell text-right">{translate.refund_method}</th>
                <th className="text-right">{translate.refund_amount}</th>
              </tr>
            </thead>
            <tbody>
              {creditNotes.map((item, i) => {
                const {
                  id,
                  status,
                  paymentMethod,
                  amount: { amount, locale },
                } = item;
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/credit_notes/${id}`}>{id}</Link>
                    </td>
                    <td className={'text-right'}>{CreditNoteStatus[status]}</td>
                    <td className={'text-right'}>{paymentMethod}</td>
                    <td className={'text-right'}>
                      {locale} {(amount - 0).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      )}
      {creditNotes.length === 0 && (
        <CardBody>
          <p className="text-center lead font-italic text-muted mb-0">{translate.no_data}</p>
        </CardBody>
      )}
    </Card>
  );
};
