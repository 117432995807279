import React from 'react';
import FormRow from 'components/form/FormRow';
import { CardElement } from 'react-stripe-elements';

export default ({ isSubmitting }) => {
  return (
    <div className={'card'}>
      <div className={'card-header'}>Card Info</div>
      <div className={'card-body'}>
        <FormRow title={'Card Number'} required={true}>
          <CardElement required={true} className="form-control stripe-input" disabled={isSubmitting} />
        </FormRow>
      </div>
    </div>
  );
};
