import React from 'react';
import { Query } from 'react-apollo';
import { GET_QUERY } from './query';

export default ({}) => {
  return (
    <div className={'row no-gutters px-3'}>
      <Query query={GET_QUERY} variables={{ id: localStorage.getItem('shopId'), limit: 8 }}>
        {({ data: { node } = {} }) => {
          const { products = [] } = node || {};
          return Array(8)
            .fill(undefined)
            .map((_, i) => {
              const { images: [image] = [] } = products[i] || {};
              return (
                <div key={i} className={'col-3 p-1'}>
                  <div className={'embed-responsive embed-responsive-1by1'}>
                    <img
                      className={'embed-responsive-item object-fit-cover'}
                      src={image || require('../../../../../assets/gray.jpg')}
                    />
                  </div>
                </div>
              );
            });
        }}
      </Query>
    </div>
  );
};
