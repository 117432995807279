import React from 'react';
import { translate } from '../../shared/translate';
import moment from 'moment';
import ChangePassword from './_changePassword';

export default ({ userId, name, email, updatedAt, group }) => (
  <div className="card mb-5 shadow">
    <div className="card-header d-flex">
      <h6 className="mb-0 flex-fill">{translate.account_details}</h6>
    </div>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-2 col-form-label text-muted">{translate.email}</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext" type="text" disabled={true} defaultValue={email} />
        </div>
      </div>

      {!!name && (
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-muted">{translate.name}</label>
          <div className="col-lg-3 col-md-5 col-sm-8">
            <input className="form-control" type="text" disabled={true} defaultValue={name} />
          </div>
        </div>
      )}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label text-muted">{translate.password}</label>
        <div className="col-sm-10 d-flex align-items-center">
          <ChangePassword userId={userId} identity={email} />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-2 col-form-label text-muted">{translate.account_permissions}</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext" type="text" disabled={true} defaultValue={group} />
        </div>
      </div>
    </div>
    <div className="card-footer d-flex justify-content-end">
      <i className="small text-muted">
        {translate.updated_at}:{moment(updatedAt).format(' YYYY/MM/DD HH:mm:ss')}
      </i>
    </div>
  </div>
);
