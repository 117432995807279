import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Shop {
        id
        companyVerifyData {
          merchant_name
        }
        credentials {
          platform
        }
      }
    }
  }
`;
