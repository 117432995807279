import React, { Fragment } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import VenueSlotModal from './_venueSlotModal';

const GET_SLOTS = gql`
  query($id: ID, $otherFilter: SlotFilterInput, $offset: OffsetInput) {
    node(id: $id) {
      id
      ... on Venue {
        slots(otherFilter: $otherFilter, offset: $offset) {
          id
          createdAt
          updatedAt
          startedAt
          capacity
          startedThru
          recurrence
        }
      }
    }
  }
`;

const localizer = momentLocalizer(moment);
const HEIGHTS = {
  month: 700,
  week: 4000,
  agenda: 700,
};

export default class VenueCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentView: 'month',
      startedSince: moment()
        .startOf('month')
        .startOf('week')
        .startOf('day')
        .toISOString(),
      startedUntil: moment()
        .endOf('month')
        .endOf('week')
        .endOf('day')
        .toISOString(),
    };
    this.view = 'month';
  }

  onView = viewName => {
    this.view = viewName;
    this.setState({ currentView: viewName });
  };

  onSelectSlot = ({ start: startedAt }) => {
    try {
      this.slotModal.init({ startedAt });
      this.slotModal.toggle();
    } catch (e) {}
  };
  onSelectEvent = ({ resource: venueSlot }) => {
    try {
      this.slotModal.init(venueSlot);
      this.slotModal.toggle();
    } catch (e) {}
  };

  genEventTitle({ startedAt, startedThru }) {
    return `${moment(startedAt).format('hh:mm')}-${moment(startedThru).format('hh:mm A')}`;
  }

  handleRangeChange(ranges) {
    switch (this.view) {
      case 'month':
        this.setState({
          startedSince: moment(ranges.start)
            .startOf('day')
            .toISOString(),
          startedUntil: moment(ranges.end)
            .endOf('day')
            .toISOString(),
        });
        return;
      case 'week':
        this.setState({
          startedSince: moment(ranges[0])
            .startOf('day')
            .toISOString(),
          startedUntil: moment(ranges[ranges.length - 1])
            .endOf('day')
            .toISOString(),
        });
        return;
    }
  }

  render() {
    const { currentView, startedSince, startedUntil } = this.state;
    const { venueId } = this.props;
    return (
      <div style={{ height: HEIGHTS[currentView] || 700 }}>
        <Query
          query={GET_SLOTS}
          variables={{
            id: venueId,
            otherFilter: {
              startedSince,
              startedUntil,
            },
            offset: {
              limit: -1,
            },
          }}
        >
          {({ loading, data: { node } = {}, refetch }) => {
            let { slots = [] } = node || {};

            slots = slots.reduce(
              (prev, slot) => [
                ...prev,
                {
                  start: new Date(slot.startedAt),
                  end: slot.startedThru === null ? null : new Date(slot.startedThru),
                  title: this.genEventTitle(slot),
                  allDay: false,
                  resource: slot,
                },
              ],
              [],
            );

            return (
              <Fragment>
                <Calendar
                  step={15}
                  timeslots={4}
                  localizer={localizer}
                  events={slots}
                  views={['week', 'month']}
                  defaultView="month"
                  startAccessor="start"
                  endAccessor="end"
                  selectable={true}
                  onView={this.onView}
                  onSelectSlot={this.onSelectSlot}
                  onSelectEvent={this.onSelectEvent}
                  onRangeChange={e => this.handleRangeChange(e)}
                  popup={true}
                />
                <VenueSlotModal ref={ref => (this.slotModal = ref)} onChange={refetch} venueId={venueId} />
              </Fragment>
            );
          }}
        </Query>
      </div>
    );
  }
}
