import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import HorizontalField from './horizontal-field';
import moment from 'moment';

class SingleDateField extends Component {
  render() {
    const { onValueChanged, date } = this.props;
    return (
      <HorizontalField {...this.props}>
        <Input
          type='date'
          value={new moment(date).format('YYYY-MM-DD')}
          onChange={e => onValueChanged(new Date(e.target.value))}
        />
      </HorizontalField>
    );
  }
}

SingleDateField.propTypes = {
  label: PropTypes.string,
  labelWidth: PropTypes.number,
  colWidth: PropTypes.number,
  date: PropTypes.string,
  onValueChanged: PropTypes.func
};

SingleDateField.defaultProps = {
  onValueChanged: () => console.warn('onValueChanged not provided'),
  date: null
};

export default SingleDateField;
