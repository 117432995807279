import React, { Component } from 'react';
import ProviderList from './ProviderList';
import { CredentialPlatformType } from './../../../shared/omniwe-types';
import cloneDeep from 'lodash/cloneDeep';

class ShippingProviderList extends Component {
  state = {
    providers: [
      {
        icon: 'faTruck',
        title: 'shipping_provider_cpcbe',
        description: 'shipping_provider_cpcbe_desc',
        platform: CredentialPlatformType.CPCB,
        active: false,
        identity: '',
        secret: '',
      },
      /** Hide SF Express : we don't have API connection to it yet **/
      // {
      //   icon: 'faTruck',
      //   title: 'shipping_provider_sf_express',
      //   description: 'shipping_provider_sf_express_desc',
      //   platform: CredentialPlatformType.SF_EXPRESS,
      //   active: false,
      //   identity: '',
      //   secret: ''
      // }
    ],
  };

  constructor(props) {
    super(props);
    const { credentials = [] } = props.shop || {};

    if (credentials) {
      credentials.forEach(credential => this.updateProvider(credential));
    }
  }

  updateProvider(credential) {
    const newProviders = cloneDeep(this.state.providers);
    const provider = newProviders.find(p => p.platform === credential.platform);

    if (provider) {
      provider.active = credential.active;
      provider.identity = credential.identity;
      provider.secret = credential.secret;

      this.state = { providers: newProviders };
    }
  }

  render() {
    const { providers } = this.state;
    return <ProviderList providers={providers} />;
  }
}

export default ShippingProviderList;
