// 商家查看市場服務詳情-申请表单

import React from 'react';
import { translate } from '../../shared/translate';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import * as pageUtility from '../../components/pageComponents/utility';
import PermissionRendering from '../../components/permissionRendering';
import _ from 'lodash';
import FormBase from '../../components/pageComponents/FormBase';

const POST_QUERY = gql`
  # OWNER APPLY SERVICE
  mutation($input: ServiceApplicationInput!) {
    serviceApplicationSet(input: $input) {
      id
      status
    }
  }
`;
export default class ApplicationForm extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      contactName: '',
      contactNumber: '',
      comment: ''
    };
    const {
      match: {
        params: { serviceId }
      }
    } = this.props;
    this.serviceId = serviceId;
  }

  buildInput = () => {
    let input = {
      shopId: localStorage.getItem('shopId'),
      serviceId: this.serviceId,
      status: 'PENDING',
      attributes: [
        {
          key: 'contact_number',
          value: this.state.contactNumber
        },
        {
          key: 'contact_name',
          value: this.state.contactName
        }
      ]
    };

    if (this.state.comment) {
      input.attributes.push({
        key: 'remark',
        value: this.state.comment
      });
    }

    return input;
  };

  onSubmit = async (e, post, client) => {
    const { history } = this.props;

    e.preventDefault();
    console.log('submit');
    const input = this.buildInput();
    console.log('input', input);

    const { data: { serviceApplicationSet: { id } = {} } = {} } = await post({
      variables: { input }
    });

    if (id) {
      pageUtility.toastSuccess(translate.application_submit_success);
      console.log('id', id);
      setTimeout(() => {
        history.push(`/market_services/${this.serviceId}/${id}`);
      }, 500);
    } else {
      pageUtility.toastError(translate.application_submit_failed);
    }

    client.resetStore();
  };

  render() {
    return (
      <PermissionRendering permission={'service_application.create'}>
        {({ permit }) =>
          permit && (
            <div className='card mb-5 shadow'>
              <div className='card-header d-flex'>
                <h6 className='mb-0 flex-fill'>{translate.applicaton}</h6>
              </div>
              <Mutation mutation={POST_QUERY}>
                {(post, { loading, error, client }) => {
                  return (
                    <form onSubmit={e => this.onSubmit(e, post, client)}>
                      <div className='card-body'>
                        <div className='form-group row'>
                          <label
                            htmlFor='title'
                            className='col-sm-2 col-form-label'
                          >
                            * {translate.contact_name}
                          </label>
                          <div className='col-sm'>
                            <input
                              value={this.state.contactName}
                              style={{ maxWidth: 200 }}
                              name='contactName'
                              onChange={this.handleInputChanged}
                              type='text'
                              className='form-control'
                              required
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label
                            htmlFor='title'
                            className='col-sm-2 col-form-label'
                          >
                            * {translate.contact_number}
                          </label>
                          <div className='col-sm'>
                            <input
                              value={this.state.contactNumber}
                              style={{ maxWidth: 200 }}
                              name='contactNumber'
                              onChange={this.handleInputChanged}
                              type='text'
                              className='form-control'
                              required
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label
                            htmlFor='title'
                            className='col-sm-2 col-form-label'
                          >
                            {translate.application_remark}
                          </label>
                          <div className='col-sm'>
                            <textarea
                              name='comment'
                              style={{ maxWidth: 800, minHeight: 100 }}
                              onChange={this.handleInputChanged}
                              className='form-control'
                              value={this.state.comment}
                            />
                          </div>
                        </div>

                        <div className='card-body' style={{ marginBottom: 20 }}>
                          <button
                            style={{ minWidth: 100 }}
                            type='submit'
                            className='btn btn-dark shadow pull-right'
                            disabled={loading}
                          >
                            {loading && <pageUtility.Spinner />}
                            {translate.submit_application}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Mutation>
            </div>
          )
        }
      </PermissionRendering>
    );
  } // end render
} // end class
