// Crumb navigation React component
// src/components/pageComponents/CrumbNavi.jsx
// created on april-19, 2019, by Tony Day
import React from "react";
import { Link } from "react-router-dom";
import eventProxy from "../../shared/eventProxy";

const Breadcrumb = ({ items }) => (
  <ul className="breadcrumb">
    {items &&
      items.map((item, i) => (
        <li
          className={
            "breadcrumb-item " + (i + 1 === items.length ? "active" : "")
          }
          key={i}
        >
          {item.url ? <Link to={item.url}>{item.text}</Link> : item}
        </li>
      ))}
  </ul>
);

export default Breadcrumb;

export class BreadcrumbProxy extends React.Component {
  static update(items) {
    eventProxy.trigger("_breadcrumb", items);
  }

  state = { items: null };
  componentDidMount() {
    eventProxy.on("_breadcrumb", items => {
      this.setState({ items });
    });
  }

  render() {
    const { items } = this.state;
    return <Breadcrumb items={items} />;
  }
}
