import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import { translate as t } from 'shared/translate';
import ChargeCard from 'routes/item_DeliveryOption/ChargeCard';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import { client } from 'shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    breadcrumb: [
      { link: '/delivery_options', label: t.delivery_options },
      { label: this.state.id || t.new_delivery_option },
    ],
    cards: [
      {
        name: t.card_title_delivery_option_details,
        fields: [
          {
            type: 'checkbox',
            label: t.availability,
            name: 'active',
            text: t.yes,
          },
          {
            type: 'text',
            label: t.name,
            name: 'name',
            required: true,
          },
          {
            type: 'textarea',
            label: t.description,
            name: 'description',
          },
          {
            type: 'number',
            label: t.sort_index,
            name: 'sortIndex',
          },
          {
            type: 'select',
            label: t.label_delivery_method,
            name: 'courier',
            options: [{ label: 'Manual', value: 'MANUAL' }],
            required: true,
          },
          {
            type: 'countries',
            label: t.label_add_country,
            name: 'countries',
          },
        ],
      },
      ChargeCard,
    ],
  };

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          name: values.name,
          shopId: localStorage.getItem('shopId'),
          courier: values.courier,
          sortIndex: values.sortIndex - 0,
          countries: values.countries,
          active: values.active,
          weights: values.weights.map(w => ({
            maxWeight: w.maxWeight - 0,
            unit: w.unit - 0,
            unitPrice: w.unitPrice - 0,
          })),
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const { active = true, name, courier, countries = [], sortIndex = 0, weights = [] } = node || {};
    return {
      active,
      name,
      courier,
      countries,
      sortIndex,
      weights: weights.map(weight => ({ maxWeight: weight.maxWeight, unit: weight.unit, unitPrice: weight.unitPrice })),
    };
  }
}
