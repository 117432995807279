import React, { Fragment } from 'react';
import MenuItem from './_menuItem';
import { translate } from '../../../shared/translate';

export default ({ name, children, onMenuClick, location: { pathname } }) => {
  const renderChildren = children.filter(({ name, link }) => !!name && !!link);

  return (
    <Fragment>
      {!!name && renderChildren.length > 0 && (
        <div className={'list-group-item bg-dark cate-header text-muted'}>
          <span>{translate[name]}</span>
        </div>
      )}
      {renderChildren.map((item, i) => {
        const { name, link, includedPathnames = [] } = item;
        if (!name || !link) return null;

        const active =
          matchPathname(link, pathname) ||
          includedPathnames.reduce((reducer, search) => {
            return reducer || matchPathname(search, pathname);
          }, false);
        return (
          <MenuItem
            key={i}
            {...item}
            active={active}
            onClick={() => onMenuClick && onMenuClick(item)}
          />
        );

        function matchPathname(search, pathname) {
          if (search === '/') {
            return pathname === '/';
          }
          const isExact = !!pathname.match(new RegExp(`^${search}$`));
          const isChildPage = !!pathname.match(new RegExp(`^${search}\/`));
          return isExact || isChildPage;
        }
      })}
    </Fragment>
  );
};
