import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '../../layout/card/Card';
import RemoveToggle from '../../form/RemoveToggle';
import { translate } from '../../../shared/translate';
import CardHeader from '../../layout/card/CardHeader';
import CardBody from '../../layout/card/CardBody';
const OMNIWE_LOGO = require('../../../assets/src_omniwe-logo.svg');

export default class ImageCard extends Component {
  state = {
    url: undefined,
  };

  handleImageChange = e => {
    const {
      field: { name },
      actions: { values, setFieldValue },
    } = this.props;

    e.preventDefault();
    if (!e) {
      setFieldValue(name, '');
      return;
    }

    if (e.target.files.length < 1) return;

    let reader = new FileReader();
    let file = e.target.files[0];

    setFieldValue(name, file);
    reader.onloadend = () => {
      this.setState({ url: reader.result });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const {
        name,
        field: { name: fieldName, ...props },
        onRemoveClick,
        actions: { values, handleChange },
        extra,
      } = this.props,
      { file, url } = this.state;
    const value = values[fieldName];
    return (
      <Card>
        <CardHeader
          title={name}
          extra={
            <Fragment>
              {extra}
              {!!onRemoveClick && <RemoveToggle title={translate.confirm_to_delete} onClick={onRemoveClick} />}
              <button
                type="button"
                className="btn btn-light btn-sm pull-right ml-2"
                onClick={() => this.imageUpload.click()}
              >
                <i className="fa fa-upload fa-fw" /> {translate.upload_image}
              </button>
              <input
                style={{ visibility: 'hidden', display: 'contents' }}
                ref={ref => (this.imageUpload = ref)}
                type="file"
                onChange={this.handleImageChange}
                {...props}
              />
            </Fragment>
          }
        />
        <CardBody>
          <div className="row justify-content-center">
            <div className="col-lg-4 text-center">
              <div className="img-thumbnail">
                <div className="embed-responsive embed-responsive-1by1">
                  <div className="embed-responsive-item">
                    <button
                      type={`button`}
                      className={`btn btn-light h-100 w-100`}
                      onClick={() => this.imageUpload.click()}
                    >
                      <img className="object-fit-contain" src={url || value || OMNIWE_LOGO} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <CardBody className="text-center">
          <p className="lead font-italic text-muted mb-0">{translate.upload_image_description}</p>
        </CardBody>
      </Card>
    );
  }
}
ImageCard.propTypes = {
  name: PropTypes.string,
  field: PropTypes.object,
  onRemoveClick: PropTypes.any,
  actions: PropTypes.any,
};
