import React, { useState } from 'react';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { Icon } from 'components/iconRender';
import { translate } from 'shared/translate';

export default ({
  className = '',
  style,
  selected,
  required,
  disabled,
  onChange = _ => _,
  onSearchChange = _ => _,
  options = [
    { label: 'label1', value: 1 },
    { label: 'label2', value: 2 },
  ],
  renderOption = option => option.label,
  placeholder = translate.please_select,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const regexp = new RegExp(search, 'i');

  return (
    <ButtonDropdown className={`d-block`} style={style} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle
        disabled={disabled}
        className={'p-0 border-0 rounded w-100 position-relative'}
        style={{ boxShadow: 'none' }}
      >
        <Input
          required={required}
          className={className}
          placeholder={placeholder}
          value={selected || ''}
          onChange={e => {
            e.preventDefault();
          }}
        />
        {!!selected && (
          <button
            disabled={disabled}
            type={'button'}
            className={'btn btn-link border-0 text-danger'}
            onClick={() => {
              onChange(null);
            }}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <Icon icon={'faTimes'} />
          </button>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <div className={'p-2'}>
          <input
            className={'form-control'}
            value={search}
            onChange={e => {
              const value = e.target.value;
              setSearch(value);
              onSearchChange(value);
            }}
          />
          <Icon
            style={{
              position: 'absolute',
              top: 24,
              right: 15,
              pointerEvents: 'none',
            }}
            icon={'faSearch'}
          />
        </div>
        <div style={{ height: '50vh', maxHeight: 500, overflow: 'auto' }}>
          {options
            .filter(option => regexp.test(option.label))
            .map((option, i) => (
              <DropdownItem
                key={i}
                onClick={() => {
                  onChange(option.value);
                }}
              >
                {renderOption(option)}
              </DropdownItem>
            ))}
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
