import React, { useEffect } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { translate } from '../../shared/translate';
import Copyright from '../../components/copyright';

const onBottomPadding = -50;

let isBottom = false;

export default ({ onMenuClick, onUserClick, remain_days = 100, pages }) => {
  useEffect(() => {
    handleScroll();
    window.addEventListener('ReachEndTrigger', () => {
      handleScroll();
    });
  });

  const handleScroll = () => {
    let ele = document.getElementById('MainWrapper');
    if (!ele) return;

    const bottomMargin = Math.abs(ele.scrollHeight - ele.scrollTop - ele.clientHeight);
    if (bottomMargin >= onBottomPadding) {
      isBottom = true;
      window.dispatchEvent(new Event('ReachEnd'));
    } else if (bottomMargin <= onBottomPadding) {
      isBottom = false;
    }
  };

  return (
    <div
      className="flex-fill d-flex flex-column"
      style={{ overflow: 'auto' }}
      onScroll={handleScroll}
      id={'MainWrapper'}
      ref={ref => {
        try {
          window.MainWrapper = ref;
        } catch (e) {}
      }}
    >
      <div className="bg-transparent shadow-none border-bottom-1 position-static p-3">
        <div className="d-flex justify-content-between">
          <div className="justify-content-start d-sm-none">
            <button className="btn btn-white rounded-circle shadow" aria-label="Menu" onClick={onMenuClick}>
              <i className="fa fa-list" />
            </button>
          </div>
          <div className="d-flex justify-content-end w-100 align-items-center">
            {!!remain_days && (
              <Link to="/plans" className="primary-hover">
                <b className={`mr-3 text-${remain_days > 100 ? 'success' : 'danger'}`}>
                  {remain_days < 0
                    ? translate.subscription_expired
                    : translate.formatString(translate.subscription_remaining, remain_days)}
                </b>
              </Link>
            )}
            <button className="btn btn-white rounded-circle shadow" onClick={onUserClick}>
              <i className="fa fa-user" />
            </button>
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <Switch>
          {pages
            .map(
              ({ link, component, exact = true }, i) =>
                !!component && !!link && <Route key={i} path={link} exact={exact} component={component} />,
            )
            .filter(_ => _)}
        </Switch>
      </div>
      <Copyright />
    </div>
  );
};
