import React from 'react';
const OMNIWE_LOGO = require('../../../../assets/src_omniwe-logo.svg');

export default ({ config: { title = '' } = {} }) => {
  return (
    <div className={'d-flex align-items-center justify-content-center pt-3'}>
      <div className={'border-top flex-fill'} />
      {!!title ? (
        <span className={'mx-2'}>{title}</span>
      ) : (
        <img
          style={{
            width: 15,
            height: 15,
          }}
          className="object-fit-contain mx-2"
          src={OMNIWE_LOGO}
        />
      )}
      <div className={'border-top flex-fill'} />
    </div>
  );
};
