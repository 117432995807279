import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import PrintInvoice from './PrintInvoice';
import PrintOrder from './PrintOrder';
import PrintDN from './PrintDN';
import Homepage from './Homepage';

export default () => (
  <Router>
    <Switch>
      <Route path={'/login'} component={Login} />
      <Route path={'/register'} component={Register} />
      <Route path={'/reset_password'} component={ResetPassword} />
      <Route path={'/print_invoices/:id?'} component={PrintInvoice} />
      <Route path={'/print_orders/:id?'} component={PrintOrder} />
      <Route path={'/print_delivery_notes/:id?'} component={PrintDN} />
      <Route path={'/'} component={Homepage} />
    </Switch>
  </Router>
);
