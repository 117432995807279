import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { translate as t } from '../../../shared/translate';
import VenueCalendar from './_venueCalendar';
import ClientCalendar from './_clientCalendar';
import PermissionRendering from 'components/permissionRendering';

export default class extends Component {
  state = {
    tab: 1,
  };

  toggle = (tab = 0) => {
    this.setState({ tab });
  };

  render() {
    const { venueId } = this.props;
    const { tab } = this.state;
    return (
      <PermissionRendering permission={'hc.venue.slot.read'}>
        {({ permit }) =>
          permit ? (
            <Fragment>
              <Nav tabs>
                <NavItem>
                  <NavLink className={tab === 0 ? 'active' : ''} onClick={() => this.toggle(0)}>
                    {t.client_schedule}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={tab === 1 ? 'active' : ''} onClick={() => this.toggle(1)}>
                    {t.venue_schedule}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={tab} className="pt-3">
                <TabPane tabId={0}>
                  <ClientCalendar venueId={venueId} />
                </TabPane>
                <TabPane tabId={1}>
                  <VenueCalendar venueId={venueId} />
                </TabPane>
              </TabContent>
            </Fragment>
          ) : (
            <Fragment>
              <Nav tabs>
                <NavItem>
                  <NavLink className={'active'}>{t.client_schedule}</NavLink>
                </NavItem>
              </Nav>
              <div className="pt-3">
                <ClientCalendar venueId={venueId} />
              </div>
            </Fragment>
          )
        }
      </PermissionRendering>
    );
  }
}
