import { gql } from 'apollo-boost';

export const SET_QUERY = gql`
  # VENUE SET
  mutation($id: ID, $input: VenueInput!) {
    venueSet(id: $id, input: $input) {
      id
      createdAt
      name
    }
  }
`;

export const UNSET_QUERY = gql`
  #DELETE VENUE
  mutation($id: ID!) {
    venueUnset(id: $id) {
      id
    }
  }
`;

export const DETAILS_QUERY = gql`
  # QUERY VENUE DETAILS
  query($id: ID) {
    node(id: $id) {
      id
      ... on Venue {
        id
        createdAt
        updatedAt
        name
        district
        images
        address {
          userId
          person
          tel
          email
          lines
          country
        }
        attributes {
          key
          value
        }
      }
    }
  }
`;
