export default [
  {
    link: '/test',
    component: require('../Test').default,
  },
  {
    link: '/shops',
    component: require('../Shops').default,
  },
  {
    link: '/profile',
    component: require('../Profile').default,
  },
  {
    link: '/billings',
    component: require('../list_Billing').default,
    permission: 'user.billing.read',
  },
  {
    link: '/billings/:id?',
    component: require('../item_Billing/index').default,
    permission: 'user.billing.read',
  },
  {
    link: '/wechat',
    component: require('../Wechat').default,
  },
  {
    link: '/plans',
    component: require('../Plan').default,
  },
  {
    link: '/settings/payments',
    component: require('../item_Payments').default,
    permission: 'shop.shop_credential.read',
  },
  {
    link: '/settings/shop_info',
    component: require('../settings/ShopInfo').default,
  },
  {
    link: '/settings/shipping',
    component: require('../settings/ShippingProviders').default,
  },
  {
    link: '/settings/notifications',
    component: require('../settings/notifications').default,
  },
  {
    link: '/market_services',
    component: require('../list_MarketService').default,
    permission: 'shop.agent.read',
  },
  {
    link: '/market_services/:id/:appId?',
    component: require('../item_MarketService').default,
    permission: 'shop.agent.read',
  },
  {
    link: '/settings/payments/stripe_connect',
    component: require('../item_StripeConnect').default,
  },
  {
    link: '/settings/payments/stripe',
    component: require('../item_Stripe').default,
  },
  {
    link: '/settings/payments/china_smart_pay',
    component: require('../item_ChinaSmartPay').default,
  },
  {
    link: '/settings/payments/swift_pass',
    component: require('../item_SwiftPass').default,
  },
  {
    link: '/settings/merchant_info',
    component: require('../item_MerchantInfo').default,
  },
];
