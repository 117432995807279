import React from 'react';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import ListPageMaker from '../../components/listPageMaker';
import { CreditNoteStatus } from '../../shared/omniwe-types';
import { GET_QUERY } from './query';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ label: translate.page_title_credit_notes }],
    gql: { get: GET_QUERY },
    fields: [
      {
        title: translate.refund_number,
        render: item => <Link to={`/credit_notes/${item.id}`}>{item.id}</Link>,
      },
      {
        title: translate.refund_status,
        align: 'right',
        render: item => CreditNoteStatus[item.status],
        width: 160,
        sortable: 'status',
      },
      {
        title: translate.refund_method,
        align: 'right',
        render: ({ paymentMethod }) => {
          return translate.paymentPlatforms[paymentMethod] || paymentMethod;
        },
        sortable: 'paymentMethod',
      },
      {
        title: translate.refund_amount,
        align: 'right',
        fieldName: 'amount',
        width: 160,
      },
      {
        title: translate.order_date,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        align: 'right',
        width: 160,
        filter: true,
        sortable: 'createdAt',
      },
      {
        title: translate.updated_at,
        type: 'datetime',
        dataKey: 'updatedAt',
        align: 'right',
        width: 160,
        sortable: 'updatedAt',
      },
    ],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  getData = (data = {}) => {
    const { node } = data || {},
      { creditNotes = [], creditNotesCount = 0 } = node || {};
    const list = creditNotes.map(item => {
      const {
        amount: { amount, locale },
      } = item;
      return { ...item, amount: `${locale} ${amount}` };
    });
    return { list, count: creditNotesCount };
  };
}
