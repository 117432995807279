import React, { Component } from 'react';

import { translate } from './../../../shared/translate';

import Card from './Card';
import CardHeader from './CardHeader';
import DatetimeCardFooter from './DatetimeCardFooter';
import DangerButton from './../../form/DangerButton';
import ActionButton from './../../form/ActionButton';

const StatusCardBody = ({
  status,
  statusOptions = [],
  onStatusChanged = () => console.warn('onStatusChanged not provided'),
  disabled,
  children,
}) => (
  <div className="card-body">
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">{translate.status}</label>
      <div className="col-sm-5">
        <select
          disabled={disabled}
          className="form-control"
          defaultValue={status}
          onChange={e => onStatusChanged(e.target.value)}
        >
          {statusOptions.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
    </div>
    {children}
  </div>
);

class StatusCard extends Component {
  handlePrint = () => {
    const { printUrl = '' } = this.props;
    if (window) {
      window.open(printUrl, '_blank');
    }
  };

  controls = ({ onRemove = () => console.warn('onRemove not provided') }) => (
    <div className="d-flex flex-row align-items-center">
      <div className="mr-2">{this.props.extra}</div>
      <div className="mr-2">
        <DangerButton onClick={onRemove} icon="faTrash" label={translate.cancel} />
      </div>
      <div className="mr-2">
        <ActionButton onClick={this.handlePrint} theme="btn-dark btn-sm">
          {translate.button_print}
        </ActionButton>
      </div>
    </div>
  );

  render() {
    const { title, onEvents = {}, data } = this.props;
    const { status, updatedAt, statusOptions, disabled } = data || {};

    return (
      <Card>
        <CardHeader title={title} extra={this.controls(onEvents)} />
        <StatusCardBody
          disabled={disabled}
          status={status}
          statusOptions={statusOptions}
          onStatusChanged={onEvents.onStatusChanged}
        >
          {this.props.children}
        </StatusCardBody>
        <DatetimeCardFooter updatedAt={updatedAt} />
      </Card>
    );
  }
}

export default StatusCard;
