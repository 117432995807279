import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!, $offset: OffsetInput, $filter: FilterInput, $otherFilter: CustomPagesFilterInput) {
    node(id: $id) {
      id
      ... on Shop {
        id
        customPages(offset: $offset, filter: $filter, otherFilter: $otherFilter) {
          id
          name
          href
          body {
            name
            config
          }
        }
      }
    }
  }
`;
