import React, { Component } from 'react';
import { Container } from 'reactstrap';
import BreadCrumbs from './BreadCrumbs';

class ContentPageLayout extends Component {
  render() {
    return (
      <Container fluid className='mb-5'>
        <BreadCrumbs trail={this.props.trail} />
        {this.props.children}
      </Container>
    );
  }
}

export default ContentPageLayout;
