import React from "react";
import { Input } from "reactstrap";
import FilterRowBase from "./FilterRowBase";
import FilterRowBaseComponent from "./FilterRowBaseComponent";

export default class Options extends FilterRowBase {
  constructor(props) {
    super(props);

    const { options = [] } = this.props;
    let defaultValue = options[0].value || "";
    this.state = {
      value: defaultValue
    };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    if(defaultValue !== null && defaultValue !== undefined)
      this.setState({ value: defaultValue })
  }


  componentDidUpdate() {
    const { onValueChanged, visible } = this.props;
    const { value } = this.state;
    const filterValues = {
      value: visible ? value : null
    };
    onValueChanged(filterValues);
  }

  render() {
    const { value } = this.state;
    const { title, visible, toggleVisible, options } = this.props;
    return (
      <FilterRowBaseComponent
        title={title}
        visible={visible}
        toggleVisible={toggleVisible}
      >
        <Input
          type="select"
          value={value}
          onChange={e => this.handleValueChanged(e, "value")}
        >
          {options.map((item, i) => (
            <option value={item.value} key={i}>
              {item.name}
            </option>
          ))}
        </Input>
      </FilterRowBaseComponent>
    );
  }
}
