import React from 'react';
import { gql } from 'apollo-boost';
import { toast } from 'react-toastify';
import _ from 'lodash';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.form = {};
  }

  getFormModel = names => {
    let obj = {};
    _.forEach(names, item => {
      // console.log("name", item);
      // console.log("type", this.getCurrentRef(item));
      // console.log("type naem", this.getCurrentRef(item).type);
      // console.log(item);
      const refItem = this.getCurrentRef(item);
      if (!refItem) {
        throw `ref was not found for '${item}'.`;
      }

      const typeName = refItem.type;
      let val;
      switch (typeName) {
        case 'checkbox':
          val = refItem.checked;
          break;
        default:
          val = refItem.value;
          break;
      }
      obj[item] = val;
    });

    return obj;
  };

  handleInputChange = (e, name = null, value = null) => {
    const {
      target: { value: inputValue, name: inputName },
    } = e;

    if (!name) {
      name = inputName;
    }

    if (!value) {
      value = inputValue;
    }

    this.setState({ [name]: value });
  };

  loading(busy) {
    if (busy === null || busy === undefined) {
      return this.state.loading;
    }
    this.setState({ loading: busy });
  }

  getRef(name) {
    if (!this.form[name]) {
      this.form[name] = React.createRef();
    }
    return this.form[name];
  }

  getCurrentRef(name) {
    return this.getRef(name).current;
  }

  removeRef(name) {
    delete this.form[name];
  }

  async createCompany(
    client,
    data = {
      input: {
        attributes: [],
      },
    },
  ) {
    try {
      const { data: { companySet } = {} } = await client.mutate({
        mutation: gql`
          mutation companySet($id: ID, $input: CompanyInput!) {
            companySet(id: $id, input: $input) {
              id
            }
          }
        `,
        variables: data,
      });
      client.resetStore();
      return companySet;
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  async setAttributes(attributesKeys = []) {
    let attributes = [];
    _.each(attributesKeys, key => {
      const { value, checked, type } = this.getCurrentRef(key) || {};
      if (
        !_.isUndefined(type) &&
        (type === 'text' ||
          type === 'date' ||
          type === 'email' ||
          type === 'password' ||
          type === 'number' ||
          type === 'select-one' ||
          type === 'textarea')
      ) {
        if (!_.isUndefined(value) && value.length !== 0) {
          attributes.push({
            key: key,
            value: value,
          });
        }
      } else if (!_.isUndefined(type) && type === 'checkbox') {
        if (!_.isUndefined(checked) && checked.length !== 0) {
          attributes.push({
            key: key,
            value: checked,
          });
        }
      } else {
        console.log(this.getRef(key));
      }
    });
    return attributes;
  }
}
