import React from 'react';
import moment from 'moment';
import { Activation } from '../omniwe';

export default ({ item, field: { headerClassName = '', type, render, dataKey, fieldName, align = 'left' } }) => {
  const key = dataKey || fieldName,
    value = item[key];
  const alignClass = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  }[align];
  if (render) {
    try {
      return <td className={`${alignClass} ${headerClassName}`}>{render(item)}</td>;
    } catch (e) {
      return <td className={`${alignClass} ${headerClassName}`} />;
    }
  }
  switch (type) {
    case 'bool':
      return (
        <td className={`text-center ${headerClassName}`}>
          <Activation active={value} />
        </td>
      );
    case 'datetime':
      return (
        <td className={`text-right text-truncate ${headerClassName}`}>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</td>
      );
    default:
      return <td className={`${alignClass} ${headerClassName}`}>{value}</td>;
  }
};
