import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { toast } from 'react-toastify';
import errorParser from 'shared/errorParser';
import ActivityIndicator from '../../components/ActivityIndicator';
import gql from 'graphql-tag';

const REGISTER_PAYMENT = gql`
  mutation($shopId: ID!, $platform: ShopCredentialPlatform!) {
    companyShopCredentialRegister(shopId: $shopId, platform: $platform) {
      id
      credentials {
        platform
        active
        identity
      }
    }
  }
`;

export default class extends Component {
  state = {
    agree: false,
    isOpen: false,
    resolve: null,
    reject: null
  };

  start = () => {
    return new Promise((resolve, reject) => {
      this.setState({ agree: false, isOpen: true, resolve, reject });
    });
  };

  onCancelClick = () => {
    const { reject } = this.state;
    reject && reject();
    this.setState({ isOpen: false, reject: null, resolve: null });
  };

  onConfirmClick = async () => {
    const { resolve } = this.state;
    try {
      ActivityIndicator.show();
      this.setState({ isOpen: false, reject: null, resolve: null });

      await client.mutate({
        mutation: REGISTER_PAYMENT,
        variables: {
          shopId: localStorage.getItem('shopId'),
          platform: 'STRIPE_CONNECT_CUSTOM'
        }
      });
      toast.success(translate.act_success);
      resolve && resolve();
    } catch (e) {
      toast.error(errorParser(e, translate.register_fail_message));
    } finally {
      ActivityIndicator.hide();
    }
  };

  render() {
    const { agree, isOpen } = this.state;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {translate.stripe_connect} {translate.register}
        </ModalHeader>
        <ModalBody>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value={agree}
              onClick={() => {
                this.setState({ agree: !agree });
              }}
              id={'agreement'}
            />
            <label className='form-check-label' htmlFor='agreement'>
              {translate.agreement_confirmation}{' '}
              <a
                href={'https://stripe.com/en-hk/connect-account/legal'}
                target={'_blank'}
              >
                {translate.view}
              </a>
            </label>
          </div>
        </ModalBody>
        <ModalFooter className={'d-flex flex-row justify-content-between'}>
          <button className={'btn btn-light'} onClick={this.onCancelClick}>
            {translate.cancel}
          </button>
          <button
            className={'btn btn-dark'}
            onClick={this.onConfirmClick}
            disabled={!agree}
          >
            {translate.submit}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
