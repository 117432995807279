import React, { Component } from 'react';
import { Icon } from '../../iconRender';

class CardHeader extends Component {
  render() {
    const {
      icon,
      title,
      extra,
      loading = false,
      whiteBackground = false
    } = this.props;
    return (
      <div className={`card-header ${whiteBackground ? 'bg-white' : ''}`}>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <div>
            {icon && <Icon icon={icon} className='mr-1' />}
            {title}
            {loading && <Icon icon='faSpinner' className='mx-1 fa-lg' spin />}
          </div>
          <div>{extra}</div>
        </div>
      </div>
    );
  }
}

export default CardHeader;
