import React from 'react';
import List from './../list';

import Card from './../layout/card/Card';
import CardHeader from './../layout/card/CardHeader';
import Filter from './../filter';
import ListNoData from './../pageComponents/ListNoData';
import { PaginationWithDiv } from '../pagination';

import TableHeader from './TableHeader';
import TableRow from './TableRow';

class TableList extends List {
  controls = () => {
    const { filters = [] } = this.props;
    return <Filter fields={filters} />;
  };

  render() {
    const {
      fields = [],
      data = [],
      loading = false,
      refetch = null
    } = this.props;

    return (
      <Card>
        <CardHeader title={this.controls()} whiteBackground />
        <div className='card-body p-0'>
          <table className='table table-hover m-0'>
            <TableHeader fields={fields} />
            <tbody>
              {data.map((row, idx) => (
                <TableRow
                  key={idx}
                  data={row}
                  datatypes={fields.map(f => f.type)}
                  colAlignments={fields.map(f => f.align)}
                />
              ))}
            </tbody>
          </table>
          {data.length === 0 && <ListNoData />}
          <PaginationWithDiv
            numberOfCount={data.length}
            loading={loading}
            offset={this.offset}
            prev={() => this.prev(refetch)}
            next={() => this.next(refetch)}
          />
        </div>
      </Card>
    );
  }
}

export default TableList;
