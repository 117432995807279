import React from 'react';

export default ({ nickname, currency, amount, interval, onClick }) => (
  <div key={`pricing-card-${nickname}`} className='col-lg-4 text-center'>
    <div className='card h-100 py-5'>
      <div className='card-body'>
        <h5 className='card-title text-danger text-capitalize font-weight-bold'>
          {nickname}
        </h5>
        <small className='text-secondary text-uppercase mr-1'>{currency}</small>
        <h2 className='card-title d-inline-block font-weight-bold'>
          {amount / 100}
        </h2>
        <small className='text-secondary'> / {interval}</small>
        <p className='card-text text-secondary'>
          <span className='text-uppercase'>{currency}</span>{' '}
          {(amount / 100 / 12).toFixed(2)} / month
        </p>
      </div>
      <div className='align-bottom'>
        <button
          className='btn btn-danger btn-lg'
          type='button'
          onClick={onClick}
        >
          SELECT
        </button>
      </div>
    </div>
  </div>
);
