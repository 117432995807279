import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import { translate } from 'shared/translate';
import { POST_QUERY } from './query';
import AddressCard from './AddressCard';
import OrderItemsCard from '../item_Order/ItemsCard';
import NewDeliveryNoteItemCard from './NewDeliveryNoteItemCard';
import { client } from 'shared/apollo';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ link: '/delivery_notes', label: translate.delivery_notes }, { label: translate.new_delivery_note }],
    gql: {
      submit: POST_QUERY,
    },
    cards: [AddressCard, OrderItemsCard, NewDeliveryNoteItemCard],
  };

  submit = async ({ id, items = [] }) => {
    const {
      gql: { submit },
    } = this.state;
    const { data: { orderDeliveryNoteCreate } = {} } = await client.mutate({
      mutation: submit,
      variables: {
        orderId: id,
        input: {
          items: items
            .map(item => ({
              orderItemId: item.id,
              quantity: item.quantity - 0,
            }))
            .filter(item => !!item && !!item.quantity && !!item.orderItemId),
        },
      },
    });
    return orderDeliveryNoteCreate.id;
  };

  getInitialData() {
    const {
      location: { state: { order } = {} },
    } = this.props;
    return {
      ...order,
      orderId: order.id,
      items: (order.items || []).map(item => ({ ...item, maxQuantity: item.quantity || 1 })),
    };
  }
}
