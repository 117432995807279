import React, { Component } from 'react';

import { translate } from '../../shared/translate';

import ContentPageLayout from '../../components/layout/ContentPageLayout';
import TableList from '../../components/table/TableList';

class SupportTicketList extends Component {
  state = {
    trail: [{ to: null, label: translate.page_title_support_ticket }],
    fields: [
      { title: 'Ticket #', align: 'left', type: 'link' },
      { title: 'Customer', align: 'center', type: 'text' },
      { title: 'Contact', align: 'center', type: 'text' },
      { title: 'Status', align: 'center', type: 'text' },
      { title: 'Last Reply', align: 'center', type: 'text' },
      { title: 'Created At', align: 'right', type: 'datetime' },
      { title: 'Updated', align: 'right', type: 'datetime' }
    ],
    bodydata: [
      [
        { display: '5cf4c3eef13c9a7c4daf017d', to: '/support_tickets' },
        'David Blaine',
        '9123 1234',
        'Pending',
        'Owner',
        '2019-05-31T03:25:31.232Z',
        '2019-05-31T03:25:31.232Z'
      ]
    ]
  };
  render() {
    return (
      <ContentPageLayout trail={this.state.trail}>
        <TableList fields={this.state.fields} data={this.state.bodydata} />
      </ContentPageLayout>
    );
  }
}

export default SupportTicketList;
