import React, { Fragment } from 'react';
import { Icon } from './iconRender';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default ({ offset: { skip = 0, limit = 20 } = {}, count = 0, setOffset, display = 5, disabled }) => {
  const current = skip / limit + 1;
  const total = Math.ceil(count / limit);
  if (total === 1) return <div />;

  const displayHalf = ~~(display / 2);
  const hasLeftMost = current - displayHalf > 1,
    hasRightMost = current + displayHalf < total;

  const prev = () => {
    setOffset({
      skip: skip <= limit ? 0 : skip - limit,
      limit,
    });
  };

  const next = () => {
    setOffset({
      skip: skip + limit >= count ? count : skip + limit,
      limit,
    });
  };

  return (
    <Pagination size={'sm'} listClassName={'mb-0'}>
      <PaginationItem disabled={disabled || current === 1}>
        <PaginationLink type={'button'} onClick={prev}>
          <Icon icon="faCaretLeft" />
        </PaginationLink>
      </PaginationItem>
      {hasLeftMost && (
        <Fragment>
          <PaginationItem disabled={disabled}>
            <PaginationLink type={'button'} onClick={() => setOffset({ skip: 0, limit })}>
              1
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={true}>
            <PaginationLink type={'button'}>...</PaginationLink>
          </PaginationItem>
        </Fragment>
      )}
      {Array(display)
        .fill(null)
        .map((_, i) => {
          if (current + displayHalf >= total) return total - display + i + 1;
          return Math.max(current - displayHalf, 1) + i;
        })
        .filter(i => i >= 1 && i <= total)
        .map(i => (
          <PaginationItem key={i} disabled={current === i || disabled}>
            <PaginationLink type={'button'} onClick={() => setOffset({ skip: (i - 1) * limit, limit })}>
              {i}
            </PaginationLink>
          </PaginationItem>
        ))}
      {hasRightMost && (
        <Fragment>
          <PaginationItem disabled={true}>
            <PaginationLink type={'button'}>...</PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={disabled}>
            <PaginationLink type={'button'} onClick={() => setOffset({ skip: (total - 1) * limit, limit })}>
              {total}
            </PaginationLink>
          </PaginationItem>
        </Fragment>
      )}
      <PaginationItem disabled={disabled || current === total}>
        <PaginationLink type={'button'} onClick={next}>
          <Icon icon="faCaretRight" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};
