// 市場服務-商家可以申請的服務列表（商家查看）
// src/routers/list_market_service.jsx
// created on april-29, 2019, by Tony Day
import React from "react";
import { translate as t } from "../../shared/translate";
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import _ from "lodash";
import moment from "moment";
import * as PU from "../../components/pageComponents/utility";
import GenericList from "../../components/GenericList";
import Loading from "../../components/loading";
import PageError from "../../components/pageComponents/PageError";

const AGENT_BINDING_QUERY = gql`
  query($shopId: ID) {
    node(id: $shopId) {
      id
      ... on Shop {
        agent {
          id
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

const _tableHeader = () => (
  <thead>
    <tr>
      <th className="border-top-0 text-truncate">{t.name}</th>
      <th style={{ width: 150 }} className="border-top-0 text-truncate">
        {t.poster}
      </th>
      <th style={{ width: 100 }} className="border-top-0 text-truncate">
        {t.opening}
      </th>
      <th
        style={{ width: 140 }}
        className="border-top-0 text-right text-truncate"
      >
        {t.created_at}
      </th>
      <th
        style={{ width: 140 }}
        className="border-top-0 text-right text-truncate"
      >
        {t.updated_at}
      </th>
    </tr>
  </thead>
);

const _item = item => (
  <tr>
    <td>
      <Link to={"market_services/" + item.id}>{item.title}</Link>
    </td>
    <td>{item.poster}</td>
    <td>
      <PU.Activation active={item.active} />
    </td>
    <td className="text-right text-truncate">
      {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
    </td>
    <td className="text-right text-truncate">
      {moment(item.updatedAt).format("YYYY-MM-DD HH:mm")}
    </td>
  </tr>
);

const GET_QUERY = gql`
  # MERCHANT QUERY AGENT SERVICES
  query($shopId: ID, $offset: OffsetInput) {
    node(id: $shopId) {
      id
      ... on Shop {
        agent {
          id
          attributes {
            key
            value
          }
          agentServices(offset: $offset) {
            id
            title
            active
            createdAt
            updatedAt
          }
          agentServicesCount
        }
      }
    }
  }
`;

export default class Page extends GenericList {
  getData = data => {
    this.fillRows();
    const {
      node: {
        agent: {
          attributes = [],
          agentServices = [],
          agentServicesCount = 0
        } = {}
      } = {}
    } = data || {};
    const items = this.fillRows(agentServices, attributes);
    let obj = {
      items,
      count: agentServicesCount
    };
    return obj;
  };

  fillRows = (items, attributes) => {
    if (!items) {
      return;
    }

    let poster = "- ? -";
    const foundName = _.find(attributes, { key: "name" });
    if (foundName) {
      poster = foundName.value;
    }

    return items.map(item => {
      let newItem = { ...item };
      newItem.poster = poster;
      return newItem;
    });
  };

  constructor(props) {
    const shopId = localStorage.getItem('shopId');
    const settings = {
      title: t.service_market_list,
      query: GET_QUERY,
      tableHeader: _tableHeader,
      renderItem: _item,
      getData: data => this.getData(data),
      variables: () => ({
        offset: this.offset,
        filter: this.filterInput,
        shopId
      })
    };
    super(props, settings);
    this.shopId = shopId;
  }

  render() {
    const shopId = this.shopId;
    return (
      <Query query={AGENT_BINDING_QUERY} variables={{ shopId }}>
        {({ loading, error, data: { node } }) => {
          if (loading) return <Loading />;
          if (error) return <PageError errors={error} />;
          if (node && node.agent) {
            return this.renderList();
          }

          return <p>{t.no_agent_binding}</p>;
        }}
      </Query>
    );

    //return this.renderList();
  } // end render()
} // end class
