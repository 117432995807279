import React from 'react';
import Form from '../../components/form';
import { Link } from 'react-router-dom';
import Route from '../../components/route';

import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import Loading from '../../components/loading';
import CollectionSearch from '../../components/search_collection';
import ProductSearch from '../../components/search_product';
import { client } from '../../shared/apollo';

import moment from 'moment';

import _ from 'lodash';
import { translate as t } from '../../shared/translate';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Discount {
        id
        name
        publishAt
        publishThru
        valid
        repeat
        discardSubsequent
        actions {
          actionType
          actionId
          actionItem {
            name
          }
          actionValueType
          actionValue
        }
        targets {
          targetType
          targetId
          targetItem {
            name
          }
          targetValueType
          targetValue
        }
        attributes {
          key
          value
        }
        createdAt
      }
    }
    me {
      id
      companies {
        id
        attributes {
          key
          value
        }
        shops {
          id
          title
        }
      }
    }
  }
`;

const POST_QUERY = gql`
  mutation discountSet($id: ID, $input: DiscountInput!) {
    discountSet(id: $id, input: $input) {
      id
      name
      publishAt
      publishThru
      valid
      repeat
      actions {
        actionType
        actionId
        actionValueType
        actionValue
      }
      targets {
        targetType
        targetId
        targetValueType
        targetValue
      }
      attributes {
        key
        value
      }
      createdAt
    }
  }
`;

const REMOVE_QUERY = gql`
  mutation($id: ID!) {
    discountUnset(id: $id) {
      id
    }
  }
`;

export default class Coupon extends Form {
  constructor(props) {
    super(props);

    const {
      match: { params = {} },
    } = this.props;

    this.state = {
      showRemoveBtn: false,
      currentId: params.id === 'new' ? undefined : params.id,
    };
  }

  async removeItem(client) {
    try {
      await client.mutate({
        mutation: REMOVE_QUERY,
        variables: {
          id: this.state.currentId,
        },
      });
      toast.success(t.discount_has_been_deleted);
      this.props.history.goBack();
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  async submit() {
    if (this.getCurrentRef(`targetType`).value === 'COLLECTION' && !this.targetId) {
      toast.warn(t.discount_select_prod_type);
      return;
    }

    if (this.getCurrentRef(`targetType`).value === 'PRODUCT' && !this.targetId) {
      toast.warn(t.discount_select_prod);
      return;
    }

    if (this.getCurrentRef(`targetType`).value === 'ALL' || this.getCurrentRef(`targetType`).value === 'SUBTOTAL') {
      if (this.getCurrentRef(`actionType`).value === 'COLLECTION' && !this.actionId) {
        toast.warn(t.discount_select_prod_type);
        return;
      }

      if (this.getCurrentRef(`actionType`).value === 'PRODUCT' && !this.actionId) {
        toast.warn(t.discount_select_prod);
        return;
      }
    }

    let attributes = await this.setAttributes(['description', 'remark']);

    try {
      const { data: { discountSet: result } = {} } = await client.mutate({
        mutation: POST_QUERY,
        variables: {
          id: this.state.currentId,
          input: {
            shopId: localStorage.getItem('shopId'),
            valid: this.getCurrentRef(`valid`).checked,
            priority: this.getCurrentRef(`priority`).value,
            name: this.getCurrentRef('name').value,
            publishAt: this.getCurrentRef('publishAt').value,
            publishThru: moment(this.getCurrentRef('publishThru').value).endOf('day'),
            discardSubsequent: this.getCurrentRef(`discardSubsequent`).checked,

            targets: [
              {
                targetType: this.getCurrentRef(`targetType`).value,
                targetId: this.targetId,
                targetValueType:
                  this.getCurrentRef(`targetType`).value === 'ALL'
                    ? 'AMOUNT'
                    : this.getCurrentRef('targetValueType').value,
                targetValue:
                  this.getCurrentRef(`targetType`).value === 'ALL' ? 0 : this.getCurrentRef(`targetValue`).value,
              },
            ],

            actions: [
              {
                actionType: this.getCurrentRef(`actionType`).value,
                actionId: this.actionId,
                actionValueType: this.getCurrentRef('actionValueType').value,
                actionValue: this.getCurrentRef(`actionValue`).value,
              },
            ],
            repeat: this.getCurrentRef('repeat').value,
            attributes: attributes,
          },
        },
      });
      toast.success(!this.state.currentId ? t.discount_added_success : t.discount_updated_success);
      if (!this.state.currentId) {
        this.setState({ currentId: result.id });
        this.props.history.replace('/discounts/' + result.id, {
          id: result.id,
        });
        client.resetStore();
      }
      this.setState({});
    } catch (e) {
      console.log(e);
      // GraphQL errors can be extracted here
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  }

  select(field, value) {
    this[field] = value.id;
  }

  // addItems() {
  //   this.targetGroups.push({
  //     type: "COLLECTION",
  //     quantity: 0
  //   });
  //   this.setState({});
  // }
  //
  // removeItem(index) {
  //   this.targetGroups.splice(index, 1);
  //   this.setState({});
  // }

  render() {
    return (
      <Query query={GET_QUERY} variables={{ id: this.state.currentId }}>
        {({
          client,
          loading,
          error: { message } = {},
          data,
          data: { node = {}, me: { companies = [] } = {} } = {},
        }) => {
          if (loading && data !== {}) return <Loading />;

          if (message) {
            return (
              <div>
                <h5>Error :(</h5>
                <p>{message}</p>
                <Route {...this.props} text={t.back} />
              </div>
            );
          }

          const {
            updatedAt,
            attributes = [],
            products = [],
            valid,
            name,
            publishAt,
            publishThru,
            membersOnly,
            discardSubsequent,
            actions: [{ actionType, actionId, actionItem, actionValueType, actionValue } = {}] = [],
            targets: [{ targetType = 'ALL', targetId, targetItem, targetValueType, targetValue } = {}] = [],
            priority = 0,
            repeat = 1,
          } = node || {};

          const { name: targetItemName } = targetItem || {};

          const { name: actionItemName } = actionItem || {};

          this.membersOnly = this.membersOnly || membersOnly || false;

          this.targetType = this.targetType || targetType;
          this.actionType = this.actionType || actionType;

          this.targetId = this.targetId || (this.targetType === targetType ? targetId : undefined);
          this.actionId = this.actionId || (this.actionType === actionType ? actionId : undefined);

          let { value: actionTypeValue = actionType } = this.getCurrentRef('actionType') || {};

          let { value: shopFilter = companies[0].id } = this.getCurrentRef('shopFilter') || {};

          let attrs = _.groupBy(attributes, 'key');
          const { description: [{ value: description } = {}] = [], remark: [{ value: remark } = {}] = [] } = attrs;

          // this.targets = this.targets || targets;

          // if(this.targetGroups.length === 0) {
          //   this.addItems();
          // }

          return (
            <div className="container-fluid mb-5">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={'/discounts'}>{t.discount_list}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {!this.state.currentId && <span>{t.discount_add}</span>}
                  {this.state.currentId && <span>{this.state.currentId}</span>}
                </li>
              </ul>

              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.submit();
                }}
              >
                <div className="card mb-5 shadow">
                  <div className="card-header d-flex">
                    <h6 className="mb-0 flex-fill">{t.discount_details}</h6>
                    {this.state.currentId && (
                      <div className="btn-group btn-group-sm">
                        {this.state.showRemoveBtn && (
                          <button
                            className="btn btn-danger no-animate ng-scope"
                            type="button"
                            onClick={() => this.removeItem(client)}
                          >
                            <i className="fa fa-fw fa-exclamation-circle" />
                            <span className="ng-scope">{t.discount_delete}</span>
                          </button>
                        )}
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() =>
                            this.setState({
                              showRemoveBtn: !this.state.showRemoveBtn,
                            })
                          }
                        >
                          {!this.state.showRemoveBtn && <i className="fa fa-caret-left" />}
                          {this.state.showRemoveBtn && <i className="fa fa-caret-right" />}
                          <i className="fa fa-fw fa-trash" />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label htmlFor="name" className="col-sm-2 col-form-label">
                        {t.availability}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <div className="form-check pt-2">
                          <input
                            ref={this.getRef('valid')}
                            className="form-check-input"
                            type="checkbox"
                            id="valid"
                            defaultChecked={valid}
                          />
                          <label className="form-check-label" htmlFor="valid">
                            {t.yes}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="address" className="col-sm-2 col-form-label">
                        {t.discount_name}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <input
                          required
                          ref={this.getRef('name')}
                          type="text"
                          className="form-control"
                          id="name"
                          defaultValue={name}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="description" className="col-sm-2 col-form-label">
                        {t.desc_of_use}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <textarea
                          ref={this.getRef('description')}
                          type="text"
                          className="form-control"
                          id="description"
                          defaultValue={description}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="remark" className="col-sm-2 col-form-label">
                        {t.remark_for_backend}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <input
                          ref={this.getRef('remark')}
                          type="text"
                          className="form-control"
                          id="remark"
                          defaultValue={remark}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="profit" className="col-sm-2 col-form-label">
                        {t.available_date}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <label className="input-group-text" htmlFor="profit">
                                  {t.available_date_from}
                                </label>
                              </div>
                              <input
                                ref={this.getRef('publishAt')}
                                type="date"
                                className="form-control"
                                id="publishAt"
                                required={true}
                                defaultValue={moment(publishAt).format('YYYY-MM-DD')}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="input-group">
                              <div className="input-group-append">
                                <label className="input-group-text" htmlFor="profit">
                                  {t.available_date_to}
                                </label>
                              </div>
                              <input
                                ref={this.getRef('publishThru')}
                                type="date"
                                className="form-control"
                                required={true}
                                id="publishThru"
                                defaultValue={moment(publishThru).format('YYYY-MM-DD')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="priority" className="col-sm-2 col-form-label">
                        {t.discount_order}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <input
                          ref={this.getRef('priority')}
                          type="number"
                          step={1}
                          className="form-control"
                          id="priority"
                          required={true}
                          defaultValue={priority}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="discardSubsequent" className="col-sm-2 col-form-label">
                        {t.discount_discount_after_expiry}
                      </label>
                      <div className="col-xl-5 col-lg-6 col-md-5">
                        <div className="form-check pt-2">
                          <input
                            ref={this.getRef('discardSubsequent')}
                            className="form-check-input"
                            type="checkbox"
                            id="discardSubsequent"
                            defaultChecked={discardSubsequent}
                          />
                          <label className="form-check-label" htmlFor="discardSubsequent">
                            {t.yes}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-5 shadow">
                  <div className="card-header">{t.discount_condition}</div>
                  <div className="card-body">
                    <div className="form-group">
                      {/*<label htmlFor="type" className="form-label">類型</label>*/}
                      <div className="row">
                        <div className="col">
                          <select
                            ref={this.getRef('targetType')}
                            className="form-control"
                            id="targetType"
                            defaultValue={this.targetType}
                            onChange={e => {
                              this.targetType = e.target.value;
                              this.targetId = undefined;
                              this.setState({});
                            }}
                          >
                            <option value="ALL">{t.discount_condition_all}</option>
                            <option value="COLLECTION">{t.discount_condition_collection}</option>
                            <option value="PRODUCT">{t.discount_condition_product}</option>
                            {false && <option value="COMBINATION">{t.discount_condition_combination}</option>}
                            <option value="SUBTOTAL">{t.discount_condition_subtotal}</option>
                          </select>
                        </div>
                        <div className="col-4">
                          {this.targetType !== 'ALL' && (
                            <div className="input-group">
                              <select
                                className="form-control"
                                ref={this.getRef(`targetValueType`)}
                                defaultValue={targetValueType}
                              >
                                <option value="AMOUNT">{t.discount_min_amount}</option>
                                <option value="QUANTITY">{t.discount_min_qty}</option>
                              </select>
                              <input
                                type="number"
                                step={1}
                                ref={this.getRef(`targetValue`)}
                                className="form-control"
                                required={true}
                                defaultValue={targetValue}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {this.targetType === 'COLLECTION' && (
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <CollectionSearch
                              select={item => this.select('targetId', item)}
                              placeholder={`Search by collection`}
                              defaultValue={this.targetType === targetType ? targetItemName : ''}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {this.targetType === 'PRODUCT' && (
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <ProductSearch
                              select={item => this.select('targetId', item)}
                              placeholder={`Search by product`}
                              defaultValue={this.targetType === targetType ? targetItemName : ''}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {false && this.targetType === 'COMBINATION' && (
                    <div className={`card-body p-0`}>
                      <table className="table">
                        <colgroup>
                          <col width="*" />
                          <col width="*" />
                          <col width="100" />
                          <col width="1" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>{t.group}</th>
                            <th>{t.items}</th>
                            <th>{t.quantity}</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {this.targetGroups.map(({ type }, index) => {
                            return (
                              <tr>
                                <td>
                                  <select
                                    ref={this.getRef('targetType')}
                                    className="form-control form-control-sm"
                                    id="targetType"
                                    onChange={e => {
                                      this.targetGroups[index].type = e.target.value;
                                      this.setState({});
                                    }}
                                    defaultValue={this.targetType}
                                  >
                                    <option value="COLLECTION">{t.product_collections}</option>
                                    <option value="PRODUCT">{t.product}</option>
                                  </select>
                                </td>
                                <td>
                                  {type === 'COLLECTION' && (
                                    <CollectionSearch
                                      inputClass={`form-control-sm`}
                                      placeholder={`Search by collection`}
                                    />
                                  )}

                                  {type === 'PRODUCT' && (
                                    <ProductSearch inputClass={`form-control-sm`} placeholder={`Search by product`} />
                                  )}
                                </td>
                                <td>
                                  <input type="number" className="form-control form-control-sm" />
                                </td>
                                <td>
                                  <botton
                                    type="button"
                                    className="btn btn-link text-danger btn-sm"
                                    disabled={this.targetGroups.length === 1}
                                    onClick={() => this.removeItem(index)}
                                  >
                                    <i className="fa fa-times" />
                                  </botton>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={99}>
                              <button
                                type={`button`}
                                className="btn btn-sm btn-outline-secondary text-dark"
                                onClick={() => this.addItems()}
                              >
                                {t.discount_add_condition}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className="card mb-5 shadow">
                  <div className="card-header">{t.discount_offer_list}</div>
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-lg-8">
                        <select
                          ref={this.getRef('actionType')}
                          className="form-control"
                          id="actionType"
                          defaultValue={this.actionType}
                          onChange={e => {
                            this.actionType = e.target.value;
                            this.actionId = undefined;
                            this.setState({});
                          }}
                        >
                          {this.targetType !== 'PRODUCT' && (
                            <option value="COLLECTION">{t.discount_for_collection}</option>
                          )}
                          {this.targetType !== 'COLLECTION' && (
                            <option value="PRODUCT">{t.discount_for_product}</option>
                          )}
                          <option value="ORDER_DISCOUNT">{t.discount_for_order}</option>
                        </select>
                      </div>
                      <div className="col">
                        <div className="input-group">
                          <select
                            className="form-control"
                            ref={this.getRef(`actionValueType`)}
                            defaultValue={actionValueType}
                          >
                            <option value="PERCENTAGE">% {t.discount_off}</option>
                            <option value="FIXED">{t.discount_fixed_amount}</option>
                            {this.actionType !== 'ORDER_DISCOUNT' && (
                              <option value="QUANTITY">{t.discount_gift}</option>
                            )}
                          </select>
                          <input
                            type="number"
                            step={1}
                            ref={this.getRef(`actionValue`)}
                            defaultValue={actionValue}
                            className="form-control"
                            required={true}
                          />
                        </div>
                      </div>
                    </div>

                    {(this.targetType === 'SUBTOTAL' || this.targetType === 'ALL') &&
                      this.actionType !== 'ORDER_DISCOUNT' && (
                        <div className="form-group row">
                          <div className="col">
                            {this.actionType === 'PRODUCT' && (
                              <ProductSearch
                                select={item => this.select('actionId', item)}
                                placeholder={`Search by product`}
                                defaultValue={this.actionType === actionType ? actionItemName : ''}
                              />
                            )}
                            {this.actionType === 'COLLECTION' && (
                              <CollectionSearch
                                select={item => this.select('actionId', item)}
                                placeholder={`Search by collection`}
                                defaultValue={this.actionType === actionType ? actionItemName : ''}
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="card-footer">
                    <div className="form-group">
                      <label htmlFor="address" className="form-label">
                        {t.discount_repeat_times}
                      </label>
                      <div className="row">
                        <div className="col-2">
                          <div className="input-group">
                            <input
                              type="number"
                              step={1}
                              ref={this.getRef(`repeat`)}
                              className="form-control"
                              min={1}
                              defaultValue={repeat}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">次</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <Route {...this.props} text={t.cancel} />
                    <button type="submit" className="btn btn-dark shadow pull-right">
                      {t.save}
                    </button>
                  </div>
                  {this.state.currentId && (
                    <div className="card-footer d-flex justify-content-end">
                      <i className="small text-muted">
                        {t.updated_at}:{moment(updatedAt).format(' YYYY-MM-DD HH:mm:ss')}
                      </i>
                    </div>
                  )}
                </div>
              </form>
            </div>
          );
        }}
      </Query>
    );
  }
}
