import React, { Component } from 'react';
import { gql } from "apollo-boost";
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import Form  from '../../components/form';

export default class extends Form {
  constructor(props) {
    super(props);
    this.state = {};

    if(localStorage.getItem('token')) {
      this.props.history.replace('/');
    }
  }

  async submit(callback) {
    if(!this.refs['email'].value) {
      toast.error('電郵必須填寫。');
      this.refs['email'].focus();
      return;
    }

    if(!this.refs['password'].value) {
      toast.error('密碼必須填寫。');
      this.refs['password'].focus();
      return;
    }

    if(!this.refs['confirmPassword'].value) {
      toast.error('確認密碼必須填寫。');
      this.refs['confirmPassword'].focus();
      return;
    }

    if(this.refs['password'].value !== this.refs['confirmPassword'].value) {
      toast.error('密碼和確認密碼必須相同。');
      return;
    }

    const attributes = await this.setAttributes(['identity']);

    callback({
      variables: {
        user: {
          attributes: []
        },
        credential: {
          platform: 'PASSWORD',
          identity: this.refs['email'].value,
          secret: this.refs['password'].value
        }
      }
    }).then(({data}) => {
      toast.success('註冊成功！');
      this.props.history.push('/login')
    }).catch((e) => {
      // GraphQL errors can be extracted here
      if(e.graphQLErrors) {
        toast.error(e.message);
      }
    });
  }

  render() {
    return (
      <div className="container mt-5">
        <form>
          <div className="card rounded-0 mb-5 shadow">
            <div className="card-header">
              <h6 className="mb-0 text-center">註冊</h6>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="form.name" className="control-label">電郵</label>
                <input ref="email" className="form-control" type="email" id="form.email"/>
              </div>

              <div className="form-group">
                <label htmlFor="form.name" className="control-label">姓名</label>
                <input ref="name" className="form-control" type="email" id="form.email"/>
              </div>

              <div className="form-group">
                <label htmlFor="form.name" className="control-label">密碼</label>
                <input ref="password" className="form-control" type="password" id="form.password"/>
              </div>
              <div className="form-group">
                <label htmlFor="form.name" className="control-label">確認密碼</label>
                <input ref="confirmPassword" className="form-control" type="password" id="form.confirmPassword"/>
              </div>
            </div>
            <div className="card-footer">
              <Mutation mutation={ gql`
                mutation userRegister($user: UserInput!, $credential: UserCredentialInput!) {
                  userRegister(user: $user, credential: $credential) {
                    id
                  }
                }` }>
                { (userRegister, {data}) => (
                  <button type="button" className="btn btn-dark shadow"
                          onClick={ () => this.submit(userRegister) }>註冊</button>
                ) }
              </Mutation>
              <button type="button" className="btn btn-outline-secondary text-dark border-0 shadow pull-right" onClick={() => this.props.history.push('/login')}>返回登入</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
