// React component of generic list page.
// src/components/GenericList.jsx
// created on april-25, 2019, by Tony Day
import React from "react";
import { Query } from "react-apollo";
import List from "./list";
import ListLoading from "./pageComponents/LoadingList";
import PageError from "./pageComponents/PageError";
import ListNoData from "./pageComponents/ListNoData";
import CrumbNavi from "./pageComponents/CrumbNavi";
import { PaginationWithDiv } from "./pagination";
import * as PU from "./pageComponents/utility";

const _mainActions = ({ tableTools }) => (
  <div className="card-header text-right d-flex align-items-center bg-white">
    <div className="flex-fill">&nbsp;</div>
    {tableTools && <tableTools />}
  </div>
);

export default class GenericList extends List {
  constructor(props, listSettings) {
    super(props);

    this.offset = {
      skip: 0,
      limit: 20
    };

    /** list page settings */
    const defaultSettings = {
      /**list query for graphql api */
      query: null,
      variables: () => ({ offset: this.offset, filter: this.filterInput }),
      /** module title */
      title: "untitled",
      /** (data):(items:[], count:Number) */
      getData: null,
      renderItem: null,
      tableHeader: null
    };

    this.list = Object.assign(defaultSettings, listSettings);
  }

  renderList = () => {
    return (
      <div className="container-fluid mb-5">
        <CrumbNavi items={[this.list.title]} />
        <Query query={this.list.query} variables={this.list.variables()}>
          {({ loading, error, data, refetch }) => {
            if (error) return <PageError errors={error} />;
            const { items, count } = this.list.getData(data);
            const hasData = items && items.length;

            return (
              <div className="card rounded-0 shadow" key="table">
                <_mainActions />
                <div className="card-body p-0">
                  <table className="table table-hover table-responsive-md mb-0">
                    <this.list.tableHeader />
                    <tbody>
                      {!loading &&
                        items.map(item => (
                          <this.list.renderItem key={item.id} {...item} />
                        ))}
                      {loading && <ListLoading />}
                      {!loading && !hasData && <PU.EmptyList />}
                    </tbody>
                  </table>
                </div>
                {!loading && items.length === 0 && <ListNoData />}
                <PaginationWithDiv
                  numberOfCount={count}
                  loading={loading}
                  offset={this.offset}
                  prev={() => this.prev(refetch)}
                  next={() => this.next(refetch)}
                />
              </div>
            );
          }}
        </Query>
      </div>
    ); // end return
  }; // end render()
} // end class
