import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID, $warehouseId: ID!) {
    node(id: $id) {
      id
      ... on Product {
        remarkSet
        name
        description
        collection {
          id
          name
        }
        attributes {
          key
          value
        }
        images
        weight
        sortIndex
        variations {
          id
          sku
          price
          attributes {
            key
            value
          }
          stockItems {
            stockItem {
              id
              name
              productVariantId
              quantity(warehouseId: $warehouseId)
            }
            cost
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const POST_QUERY = gql`
  mutation productSet($id: ID, $input: ProductInput!, $warehouseId: ID!) {
    productSet(id: $id, input: $input) {
      id
      name
      attributes {
        key
        value
      }
      remarkSet
      variations {
        id
        sku
        price
        attributes {
          key
          value
        }
        stockItems {
          stockItem {
            id
            name
            productVariantId
            quantity(warehouseId: $warehouseId)
          }
          cost
        }
      }
      images
      updatedAt
      createdAt
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation productUnset($id: ID!) {
    productUnset(id: $id) {
      id
    }
  }
`;
