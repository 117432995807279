import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!, $limit: Int) {
    node(id: $id) {
      id
      ... on Shop {
        products(offset: { limit: $limit }) {
          id
          images(offset: { limit: 1 })
        }
      }
    }
  }
`;
