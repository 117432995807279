import React from 'react';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { translate as t } from '../../../shared/translate';
import { Icon, ToggleButton, toast } from '../../../components/omniwe';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const QUERY = gql`
  mutation($id: ID!, $updateAll: Boolean) {
    venueSlotUnset(id: $id, updateAll: $updateAll) {
      id
    }
  }
`;

export default ({ slotId, updateAll, afterDeleted }) => {
  const submit = async post => {
    await post();
    toast.success(t.delete_success);
    afterDeleted();
  };

  return (
    <Mutation mutation={QUERY} variables={{ id: slotId, updateAll }}>
      {(post, { loading }) => (
        <ToggleButton
          color="danger"
          side={t.confirm_to_delete}
          immutable={<Icon icon={faTrash} />}
          action={() => submit(post)}
          disabled={loading}
        />
      )}
    </Mutation>
  );
};
