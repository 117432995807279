import React from 'react';
import { Link } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { translate as t } from '../../shared/translate';
import ListPageMaker from '../../components/listPageMaker';

const GET_QUERY = gql`
  query($offset: OffsetInput, $filter: FilterInput) {
    list: users(filter: $filter, offset: $offset) {
      id
      attributes(keys: ["name"]) {
        key
        value
      }
      groups {
        shop {
          id
          title
        }
        name
      }
      updatedAt
      createdAt
    }
    count: usersCount(filter: $filter)
  }
`;

export default class Products extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: t.user_list }],
    gql: {
      get: GET_QUERY
    },
    createUrl: '/users/new',
    fields: [
      {
        title: t.name,
        render: ({ attributes, id }) => {
          const name = (attributes.find(attr => attr.key === 'name') || {})
            .value;
          return <Link to={`/users/${id}`}>{name || '-'}</Link>;
        }
      },
      {
        title: t.account_permissions,
        render: ({ groups }) => {
          const shopGroups = groups.reduce((reducer, { shop, name }) => {
            if (!shop) return reducer;
            const { title } = shop || {};
            let container = [];
            if (reducer[title]) container = reducer[title];
            container.push(name);
            reducer[title] = container;
            return reducer;
          }, {});
          return (
            <span>
              {Object.keys(shopGroups)
                .map(title => `${title}: ${shopGroups[title].join(' ')}`)
                .join('\n')}
            </span>
          );
        }
      },
      {
        title: t.created_at,
        width: 120,
        fieldName: 'created',
        type: 'datetime'
      },
      {
        title: t.updated_at,
        width: 120,
        fieldName: 'updated',
        type: 'datetime'
      }
    ]
  };
  getData = (data = {}) => {
    const { list = [], count = 0 } = data || {};
    return { list, count };
  };
}
