import React from 'react';
import { translate } from 'shared/translate';
import DangerButton from 'components/form/DangerButton';
import CountrySelections from './CountrySelections';
import { client } from 'shared/apollo';
import { REMOVE_QUERY } from 'routes/item_Tax/query';
import { toast } from 'react-toastify';

const TaxesInfo = props => {
  const { isSubmitting, currentId, values, handleChange, setFieldValue } = props;

  const removeItem = async () => {
    try {
      await client.mutate({
        mutation: REMOVE_QUERY,
        variables: {
          id: currentId,
        },
      });
      client.resetStore();
      toast.success(translate.collection_removed_msg);
      props.history.replace('/taxes');
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="card mb-5 shadow">
        <div className="card-header d-flex">
          <h6 className="mb-0 flex-fill">{translate.tax_details}</h6>
          {currentId && (
            <DangerButton
              onClick={removeItem}
              icon="faTrash"
              label={translate.delete}

            />
          )}
        </div>
        <div className="card-body">
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-2 col-form-label">
              {translate.name}
            </label>
            <div className="col-sm">
              <input
                name="name"
                value={values.name}
                onChange={handleChange}
                type="text"
                className="form-control"
                id="name"
                maxLength={255}
                disabled={isSubmitting}
                required={true}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="taxRate" className="col-sm-2 col-form-label">
              稅率
            </label>
            <div className="col-2">
              <div className="input-group">
                <input
                  type="number"
                  name="taxRate"
                  value={values.taxRate}
                  onChange={handleChange}
                  step={0.1}
                  className="form-control"
                  id="taxRate"
                  max={100}
                  disabled={isSubmitting}
                  required={true}
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>

          <CountrySelections
            selectedCountries={values.countries}
            onSelect={country => setFieldValue('countries', [...values.countries, country])}
            onCountriesChanged={countries => setFieldValue('countries', countries)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TaxesInfo;
