import React, { Component } from 'react';
import CardHeader from '../../../components/layout/card/CardHeader';
import CardDescriptionWithToggle from '../../../components/layout/card/CardDescriptionWithToggle';
import { translate } from './../../../shared/translate';
import SingleLineTextField from './../../../components/form/SingleLineTextField';
import SingleCheckbox from './../../../components/form/SingleCheckbox';
import ActionButton from './../../../components/form/ActionButton';
import { ShippingProviderField } from './fields';
import CollapseRegion from './../../../components/form/CollapseRegion';

class ProviderCard extends Component {
  state = {
    showEditForm: false
  };

  render() {
    const {
      icon,
      title,
      description,
      identity,
      secret,
      active
    } = this.props.data;
    const { onUpdate, onSubmit, disable } = this.props;
    return (
      <div className='card'>
        <CardHeader icon={icon} title={translate[title]} />

        <CardDescriptionWithToggle
          description={translate[description]}
          onToggle={val => this.setState({ showEditForm: val })}
        />

        <CollapseRegion show={this.state.showEditForm}>
          <div className='card-body'>
            <form role='form'>
              <fieldset disabled={disable}>
                <SingleCheckbox
                  label={translate.settings_label_credential_activate_service}
                  checked={active}
                  onValueChanged={value =>
                    onUpdate(ShippingProviderField.enable, value)
                  }
                />
                <SingleLineTextField
                  label={translate.settings_label_credential_client_id}
                  value={identity}
                  onValueChanged={value =>
                    onUpdate(ShippingProviderField.id, value)
                  }
                />
                <SingleLineTextField
                  label={translate.settings_label_credential_secret_key}
                  value={secret}
                  onValueChanged={value =>
                    onUpdate(ShippingProviderField.secret, value)
                  }
                />
              </fieldset>
              <div className='text-right'>
                <ActionButton onClick={onSubmit} disabled={disable}>
                  {translate.save}
                </ActionButton>
              </div>
            </form>
          </div>
        </CollapseRegion>
      </div>
    );
  }
}

export default ProviderCard;
