import React from 'react';
import { translate } from 'shared/translate';
import FormPageMaker from '../../components/formPageMaker';
import gql from 'graphql-tag';
import { client } from 'shared/apollo';
import { GET_QUERY, SUBMIT_QUERY } from './query';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    breadcrumb: [
      { name: translate.settings, link: '/settings' },
      {
        name: translate.settings_title_payment_providers,
        link: '/settings/payments',
      },
      { name: translate.stripe },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
      remove: '',
    },
    cards: [
      {
        name: translate.account_details,
        fields: [
          {
            label: ' ',
            type: 'checkbox',
            name: 'active',
            text: translate.activate_services,
          },
          {
            label: translate.stripe_app_id,
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: translate.stripe_secret,
            type: 'text',
            name: 'secret',
            required: true,
            maxLength: 255,
          },
        ],
      },
    ],
  };

  submit = async ({ id, active, identity, secret }) => {
    const {
      gql: { submit },
    } = this.state;
    await client.mutate({
      mutation: submit,
      variables: {
        shopId: localStorage.getItem('shopId'),
        input: {
          id,
          active: !!active,
          platform: 'STRIPE_CREDIT_CARD',
          type: 'PAYMENT',
          identity,
          secret,
          attributes: [],
        },
      },
    });
  };

  getInitialData({ node } = {}) {
    const { credentials = [] } = node || {},
      credential = credentials[0] || {};
    return {
      ...credential,
    };
  }
}
