/**
 *  /src/components/FormHelper.jsx
 *  Useful form sections of React component
 *  created on April-23, 2019, by Tony Day
 */
import React from "react";

/** Form Card React component */
export const Card = ({ children, title , className}) => (
  <div className={`card mb-5 shadow ${className}`}>
    <div className="card-header d-flex">
      <h6 className="mb-0 flex-fill">{title}</h6>
    </div>
    <div className="card-body">
      {children}
    </div>
  </div>
);

export const Row = ({ children }) => (
  <div className="form-group row">
    {children}
  </div>
);

export const FormItem = ({ children, title, tips, inputColClass="col" }) => (
  <Row>
    <label className="col-sm-2 col-form-label text-muted">{title}</label>
    <div className={inputColClass}>
      {children}
      {tips &&
        <p className="form-text small text-muted">
          {tips}
        </p>
      }
    </div>
  </Row>
);