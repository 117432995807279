import React from 'react';
import { Link } from 'react-router-dom';

const GenericForm = ({ breadcrumb = [], className = '', children }) => {
  return (
    <div className={`container-fluid ${className}`}>
      {breadcrumb.length > 0 && (
        <ul className="breadcrumb">
          {breadcrumb.map(({ route, link, name, label }, i) => {
            const text = label || name;
            const to = route || link;
            return (
              <li key={i} className="breadcrumb-item">
                {!!to ? <Link to={to}>{text}</Link> : <span>{text}</span>}
              </li>
            );
          })}
        </ul>
      )}
      {children}
    </div>
  );
};

export default GenericForm;
