import React from 'react';
import { GET_QUERY } from './query';
import { Query } from 'react-apollo';
import { Loading } from 'components/omniwe';
import ItemCustomPageFull from '../item_CustomPageFull';

export default props => {
  return (
    <Query
      query={GET_QUERY}
      variables={{
        id: localStorage.getItem('shopId'),
        offset: {
          limit: 1,
        },
        otherFilter: {
          href: 'homepage',
          active: true,
          display: true,
        },
      }}
    >
      {({ loading, data: { node } = {} }) => {
        if (loading) return <Loading />;
        const { customPages } = node || {};
        const [homePage] = customPages || [],
          { id } = homePage || {};
        return <CustomPageFullGrape grape={true} {...props} match={{ params: { id } }} />;
      }}
    </Query>
  );
};

class CustomPageFullGrape extends ItemCustomPageFull {
  state = {
    ...this.state,
    breadcrumb: [],
  };
  constructor(props) {
    super(props);
    this.submit2 = this.submit;
    this.submit = async (...args) => {
      await this.submit2(...args);
      return false;
    };
  }
}
