import React from 'react';
import FormPageMaker from 'components/formPageMaker';
import { translate } from 'shared/translate';
import { CreditNoteStatus } from 'shared/omniwe-types';
import { GET_QUERY } from './query';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: { prepare: GET_QUERY },
    breadcrumb: [
      { link: '/credit_notes', name: translate.page_title_credit_notes },
      { name: this.state.id || translate.new_refund },
    ],
    cards: [
      {
        name: 'Refund detail',
        fields: [
          {
            label: translate.refund_status,
            type: 'select',
            name: 'status',
            options: Object.keys(CreditNoteStatus).map(key => ({
              label: CreditNoteStatus[key],
              value: key,
            })),
            disabled: true,
            required: true,
          },
          {
            label: translate.amount,
            render: ({ values, handleChange }) => (
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{values.locale}</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={values.invoiceAmount}
                  name={'invoiceAmount'}
                  onChange={handleChange}
                  required={true}
                  disabled={true}
                />
              </div>
            ),
          },
          {
            label: translate.refund_amount,
            render: ({ values, handleChange }) => (
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{values.locale}</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={values.amount}
                  name={'amount'}
                  onChange={handleChange}
                  required={true}
                  disabled={true}
                />
              </div>
            ),
          },
          {
            label: translate.refund_method,
            render: ({ values }) => (
              <span className={'form-control-plaintext'}>
                {translate.paymentPlatforms[values.paymentMethod] || values.paymentMethod}
              </span>
            ),
          },
        ],
      },
    ],
  };
  getInitialData(data) {
    const { node } = data || {},
      {
        invoice: { amount: { amount: invoiceAmount = 0, locale = 'HKD' } = {} } = {},
        paymentMethod,
        status = 'PENDING',
        amount: { amount = 0 } = {},
        createdAt,
        updatedAt,
      } = node || {};

    return {
      status,
      amount,
      invoiceAmount,
      locale,
      paymentMethod,
      createdAt,
      updatedAt,
    };
  }

  renderFormToolBar(initialValues) {
    return null;
  }
}
