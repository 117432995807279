import React from 'react';

export default class ListBase extends React.Component {
  constructor(props) {
    super(props);

    this.offset = {
      skip: 0,
      limit: 20
    };
  }

  next(refetch) {
    this.offset.skip += this.offset.limit;
    this.props.history.push(this.props.match.path, { offset: this.offset });
    refetch();
  }

  prev(refetch) {
    if (this.offset.skip > 0) {
      this.offset.skip -= this.offset.limit;
      this.props.history.push(this.props.match.path, { offset: this.offset });
      refetch();
    }
  }
}
