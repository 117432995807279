import React from 'react';
import { Button } from 'reactstrap';
import Loading from './../../components/loading';

export default ({ children, disabled, loading, onClick, theme, className, type = 'button', ...props }) => {
  if (!theme) {
    theme = 'outline-secondary';
    className = 'shadow border-white text-dark';
  }
  return (
    <Button className={className} color={theme} onClick={onClick} disabled={disabled || loading} type={type} {...props}>
      {loading && <Loading spacing="" size="fa-1x" />}
      {!loading && children}
    </Button>
  );
};
