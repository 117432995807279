// React component of generic list page.
// src/components/ListComponent.jsx
// created on may-10, 2019, by Tony Day
import React from 'react';
import { Query } from 'react-apollo';
import _ from 'lodash';
import * as RS from 'reactstrap';
import ListLoading from './pageComponents/LoadingList';
import PageError from './pageComponents/PageError';
import ListNoData from './pageComponents/ListNoData';
import { PaginationWithDiv } from './pagination';
import { CardHeader } from 'reactstrap';
import Filter from './filter';
import { Link } from 'react-router-dom';
import { translate as t } from '../shared/translate';
import sharedService from '../service/sharedService';

const { Table, CardBody } = RS;

const PageHeader = ({ fields, handleFilter, left }) => {
  return (
    <CardHeader className='text-right d-flex align-items-center bg-white'>
      <div className='flex-fill'>
        <Filter filterChanged={handleFilter} fields={fields} />
      </div>
      {left}
    </CardHeader>
  );
};

/**
 *
 * @param {string} title the page title
 */
export default class ListComponent extends React.Component {
  constructor(props) {
    super(props);

    const {
      title,
      variables,
      getData,
      offset,
      renderItem,
      tableHeader
      // variablesReducer
    } = props;

    this.offset = offset || {
      skip: 0,
      limit: 20
    };
    this.filterInput = {};
    this.otherFilter = {};

    // this.variables = variables || {
    //   offset: this.offset,
    //   filter: this.filterInput,
    //   otherFilter: this.otherFilter
    // };
    // if (variablesReducer) {
    //   variablesReducer(this.variables);
    // }

    this.title = title || '#dev-info#untitled';
    this.getData = getData;
    this.renderItem = renderItem;
    this.tableHeader = tableHeader;
    /** data on current list */
    this.dataItems = null;
  }

  onDataLoaded = items => {
    const { onDataLoaded } = this.props;
    this.dataItems = items;

    if (onDataLoaded) {
      onDataLoaded(this.dataItems);
    }
  };

  variables = () => {
    const { variables, variablesReducer } = this.props;
    const newVariables = variables || {
      offset: this.offset,
      filter: this.filterInput,
      otherFilter: this.otherFilter
    };
    if (variablesReducer) {
      variablesReducer(newVariables);
    }

    return newVariables;
  };

  getFilterObject = fieldValues => {
    return sharedService.buildFilter(fieldValues);
  };

  handleFilter = fieldValues => {
    const filterComposite = this.getFilterObject(fieldValues);
    this.offset = {
      skip: 0,
      limit: 40
    };
    this.filterInput = filterComposite.filter;
    this.otherFilter = filterComposite.otherFilter;
    this.setState({});
  };

  next = refetch => {
    this.offset.skip += this.offset.limit;
    this.setState({});
    // this.props.history.push(this.props.match.path, { offset: this.offset });
    refetch();
  };

  prev = refetch => {
    if (this.offset.skip > 0) {
      this.offset.skip -= this.offset.limit;
      // this.props.history.push(this.props.match.path, { offset: this.offset });
      this.setState({});
      refetch();
    }
  };

  render() {
    const { query, fields, left, listUrl } = this.props;
    return (
      <Query query={query} variables={this.variables()}>
        {({ loading, error, data, refetch }) => {
          if (error) return <PageError errors={error} />;

          const { items, count } = this.getData(data);

          if (!loading) {
            this.onDataLoaded(items);
          }

          return (
            <React.Fragment>
              {fields && (
                <PageHeader
                  handleFilter={this.handleFilter}
                  fields={fields}
                  left={left}
                />
              )}
              <CardBody className='p-0'>
                <Table hover>
                  <this.tableHeader />
                  <tbody>
                    {!loading &&
                      items.map(item => (
                        <this.renderItem
                          key={item.id}
                          {...item}
                          listUrl={listUrl}
                        />
                      ))}
                    {loading && <ListLoading />}
                  </tbody>
                </Table>
              </CardBody>
              {!loading && items.length === 0 && <ListNoData />}
              <PaginationWithDiv
                numberOfCount={count}
                loading={loading}
                offset={this.offset}
                prev={() => this.prev(refetch)}
                next={() => this.next(refetch)}
              />
            </React.Fragment>
          );
        }}
      </Query>
    ); // end return
  } // end render()
} // end class
