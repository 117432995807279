import _ from 'lodash';

function _buildFilter(fieldValues) {
  const isCommonField = fieldName => {
    const commonFieldNames = ['created', 'updated'];
    return _.includes(commonFieldNames, fieldName);
  };

  const generateDatetime = (fieldName, values) => {
    const since = fieldName + 'Since';
    const until = fieldName + 'Until';
    let obj = {
      [since]: null,
      [until]: null
    };

    if (values.since) {
      obj[since] = values.since;
    }

    if (values.until) {
      obj[until] = values.until;
    }

    return obj;
  };

  const generateText = (fieldName, nestedAttr, values) => {
    if (values.text === null) {
      return null;
    }

    if (nestedAttr) {
      return { key: fieldName, value: values.text };
    }

    return { [fieldName]: values.text };
  };

  const generateBool = (fieldName, nestedAttr, values) => {
    if (values.value === undefined || values.value === null) return {};

    let bool = true;
    switch(values.value){
      case 'false':
      case false:
        bool = false;
    }

    if (nestedAttr) {
      return { key: fieldName, value: bool };
    }

    return { [fieldName]: bool };
  };

  const generateOptions = (fieldName, nestedAttr, values) => {
    if (nestedAttr) {
      return { key: fieldName, value: values.value };
    }

    return { [fieldName]: values.value };
  };

  const generateUserComponent = (fieldName, nestedAttr, values) => {
    if (nestedAttr) {
      return { key: fieldName, value: values };
    }
    return { [fieldName]: values };
  };

  let filter = {};
  let otherFilter = {};
  fieldValues.forEach(({ fieldName, nestedAttr, type, values }) => {
    let content;
    switch (type) {
      case 'datetime':
        content = generateDatetime(fieldName, values);
        break;
      case 'text':
        content = generateText(fieldName, nestedAttr, values);
        break;
      case 'bool':
        content = generateBool(fieldName, nestedAttr, values);
        break;
      case 'option':
        content = generateOptions(fieldName, nestedAttr, values);
        break;
      case 'multiple-option':
        content = generateOptions(fieldName, nestedAttr, values);
        break;
      default:
        content = generateUserComponent(fieldName, nestedAttr, values);
        break;
    }

    if (!content) {
      return;
    }

    if (isCommonField(fieldName)) {
      filter = { ...filter, ...content };
    } else if (nestedAttr) {
      // console.log(nestedAttr + " " + fieldName);
      let { attributes = [] } = filter;
      attributes = [...attributes, content];
      filter = { ...filter, attributes };
    } else {
      otherFilter = { ...otherFilter, ...content };
    }
  });

  const obj = { filter, otherFilter };
  console.log("filter obj", obj);
  return obj;
}

export default {
  serviceApplicationCompletable(status) {
    return ['PENDING', 'PROCESSING'].includes(status);
  },
  /**
   * return if the service application could be cancelled.
   * @param {string} status Status of service application
   */
  serviceApplicationCancelable(status) {
    return ['PENDING', 'PROCESSING'].includes(status);
  },
  /**
   * return if the service application could be added a new comment.
   * @param {string} status Status of service application
   */
  serviceApplicationCommentable(status) {
    return ['PENDING', 'PROCESSING'].includes(status);
  },
  /**
   * build list filter and otherFilter objects.
   * @param {object} fieldValues
   */
  buildFilter(fieldValues) {
    return _buildFilter(fieldValues);
  }
};
