import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate } from '../../shared/translate';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { GET_QUERY } from './query';
import { InvoiceStatus } from 'shared/omniwe-types';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [{ name: translate.invoices }],
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.invoice_number,
        render: item => <Link to={{ pathname: '/invoices/' + item.id }}>{item.id}</Link>,
      },
      {
        title: translate.status,
        fieldName: 'status',
        type: 'option',
        headerClassName: 'text-right',
        filter: true,
        render: item => InvoiceStatus[item.status],
        options: Object.keys(InvoiceStatus).map(key => ({
          name: InvoiceStatus[key],
          value: key,
        })),
        sortable: 'status',
      },
      {
        title: translate.bill_to,
        headerClassName: 'text-right',
        render: item => item.buyer.person,
      },
      {
        title: translate.payment_method,
        render: ({ paymentCredential, paymentMethod }) => {
          const { name: credentialName } = paymentCredential || {};
          return credentialName || translate.paymentPlatforms[paymentMethod];
        },
        headerClassName: 'text-right',
        type: 'text',
      },
      {
        title: translate.amount,
        headerClassName: 'text-right',
        render: ({ amount: { amount = 0 } = {} }) => `$ ${(amount - 0).toFixed(2)}`,
        sortable: 'amount',
      },
      {
        title: translate.refunded,
        headerClassName: 'text-right',
        render: ({ refundedAmount: { amount = 0 } = {} }) => `$ ${(amount - 0).toFixed(2)}`,
      },
      {
        width: 120,
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        headerClassName: 'text-right',
        type: 'datetime',
        filter: true,
        sortable: 'createdAt',
      },
      {
        width: 120,
        title: translate.updated_at,
        fieldName: 'updated',
        dataKey: 'updatedAt',
        headerClassName: 'text-right',
        type: 'datetime',
        filter: true,
        sortable: 'updatedAt',
      },
    ],
  };
  getData = ({ node } = {}) => {
    const { invoices = [], invoicesCount = 0 } = node || {};

    const list = invoices.map(item => {
      const { attributes = [], order } = item;
      const { billingAddress = {} } = order || {};
      let { payment_method: [{ value: payment_method = 'MANUAL' } = {}] = [] } = _.groupBy(attributes, 'key');
      return { ...item, buyer: billingAddress || {}, payment_method };
    });
    return { list, count: invoicesCount };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
