import React from 'react';
import { translate } from '../../../shared/translate';
import SearchComboProduct from '../../../components/SearchComboProduct';
import PromisifyModal from '../../../components/promisifyModal';
import { client } from 'shared/apollo';
import gql from 'graphql-tag';

const GET_PRODUCT = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Product {
        name
        variations {
          id
          sku
          price
          quantity
          attributes {
            key
            value
          }
        }
        attributes {
          key
          value
        }
      }
    }
  }
`;

class _CopyModal extends PromisifyModal {
  state = {
    ...this.state,
    selected: undefined,
  };

  onConfirm = async () => {
    const { selected } = this.state;
    if (!selected) throw new Error('');
    const {
      data: { node },
    } = await client.query({
      query: GET_PRODUCT,
      variables: { id: selected.id },
      fetchPolicy: 'network-only',
    });
    return node;
  };

  renderHeader() {
    return translate.copy_variant;
  }
  renderBody() {
    return <SearchComboProduct onChange={selected => this.setState({ selected })} />;
  }
}

export default _CopyModal;
