import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from './_header';
import MenuGroup from './_menuGroup';
import LanguageSelector from '../../../components/languageSelector';

export default class LeftSideBar extends Component {
  state = {
    toggle: true
  };

  toggle = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };

  render() {
    const { toggle } = this.state;
    return (
      <Fragment>
        <div
          className={`h-100 position-md-relative position-fixed d-md-flex ${
            toggle ? '' : 'd-none'
          }`}
          style={{
            zIndex: 101
          }}
        >
          <div
            className={`bg-dark  h-100 d-flex flex-column`}
            style={{ width: 260 }}
          >
            <div>{this.renderHeader()}</div>
            <div
              className={'flex-fill d-flex flex-column'}
              style={{ overflow: 'auto' }}
            >
              <div>{this.renderMenu()}</div>
            </div>
          </div>
        </div>
        {toggle && (
          <div
            onClick={this.toggle}
            className={'h-100 w-100 position-fixed d-md-none bg-dark'}
            style={{ opacity: 0.75, zIndex: 100 }}
          />
        )}
      </Fragment>
    );
  }
  renderHeader() {
    const {
      title,
      identity,
      onHeaderClick,
      location: { pathname }
    } = this.props;
    return (
      <Header
        title={title}
        description={identity}
        onClick={onHeaderClick}
        active={pathname === '/shops'}
      />
    );
  }
  renderMenu() {
    const { menu, onMenuClick, location } = this.props;
    return menu.map((group, i) => (
      <MenuGroup
        key={i}
        {...group}
        onMenuClick={onMenuClick}
        location={location}
      />
    ));
  }
}
LeftSideBar.propTypes = {
  title: PropTypes.string,
  identity: PropTypes.string,
  menu: PropTypes.array,
  location: PropTypes.object,
  onHeaderClick: PropTypes.func,
  onMenuClick: PropTypes.func
};
