import React from 'react';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik } from 'formik';

import TaxesInfo from './TaxesInfo';
import { GET_QUERY, POST_QUERY } from './query.js';
import Loading from '../../components/loading';
import { translate } from '../../shared/translate';
import BreadCrumbs from '../../components/layout/BreadCrumbs';
import Route from '../../components/route';
import { client } from '../../shared/apollo';

const ItemTax = props => {
  const {
    match: { params = {} },
  } = props;

  const currentId = params.id === 'new' ? undefined : params.id;

  const trail = [
    { to: '/taxes', label: translate.taxes },
    { to: null, label: currentId || translate.new_tax },
  ];

  const submit = async values => {
    try {
      const { data: { taxSet: result } = {} } = await client.mutate({
        mutation: POST_QUERY,
        variables: {
          id: currentId,
          input: {
            name: values.name,
            shopId: await localStorage.getItem('shopId'),
            taxRate: values.taxRate,
            countries: values.countries,
            attributes: [],
          },
        },
      });
      toast.success(!currentId ? translate.collection_create_success_msg : translate.collection_update_success_msg);
      if (!currentId) {
        props.history.replace('/taxes/' + result.id, { id: result.id });
        client.resetStore();
      }
    } catch (e) {
      if (e.graphQLErrors) {
        toast.error(e.message);
      }
    }
  };

  return (
    <div className="container-fluid mb-5">
      <BreadCrumbs trail={trail} />

      <Query query={GET_QUERY} variables={{ id: currentId }}>
        {({ client, loading, error: { message } = {}, data, data: { node = {} } = {} }) => {
          if (loading && data !== {}) return <Loading />;

          if (message) {
            return (
              <div>
                <h5>Error :(</h5>
                <p>{message}</p>
                <Route {...props} text={translate.back} />
              </div>
            );
          }

          const { name, taxRate, updatedAt, countries = [] } = node || {};

          return (
            <Formik
              initialValues={{
                name: name,
                taxRate: taxRate,
                countries: countries,
              }}
              onSubmit={values => submit(values)}
            >
              {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <TaxesInfo
                    {...props}
                    currentId={currentId}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                  />

                  <div className="card">
                    <div className="card-body">
                      <Route {...props} text={translate.cancel} disabled={isSubmitting} />
                      <button type="submit" className="btn btn-dark shadow pull-right" disabled={isSubmitting}>
                        {translate.save}
                      </button>
                    </div>
                    {currentId && (
                      <div className="card-footer d-flex justify-content-end">
                        <i className="small text-muted">
                          {translate.updated_at}:{moment(updatedAt).format(' YYYY-MM-DD HH:mm:ss')}
                        </i>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          );
        }}
      </Query>
    </div>
  );
};

export default ItemTax;
