import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query(
    $id: ID!
    $offset: OffsetInput
    $filter: FilterInput
    $otherFilter: InvoiceFilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Shop {
        id
        invoicesCount(otherFilter: $otherFilter, filter: $filter)
        invoices(offset: $offset, otherFilter: $otherFilter, filter: $filter, sorter: $sorter) {
          id
          status
          paymentMethod
          paymentCredential {
            id
            platform
            name
          }
          createdAt
          updatedAt
          refundedAmount {
            amount
          }
          amount {
            amount
          }
          order {
            id
            billingAddress {
              person
            }
          }
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;
