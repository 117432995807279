import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { GET_QUERY } from './query';
import { translate } from 'shared/translate';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    breadcrumb: [
      {
        name: translate.cash_flow_statement,
      },
    ],
    fields: [
      {
        title: translate.device,
        render: ({ deviceId }) => deviceId,
        width: 250,
        sortable: 'deviceId',
      },
      {
        title: translate.remark,
        render: ({ remark }) => remark,
        sortable: 'remark',
      },
      {
        title: translate.label_invoice_amount,
        render: ({ direction, amount: { amount, locale } = {} }) => {
          const isInbound = direction === 'INBOUND';
          return (
            <span className={`${isInbound ? 'text-success' : 'text-danger'}`}>
              {!isInbound && '-'} {locale} {(amount || 0).toFixed(2)}
            </span>
          );
        },
        width: 200,
        align: 'right',
        sortable: 'amount',
      },
      {
        title: translate.created_at,
        fieldName: 'created',
        dataKey: 'createdAt',
        type: 'datetime',
        width: 180,
        align: 'right',
        filter: true,
        sortable: 'createdAt',
      },
    ],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  getData = ({ node } = {}) => {
    return node || {};
  };
}
