import React from 'react';
import _ from 'lodash';
import { Card, CardBody, Table, FormText } from 'reactstrap';
import { H6CardHeader } from '../../../components/omniwe';
import { translate as t } from '../../../shared/translate';
import TableColumns from './TableColumns';
import HeaderActions from './HeaderActions';
import NewItem from './NewItem';

export default class ContentItems extends React.Component {
  constructor(props) {
    super(props);

    const { items = [this.genBlankItem()], shopId, onChange } = this.props;
    this.shopId = shopId;
    this.onChange = onChange;
    this.state = { items };

    this.onChange(items);
  }

  genBlankItem = () => {
    return {
      name: '',
      type: 'TEXT',
      sortIndex: '50',
      required: false,
      display: false,
      description: '',
      options: []
    };
  };

  addNewItem = () => {
    const { items } = this.state;
    const newItems = [...items, this.genBlankItem()];
    this.setState({ items: newItems });
    this.onChange(newItems);
  };
  removeItem = index => {
    console.log('remove item', index);

    const { items } = this.state;
    const newItems = _.cloneDeep(items);
    _.remove(newItems, (x, i) => i === index);
    this.setState({
      items: newItems
    });
    this.onChange(newItems);
  };
  handleItemChange = (e, name, index) => {
    const { items } = this.state;
    const {
      target: { value, type, checked }
    } = e;
    console.log(name, value);
    console.log('index', index);

    let newItems = [...items];
    let item = newItems[index];

    if (type === 'checkbox') {
      item[name] = checked;
    } else {
      item[name] = value;
    }

    this.setState({
      items: newItems
    });
    this.onChange(newItems);
  };

  render() {
    const { items } = this.state;
    const showActionColumn = items.length > 1;
    return (
      <Card>
        <H6CardHeader text={t.promotion_form_items}>
          <HeaderActions onClick={this.addNewItem} />
        </H6CardHeader>
        <CardBody className='p-0'>
          <Table>
            <TableColumns showActionColumn={showActionColumn} />
            <tbody>
              {items.map((item, i) => (
                <NewItem
                  shopId={this.shopId}
                  onChange={(e, name) => this.handleItemChange(e, name, i)}
                  key={i}
                  item={item}
                  showActionColumn={showActionColumn}
                  removeItem={() => this.removeItem(i)}
                />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}
