import React from 'react';
import { Query } from 'react-apollo/index';
import { translate } from '../../shared/translate';
import GenericForm from '../GenericForm';
import { PaginationWithDiv } from '../pagination';
import Filter from '../filter';
import { Link } from 'react-router-dom';
import ItemRenderer from './_itemRenderer';
import sharedService from 'service/sharedService';
import { Icon } from 'components/iconRender';
import QueryParamsWrapper from 'components/QueryParamsWrapper';
import Skeleton from 'react-loading-skeleton';
import PaginationMultipleApproach from 'components/PaginationMultipleApproach';
import { CardFooter } from 'reactstrap';

const defaultOffset = { skip: 0, limit: 20 };
export default class ListPageTemplate extends QueryParamsWrapper {
  state = {
    /* you should overwrite */
    title: '',
    breadcrumb: [],
    gql: {
      get: '',
    },
    createUrl: '',
    fields: [],

    /* only overwrite if you need */
    offset: { ...defaultOffset },
    otherFilter: {},
    filterInput: {},
    sorter: {},
    ready: false,
  };
  refetch = undefined;
  getExtraFetchVariables() {
    return {};
  }

  componentDidMount() {
    this.unZipQueryParams();
  }

  componentDidUpdate(prevProps) {
    Check_Search_Update: {
      const { location: { search = '' } = {} } = this.props,
        { location: { search: _search = '' } = {} } = prevProps;
      if (search !== _search) {
        this.unZipQueryParams();
      }
      break Check_Search_Update;
    }
  }

  unZipQueryParams = () => {
    const {
      skip = 0,
      limit = defaultOffset.limit,
      sorterField,
      sorterOrder,
      createdSince,
      createdUntil,
      updatedSince,
      updatedUntil,
      ...otherFilter
    } = this.getQueryParams();
    this.setState(origin => ({
      ready: true,
      fields: (origin.fields || []).map((field = {}) => {
        if (!field.filter || !field.fieldName) return field;
        const { fieldName } = field;
        switch (fieldName) {
          case 'created':
            if (createdSince || createdUntil) field.defaultValue = { since: createdSince, until: createdUntil };
            break;
          case 'updated':
            if (updatedSince || updatedUntil) field.defaultValue = { since: updatedSince, until: updatedUntil };
            break;
          default:
            field.defaultValue = otherFilter[fieldName] || undefined;
            break;
        }
        field.visible = !!field.defaultValue;
        return field;
      }),
      offset: { ...origin.offset, skip: skip - 0, limit: limit - 0 },
      sorter: { field: sorterField, order: sorterOrder },
      filterInput: { createdSince, createdUntil, updatedSince, updatedUntil },
      otherFilter: {
        ...origin.otherFilter,
        ...Object.keys(otherFilter).reduce(
          (reducer, key) => ({
            ...reducer,
            [key]: otherFilter[key],
          }),
          {},
        ),
      },
    }));
  };

  zipQueryParams = () => {
    const {
      offset: { skip, limit } = {},
      otherFilter = {},
      filterInput: { createdSince, createdUntil, updatedSince, updatedUntil } = {},
      sorter: { field: sorterField, order: sorterOrder } = {},
    } = this.state;
    this.patchQueryParams({
      skip,
      limit,
      sorterField,
      sorterOrder,
      createdSince,
      createdUntil,
      updatedSince,
      updatedUntil,
      ...otherFilter,
    });
  };

  getData = (data = {}) => {
    return { list: [], count: 0 };
  };

  onSortingClick = ({ fieldName, sortable }) => {
    let { sorter } = this.state,
      { field, order } = sorter || {};
    const sortField = typeof sortable === 'string' ? sortable : fieldName;

    if (!!sortField) {
      if (field !== sortField) {
        sorter = { field: sortField, order: 'ASC' };
      } else if (order === 'ASC') {
        sorter = { field: sortField, order: 'DESC' };
      } else if (order === 'DESC') {
        sorter = undefined;
      }
      this.setState({ sorter });
    } else {
      this.setState({ sorter: undefined });
    }
  };

  render() {
    const {
      gql: { get },
      offset = defaultOffset,
      otherFilter = {},
      filterInput = {},
      sorter,
      ready,
    } = this.state;
    if (!ready) return null;
    return (
      <Query
        query={get}
        fetchPolicy={'cache-and-network'}
        onCompleted={() => this.zipQueryParams()}
        variables={{
          offset,
          otherFilter,
          filter: filterInput,
          sorter: sorter && sorter.field ? [sorter] : undefined,
          ...this.getExtraFetchVariables(),
        }}
      >
        {(...args) => this.renderContent(...args)}
      </Query>
    );
  }
  renderContent({ loading, data = {}, refetch = _ => _ } = {}) {
    const { breadcrumb, title } = this.state;
    const { className } = this.props;
    const { list = [], count = 0 } = this.getData(data || {});
    this.refetch = refetch;
    return (
      <GenericForm breadcrumb={breadcrumb} className={className}>
        <div className="card rounded-0 shadow" key="table">
          <div className="card-header text-right d-flex align-items-center bg-white">
            {!!title && <h6 className="my-0 mr-3">{title}</h6>}
            <div className={'flex-fill'}>{this.renderFilter()}</div>
            {this.renderCreateButton()}
          </div>
          <React.Fragment>
            <div className="card-body p-0">
              <table className="table table-hover table-responsive-md mb-0">
                {this.renderColGroup()}
                {this.renderHeader(list)}
                {!!loading && Object.keys(data).length === 0 && <tbody>{this.renderListSkeleton()}</tbody>}
                <tbody>{this.renderList(list, loading)}</tbody>
              </table>
            </div>
            {!loading && list.length === 0 && this.renderNoData()}
          </React.Fragment>
          {this.renderPagination({ loading, data: { list, count }, refetch })}
        </div>
        {this.renderModal({ list, count })}
      </GenericForm>
    );
  }
  renderPagination({ loading, data, refetch }) {
    const { offset = defaultOffset } = this.state;
    return (
      <CardFooter className={'d-flex justify-content-between align-items-center'}>
        <PaginationMultipleApproach
          count={data.count}
          offset={offset}
          setOffset={offset => {
            this.setState({ offset });
            refetch();
          }}
        />
        <span>
          {translate['total']}: {data.count}
        </span>
      </CardFooter>
    );
    return (
      <PaginationWithDiv
        numberOfCount={data.count}
        loading={loading}
        offset={offset}
        prev={() => this.prev(refetch)}
        next={() => this.next(refetch)}
      />
    );
  }
  renderModal() {
    return null;
  }
  renderNoData() {
    return (
      <div className="card-body text-center">
        <p className="lead font-italic text-muted mb-0">{translate.no_data}</p>
      </div>
    );
  }
  renderList(list, loading) {
    const { fields } = this.state;
    return list.map((item, i) => {
      const { id } = item || {};
      return (
        <tr key={id || i}>
          {fields.map(
            ({ column = true, ...field }, i) =>
              column &&
              (loading ? (
                <td key={i}>
                  <Skeleton height={15} />
                </td>
              ) : (
                <ItemRenderer key={i} item={item} field={field} />
              )),
          )}
        </tr>
      );
    });
  }
  renderListSkeleton() {
    const { fields } = this.state;
    return Array(~~(2 + 5 * Math.random()))
      .fill(undefined)
      .map((__, i) => (
        <tr key={i}>
          {fields.map(
            ({ column = true }, i) =>
              column && (
                <td key={i}>
                  <Skeleton height={15} />
                </td>
              ),
          )}
        </tr>
      ));
  }
  renderHeader(list) {
    const { fields } = this.state;
    return (
      <thead>
        <tr>
          {fields.map((field, i) => {
            const { column = true, title, align = 'left', headerClassName = '', sortable } = field;
            return (
              column && (
                <th
                  key={i}
                  className={`position-relative border-top-0 text-truncate text-${align} ${headerClassName}`}
                  style={{
                    verticalAlign: 'baseline',
                  }}
                >
                  {typeof title === 'function' ? title(field, list) : title}
                  {!!sortable &&
                    (() => {
                      const { sorter: { field: sortField, order } = {} } = this.state;
                      const isUp = sortField === sortable && order === 'ASC',
                        isDown = sortField === sortable && order === 'DESC';
                      return (
                        <button
                          type={'button'}
                          onClick={() => this.onSortingClick(field)}
                          className={'btn btn-link btn-sm pr-0'}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className={'d-flex flex-column'}>
                            <Icon
                              icon={'faAngleUp'}
                              size={'xs'}
                              className={`text-dark`}
                              style={{ opacity: isUp ? 1 : 0.25 }}
                            />
                            <Icon
                              icon={'faAngleDown'}
                              size={'xs'}
                              className={`text-dark`}
                              style={{ opacity: isDown ? 1 : 0.25 }}
                            />
                          </div>
                        </button>
                      );
                    })()}
                </th>
              )
            );
          })}
        </tr>
      </thead>
    );
  }
  renderColGroup() {
    const { fields } = this.state;
    return (
      <colgroup>{fields.map(({ column = true, width = '*' }, i) => column && <col key={i} width={width} />)}</colgroup>
    );
  }
  renderFilter() {
    const { fields } = this.state;
    const filterFields = fields.filter(field => field.filter);
    if (filterFields.length === 0) return null;
    return <Filter filterChanged={values => this.handleFilter(values)} fields={filterFields} />;
  }
  renderCreateButton() {
    const { createUrl } = this.state;
    return (
      !!createUrl && (
        <Link to={{ pathname: createUrl }} className="btn btn-light btn-sm">
          <i className="fa fa-plus fa-fw" /> {translate.create}
        </Link>
      )
    );
  }

  /* Copy From ./list.js */
  getFilterObject = fieldValues => {
    return sharedService.buildFilter(fieldValues);
  };
  handleFilter = fieldValues => {
    const filterComposite = this.getFilterObject(fieldValues);
    this.setState({
      offset: {
        skip: 0,
        limit: 20,
      },
      otherFilter: filterComposite.otherFilter,
      filterInput: filterComposite.filter,
    });
  };
  next(refetch) {
    const { offset: { skip, limit } = defaultOffset } = this.state;
    this.setState({
      offset: {
        skip: skip + limit,
        limit,
      },
    });
    refetch();
  }
  prev(refetch) {
    const { offset: { skip, limit } = defaultOffset } = this.state;
    if (skip > 0) {
      this.setState({ offset: { skip: skip - limit, limit } });
      refetch();
    }
  }

  get offset() {
    const { offset = defaultOffset } = this.state;
    return offset;
  }
  set offset(v) {
    this.setState({ offset: v });
  }
  get otherFilter() {
    const { otherFilter = {} } = this.state;
    return otherFilter;
  }
  set otherFilter(v) {
    this.setState({ otherFilter: v });
  }
  get filterInput() {
    const { filterInput = {} } = this.state;
    return filterInput;
  }
  set filterInput(v) {
    this.setState({ filterInput: v });
  }
}
