import React, { Component } from 'react';
import Loading from '../loading';
import { translate } from 'shared/translate';

let inst;
export default class ActivityIndicator extends Component {
  state = {
    show: false,
    msg: ''
  };
  componentDidMount() {
    inst = this;
  }
  componentWillUnmount() {
    inst = null;
  }
  static show(...args) {
    !!inst && inst.show(...args);
  }
  static hide() {
    !!inst && inst.hide();
  }

  show(msg = translate.loading) {
    this.setState({ msg, show: true });
  }
  hide() {
    this.setState({ msg: '', show: false });
  }
  render() {
    const { show, msg } = this.state;
    if (!show) return null;
    return (
      <div
        className={'p-2 rounded'}
        style={{
          backgroundColor: 'rgba(0,0,0,0.25)',
          pointerEvents: 'none',
          position: 'fixed',
          top: '50%',
          left: '50%',
          zIndex: 99999,
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Loading spacing={'m-0'} color={'text-light'} />
        {!!msg && <small className={'text-light mt-2'}>{msg}</small>}
      </div>
    );
  }
}
