import React, { Component } from 'react';
import HorizontalField from './horizontal-field';

class TextAreaField extends Component {
  render() {
    const {
      label,
      value,
      onValueChanged,
      colWidth = null,
      required = false
    } = this.props;

    return (
      <HorizontalField label={label} colWidth={colWidth}>
        <textarea
          className='form-control'
          type='text'
          required={required}
          value={value}
          maxLength={255}
          onChange={e => onValueChanged(e.target.value)}
        />
      </HorizontalField>
    );
  }
}

export default TextAreaField;
