import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const LinkTrail = ({ data: { to, label, last } }) => (
  <li className={breadCrumbClasses(last)}>
    <Link to={to}>{label}</Link>
  </li>
);

const LabelTrail = ({ data: { label, last } }) => (
  <li className={breadCrumbClasses(last)}>{label}</li>
);

const breadCrumbClasses = last => `breadcrumb-item ${last ? 'active' : ''}`;

class BreadCrumbs extends Component {
  render() {
    const { trail } = this.props;
    const lastPathIndex = trail.length - 1;

    return (
      <ol className='breadcrumb'>
        {trail.map((item, index) => {
          const data = { ...item, last: index === lastPathIndex };
          if (item.to) return <LinkTrail key={index} data={data} />;
          else return <LabelTrail key={index} data={data} />;
        })}
      </ol>
    );
  }
}

export default BreadCrumbs;
