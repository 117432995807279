import React from 'react';
import FormRow from 'components/form/FormRow';
import SearchComboCollection from 'components/SearchComboCollection';
import { translate } from 'shared/translate';

export default ({ config = {}, onChange = _ => _, disabled }) => {
  const { collection } = config || {};
  return (
    <div className={'p-3'}>
      <FormRow title={translate.product_collections}>
        <SearchComboCollection
          disabled={disabled}
          selected={collection}
          onChange={collection => {
            config.collection = collection;
            onChange(config);
          }}
        />
      </FormRow>
    </div>
  );
};
