import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Form } from 'formik';

import { translate } from './../../shared/translate';

import DropdownField from './../../components/form/DropdownField';
import ActionButton from './../../components/form/ActionButton';
import SingleDateField from '../../components/form/SingleDateField';

import PropTypes from 'prop-types';
import { toast } from '../../components/omniwe';

class CustomerGroupModal extends Component {
  state = {
    options: [
      { value: 'NORMAL', display: 'Normal' },
      { value: 'VIP', display: 'VIP' },
      { value: 'VVIP', display: 'VVIP' }
    ]
  };

  renderModal = ({ values, handleSubmit, setFieldValue }) => {
    const { onCancel, open } = this.props;
    return (
      <Modal isOpen={open} toggle={onCancel}>
        <ModalHeader className='d-flex align-items-center' toggle={onCancel}>
          {translate.customer_group}
        </ModalHeader>
        <ModalBody>
          <Form>
            <DropdownField
              label={translate.customer_group}
              value={values.customerGroup}
              options={this.state.options}
              onValueChanged={newValue =>
                setFieldValue('customerGroup', newValue)
              }
              labelWidth={4}
              colWidth={8}
            />
            <SingleDateField
              label={translate.expire_on}
              date={values.expiryDate}
              labelWidth={4}
              colWidth={8}
              onValueChanged={date => setFieldValue('expiryDate', date)}
              minDate={new Date()}
            />
          </Form>
          <div className='d-flex justify-content-between'>
            <ActionButton theme='btn-light' onClick={onCancel}>
              {translate.cancel}
            </ActionButton>
            <ActionButton theme='btn-primary' onClick={handleSubmit}>
              {translate.save}
            </ActionButton>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{
          customerGroup: this.props.currentGroup,
          expiryDate: this.props.currentExpiryDate
        }}
        onSubmit={values => toast.error(translate.coming_soon)}
        render={props => this.renderModal(props)}
      />
    );
  }
}

CustomerGroupModal.propTypes = {
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  currentGroup: PropTypes.string,
  currentExpiryDate: PropTypes.string
};

CustomerGroupModal.defaultProps = {
  onCancel: () => console.warn('onCancel not provided'),
  open: false,
  currentGroup: null,
  currentExpiryDate: null
};

export default CustomerGroupModal;
