import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query(
    $id: ID!
    $offset: OffsetInput
    $filter: FilterInput
    $otherFilter: DiscountFilterInput
    $sorter: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Shop {
        id
        discountsCount(filter: $filter, otherFilter: $otherFilter)
        discounts(offset: $offset, filter: $filter, otherFilter: $otherFilter, sorter: $sorter) {
          id
          name
          publishAt
          publishThru
          valid
          repeat
          createdAt
          updatedAt
        }
      }
    }
  }
`;
