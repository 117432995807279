import React from 'react';
import FormPageMaker from '../../components/formPageMaker';
import { translate } from 'shared/translate';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import CustomerGroupModal from './_customerGroupModal';
import ActionButton from '../../components/form/ActionButton';
import OrderTable from './_orderTable';
import { client } from 'shared/apollo';
import { toast } from 'components/omniwe';
import errorParser from 'shared/errorParser';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on User {
        credentials {
          platform
          identity
        }
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const POST_QUERY = gql`
  mutation userUpdate($input: UserInput!, $id: ID!) {
    userUpdate(input: $input, id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;

const REMOVE_QUERY = gql`
  mutation userSuspend($id: ID!) {
    userSuspend(id: $id) {
      id
    }
  }
`;

const RESET_PASSWORD_QUERY = gql`
  mutation($identity: String!) {
    requestPasswordReset(identity: $identity)
  }
`;

export default class extends FormPageMaker {
  state = {
    ...this.state,
    breadcrumb: [
      { link: '/customers', name: translate.customer_details },
      { name: this.state.id || translate.new_customer },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    cards: [
      {
        name: translate.customer_details,
        fields: [
          {
            label: translate.avatar,
            render: ({ values }) => <img src={values.thumbnail} className={`rounded-circle`} alt="" />,
          },
          {
            label: translate.email,
            type: 'email',
            name: 'identity',
          },
          {
            label: translate.customer_group,
            render: () => (
              <ActionButton
                theme="btn-light"
                onClick={() =>
                  this.setState({
                    showCustomerGroupModal: !this.state.showCustomerGroupModal,
                  })
                }
              >
                {translate.change_group}
              </ActionButton>
            ),
          },
          {
            label: translate.password,
            render: ({ values }) => (
              <ActionButton
                theme="btn-light"
                onClick={async () => {
                  try {
                    await client.mutate({
                      mutation: RESET_PASSWORD_QUERY,
                      variables: { identity: values.identity },
                    });
                    toast.success(translate.formatString(translate['reset_password_msg'], values.identity));
                  } catch (e) {
                    toast.error(errorParser(e));
                  }
                }}
              >
                {translate.reset_password}
              </ActionButton>
            ),
          },
          {
            label: translate.name,
            type: 'text',
            name: 'name',
          },
          {
            label: translate.screen_name,
            type: 'text',
            name: 'nickname',
          },
          {
            label: translate.sex,
            type: 'text',
            name: 'gender',
          },
          {
            label: translate.country,
            type: 'text',
            name: 'country',
          },
          {
            label: translate.city,
            type: 'text',
            name: 'city',
          },
          {
            label: translate.province,
            type: 'text',
            name: 'province',
          },
          {
            label: translate.contact_number,
            type: 'text',
            name: 'tel',
          },
          {
            label: translate.birthday,
            type: 'date',
            name: 'birth',
          },
          {
            label: translate.email,
            type: 'email',
            name: 'email',
          },
        ],
      },
      {
        render: () => !!this.state.id && <OrderTable userId={this.state.id} className={'mb-0 p-0'} />,
      },
    ],
    showCustomerGroupModal: false,
  };

  submit = async values => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const attributes = [
      'customer_group',
      'email',
      'name',
      'nickname',
      'tel',
      'birth',
      'thumbnail',
      'country',
      'province',
      'city',
      'gender',
      'language',
    ]
      .map(key => ({ key, value: values[key] }))
      .filter(kv => !!kv.value);

    const variables = { id, input: { attributes } };

    await client.mutate({
      mutation: submit,
      variables,
    });
  };

  getInitialData(data) {
    const { node } = data || {},
      { attributes = [], credentials = [], updatedAt } = node || {};

    let attrs = _.groupBy(attributes, 'key');
    const {
      name: [{ value: name = '' } = {}] = [],
      customer_group: [{ value: customer_group = '' } = {}] = [],
      email: [{ value: email = '' } = {}] = [],
      nickname: [{ value: nickname = '' } = {}] = [],
      tel: [{ value: tel = '' } = {}] = [],
      birth: [{ value: birth = '' } = {}] = [],
      thumbnail: [{ value: thumbnail = '' } = {}] = [],
      country: [{ value: country = '' } = {}] = [],
      province: [{ value: province = '' } = {}] = [],
      city: [{ value: city = '' } = {}] = [],
      gender: [{ value: gender = '' } = {}] = [],
      language: [{ value: language = '' } = {}] = [],
    } = attrs;

    const { identity = '' } = _.find(credentials, { platform: 'PASSWORD' }) || {};

    return {
      identity,
      name,
      customer_group,
      email,
      nickname,
      tel,
      birth,
      thumbnail,
      country,
      province,
      city,
      gender,
      language,
      updatedAt,
    };
  }

  renderOthers(data, { values }) {
    const { showCustomerGroupModal } = this.state;
    return (
      <CustomerGroupModal
        currentGroup={values.customer_group}
        open={showCustomerGroupModal}
        onCancel={() => this.setState({ showCustomerGroupModal: false })}
      />
    );
  }
}
