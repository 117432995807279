import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

import _ from 'lodash';
import moment from 'moment';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Invoice {
        order {
          id
          shop {
            id
            title
          }
          attributes {
            key
            value
          }
        }
        items {
          orderItem {
            id
            description
            remarks
            productVariant {
              id
              attributes {
                key
                value
              }
              product {
                id
                name
              }
            }
            quantity
            unitPrice {
              amount
              locale
            }
          }
          quantity
        }
        paymentMethod
        attributes {
          key
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export default class Login extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Query query={GET_QUERY} variables={{ id: this.props.match.params.id }}>
        {({ loading, data, data: { node = {} } = {} }) => {
          if (loading) return <div />;

          const { id, items = [], order = {}, createdAt } = node;

          const { attributes: orderAttributes = [], shop: { title: shopTitle } = {} } = order;

          let OrderAttrs = _.groupBy(orderAttributes, 'key');
          const { receiver: [{ value: receiver } = {}] = [] } = OrderAttrs;

          const {
            lines: billLines = [],
            country: billCountry,
            email: billEmail,
            fax: billFax,
            tel: billTel,
            person: billPerson,
          } = JSON.parse(receiver || '{}');

          const { match: { path } = {} } = this.props;

          const hasPrice = path === '/invoice_print_view_with_price/:id?';

          return (
            <div className="container mt-5">
              <h1>
                Invoice
                <span className="pull-right border border-dark pl-4 pr-4">
                  <small>{shopTitle}</small>
                </span>
              </h1>

              <table className="dn" width="100%">
                <tbody>
                  <tr>
                    <td width="130" valign="top" nowrap="nowrap">
                      <strong>Invoice No.</strong>
                    </td>
                    <td width="555" valign="top">
                      {id}
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" nowrap="nowrap">
                      <strong>Invoice Date</strong>
                    </td>
                    <td valign="top">{moment(createdAt).format('YYYY-MM-DD')}</td>
                  </tr>
                  <tr>
                    <td valign="top" nowrap="nowrap">
                      <strong>Company</strong>
                    </td>
                    <td valign="top">{shopTitle}</td>
                  </tr>
                  <tr>
                    <td valign="top" nowrap="nowrap">
                      <strong>Attn</strong>
                    </td>
                    <td valign="top">{billPerson}</td>
                  </tr>
                  <tr>
                    <td valign="top" nowrap="nowrap">
                      <strong>Delivery Address</strong>
                    </td>
                    <td valign="top">{billLines.join(', ')}</td>
                  </tr>
                </tbody>
              </table>

              <div className="mt-4">
                <table className="table table-borderless table-striped">
                  <col width="50" />
                  <col width="*" />
                  <col width="50" />
                  <col width="80" />
                  <col width="80" />
                  <thead className="thead-white">
                    <tr>
                      <th className="border-0" />
                      <th className="border-0">Product Name</th>
                      <th className="text-right border-0">Quantity</th>
                      <th className="text-right border-0">Price</th>
                      <th className="text-right border-0">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map(({ orderItem, quantity }, index) => {
                      const { description, remarks, productVariant, unitPrice: { amount = 0 } = {} } = orderItem,
                        { attributes = [], product: { name } = {} } = productVariant || {};

                      const variantNames = attributes
                        .filter(({ value }) => !!value && value !== '-')
                        .map(({ key, value }) => `${key}: ${value}`)
                        .concat((remarks || '').split('\n'))
                        .join(' / ')
                        .trim();
                      const productName = !productVariant ? description : name;
                      return (
                        <tr>
                          <td>{index + 1}.</td>
                          <td>
                            <div>{productName}</div>
                            <div className={'text-muted small'}>{variantNames}</div>
                          </td>
                          <td className="text-right">{quantity}</td>
                          <td className="text-right">{(amount || 0).toFixed(2)}</td>
                          <td className="text-right">{(amount * quantity || 0).toFixed(2)}</td>
                        </tr>
                      );
                    })}
                    {hasPrice && (
                      <tr>
                        <td colSpan={99} className="text-right">
                          {items
                            .reduce((prev, { orderItem, quantity }) => {
                              const { unitPrice: { amount = 0 } = {} } = orderItem;
                              return (prev += amount * quantity);
                            }, 0)
                            .toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="mt-5">
                <p>Received By:</p>
                <p>Name:</p>
                <p>Date:</p>
                <h4 className="mt-4">
                  <img
                    height={25}
                    width={`auto`}
                    className={`align-baseline`}
                    src={require('../../assets/src_omniwe-logo.svg')}
                  />{' '}
                  OmniWe
                </h4>
                {/*<p className="mb-0">*/}
                {/*Unit 6D, Mercantile Industrial &amp; Warehouse Building, 16-24 Ta Chuen Ping Street, Kwai Chung, Hong*/}
                {/*Kong*/}
                {/*</p>*/}
                {/*<p>Tel: (852) 2545 0293  &nbsp;&nbsp; Fax: (852) 3547 9293  &nbsp;&nbsp; E-mail: info@omnidis.com</p>*/}
                <p>Copyright © omniwe.com All Rights Reserved</p>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
