import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { translate } from '../../shared/translate';
import { toast } from 'react-toastify';
import errorParser from '../../shared/errorParser';
import { gql } from 'apollo-boost';
import { client } from '../../shared/apollo';

const CHECK_PASSWORD = gql`
  mutation sessionCreate($identity: String!, $secret: String!) {
    sessionCreate(identity: $identity, secret: $secret)
  }
`;

const UPDATE_PASSWORD = gql`
  mutation userCredentialSet($userId: ID!, $credential: UserCredentialInput!) {
    userCredentialSet(userId: $userId, credential: $credential) {
      id
    }
  }
`;

export default class ChangePasswordModal extends Component {
  state = {
    toggle: false
  };
  toggle = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };
  onSubmit = async (values, { setSubmitting }) => {
    const { identity, userId } = this.props;
    const { currentPassword, newPassword, confirmPassword } = values;
    try {
      await this.checkPassword({ identity, secret: currentPassword });

      if (newPassword !== confirmPassword)
        throw new Error(translate.new_password_fail_msg);

      await client.mutate({
        mutation: UPDATE_PASSWORD,
        variables: {
          userId,
          credential: {
            platform: 'PASSWORD',
            identity,
            secret: newPassword
          }
        }
      });
      this.toggle();
      toast.success(translate.password_update_success_msg);
    } catch (e) {
      toast.error(errorParser(e));
    }
  };
  checkPassword = async ({ identity, secret }) => {
    try {
      await client.mutate({
        mutation: CHECK_PASSWORD,
        variables: {
          identity,
          secret
        }
      });
    } catch (e) {
      throw new Error(translate.current_password_fail_msg);
    }
  };
  render() {
    const { toggle } = this.state;
    return (
      <Fragment>
        <button
          className='btn btn-light btn-sm'
          type='button'
          onClick={this.toggle}
        >
          {translate.change_password}
        </button>
        <Modal isOpen={toggle} size={'sm'} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {translate.change_password}
          </ModalHeader>
          <ModalBody>
            <Formik
              onSubmit={this.onSubmit}
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
              }}
            >
              {({ handleSubmit, handleChange, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <input
                      className='form-control'
                      type='password'
                      placeholder={translate.login_password}
                      name={'currentPassword'}
                      value={values.currentPassword}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      className='form-control'
                      type='password'
                      placeholder={translate.new_password}
                      name={'newPassword'}
                      value={values.newPassword}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      className='form-control'
                      type='password'
                      placeholder={translate.re_enter_password}
                      name={'confirmPassword'}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                  <div className='row'>
                    <div className='col text-right'>
                      <button
                        type='button'
                        onClick={this.toggle}
                        className='btn btn-outline-secondary text-dark border-0 shadow'
                      >
                        {translate.cancel}
                      </button>
                      <button type='submit' className='btn btn-primary ml-2'>
                        {translate.save}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}
ChangePasswordModal.propTypes = {
  userId: PropTypes.string,
  identity: PropTypes.string
};
