import React from 'react';
import {gql} from "apollo-boost";
import _ from "lodash";
import {translate as t} from "shared/translate";
import {Query} from "react-apollo";
import * as FormHelper from "components/FormHelper";
import * as pageUtility from "components/pageComponents/utility";

const _agentBindingPanel = ({ getRef, selectedAgentId }) => {
  const query = gql`
    query($offset: OffsetInput) {
      admin {
        agents(offset: $offset) {
          id
          attributes {
            key
            value
          }
        }
      }
    }
  `;
  const offset = {
    offset: {
      skip: 0,
      limit: 999,
    },
  };
  const getName = agentItem => {
    const found = _.find(agentItem.attributes, { key: 'name' });
    if (found) {
      return found.value;
    }
    return agentItem.id;
  };

  const _select = ({ items }) => (
    <select ref={getRef} className="form-control" defaultValue={selectedAgentId}>
      <option value="">{t.please_select}</option>
      {items.map((item, i) => (
        <option key={i} value={item.id}>
          {getName(item)}
        </option>
      ))}
    </select>
  );
  return (
    <Query query={query} variables={offset}>
      {({ loading, error, data: { admin: { agents } = {} } }) => {
        return (
          <FormHelper.Card title={t.shop_agent_binding} className="h-100">
            <FormHelper.FormItem title={t.shop_valid_agent_list} tips={t.shop_agent_binding_tips}>
              {loading && <pageUtility.Spinner />}
              {error && <pageUtility.Error errors={error} />}
              {!loading && agents && <_select items={agents} />}
            </FormHelper.FormItem>
          </FormHelper.Card>
        );
      }}
    </Query>
  );
};
export default _agentBindingPanel;