import React from 'react';
import { translate } from 'shared/translate';
import FormPageMaker from '../../components/formPageMaker';
import gql from 'graphql-tag';
import { client } from 'shared/apollo';
import { GET_QUERY, SUBMIT_QUERY } from './query';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    breadcrumb: [
      { name: translate.settings, link: '/settings' },
      {
        name: translate.settings_title_payment_providers,
        link: '/settings/payments',
      },
      { name: translate.china_smartpay },
    ],
    gql: {
      prepare: GET_QUERY,
      submit: SUBMIT_QUERY,
      remove: '',
    },
    cards: [
      {
        name: translate.activate_services,
        fields: [
          {
            label: translate.wechat_pay_hk,
            type: 'checkbox',
            name: 'active_wechat_pay_hk',
            text: translate.activate_checkbox,
          },
          {
            label: translate.alipay_hk,
            type: 'checkbox',
            name: 'active_alipay_hk',
            text: translate.activate_checkbox,
          },
        ],
      },
      {
        name: translate.account_details,
        fields: [
          {
            label: translate.china_smartpay_app_id,
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: translate.china_smartpay_secret,
            type: 'textarea',
            name: 'secret',
            required: true,
          },
          {
            label: translate.china_smartpay_login_id,
            type: 'text',
            name: 'login_id',
            required: true,
            maxLength: 255,
          },
          {
            label: translate.china_smartpay_login_pwd,
            type: 'text',
            name: 'login_pwd',
            required: true,
            maxLength: 255,
          },
        ],
      },
    ],
  };

  submit = async ({
    wechat = {},
    alipay = {},
    active_wechat_pay_hk,
    active_alipay_hk,
    login_id,
    login_pwd,
    identity,
    secret,
  }) => {
    const attributes = [
      {
        key: 'login_id',
        value: login_id,
      },
      {
        key: 'login_pwd',
        value: login_pwd,
      },
    ];

    const {
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        shopId: localStorage.getItem('shopId'),
        input: {
          id: wechat.id,
          name: '',
          active: !!active_wechat_pay_hk,
          platform: 'CHINA_SMARTPAY_WECHAT_PAY_HK',
          type: 'PAYMENT',
          identity,
          secret,
          attributes,
        },
      },
    });
    await client.mutate({
      mutation: submit,
      variables: {
        shopId: localStorage.getItem('shopId'),
        input: {
          id: alipay.id,
          name: '',
          active: !!active_alipay_hk,
          platform: 'CHINA_SMARTPAY_ALIPAY_HK',
          type: 'PAYMENT',
          identity,
          secret,
          attributes,
        },
      },
    });
  };

  getInitialData({ node } = {}) {
    const { credentials = [] } = node || {};

    const credential_wechat_pay_hk = credentials.find(cred => cred.platform === 'CHINA_SMARTPAY_WECHAT_PAY_HK') || {},
      credential_alipay_hk = credentials.find(cred => cred.platform === 'CHINA_SMARTPAY_ALIPAY_HK') || {};
    const { attributes = [] } = credential_wechat_pay_hk;
    const login_id = (attributes.find(attr => attr.key === 'login_id') || {}).value,
      login_pwd = (attributes.find(attr => attr.key === 'login_pwd') || {}).value;

    const { identity, secret } = credential_wechat_pay_hk || {};

    return {
      wechat: credential_wechat_pay_hk,
      alipay: credential_alipay_hk,
      active_wechat_pay_hk: credential_wechat_pay_hk.active,
      active_alipay_hk: credential_alipay_hk.active,
      identity,
      secret,
      login_id,
      login_pwd,
    };
  }
}
