import { gql } from 'apollo-boost';

export const GET_QUERY = gql`
  query($id: ID!, $offset: OffsetInput, $filter: FilterInput, $otherFilter: OrderFilterInput, $sorter: [SorterInput!]) {
    node(id: $id) {
      id
      ... on Shop {
        id
        orders(offset: $offset, otherFilter: $otherFilter, filter: $filter, sorter: $sorter) {
          id
          status
          referenceNo
          shipment {
            provider
          }
          total {
            amount
          }
          billingAddress {
            person
            tel
            email
            lines
            country
          }
          shippingAddress {
            person
            tel
            email
            lines
            country
          }
          attributes {
            key
            value
          }
          createdAt
          updatedAt
        }
        ordersCount(filter: $filter, otherFilter: $otherFilter)
      }
    }
  }
`;
