// 中介服務申請列表-服務條目信息（中介查看）
// src/routers/agent-application/service_info.jsx
// created on may-10, 2019, by Tony Day
import React from 'react';
import { translate } from '../../shared/translate';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import _ from 'lodash';
import * as RS from 'reactstrap';
import * as PU from '../../components/pageComponents/utility';
import { BreadcrumbProxy as Breadcrumb } from '../../components/pageComponents/CrumbNavi';
const { Card, CardBody, CardHeader, Col, Label, FormGroup } = RS;

const GET_QUERY = gql`
  # QUERY MARKET SERVICE DETAILS
  query($id: ID) {
    node(id: $id) {
      id
      createdAt
      updatedAt
      ... on AgentService {
        title
        active
        description
        applicationsCount
      }
      attributes {
        key
        value
      }
    }
  }
`;

export default class ServiceInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  updateBreadcrumbItems = node => {
    let items = [
      {
        url: '/agent_service',
        text: translate.agent_service_list
      },
      {
        url: '/agent_service/' + node.id,
        text: node.title
      },
      translate.all_applications
    ];

    Breadcrumb.update(items);
  };

  render() {
    const { id } = this.props;
    return (
      <Query query={GET_QUERY} variables={{ id }}>
        {({ loading, error, data: { node = {} } }) => {
          if (node && node.id) {
            this.updateBreadcrumbItems(node);
          }

          return (
            <React.Fragment>
              <Breadcrumb />
              <Card>
                <CardHeader>{translate.agent_service_details}</CardHeader>
                {loading && <PU.Loading />}
                {error && <PU.PageError errors={error} />}
                {!loading && node && (
                  <CardBody>
                    <FormGroup row>
                      <Col sm={1}>
                        <Label>{translate.name}</Label>
                      </Col>
                      <Col>{node.title}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={1}>
                        <Label>{translate.description}</Label>
                      </Col>
                      <Col>
                        {!node.description && <label>-</label>}
                        {node.description && (
                          <PU.Html content={node.description} />
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={1}>
                        <Label>{translate.opening}</Label>
                      </Col>
                      <Col>
                        <PU.Activation active={node.active} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={1}>
                        <Label>{translate.agent_applied_times}</Label>
                      </Col>
                      <Col>{node.applicationsCount}</Col>
                      <Col sm={1} />
                      <Col />
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={1}>
                        <Label>{translate.created_at}</Label>
                      </Col>
                      <Col>
                        <PU.DateTime datetime={node.createdAt} />
                      </Col>
                      <Col sm={1}>
                        <Label>{translate.updated_at}</Label>
                      </Col>
                      <Col>
                        <PU.DateTime datetime={node.updatedAt} />
                      </Col>
                    </FormGroup>
                  </CardBody>
                )}
              </Card>
            </React.Fragment>
          );
        }}
      </Query>
    );
  } // end render()
} // end class
