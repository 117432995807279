import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { translate } from '../../shared/translate';

class NewInvoiceLink extends Component {
  showLink = disabled =>
    disabled ? { display: 'none' } : { display: 'block' };

  render() {
    const { node, disabled } = this.props;
    return (
      <div style={this.showLink(disabled)}>
        <Link
          to={{
            pathname: '/invoices/new',
            state: { order: node }
          }}
          className='btn btn-light btn-sm'
        >
          <i className='fa fa-plus fa-fw' /> {translate.new_invoice}
        </Link>
      </div>
    );
  }
}

export default NewInvoiceLink;
