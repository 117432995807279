import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on CreditNote {
        invoice {
          id
          amount {
            amount
            locale
          }
        }
        status
        amount {
          amount
          locale
        }
        paymentMethod
        createdAt
        updatedAt
      }
    }
  }
`;
