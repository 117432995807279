import React, { Component, Fragment } from 'react';
import { translate } from '../../../shared/translate';
import StockItemModal from './_stockItemModal';
import md5 from 'md5';
import PriceCalculateModal from 'routes/item_Product/_multiSKUCard/_priceCalculateModal';

export default class extends Component {
  onSKUChange = (e, i) => {
    const { variations, onVariationsChange } = this.props;
    variations[i].sku = e.target.value;
    onVariationsChange && onVariationsChange(variations);
  };
  onPriceChange = (e, i) => {
    const { variations, onVariationsChange } = this.props;
    variations[i].price = e.target.value;
    onVariationsChange && onVariationsChange(variations);
  };
  onQuantityChange = (e, i) => {
    const { variations, onVariationsChange } = this.props;
    variations[i].quantity = e.target.value;
    onVariationsChange && onVariationsChange(variations);
  };
  onAutoSKUClick = async () => {
    const { productName, variations, onVariationsChange } = this.props;
    variations.forEach(variation => {
      variation.sku = md5(`${productName}-${variation.attributes.map(attr => `${attr.key}:${attr.value}`).join(',')}`);
    });
    onVariationsChange(variations);
  };
  onPriceCalculateClick = async () => {
    const { variations, onVariationsChange } = this.props;
    try {
      onVariationsChange && onVariationsChange(await this.priceCalculateModal.execute(variations));
    } catch (e) {}
  };

  render() {
    const { variations, onVariationsChange, isSubmitting } = this.props;
    return (
      <Fragment>
        <table className="table">
          <colgroup>
            <col width="200" />
            <col width="*" />
            <col width="200" />
            <col width="240" />
          </colgroup>
          <thead>
            <tr>
              <th>
                SKU
                <button
                  type={'button'}
                  className={'btn btn-sm btn-primary ml-2'}
                  disabled={isSubmitting}
                  onClick={this.onAutoSKUClick}
                >
                  {translate.auto}
                </button>
              </th>
              <th>{translate.name}</th>
              <th>
                {translate.price}
                <button
                  type={'button'}
                  className={'btn btn-sm btn-primary ml-2'}
                  disabled={isSubmitting}
                  onClick={this.onPriceCalculateClick}
                >
                  {translate.calculate_price}
                </button>
              </th>
              <th>{translate.stock}</th>
            </tr>
          </thead>
          <tbody>
            {variations.map((variation, i) => {
              const { attributes = [], price = 1, quantity = 1, sku = '', stockItems = [] } = variation;
              return (
                <tr key={i}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={sku}
                      onChange={e => this.onSKUChange(e, i)}
                      required={true}
                      disabled={isSubmitting}
                    />
                  </td>
                  <td>
                    {attributes.map(({ key, value }) => (
                      <span className={'mr-4'}>
                        {key}: {value}
                      </span>
                    ))}
                  </td>
                  <td>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">HKD</div>
                      </div>
                      <input
                        type="number"
                        min={1}
                        className="form-control"
                        value={price}
                        onChange={e => this.onPriceChange(e, i)}
                        required={true}
                        disabled={isSubmitting}
                      />
                    </div>
                  </td>
                  {(stockItems || []).length > 0 && (
                    <td>
                      <button
                        disabled={isSubmitting}
                        type={'button'}
                        className={'btn btn-outline-secondary mr-2'}
                        onClick={async () => {
                          try {
                            variation.stockItems = await this.stockItemModal.execute(stockItems || []);
                            onVariationsChange(variations);
                          } catch (e) {}
                        }}
                      >
                        {translate.edit_stock_items}
                      </button>
                      <button
                        disabled={isSubmitting}
                        type={'button'}
                        className={'btn btn-outline-secondary'}
                        onClick={() => {
                          variation.stockItems = [];
                          onVariationsChange(variations);
                        }}
                      >
                        {translate.use_quantity}
                      </button>
                    </td>
                  )}
                  {(stockItems || []).length === 0 && (
                    <td>
                      <div className={'d-flex'}>
                        <input
                          disabled={isSubmitting}
                          type="number"
                          step="any"
                          className={'form-control'}
                          value={quantity}
                          onChange={e => this.onQuantityChange(e, i)}
                          required={true}
                        />
                        <button
                          disabled={isSubmitting}
                          type={'button'}
                          className={'btn btn-outline-secondary ml-2'}
                          onClick={async () => {
                            try {
                              variation.stockItems = await this.stockItemModal.execute(stockItems);
                              onVariationsChange(variations);
                            } catch (e) {}
                          }}
                        >
                          {translate.use_stock_items}
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <StockItemModal ref={ref => (this.stockItemModal = ref)} />
        <PriceCalculateModal ref={ref => (this.priceCalculateModal = ref)} />
      </Fragment>
    );
  }
}
