export const setAttributes = (data = {}, fields = []) => {
  return (Object.keys(data)).reduce((prev, key) => {
    if (fields.indexOf(key) >= 0) {
      if (!data[key] || data[key] === '') {
        return prev;
      }

      return [
        ...prev,
        {
          key,
          value: data[key]
        }
      ]
    } else {
      return prev;
    }
  }, [])
};

export function JSONParseSafely(json, fallback = {}) {
  try {
    if (json === undefined) return fallback;
    if (typeof json === "string") return JSON.parse(json);
  } catch (e) {
    return fallback;
  }
}