import React from 'react';
import ListPageMaker from '../../components/listPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { Link } from 'react-router-dom';

export default class extends ListPageMaker {
  state = {
    ...this.state,
    breadcrumb: [
      {
        name: translate.dm_plan,
      },
    ],
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/dm_plans/new',
    offset: { limit: 999, skip: 0 },
    fields: [
      {
        title: translate.name,
        render: ({ id, name }) => <Link to={`/dm_plans/${id}`}>{name}</Link>,
      },
    ],
  };
  getData = ({ node } = {}) => {
    const { list = [] } = node || {};
    return { list, count: list.length };
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
}
