import gql from 'graphql-tag';
import { client } from 'shared/apollo';

const IMG_UPLOAD = gql`
  mutation imageUpload($file: Upload!, $temporary: Boolean) {
    imageUpload(file: $file, temporary: $temporary)
  }
`;

export default async (file, temporary = true) => {
  const {
    data: { imageUpload },
  } = await client.mutate({
    mutation: IMG_UPLOAD,
    variables: {
      file,
      temporary,
    },
  });
  return imageUpload;
};
